import React from "react";
import { withTranslation } from "react-i18next";

// TODO: make some better way to get description here
function getDescription(plan) {
  switch (plan.id) {
    case 1:
      return "subscription.descriptionYearly";
    case 2:
      return "subscription.descriptionMonthly";
    default:
      if (plan.category === "monthly") {
        return "subscription.descriptionMonthly";
      } else if (plan.category === "yearly") {
        return "subscription.descriptionYearly";
      }
      return "subscription.descriptionMonthly";
  }
}

function SubscriptionPlan(props) {
  const { plan, t, onSubscribe } = props;
  const description = getDescription(plan);
  if (plan.category === "yearly") {
    plan.name = "AgiNotes Premium " + t("subscription.yearly");
  }
  if (plan.category === "monthly") {
    plan.name = "AgiNotes Premium " + t("subscription.monthly");
  }
  return (
    <div style={{ padding: "20px" }}>
      <h2>
        {`${plan.name}, € ${plan.price_per_month}`}
        <span className="text-grey">/{t("subscription.month")}</span>
      </h2>
      <p>{t(description)}</p>
      <button
        className="btn btn-primary subscription-container__upgrade"
        onClick={() => onSubscribe(plan)}
      >
        {t("subscription.upgradeNow")}
      </button>
    </div>
  );
}

const translatedPlan = withTranslation()(SubscriptionPlan);
export default translatedPlan;
