import React, { Component } from "react";
import PropTypes from "prop-types";

class TextArea extends Component {
  state = {
    wasValidated: false
  };

  render() {
    const { label, error, value, type, ...rest } = this.props;
    return (
      <div className={this.state.wasValidated ? "was-validated" : ""}>
        {label !== "none" && <label>{label}</label>}
        {type === "text" ? (
          <textarea
            className="form-control"
            //onBlur={() => this.setState({ wasValidated: true })}
            value={value || ""}
            {...rest}
          />
        ) : (
          <textarea
            className="form-control"
            onBlur={() => this.setState({ wasValidated: true })}
            value={value || ""}
            {...rest}
          />
        )}
        <div className="invalid-feedback">{error}</div>
      </div>
    );
  }
}

TextArea.propTypes = {
  label: PropTypes.any,
  type: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  error: PropTypes.string
};

export default TextArea;
