import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import moment from "moment";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

class PaywallNotification extends Component {
  render() {
    const { t, hasActivePlan, text, button } = this.props;

    if (hasActivePlan) return null;

    return (
      <div className="alert alert-warning">
        {text && <p>{t(text)}</p>}
        <Link to="/subscription">{t(button)}</Link>
      </div>
    );
  }
}

PaywallNotification.propTypes = {
  t: PropTypes.func,
  hasActivePlan: PropTypes.bool,
  text: PropTypes.string,
  button: PropTypes.string,
};

PaywallNotification.defaultProps = {
  text: "paywall.message",
  button: "paywall.button",
};

const mapStateToProps = ({ subscriptions }) => {
  return {
    hasActivePlan:
      subscriptions.currentPlan &&
      moment(subscriptions.currentPlan.paid_until) > moment(),
  };
};

const PaywallNotificationWithTranslate = withTranslation()(PaywallNotification);
export default connect(mapStateToProps)(PaywallNotificationWithTranslate);
