import React, { Component } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { isStaging } from "../config/env";
import { fetchUser } from "../actions";
import { connect } from "react-redux";

import { Container, Avatar, Icon } from "../common";
import SettingsMenu from "../components/SettingsMenu";
import logo from "../images/icons/logo.svg";

import i18n from "../config/i18n";

import moment from "moment";
import "moment/locale/fi";
import "moment/locale/sv";
import "moment/locale/fr";
import "moment/locale/nb";
import "moment/locale/es";
import "moment/locale/de";
import "moment/locale/en-gb";
import "moment/locale/da";
import "moment/locale/it";
import "moment/locale/pt";
import "moment/locale/nl";
import "moment/locale/pl";

class Header extends Component {
  goBack = () => {
    window.history.back();
  };

  handleLogout = () => {
    const { onLogout } = this.props;
    onLogout();
  };

  render() {
    const { actions, language } = this.props;
    const stagingEnv = isStaging === "true";
    if (i18n.language !== language) {
      i18n.changeLanguage(language);
      moment.locale(language);
    }
    return (
      <header className="app-header">
        {stagingEnv ? (
          <div className="info-text text-huge">!!! STAGING !!!</div>
        ) : (
          ""
        )}
        {actions ? this.renderWithActions() : this.renderWithoutActions()}
      </header>
    );
  }

  renderWithActions() {
    const { activeDog, user } = this.props;
    return (
      <Container className="app-header__container" size="large">
        <Link to="/" className="settigs-menu-button" exact>
          <Icon icon="homeWhite" />
        </Link> 
        <Link to="/dogs/">
          <Avatar size="small" image={activeDog.photo} />
        </Link>
        <SettingsMenu user={user} />
      </Container>
    );
  }

  renderWithoutActions() {
    return (
      <Container className="app-header__container">
        <button onClick={this.goBack} className="btn btn-link">
          <Icon icon="chevronLeft" />
        </button>

        <Link to="/" className="app-header__logo">
          <img src={logo} alt="AgiNotes logo" />
        </Link>

        <p />
      </Container>
    );
  }
}

Header.propTypes = {
  actions: PropTypes.bool,
  onLogout: PropTypes.func,
  activeDog: PropTypes.object,
  user: PropTypes.object,
  language: PropTypes.string
};

Header.defaultProps = {
  actions: true
};

//export default Header;
const mapStateToProps = ({ user }) => {
  return {
    user: user.user,
    language: user.user.language
  };
};

export default connect(mapStateToProps, { fetchUser })(Header);