import React, { Component } from "react";
import PropTypes from "prop-types";

import Avatar from "./Avatar";
import RoundButton from "./RoundButton";
import Loader from "./Loader";
import Image from "./Image";
// import { fixFileOrientation } from "../helpers/ImageHelpers";
import { Document, Page, pdfjs } from "react-pdf";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

class ImageUploader extends Component {
  state = {
    file: null,
    imagePreviewUrl: null,
    loading: false
  };

  componentDidMount() {
    const { imageUrl } = this.props;
    if (imageUrl) this.setState({ imagePreviewUrl: imageUrl });
  }

  componentDidUpdate(prevProps) {
    const { imageUrl } = this.props;
    if (prevProps.imageUrl !== imageUrl) {
      this.setState({ imagePreviewUrl: imageUrl });
    }
  }

  handleImageChange = e => {
    e.preventDefault();
    this.setState({ loading: true });

    let reader = new FileReader();
    let file = e.target.files[0];
    const that = this;

    reader.readAsDataURL(file);

    reader.onloadend = () => {
      that.setState({
        file: file,
        imagePreviewUrl: reader.result,
        loading: false
      });
      that.props.onUpload({ file, imagePreviewUrl: reader.result });

      // fixFileOrientation(file, reader.result, imagePreviewUrl => {
      //   function dataURLtoFile(dataurl, filename) {
      //     var arr = dataurl.split(","),
      //       mime = arr[0].match(/:(.*?);/)[1],
      //       bstr = atob(arr[1]),
      //       n = bstr.length,
      //       u8arr = new Uint8Array(n);
      //     while (n--) {
      //       u8arr[n] = bstr.charCodeAt(n);
      //     }
      //     return new File([u8arr], filename, { type: mime });
      //   }

      //   const rotatedFile = dataURLtoFile(imagePreviewUrl, file.name);
      //   that.setState({
      //     file: rotatedFile,
      //     imagePreviewUrl,
      //     loading: false
      //   });
      //   that.props.onUpload({ file: rotatedFile, imagePreviewUrl });
      // });
    };
  };

  triggerUpload = e => {
    e.preventDefault();
    this.uploadInput.click();
  };

  render() {
    return this.props.useAvatar
      ? this.renderWithAvatar()
      : this.renderDefault();
  }

  renderWithAvatar = () => {
    let { imagePreviewUrl, loading } = this.state;

    return (
      <div className="image-uploader-container">
        <div className="image-uploader image-uploader--with-avatar">
          <input
            className="image-uploader__input"
            type="file"
            onChange={this.handleImageChange}
            ref={input => {
              this.uploadInput = input;
            }}
          />
          <RoundButton
            className="image-uploader__add-button"
            onClick={this.triggerUpload}
            size="small"
          />

          <Avatar image={imagePreviewUrl} size="large" loading={loading} />
        </div>
      </div>
    );
  };

  renderDefault = () => {
    let { imagePreviewUrl, loading } = this.state;
    const { buttonLabel } = this.props;

    return (
      <div className="image-uploader-container">
        <div className="image-uploader">
          <input
            className="image-uploader__input"
            type="file"
            onChange={this.handleImageChange}
            ref={input => {
              this.uploadInput = input;
            }}
          />
          <button
            className="btn btn-secondary btn-block"
            onClick={this.triggerUpload}
          >
            {buttonLabel}
          </button>

          {loading && <Loader />}
          {!loading &&
            imagePreviewUrl &&
            imagePreviewUrl.indexOf("data:application/pdf") !== -1 && (
              <Document file={imagePreviewUrl}>
                <Page pageNumber={1} />
              </Document>
            )}
          {!loading &&
            imagePreviewUrl &&
            imagePreviewUrl.indexOf("data:application/pdf") === -1 && (
              <Image
                className=" image-uploader__image"
                src={imagePreviewUrl}
                alt=""
              />
            )}
        </div>
      </div>
    );
  };
}

ImageUploader.propTypes = {
  imageUrl: PropTypes.string,
  onLoad: PropTypes.func,
  useAvatar: PropTypes.bool,
  buttonLabel: PropTypes.string
};

ImageUploader.defaultProps = {
  imageUrl: null,
  onLoad: () => {},
  useAvatar: false,
  buttonLabel: "Upload an image"
};

export default ImageUploader;
