import { FETCH_SETTINGS } from "../actions/types";

const INITIAL_STATE = {
  focusCategories: []
};

export default (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case FETCH_SETTINGS.SUCCEEDED:
      return { ...payload };
    default:
      return state;
  }
};
