import React, { Component } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";

import { RoundButton } from "../common";

const ActionMenuItem = ({ children, to, onClick }) => (
  <li className="action-menu__item">
    <Link to={to} className="btn btn-primary btn-sm" onClick={onClick}>
      {children}
    </Link>
  </li>
);

const SubActionMenuItem = ({ children, to, onClick }) => (
  <li className="action-menu__item">
    <button className="btn btn-primary btn-sm" onClick={onClick}>
      {children}
    </button>
  </li>
);

class ActionMenu extends Component {
  state = {
    open: false,
  };

  toggle = (e) => {
    this.setState((prevState, props) => {
      return {
        open: !prevState.open,
      };
    });
  };

  close = () => {
    this.setState({ open: false });
  };

  render() {
    const { subActions, mainAction } = this.props;
    const toggleState = this.state.open ? "open" : "closed";
    let t = this.props.t;

    return (
      <div
        className={`action-menu-container action-menu-container--${toggleState}`}
      >
        <div
          className={`overlay overlay--${toggleState}`}
          onClick={this.close}
        />

        <ul className={`action-menu action-menu--${toggleState}`}>
          {subActions.map((a) => (
            <SubActionMenuItem
              key={a.label}
              onClick={() => {
                this.close();
                a.onClick();
              }}
            >
              {a.label}
            </SubActionMenuItem>
          ))}
          {(!mainAction || mainAction === "training") && (
            <ActionMenuItem to="/training-diary/add" onClick={this.close}>
              {t("actionMenu.addTraining")}
            </ActionMenuItem>
          )}
          {(!mainAction || mainAction === "trial") && (
            <ActionMenuItem to="/trial/add" onClick={this.close}>
              {t("actionMenu.addTrial")}
            </ActionMenuItem>
          )}
        </ul>

        <RoundButton
          onClick={this.toggle}
          size="large"
          icon={subActions.length > 0 ? "edit" : "add"}
          className="action-button"
        />
      </div>
    );
  }
}

ActionMenu.propTypes = {
  subActions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      onClick: PropTypes.func,
    })
  ),
  mainAction: PropTypes.string,
  t: PropTypes.func.isRequired,
};

ActionMenu.defaultProps = {
  subActions: [],
};

export default withTranslation()(ActionMenu);
