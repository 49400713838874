import { FETCH_ADMIN_INFO, LOGOUT } from "../actions/types";

const INITIAL_STATE = {
  usersCount: 0,
  dogsCount: 0,
  trainingsCount: 0,
  trialsCount: 0,
  show: false,
  loading: false,
  error: null
};

export default function(state = INITIAL_STATE, { type, payload }) {
  switch (type) {
    case FETCH_ADMIN_INFO.REQUEST:
      return { ...state, loading: true, show: true };
    case FETCH_ADMIN_INFO.FAILED:
      return { ...state, error: payload, loading: false, show: false };
    case FETCH_ADMIN_INFO.SUCCEEDED:
      return { ...state, ...payload, loading: false, show: true };

    case LOGOUT.REQUEST:
      return { ...INITIAL_STATE };

    default:
      return state;
  }
}
