import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { Redirect } from "react-router-dom";
import PropTypes from "prop-types";

import { AppContent, Loader } from "../../common";
import { deleteTrial } from "../../actions/";
import ActionMenu from "../../components/ActionMenu";
import confirm from "../../components/Confirm";
import TrialView from "./TrialView";

class TrialSingle extends Component {
  constructor(props) {
    super(props);
    let t = props.t;
    this.state = {
      subActions: [
        {
          label: t("common.edit"),
          onClick: () => {
            const { id } = this.props.match.params;
            this.props.history.push(`/trial/edit/${id}`);
          },
        },
        {
          label: t("common.delete"),
          onClick: () => {
            const { t, trial } = this.props;

            confirm(t("trial.deleteConfirm"), {
              title: t("common.areyousure"),
              ok: t("trial.deleteOk"),
              cancel: t("common.cancel"),
            }).then(
              () => this.props.deleteTrial(trial),
              () => {}
            );
          },
        },
      ],
    };
  }

  render() {
    const { isAuthenticated, trial, user } = this.props;
    if (isAuthenticated && !trial) return <Redirect to="/trials" />;

    if (!trial)
      return (
        <AppContent>
          <Loader />
        </AppContent>
      );

    return (
      <AppContent size="medium">
        <TrialView trial={trial} user={user} history={this.props.history} />
        <ActionMenu subActions={this.state.subActions} mainAction="trial" />
      </AppContent>
    );
  }
}

TrialSingle.propTypes = {
  t: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool,
  trial: PropTypes.object,
  deleteTrial: PropTypes.func,
  dog: PropTypes.object,
  history: PropTypes.any,
  match: PropTypes.object,
};

const mapStateToProps = ({ auth, trials, dogs, activeDog, user }, props) => {
  const { id } = props.match.params;
  const trial = trials.trials.find((t) => t.id === parseInt(id, 10));
  const dog = activeDog;
  const u = user.user;
  return { isAuthenticated: auth.isAuthenticated, trial, dog, user: u };
};

const TrialSingleWithTranslate = withTranslation()(TrialSingle);
export default connect(mapStateToProps, { deleteTrial })(
  TrialSingleWithTranslate
);
