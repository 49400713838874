import { FETCH_ADMIN_INFO } from "./types";

export const fetchAdminInfo = () => ({
  type: FETCH_ADMIN_INFO.REQUEST
});

export const fetchAdminInfoSucceeded = info => ({
  type: FETCH_ADMIN_INFO.SUCCEEDED,
  payload: info
});

export const fetchAdminInfoFailed = error => ({
  type: FETCH_ADMIN_INFO.FAILED,
  payload: error
});
