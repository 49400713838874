import {
  FETCH_DOGS,
  ADD_DOG,
  UPDATE_DOG,
  DELETE_DOG,
  UPDATE_DOG_TRIAL_SIZE_CLASS,
  LOGOUT,
  AFTER_REHYDRATE,
  FETCH_DOG
} from "../actions/types";
/*
{
  id: number,
  name: string,
  officialName: string,
  image: string,
  race: string,
  sex: string,
  dayOfBirth: string
}
*/

const INITIAL_STATE = {
  dogs: [],
  fetching: true,
  saving: false,
  error: null,
  hasNoDogs: null,
  updatingSizeClass: false
};

export default (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    // FETCH ALL
    case FETCH_DOGS.REQUEST:
      return { ...state, error: null, fetching: true };
    case FETCH_DOGS.SUCCEEDED:
      const hasNoDogs = payload.length === 0;
      return {
        ...state,
        error: null,
        fetching: false,
        dogs: [...payload],
        hasNoDogs
      };

    //FETCH
    case FETCH_DOG.REQUEST:
      return { ...state, saving: true };
    case FETCH_DOG.SUCCEEDED:
      const fetchedDogs = state.dogs.map(dog =>
        dog.id === payload.id ? payload : dog
      );
      return { ...state, saving: false, dogs: fetchedDogs };
    case FETCH_DOG.FAILED:
      return { ...state, saving: false, error: payload };

    // ADD
    case ADD_DOG.REQUEST:
      return { ...state, saving: true, error: null };
    case ADD_DOG.SUCCEEDED:
      return {
        ...state,
        saving: false,
        error: null,
        dogs: [...state.dogs, payload],
        hasNoDogs: false
      };
    case ADD_DOG.FAILED:
      return { ...state, saving: false, error: payload };

    // UPDATE
    case UPDATE_DOG.REQUEST:
      return { ...state, saving: true };
    case UPDATE_DOG.SUCCEEDED:
      const updatedDogs = state.dogs.map(dog =>
        dog.id === payload.id ? payload : dog
      );
      return { ...state, saving: false, dogs: updatedDogs };
    case UPDATE_DOG.FAILED:
      return { ...state, saving: false, error: payload };

    // DELETE
    case DELETE_DOG.REQUEST:
      return { ...state, deleting: true, error: null };
    case DELETE_DOG.SUCCEEDED:
      return { ...state, deleting: false };
    case DELETE_DOG.FAILED:
      return { ...state, deleting: false, error: payload };

    // UPDATE SIZE CLASSES
    case UPDATE_DOG_TRIAL_SIZE_CLASS.REQUEST:
      return { ...state, updatingSizeClass: true, error: null };
    case UPDATE_DOG_TRIAL_SIZE_CLASS.SUCCEEDED:
      return { ...state, updatingSizeClass: false };
    case UPDATE_DOG_TRIAL_SIZE_CLASS.FAILED:
      return { ...state, updatingSizeClass: false, error: payload };

    // LOGOUT
    case LOGOUT.REQUEST:
      return { ...INITIAL_STATE };

    case AFTER_REHYDRATE:
      return { ...state, saving: false, fetching: false };

    default:
      return state;
  }
};
