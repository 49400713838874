import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";

import { AppContent, Avatar, RoundButton } from "../../common";
import { setActiveDog } from "../../actions/ActiveDogActions";

class DogList extends Component {
  selectDog = (dog) => {
    const { setActiveDog } = this.props;
    setActiveDog(dog);
  };

  goToDogEditPage = () => {
    const { push } = this.props.history;
    push("/dogs/add");
  };

  render() {
    const { dogs, t } = this.props;
    return (
      <AppContent>
        {dogs.length === 0 && (
          <p className="placeholder-text">{t("dog.dogs-list-empty")}</p>
        )}

        <ul className="list list--simple dog-list">
          {dogs.map((dog) => (
            <li key={dog.id} className="list__item dog-list__item">
              <div className="dog-list__avatar">
                <button
                  onClick={() => this.selectDog(dog)}
                  className="btn-invisible"
                >
                  <Avatar image={dog.photo} />
                </button>
              </div>
              <div className="dog-list__names">
                <h2 className="no-margin h3">{dog.name}</h2>
                <p className="no-margin text-grey">{dog.officialName}</p>
              </div>
              <div className="dog-list__actions">
                <Link to={`/dogs/${dog.id}`}>
                  <RoundButton size="small" icon="edit" />
                </Link>
              </div>
            </li>
          ))}
        </ul>

        <RoundButton
          size="large"
          atCorner
          icon="add"
          onClick={this.goToDogEditPage}
        />
      </AppContent>
    );
  }
}

const mapStateToProps = ({ dogs }) => ({
  dogs: dogs.dogs,
});

const DogListWithTranslate = withTranslation()(DogList);
export default connect(mapStateToProps, { setActiveDog })(DogListWithTranslate);
