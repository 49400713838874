import React from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import Modal from "../common/Modal";
import Api from "../helpers/Api";
import { persistStore } from "redux-persist";

class CheckForUpdate extends React.Component {
  state = {
    bannerVisible: true,
    apiVersion: 0,
    fetching: false,
  };

  componentDidMount() {
    this.fetchApiVersion();
  }

  handleCloseBannerBtnClick() {
    this.setState({ bannerVisible: false });
    localStorage.setItem("apiClientVersion", this.state.apiVersion);
    persistStore(window.store).purge();
    window.location.reload(true);
  }

  fetchApiVersion() {
    const { auth_token } = this.props;
    this.setState({ fetching: true });
    Api({ auth_token: auth_token })
      .fetchApiClientVersion()
      .then((res) => {
        this.setState({ apiVersion: res.data.version, fetching: false });
      });
  }

  render() {
    const { bannerVisible, apiVersion, fetching } = this.state;
    const { t } = this.props;
    let storedVersion = localStorage.getItem("apiClientVersion");

    if (!storedVersion || storedVersion === "0" || storedVersion === 0) {
      storedVersion = this.state.apiVersion;
      localStorage.setItem("apiClientVersion", storedVersion);
    }

    const shouldRenderNotification =
      bannerVisible &&
      !fetching &&
      apiVersion !== 0 &&
      storedVersion !== apiVersion;

    return shouldRenderNotification ? (
      <Modal
        className="modal-skinny"
        contentClassName="modal-content"
        show={shouldRenderNotification}
        hideHorizontalLine
        onClose={() => {
          this.handleCloseBannerBtnClick();
        }}
      >
        <p>{t("notification.reload")}</p>
      </Modal>
    ) : null;
  }
}

CheckForUpdate.propTypes = {
  auth_token: PropTypes.any,
  t: PropTypes.any,
};

const mapStateToProps = ({ auth }) => ({
  auth_token: auth.authToken,
});

const UpdateWithTranslate = withTranslation()(CheckForUpdate);
export default connect(mapStateToProps)(UpdateWithTranslate);
