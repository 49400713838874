import React, { Component } from "react";

import { Loader } from "./";

class Image extends Component {
  render() {
    const { src, alt, ...rest } = this.props;

    if (!src) return null;
    return !!src ? <img src={src} alt={alt} {...rest} /> : <Loader />;
  }
}

Image.defaultProps = {
  alt: ""
};

export default Image;
