import React from "react";
import { withTranslation } from "react-i18next";
import moment from "moment";
import { SubscriptionStatus } from "../helpers/Constants";

const dateFormat = "Do MMM YYYY";

function ActiveSubscription(props) {
  const {
    plan,
    t,
    onCancel,
    onReactivate,
    onCreateCustomerPortalSession,
    showModal,
  } = props;
  const isActive = plan.status === SubscriptionStatus.PAID_AND_RECURRING;
  const requiresAction = plan.status === SubscriptionStatus.REQUIRES_ACTION;

  const btnStyles = [
    "btn",
    "btn-primary",
    "subscription-container__upgrade",
  ].join(" ");

  const dates = {
    purchaseDate: moment(plan.created_at).format(dateFormat),
    cancellationDate: moment(plan.cancelled_at).format(dateFormat),
    validUntilDate: moment(plan.paid_until).format(dateFormat),
    renewDate: moment(plan.paid_until).format(dateFormat),
  };

  let description = t("subscription.subscriptionActive", dates);
  let status = t("subscription.active");
  if (!isActive) {
    if (requiresAction) {
      description = t("subscription.subscriptionRequiresAction");
      status = t("subscription.requiresAction");
    } else {
      description = t("subscription.subscriptionCancelled", dates);
      status = t("subscription.cancelled");
    }
  }

  return (
    <div style={{ padding: "20px", paddingBottom: "0px" }}>
      <h2>
        {`${plan.subscription_plan.name} `}
        <span className="text-grey">{status}</span>
      </h2>
      <p>{description}</p>
      <button
        className={btnStyles}
        onClick={() => {
          onCreateCustomerPortalSession();
        }}
      >
        {t("subscription.openBillingPortal")}
      </button>
    </div>
  );
}

const translatedSubscription = withTranslation()(ActiveSubscription);
export default translatedSubscription;
