import * as TYPES from "../actions/types";

const INITIAL_STATE = {
  text: "",
  time: null,
  type: "notify"
};

export default (state = INITIAL_STATE, { type: actionType, payload }) => {
  const time = new Date().getTime();
  let type = "default";

  switch (actionType) {
    case TYPES.LOGIN.FAILED:
    case TYPES.SIGNUP.FAILED:
    case TYPES.UPDATE_USER.FAILED:
    case TYPES.RETURN_PASSWORD.FAILED:
      return { text: payload, type: "error", time };

    case TYPES.AUTH_EXPIRED:
      return { text: "notification.authExpired", type: "error", time };
    case TYPES.ADD_TRIAL.FAILED:
      return { text: "notification.addTrialFailed", type: "error", time };

    case TYPES.RETURN_PASSWORD.SUCCEEDED:
      return { text: "notification.returnPasswordSucceeded", type, time };

    case TYPES.SAVE_SETTINGS.SUCCEEDED:
      return { text: "notification.saveSettingsSucceeded", type, time };

    case TYPES.UPDATE_USER.SUCCEEDED:
      return { text: "notification.userUpdateSucceeded", type, time };

    case TYPES.UPDATE_DOG.SUCCEEDED:
      return { text: "notification.dogUpdateSucceeded", type, time };
    case TYPES.ADD_DOG.SUCCEEDED:
      return { text: "notification.dogAddSucceeded", type, time };
    case TYPES.DELETE_DOG.SUCCEEDED:
      return { text: "notification.dogDeleteSucceeded", type, time };

    case TYPES.ADD_TRIAL.SUCCEEDED:
      return { text: "notification.addTrialSucceeded", type, time };

    case TYPES.UPDATE_TRIAL.SUCCEEDED:
      return { text: "notification.updateTrialSucceeded", type, time };
    case TYPES.DELETE_TRIAL.SUCCEEDED:
      return { text: "notification.deleteTrialSucceeded", type, time };

    case TYPES.ADD_TRAINING.SUCCEEDED:
      return { text: "notification.addTrainingSucceeded", type, time };
    case TYPES.UPDATE_TRAINING.SUCCEEDED:
      return { text: "notification.updateTrainingSucceeded", type, time };
    case TYPES.DELETE_TRAINING.SUCCEEDED:
      return { text: "notification.deleteTrainingSucceeded", type, time };

    case TYPES.COPY_TRAINING_TOPIC.SUCCEEDED:
      return { text: "notification.topicCopySuccess", type, time };
    case TYPES.COPY_TRAINING_TOPIC.FAILED:
      return { text: "notification.topicCopyFail", type, time };

    case TYPES.COPY_SHARED_TOPIC.SUCCEEDED:
      return { text: "notification.topicCopySuccess", type, time };
    case TYPES.COPY_SHARED_TOPIC.FAILED:
      return { text: "notification.topicCopyFail", type, time };

    case TYPES.DELETE_TRAINING_TOPIC.SUCCEEDED:
      return { text: "notification.topicDeleteSuccess", type, time };
    case TYPES.DELETE_TRAINING_SUBTOPIC.SUCCEEDED:
      return { text: "notification.subtopicDeleteSuccess", type, time };
    case TYPES.DELETE_TRAINING_EXERCISE.SUCCEEDED:
      return { text: "notification.exerciseDeleteSuccess", type, time };

    case TYPES.ADD_TRAINING_TOPIC_FORM.SUCCEEDED:
      return { text: "notification.topicAddSuccess", type, time };
    case TYPES.UPDATE_TRAINING_TOPIC.SUCCEEDED:
      return { text: "notification.topicUpdateSuccess", type, time };

    case TYPES.ADD_TRAINING_EXERCISE.SUCCEEDED:
      return { text: "notification.exerciseAddSuccess", type, time };
    case TYPES.UPDATE_TRAINING_EXERCISE.SUCCEEDED:
      return { text: "notification.exerciseUpdateSuccess", type, time };

    case TYPES.UPDATE_TRAINING_SUBTOPIC.SUCCEEDED:
      return { text: "notification.subtopicUpdateSuccess", type, time };

    //TODO
    case TYPES.UPDATE_DOG_TRIAL_SIZE_CLASS.SUCCEEDED:
      return {
        text: "notification.dogTrialSizeClassUpdateSuccess",
        type,
        time
      };

    default:
      return state;
  }
};
