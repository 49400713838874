import {
  FETCH_COUPON,
  VALIDATE_COUPON,
  UPDATE_COUPON,
  ADD_COUPON,
  FETCH_COUPON_AVAILABLE,
  CLEAR_COUPON_ERRORS
} from "./types";

export const fetchCoupon = id => ({
  type: FETCH_COUPON.REQUEST,
  payload: id
});

export const fetchCouponSucceeded = coupon => ({
  type: FETCH_COUPON.SUCCEEDED,
  payload: coupon
});

export const fetchCouponFailed = error => ({
  type: FETCH_COUPON.FAILED,
  payload: error
});

export const validateCoupon = id => ({
  type: VALIDATE_COUPON.REQUEST,
  payload: id
});

export const validateCouponSucceeded = payload => ({
  type: VALIDATE_COUPON.SUCCEEDED,
  payload: payload
});

export const validateCouponFailed = error => ({
  type: VALIDATE_COUPON.FAILED,
  payload: error
});

export const updateCoupon = coupon => ({
  type: UPDATE_COUPON.REQUEST,
  payload: coupon
});

export const updateCouponSucceeded = coupon => ({
  type: UPDATE_COUPON.SUCCEEDED,
  payload: coupon
});

export const updateCouponFailed = error => ({
  type: UPDATE_COUPON.FAILED,
  payload: error
});

export const addCoupon = coupon => ({
  type: ADD_COUPON.REQUEST,
  payload: coupon
});

export const addCouponSucceeded = coupon => ({
  type: ADD_COUPON.SUCCEEDED,
  payload: coupon
});

export const addCouponFailed = error => ({
  type: ADD_COUPON.FAILED,
  payload: error
});

export const fetchCouponAvailable = id => ({
  type: FETCH_COUPON_AVAILABLE.REQUEST,
  payload: id
});

export const fetchCouponAvailableSucceeded = payload => ({
  type: FETCH_COUPON_AVAILABLE.SUCCEEDED,
  payload: payload
});

export const fetchCouponAvailableFailed = error => ({
  type: FETCH_COUPON_AVAILABLE.FAILED,
  payload: error
});

export const clearCouponErrors = () => ({
  type: CLEAR_COUPON_ERRORS
});
