import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import confirm from "../../components/Confirm";
import moment from "moment";
import PaywallNotification from "../../containers/App/PaywallNotification";
import {
  AppContent,
  Input,
  FormGroup,
  Icon,
  Card,
  RoundButton,
  TextArea,
} from "../../common";
import {
  updateTrainingSubTopic,
  deleteTrainingSubTopic,
  updateTrainingExerciseNoNotification,
} from "../../actions";
import { ExerciseSortableList } from "./ExerciseSortableList";
import { arrayMove } from "react-sortable-hoc";
import { Redirect } from "react-router-dom";

class SubTopicEdit extends Component {
  state = {
    submitted: false,
    errors: {},
    edited: false,
    edit: false,
    editName: false,
    editNotes: false,
    form: {
      name: "",
      notes: "",
      exercises: [],
    },
  };

  componentDidMount() {
    // const { id } = this.props.match.params;
    let exercises = this.props.subtopic.exercises;
    let notes = this.props.subtopic.notes;
    if (!this.props.subtopic.exercises) exercises = [];

    exercises = exercises.sort((a, b) => a.orderNumber - b.orderNumber);

    this.setState({
      form: {
        ...this.props.subtopic,
        exercises: exercises,
        notes: notes,
        trainingTopicId: this.props.topic.id,
      },
      edit: true,
    });
  }

  getPremium = (e) => {
    const { t } = this.props;
    e.stopPropagation();
    e.preventDefault();
    console.log("Get premium to unlock this feature!");
    confirm(t("subscription.noPlanYet"), {
      title: t("paywall.getPremium"),
      ok: t("common.ok"),
      cancel: t("common.cancel"),
    }).then(
      () => {
        this.props.history.push("/subscription");
      },
      () => {}
    );
  };

  // handleEditSubtopicName = () => {
  //   if (this.state.editName) {
  //     this.props.updateTrainingSubTopic(this.state.form);
  //   }
  //   this.setState({ editName: !this.state.editName });
  // };

  toggleEditName = () => {
    this.setState({ editName: !this.state.editName });
  };

  toggleEditNotes = () => {
    this.setState({ editNotes: !this.state.editNotes });
  };

  handleInputChange = (e) => {
    const { name, value } = e.target;
    this.setState((prevState, props) => ({
      ...prevState,
      form: {
        ...prevState.form,
        [name]: value,
      },
      edited: true,
    }));
  };

  handleGoBack = () => {
    // this.props.history.goBack(); TODO: Back to topic of back to topiclist? how to know?
    this.props.history.push(`/training-topic/${this.props.topic.id}/`);
  };

  onExpandExercise = (id) => {
    let exerciseIndex = this.state.form.exercises.findIndex(
      (exercise) => exercise.id === id
    );
    let newExerciseList = [...this.state.form.exercises];
    newExerciseList[exerciseIndex].expanded = !newExerciseList[exerciseIndex]
      .expanded;
    this.setState({ ...this.state.form, exercises: newExerciseList });
  };

  handleExerciseCheckChange = (e) => {
    e.stopPropagation();
    const id = parseInt(e.target.value, 10);
    const done = e.target.checked;

    // Updates in state.
    let exerciseIndex = this.state.form.exercises.findIndex(
      (exercise) => exercise.id === id
    );
    let newExerciseList = [...this.state.form.exercises];
    newExerciseList[exerciseIndex].done = done;

    this.setState({
      form: {
        ...this.state.form,
        exercises: newExerciseList,
      },
      edited: true,
    });
    // Updates in database
    this.props.updateTrainingExerciseNoNotification({
      ...newExerciseList[exerciseIndex],
      trainingTopicId: this.props.topic.id,
      subTopicId: this.props.subtopic.id,
    });
  };

  onExerciseSortEnd = ({ oldIndex, newIndex }) => {
    let newExerciseList = arrayMove(
      [...this.state.form.exercises],
      oldIndex,
      newIndex
    );
    newExerciseList = newExerciseList.map((item, index) => {
      item.orderNumber = index + 1;
      return item;
    });
    // Updates in state.
    this.setState({
      form: {
        ...this.state.form,
        exercises: newExerciseList,
      },
      edited: true,
    });
    // Updates in database.
    for (let exercise of newExerciseList) {
      this.props.updateTrainingExerciseNoNotification({
        ...exercise,
        trainingTopicId: this.props.topic.id,
        subTopicId: this.props.subtopic.id,
      });
    }
  };

  handleAddExercise = (e) => {
    this.handleSave(e);
    this.props.history.push(
      `/training-topic/${this.props.topic.id}/${this.props.subtopic.id}/add`
    );
  };

  handleExerciseEdit = (id, e) => {
    e.stopPropagation();
    this.handleSave(e);
    let topicId = this.props.topic.id;
    let subtopicId = this.props.subtopic.id;
    console.log(
      "Going to edit exercise id: " +
        id +
        " of topic ID: " +
        topicId +
        " and subtopic ID: " +
        subtopicId
    );
    this.props.history.push(
      `/training-topic/${topicId}/${subtopicId}/edit/${id}`
    );
  };

  handleExerciseView = (id, e) => {
    e.stopPropagation();
    this.handleSave(e);
    let topicId = this.props.topic.id;
    let subtopicId = this.props.subtopic.id;
    console.log(
      "Going to view exercise id: " +
        id +
        " of topic ID: " +
        topicId +
        " and subtopic ID: " +
        subtopicId
    );
    this.props.history.push(
      `/training-topic/${topicId}/${subtopicId}/exercise/${id}`
    );
  };

  handleTrainingView = (id, e) => {
    e.stopPropagation();
    console.log("Going to view training " + id);
    this.props.history.push(`/training-diary/${id}`);
  };

  handleMoveToTrainingDiary = (id, e) => {
    e.stopPropagation();
    this.handleSave(e);
    console.log("Moving excercise id: " + id + " to training diary!");
    this.props.history.push(`/training-diary/add/${id}`);
    // Has to add a date of training to the list of training dates. Like: trainedAt: ["2019-07-09T10:59:49.843Z"]
  };

  handleSave = (e) => {
    e.preventDefault();
    if (this.state.edited) {
      this.props.updateTrainingSubTopic(this.state.form);
      this.setState({
        edited: false,
        submitted: true,
        editName: false,
        editNotes: false,
      });
    } else {
      this.setState({
        editName: false,
        editNotes: false,
      });
    }
  };

  handleDeleteSubTopic = () => {
    const { t } = this.props;

    confirm(t("dog.deleteDogConfirmTitle"), {
      title: t("common.delete") + " " + t("topics.subtopic") + "?",
      ok: t("common.delete"),
      cancel: t("common.cancel"),
    }).then(
      () => {
        this.props.deleteTrainingSubTopic({
          id: this.props.subtopic.id,
          trainingTopicId: this.props.topic.id,
        });
      },
      () => {}
    );
  };

  render() {
    const { t, hasActivePlan } = this.props;

    if (!this.props.topic) return <Redirect to="/training-topics" />;
    if (!this.props.subtopic) return <Redirect to={`/training-topic/${this.props.topic.id}`} />;

    return (
      <AppContent size="medium">
        <button className="trial-back-button" onClick={this.handleGoBack}>
          <strong>&larr;</strong> {t("common.back")}
        </button>
        <p className="py-2" />
        {this.renderNameEdit()}

        {this.renderNotes()}

        {this.renderExercises()}

        <Card>
          <FormGroup className="column">
            <button
              className="btn btn-link"
              onClick={this.handleDeleteSubTopic}
            >
              {t("common.delete")}
            </button>
          </FormGroup>
        </Card>
        {!hasActivePlan && (
          <PaywallNotification
            text="subscription.noPlanYet"
            button="paywall.getPremium"
          />
        )}
        {hasActivePlan && (
          <RoundButton
            icon={"complete"}
            size="large"
            atCorner
            onClick={this.handleSave}
          />
        )}
      </AppContent>
    );
  }

  renderNameEdit() {
    const { editName } = this.state;
    const { name } = this.state.form;
    const { t, hasActivePlan } = this.props;

    return (
      <React.Fragment>
        {editName ? (
          <React.Fragment>
            <h2>
              <FormGroup className="form-inline justify-content-center align-items-end">
                <Input
                  type="text"
                  name="name"
                  placeholder={t("topics.addNew-placeholder")}
                  onChange={this.handleInputChange}
                  value={name}
                  label={this.props.topic.name + " | "}
                  className="d-inline-block"
                />
                {/* <button
                  className="btn btn-primary"
                  onClick={this.handleEditSubtopicName}
                >
                  {t("common.ok")}
                </button> */}
              </FormGroup>
            </h2>
          </React.Fragment>
        ) : (
          <h2 className="text-center">
            {this.props.topic.name} | {this.state.form.name}
            {hasActivePlan && (
              <button
                className="todo-list__delete btn--link text-danger"
                onClick={this.toggleEditName}
              >
                <Icon icon="editGrey" />
              </button>
            )}
          </h2>
        )}
      </React.Fragment>
    );
  }

  renderNotes() {
    const { t } = this.props;
    const { editNotes } = this.state;
    const { notes } = this.state.form;

    return (
      <Card>
        <h2>
          {t("training.notes")}
          <button
            className="todo-list__delete btn--link text-danger"
            onClick={this.toggleEditNotes}
          >
            <Icon icon="editGrey" />
          </button>
        </h2>
        {editNotes ? (
          <React.Fragment>
            <FormGroup>
              <TextArea
                type="text"
                name="notes"
                label="none"
                placeholder={""}
                onChange={this.handleInputChange}
                value={notes}
              />
              {/* <button className="btn btn-primary" onClick={this.toggleEditNotes}>
                {t("common.ok")}
              </button> */}
            </FormGroup>
          </React.Fragment>
        ) : (
          <p className="multiLine">{notes}</p>
        )}
      </Card>
    );
  }

  renderExercises() {
    const { exercises } = this.state.form;
    // Get translations on "Move to my training diary"
    const { t, hasActivePlan } = this.props;

    return (
      <Card>
        <h2>{t("topics.exercisesTitle")}</h2>
        <ExerciseSortableList
          items={exercises}
          onItemDelete={this.handleDeleteExercise}
          onMoveToTraining={this.handleMoveToTrainingDiary}
          onSortEnd={this.onExerciseSortEnd}
          onItemEdit={this.handleExerciseEdit}
          onItemView={this.handleExerciseView}
          onExpand={this.onExpandExercise}
          onViewTraining={this.handleTrainingView}
          onCheck={this.handleExerciseCheckChange}
          useDragHandle={true}
          t={t}
          hasActivePlan={hasActivePlan}
        />
        <p />
        {hasActivePlan ? (
          <button className="btn btn-primary" onClick={this.handleAddExercise}>
            {t("topics.addNewButton") + "..."}
          </button>
        ) : (
          <button
            className="btn btn-outline-secondary"
            onClick={(e) => this.getPremium(e)}
          >
            {t("topics.addNewButton") + "..."}
          </button>
        )}
      </Card>
    );
  }
}

SubTopicEdit.propTypes = {
  t: PropTypes.func,
  history: PropTypes.object,
  topics: PropTypes.object,
  topic: PropTypes.object,
  subtopic: PropTypes.object,
  focusCategories: PropTypes.array,
  updateTrainingSubTopic: PropTypes.func,
  deleteTrainingSubTopic: PropTypes.func,
  updateTrainingExerciseNoNotification: PropTypes.func,
  hasActivePlan: PropTypes.bool
};

const mapStateToProps = ({ topics, subscriptions }, props) => {
  const { topicid, subtopicid } = props.match.params;
  // Empty topic & subtopic also on delete uses history.goBack()
  const topic = topics.topics.find((t) => t.id === Number(topicid));
  const subtopic = topic?.trainingSubTopics.find((t) => t.id === Number(subtopicid));

  return {
    topic,
    subtopic,
    saving: topics.saving,
    deleting: topics.deleting,
    hasActivePlan:
      subscriptions.currentPlan &&
      moment(subscriptions.currentPlan.paid_until) > moment(),
  };
};

const SubTopicEditWithTranslate = withTranslation()(SubTopicEdit);

export default connect(mapStateToProps, {
  updateTrainingSubTopic,
  deleteTrainingSubTopic,
  updateTrainingExerciseNoNotification,
})(SubTopicEditWithTranslate);
