import React, { useState } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import {
  FormGroup,
  Input,
  RoundButton,
  TextArea,
  ImageUploader
} from "../../../common";

const AdditionalForm = (props) => {

  const { t, courseTypes, form, submitAdditionalForm, additionalFormBack, saving  } = props;

  const [additionalForm, setAdditionalForm] = useState({
    courseMap: form.courseMap,
    notes: form.notes,
    videoUrl: form.videoUrl,
    certs: form.certs,
  });

  let courseType = courseTypes.find(
    (ct) => ct.id === parseInt(form.courseTypeId)
  );
  let trialText =
      "trial." + (courseType ? courseType.name.toLowerCase() : "") + "Course";
  let titleText = t("trial.additional") + " • " + t(trialText);

  const handleInputChange = (e) => {
    let { name, value } = e.target;
    setAdditionalForm({ ...additionalForm, [name]: value });
  };

  const handleCourseMapUpload = (image) => {
    setAdditionalForm({ ...additionalForm, 
      courseMap: image.imagePreviewUrl,
      courseMapFile: image.file });
  };

  return (
    <React.Fragment>
      <button
        className="trial-back-button"
        onClick={() => additionalFormBack()}
      >
        <strong>&larr;</strong> {t("common.back")}
      </button>
      <h2 className="text-center">{titleText}</h2>

      <FormGroup>
        <TextArea
          name="notes"
          label={t("trial.notes")}
          type="text"
          placeholder={t("trial.notes-placeholder")}
          value={additionalForm.notes}
          onChange={handleInputChange}
        />
      </FormGroup>

      <FormGroup>
        <label>{t("trial.courseMap")}</label>
        <div>
          <ImageUploader
            buttonLabel={t("training.courseMapButton")}
            imageUrl={additionalForm.courseMap}
            onUpload={handleCourseMapUpload}
          />
        </div>
      </FormGroup>

      <FormGroup>
        <Input
          name="videoUrl"
          label={t("trial.videoUrl")}
          type="url"
          placeholder=""
          value={additionalForm.videoUrl}
          onChange={handleInputChange}
        />
      </FormGroup>

      <FormGroup>
        <Input
          name="certs"
          label={t("trial.certs")}
          type="text"
          placeholder=""
          value={additionalForm.certs}
          onChange={handleInputChange}
        />
      </FormGroup>

      <RoundButton
        atCorner
        size="large"
        icon={saving ? "loader" : "complete"}
        onClick={() => submitAdditionalForm({...form, ...additionalForm})}
      />
    </React.Fragment>
  );
};

AdditionalForm.propTypes = {
  t: PropTypes.func,
  auth_token: PropTypes.any,
  dog: PropTypes.object,
  user: PropTypes.object,
  courseTypes: PropTypes.array,
  form: PropTypes.object,
  submitAdditionalForm: PropTypes.func,
  additionalFormBack: PropTypes.func,
  saving: PropTypes.bool
};

const mapStateToProps = (
  { activeDog, auth, trials, courseTypes, user }
) => {
  return {
    dog: activeDog,
    auth_token: auth.authToken,
    courseTypes: courseTypes.courseTypes,
    saving: trials.saving,
    user: user.user,
  };
};

const AdditionalFormWithTranslate = withTranslation()(AdditionalForm);
export default connect(mapStateToProps, {})(AdditionalFormWithTranslate);