import React from "react";
import moment from "moment";
import {
  SortableContainer,
  SortableElement,
  SortableHandle
} from "react-sortable-hoc";

import { Icon } from "../../common";

/*
 * Used in SubTopicExpandedList on the trainingTopicList and in TrainingTopicEdit for
 * rendering sortable list of subtopics. Difference is in background-attribute which
 * governs if every other is item background grey or not and if there is a bit more
 * padding on the list.
 * Also used in SharedTrainingTopic without a onCheck.
 */
const SortableHandleHelper = SortableHandle(() => (
  <div
    className="todo-list__sort-handle ml-2"
    onClick={e => e.stopPropagation()}
  >
    <Icon icon="arrows" />
  </div>
));
// column--margin
const LowerSortableItem = SortableElement(
  ({ value: subTopic, onItemDelete, onCheck, onItemEdit, greyIndex, t }) => (
    <li
      key={subTopic.id}
      className={`${
        greyIndex % 2 === 0
          ? "bg-grey column column--align-top p-2"
          : "column column--align-top p-2"
      }`}
    >
      {onCheck ? (
        <React.Fragment>
          <SortableHandleHelper />
          <div>
            <input
              type="checkbox"
              className="todo-list__input"
              value={subTopic.id}
              checked={subTopic.done}
              onChange={onCheck}
              onClick={e => e.stopPropagation()}
              readOnly
            />
          </div>
        </React.Fragment>
      ) : (
        <span className="mr-2" />
      )}

      <label className="todo-list__label">
        <span className="todo-list__name">{subTopic.name} </span>
      </label>
      {!subTopic.notSaved ? (
        <span>
          <span>
            {subTopic.done ? (
              <span className="text-small mx-3 py-1">
                {moment(subTopic.updatedAt).format("ll")}
              </span>
            ) : (
              <span />
            )}
          </span>
          <button
            className="btn--link"
            onClick={e => onItemEdit(subTopic.id, e)}
          >
            {!subTopic.notSaved && !subTopic.done ? (
              <span className="text-info text-small mx-3">
                {subTopic.exercises ? subTopic.exercises.length : 0}{" "}
                {subTopic.exercises && subTopic.exercises.length === 1 ? (
                  <span>{t("topics.exerciseLower")}</span>
                ) : (
                  <span>{t("topics.exercisesList")}</span>
                )}
              </span>
            ) : (
              <span />
            )}
            <span className="pr-2">&rarr;</span>
          </button>
        </span>
      ) : (
        <span>
          {" "}
          <button
            className="btn--link"
            onClick={() => onItemDelete(subTopic.id)}
          >
            <Icon icon="times" />
          </button>
        </span>
      )}
    </li>
  )
);

export const SubSortableList = SortableContainer(
  ({ items: subTopics, onItemDelete, onCheck, onItemEdit, topicList, t }) => {
    return (
      <ul className={`${topicList ? "todo-list px-2" : "todo-list"}`}>
        {subTopics.map((topic, index) => (
          <LowerSortableItem
            key={`item-${topic.id}`}
            index={index}
            value={topic}
            onItemDelete={onItemDelete}
            onCheck={onCheck}
            onItemEdit={onItemEdit}
            greyIndex={!topicList ? index : 1} // 1 instead of index if from SubTopicExpandedList (topicList = true)
            t={t}
          />
        ))}
      </ul>
    );
  }
);
