import React, { Component } from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import moment from "moment";
import ReactPlayer from "react-player";
import InstagramEmbed from "react-instagram-embed";
import _ from "lodash";

import { Medal, Image } from "../../common";
import { castToNestedFocusArray } from "../../helpers/Transformer";
import { Document, Page, pdfjs } from "react-pdf";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const STANDARD_COURSES = [1, 2, 3, 12]; //Agility, Jumping, Speedstakes, Dog jumping
const POINTS_COURSES = [4, 5]; //WAO: Gamblers, Snooker
const TIME_GAMBLE = [6];
const POWER_AND_SPEED = [7];
const FAST = [8];
const TIME2BEAT = [9];
const POINTS_AND_TIME = [10, 11];

class TrialView extends Component {
  handleGoBack = () => {
    if (localStorage.getItem("trialFilters")) {
      this.props.history.push(`/trials/?f`);
    } else {
      this.props.history.push(`/trials/`);
    }
  };

  render() {
    const { trial, t, user } = this.props;
    return (
      <React.Fragment>
        <button className="trial-back-button" onClick={this.handleGoBack}>
          <strong>&larr;</strong> {t("common.back")}
        </button>
        <header className="text-center">
          <h1>
            {trial.location && <>{trial.location + " • "}</>}
            {trial.courseType && (
              <React.Fragment>
                {t(
                  "trial." +
                    (trial.courseType && trial.courseType !== undefined
                      ? trial.courseType.toLowerCase()
                      : "") +
                    "Course"
                )}
              </React.Fragment>
            )}
            {trial.organization && (
              <React.Fragment>{" • " + trial.organization}</React.Fragment>
            )}
            {trial.dsq && (
              <React.Fragment>{" • " + t("trial.disqualified")}</React.Fragment>
            )}
          </h1>
          <h2 className="text-grey">{moment(trial.date).format("L")}</h2>
        </header>

        <br />
        <br />

        {_.includes(STANDARD_COURSES, parseInt(trial.courseTypeId)) && (
          <div className="column column--align">
            {trial.result && !trial.dsq && (
              <div>
                <p className="text-grey no-margin">{t("trial.result")}</p>
                <p>
                  <span className="text-huge">{trial.result}</span>
                  <br />
                  <span className="text-gold">
                    {trial.winningSpeed ? "\u00a0" : ""}
                  </span>
                </p>
              </div>
            )}
            {trial.speed && !trial.dsq && (
              <div>
                <p className="text-grey no-margin">{t("trial.speed")}</p>
                <p>
                  <span className="text-huge">
                    {trial.speed + " " + t("common.unit-speed-" + user.units)}
                  </span>
                  <br />
                  <span className="text-gold">
                    {trial.winningSpeed
                      ? "(" +
                        trial.winningSpeed +
                        " " +
                        t("common.unit-speed-" + user.units) +
                        ")"
                      : ""}
                  </span>
                </p>
              </div>
            )}
            <div>
              {trial.placement && !trial.dsq && (
                <Medal placement={trial.placement} />
              )}
            </div>
          </div>
        )}

        {_.includes(POINTS_COURSES, parseInt(trial.courseTypeId)) && (
          <div className="column column--align">
            {trial.totalPoints && !trial.dsq && (
              <div>
                <p className="text-grey no-margin">{t("trial.points")}</p>
                <p>
                  <span className="text-huge">{trial.totalPoints}</span>
                  <br />
                  <span className="text-gold">
                    {trial.winningPoints ? "(" + trial.winningPoints + ")" : ""}
                  </span>
                </p>
              </div>
            )}
            {trial.trialTime && !trial.dsq && (
              <div>
                <p className="text-grey no-margin">{t("trial.time")}</p>
                <p>
                  <span className="text-huge">{trial.trialTime}</span>
                  <br />
                  <span className="text-gold">
                    {trial.winningTime ? "(" + trial.winningTime + ")" : ""}
                  </span>
                </p>
              </div>
            )}
            <div>
              {trial.placement && !trial.dsq && (
                <Medal placement={trial.placement} />
              )}
            </div>
          </div>
        )}

        {_.includes(TIME_GAMBLE, parseInt(trial.courseTypeId)) && (
          <div className="column column--align">
            {trial.designatedTime && !trial.dsq && (
              <div>
                <p className="text-grey no-margin">
                  {t("trial.designatedTime")}
                </p>
                <p>
                  <span className="text-huge">{trial.designatedTime}</span>
                </p>
              </div>
            )}
            {trial.trialTime && !trial.dsq && (
              <div>
                <p className="text-grey no-margin">{t("trial.time")}</p>
                <p>
                  <span className="text-huge">{trial.trialTime}</span>
                </p>
              </div>
            )}
            {trial.timeDifference && !trial.dsq && (
              <div>
                <p className="text-grey no-margin">
                  {t("trial.timeDifference")}
                </p>
                <p>
                  <span className="text-huge">{trial.timeDifference}</span>
                </p>
              </div>
            )}
            <div>
              {trial.placement && !trial.dsq && (
                <Medal placement={trial.placement} />
              )}
            </div>
          </div>
        )}

        {_.includes(POWER_AND_SPEED, parseInt(trial.courseTypeId)) && (
          <div className="column column--align">
            {trial.result && !trial.dsq && (
              <div>
                <p className="text-grey no-margin">{t("trial.result")}</p>
                <p>
                  <span className="text-huge">{trial.result}</span>
                  <br />
                  <span className="text-gold">
                    {trial.winningSpeed ? "\u00a0" : ""}
                  </span>
                </p>
              </div>
            )}
            {trial.trialTime && !trial.dsq && (
              <div>
                <p className="text-grey no-margin">{t("trial.time")}</p>
                <p>
                  <span className="text-huge">{trial.trialTime}</span>
                </p>
              </div>
            )}
            <div>
              {trial.placement && !trial.dsq && (
                <Medal placement={trial.placement} />
              )}
            </div>
          </div>
        )}

        {_.includes(FAST, parseInt(trial.courseTypeId)) && (
          <div className="column column--align">
            {trial.totalPoints && !trial.dsq && (
              <div>
                <p className="text-grey no-margin">{t("trial.points")}</p>
                <p>
                  <span className="text-huge">{trial.totalPoints}</span>
                  <br />
                  <span className="text-gold">
                    {trial.winningPoints ? "(" + trial.winningPoints + ")" : ""}
                  </span>
                </p>
              </div>
            )}
            {trial.trialTime && !trial.dsq && (
              <div>
                <p className="text-grey no-margin">{t("trial.time")}</p>
                <p>
                  <span className="text-huge">{trial.trialTime}</span>
                  <br />
                  <span className="text-gold">
                    {trial.winningTime ? "(" + trial.winningTime + ")" : ""}
                  </span>
                </p>
              </div>
            )}
            <div>
              {trial.placement && !trial.dsq && (
                <Medal placement={trial.placement} />
              )}
            </div>
          </div>
        )}

        {_.includes(TIME2BEAT, parseInt(trial.courseTypeId)) && (
          <div className="column column--align">
            {trial.result && !trial.dsq && (
              <div>
                <p className="text-grey no-margin">{t("trial.result")}</p>
                <p>
                  <span className="text-huge">{trial.result}</span>
                  <br />
                  <span className="text-gold">
                    {trial.winningSpeed ? "\u00a0" : ""}
                  </span>
                </p>
              </div>
            )}
            {trial.trialTime && !trial.dsq && (
              <div>
                <p className="text-grey no-margin">{t("trial.time")}</p>
                <p>
                  <span className="text-huge">{trial.trialTime}</span>
                  <br />
                  <span className="text-gold">
                    {trial.winningTime ? "(" + trial.winningTime + ")" : ""}
                  </span>
                </p>
              </div>
            )}
            <div>
              {trial.placement && !trial.dsq && (
                <Medal placement={trial.placement} />
              )}
            </div>
          </div>
        )}

        {_.includes(POINTS_AND_TIME, parseInt(trial.courseTypeId)) && (
          <div className="column column--align">
            {trial.totalPoints && !trial.dsq && (
              <div>
                <p className="text-grey no-margin">{t("trial.points")}</p>
                <p>
                  <span className="text-huge">{trial.totalPoints}</span>
                  <br />
                  <span className="text-gold">
                    {trial.winningPoints ? "(" + trial.winningPoints + ")" : ""}
                  </span>
                </p>
              </div>
            )}
            {trial.trialTime && !trial.dsq && (
              <div>
                <p className="text-grey no-margin">{t("trial.time")}</p>
                <p>
                  <span className="text-huge">{trial.trialTime}</span>
                  <br />
                  <span className="text-gold">
                    {trial.winningTime ? "(" + trial.winningTime + ")" : ""}
                  </span>
                </p>
              </div>
            )}
            <div>
              {trial.placement && !trial.dsq && (
                <Medal placement={trial.placement} />
              )}
            </div>
          </div>
        )}

        {trial.videoUrl && trial.videoUrl.indexOf("instagram") === -1 && (
          <div className="p">
            <ReactPlayer url={trial.videoUrl} controls width="100%" />
          </div>
        )}

        {trial.videoUrl && trial.videoUrl.indexOf("instagram") !== -1 && (
          <div className="p">
            <InstagramEmbed url={trial.videoUrl} />
          </div>
        )}

        <p className="multiLine">{trial.notes}</p>

        {trial.courseMap && (
          <div className="p">
            {t("trial.courseMap")}
            {trial.courseMap.indexOf(".pdf") !== -1 && (
              <Document file={trial.courseMap}>
                <Page pageNumber={1} />
              </Document>
            )}
            {trial.courseMap.indexOf(".pdf") === -1 && (
              <Image src={trial.courseMap} alt={t("trial.courseMap")} />
            )}
          </div>
        )}

        {/* TODO: tsekkaa tästä alaspäin */}
        {/* standard, fast */}
        {(_.includes(STANDARD_COURSES, parseInt(trial.courseTypeId)) ||
          _.includes(FAST, parseInt(trial.courseTypeId))) &&
          trial.timeFaults &&
          !trial.dsq && (
            <div>
              <p className="text-grey no-margin">{t("trial.timeFaults")}</p>
              <p className="text-huge">{trial.timeFaults}</p>
            </div>
          )}

        {trial.faults && trial.faults.length > 0 && (
          <div>
            <p className="text-grey no-margin">{t("trial.courseFaults")}</p>
            <p className="h2 inline-list">
              {castToNestedFocusArray(trial.faults).map((fault) => (
                <span key={fault.key}>
                  {t(`misc.${fault.name}`)}
                  {!fault.children && `(${fault.count})`}
                  {fault.children &&
                    ": " +
                      fault.children
                        .map(
                          (subFault) =>
                            t(`misc.${subFault.name}`) + `(${subFault.count})`
                        )
                        .join(", ")}
                </span>
              ))}
            </p>
          </div>
        )}

        {trial.faultReasons && trial.faultReasons.length > 0 && (
          <div>
            <p className="text-grey no-margin">
              {t("trial.courseFaultReasons")}
            </p>
            <p className="h2 inline-list">
              {castToNestedFocusArray(trial.faultReasons).map((faultReason) => (
                <span key={faultReason.key}>
                  {t(`misc.${faultReason.name}`)}
                  {faultReason.children &&
                    ": " +
                      faultReason.children
                        .map((subFaultReason) =>
                          t(`misc.${subFaultReason.name}`)
                        )
                        .join(", ")}
                </span>
              ))}
            </p>
          </div>
        )}

        {trial.judge && (
          <div>
            <p className="tag no-margin">{t("trial.judge")}</p>
            <p className="h1">{trial.judge}</p>
          </div>
        )}
        {trial.certs && (
          <div>
            <p className="tag no-margin">{t("trial.certs")}</p>
            <p className="h1">{trial.certs}</p>
          </div>
        )}
      </React.Fragment>
    );
  }
}

TrialView.propTypes = {
  trial: PropTypes.object,
  t: PropTypes.func,
  history: PropTypes.object,
};

export default withTranslation()(TrialView);
