import {
  FETCH_TRIAL_STATS,
  FETCH_TRAINING_STATS,
  FETCH_TRAINING_FOCUS_STATS,
  FETCH_ACTIVITY_STATS,
  FETCH_TRIAL_POSITION_STATS,
  FETCH_CLEAN_RUN_STATS,
  FETCH_SPEED_STATS,
  FETCH_TRIAL_LIST_SPEED_STATS,
  FETCH_TRIAL_LIST_CLEAN_RUN_STATS,
  FETCH_TRIAL_LIST_FAULT_REASON_STATS,
  FETCH_TRIAL_LIST_FAULT_STATS,
  FETCH_TRIAL_LIST_POINT_STATS,
  FETCH_TRIAL_LIST_TIME_STATS,
  FETCH_TRIAL_LIST_DESIGNATED_TIME_STATS
} from "./types";

export const fetchTrialStats = (startDate, endDate) => ({
  type: FETCH_TRIAL_STATS.REQUEST,
  payload: { startDate: startDate, endDate: endDate }
});

export const fetchTrialStatsSucceeded = stats => ({
  type: FETCH_TRIAL_STATS.SUCCEEDED,
  payload: stats
});

export const fetchTrialStatsFailed = error => ({
  type: FETCH_TRIAL_STATS.FAILED,
  payload: error
});

export const fetchTrainingStats = (startDate, endDate) => ({
  type: FETCH_TRAINING_STATS.REQUEST,
  payload: { startDate: startDate, endDate: endDate }
});

export const fetchTrainingStatsSucceeded = stats => ({
  type: FETCH_TRAINING_STATS.SUCCEEDED,
  payload: stats
});

export const fetchTrainingStatsFailed = error => ({
  type: FETCH_TRAINING_STATS.FAILED,
  payload: error
});

export const fetchTrainingFocusStats = params => ({
  type: FETCH_TRAINING_FOCUS_STATS.REQUEST,
  payload: params
});

export const fetchTrainingFocusStatsSucceeded = stats => ({
  type: FETCH_TRAINING_FOCUS_STATS.SUCCEEDED,
  payload: stats
});

export const fetchTrainingFocusStatsFailed = error => ({
  type: FETCH_TRAINING_FOCUS_STATS.FAILED,
  payload: error
});

export const fetchActivityStats = (startDate, endDate) => ({
  type: FETCH_ACTIVITY_STATS.REQUEST,
  payload: { startDate: startDate, endDate: endDate }
});

export const fetchActivityStatsSucceeded = stats => ({
  type: FETCH_ACTIVITY_STATS.SUCCEEDED,
  payload: stats
});

export const fetchActivityStatsFailed = error => ({
  type: FETCH_ACTIVITY_STATS.FAILED,
  payload: error
});

export const fetchTrialPositionStats = (startDate, endDate) => ({
  type: FETCH_TRIAL_POSITION_STATS.REQUEST,
  payload: { startDate: startDate, endDate: endDate }
});

export const fetchTrialPositionStatsSucceeded = stats => ({
  type: FETCH_TRIAL_POSITION_STATS.SUCCEEDED,
  payload: stats
});

export const fetchTrialPositionStatsFailed = error => ({
  type: FETCH_TRIAL_POSITION_STATS.FAILED,
  payload: error
});

export const fetchCleanRunStats = (startDate, endDate) => ({
  type: FETCH_CLEAN_RUN_STATS.REQUEST,
  payload: { startDate: startDate, endDate: endDate }
});

export const fetchCleanRunStatsSucceeded = stats => ({
  type: FETCH_CLEAN_RUN_STATS.SUCCEEDED,
  payload: stats
});

export const fetchCleanRunStatsFailed = error => ({
  type: FETCH_CLEAN_RUN_STATS.FAILED,
  payload: error
});

export const fetchSpeedStats = (startDate, endDate) => ({
  type: FETCH_SPEED_STATS.REQUEST,
  payload: { startDate: startDate, endDate: endDate }
});

export const fetchSpeedStatsSucceeded = stats => ({
  type: FETCH_SPEED_STATS.SUCCEEDED,
  payload: stats
});

export const fetchSpeedStatsFailed = error => ({
  type: FETCH_SPEED_STATS.FAILED,
  payload: error
});

export const fetchTrialListSpeedStats = params => ({
  type: FETCH_TRIAL_LIST_SPEED_STATS.REQUEST,
  payload: params
});

export const fetchTrialListSpeedStatsSucceeded = stats => ({
  type: FETCH_TRIAL_LIST_SPEED_STATS.SUCCEEDED,
  payload: stats
});

export const fetchTrialListSpeedStatsFailed = error => ({
  type: FETCH_TRIAL_LIST_SPEED_STATS.FAILED,
  payload: error
});

export const fetchTrialListCleanRunStats = params => ({
  type: FETCH_TRIAL_LIST_CLEAN_RUN_STATS.REQUEST,
  payload: params
});

export const fetchTrialListCleanRunStatsSucceeded = stats => ({
  type: FETCH_TRIAL_LIST_CLEAN_RUN_STATS.SUCCEEDED,
  payload: stats
});

export const fetchTrialListCleanRunStatsFailed = error => ({
  type: FETCH_TRIAL_LIST_CLEAN_RUN_STATS.FAILED,
  payload: error
});

export const fetchTrialListFaultReasonStats = params => ({
  type: FETCH_TRIAL_LIST_FAULT_REASON_STATS.REQUEST,
  payload: params
});

export const fetchTrialListFaultReasonStatsSucceeded = stats => ({
  type: FETCH_TRIAL_LIST_FAULT_REASON_STATS.SUCCEEDED,
  payload: stats
});

export const fetchTrialListFaultReasonStatsFailed = error => ({
  type: FETCH_TRIAL_LIST_FAULT_REASON_STATS.FAILED,
  payload: error
});

export const fetchTrialListFaultStats = params => ({
  type: FETCH_TRIAL_LIST_FAULT_STATS.REQUEST,
  payload: params
});

export const fetchTrialListFaultStatsSucceeded = stats => ({
  type: FETCH_TRIAL_LIST_FAULT_STATS.SUCCEEDED,
  payload: stats
});

export const fetchTrialListFaultStatsFailed = error => ({
  type: FETCH_TRIAL_LIST_FAULT_STATS.FAILED,
  payload: error
});
export const fetchTrialListPointStats = params => ({
  type: FETCH_TRIAL_LIST_POINT_STATS.REQUEST,
  payload: params
});

export const fetchTrialListPointStatsSucceeded = stats => ({
  type: FETCH_TRIAL_LIST_POINT_STATS.SUCCEEDED,
  payload: stats
});

export const fetchTrialListPointStatsFailed = error => ({
  type: FETCH_TRIAL_LIST_POINT_STATS.FAILED,
  payload: error
});

export const fetchTrialListTimeStats = params => ({
  type: FETCH_TRIAL_LIST_TIME_STATS.REQUEST,
  payload: params
});

export const fetchTrialListTimeStatsSucceeded = stats => ({
  type: FETCH_TRIAL_LIST_TIME_STATS.SUCCEEDED,
  payload: stats
});

export const fetchTrialListTimeStatsFailed = error => ({
  type: FETCH_TRIAL_LIST_TIME_STATS.FAILED,
  payload: error
});

export const fetchTrialListDesignatedTimeStats = params => ({
  type: FETCH_TRIAL_LIST_DESIGNATED_TIME_STATS.REQUEST,
  payload: params
});

export const fetchTrialListDesignatedTimeStatsSucceeded = stats => ({
  type: FETCH_TRIAL_LIST_DESIGNATED_TIME_STATS.SUCCEEDED,
  payload: stats
});

export const fetchTrialListDesignatedTimeStatsFailed = error => ({
  type: FETCH_TRIAL_LIST_DESIGNATED_TIME_STATS.FAILED,
  payload: error
});
