import React from "react";
import PropTypes from "prop-types";

const Progress = ({ percent, topiclist, className }) => (
  <div style={{ position: "relative" }}>
    <div className={`progress ${className}`}>
      <div
        className="progress-bar bg-success"
        role="progressbar"
        style={{ width: `${percent}%` }}
        aria-valuenow={percent}
        aria-valuemin={0}
        aria-valuemax={100}
      />
    </div>
    {topiclist && percent > 99 ? (
      <img
        src={require("../images/icons/check.svg")}
        className="progress-icon round-icon bg-success"
        alt={"checkmark"}
      />
    ) : (
      <div />
    )}
  </div>
);

Progress.propTypes = {
  percent: PropTypes.number,
  topiclist: PropTypes.bool,
  className: PropTypes.string
};

Progress.defaultProps = {
  topiclist: false,
  className: ""
};

export default Progress;
