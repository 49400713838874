import React from "react";
import { TransitionGroup, CSSTransition } from "react-transition-group";

const Fade = ({ animationKey, children }) => (
  <TransitionGroup>
    <CSSTransition key={animationKey} timeout={400} classNames="fade">
      <div className="fade">{children}</div>
    </CSSTransition>
  </TransitionGroup>
);

export default Fade;
