import React, { Component } from "react";
import { Route } from "react-router-dom";
import { connect } from "react-redux";

import { AppContent, Container, Progress } from "../common";
import DogOnboarding from "../containers/Dog/DogOnboarding";
import Signup from "../containers/Auth/Signup";
import Header from "../components/Header";

class OnboardingRoutes extends Component {
  render() {
    const progressPercent = this.props.isAuthenticated ? 66.66 : 33.33;

    return (
      <div className="app">
        <Header actions={false} />
        <AppContent>
          <Container size="small">
            <Progress percent={progressPercent} />
          </Container>

          <Route exact path="/signup" component={Signup} />
          <Route exact path="/signup/add-dog" component={DogOnboarding} />
        </AppContent>
      </div>
    );
  }
}

const mapStateToProps = ({ auth }) => ({
  isAuthenticated: auth.isAuthenticated
});

export default connect(mapStateToProps)(OnboardingRoutes);
