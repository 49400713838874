import { FETCH_JUDGES_FILTER, FETCH_TRIAL_LOCATIONS_FILTER } from "./types";

export const fetchJudgesFilter = () => ({
  type: FETCH_JUDGES_FILTER.REQUEST,
});

export const fetchJudgesFilterSucceeded = (judges) => ({
  type: FETCH_JUDGES_FILTER.SUCCEEDED,
  payload: judges,
});

export const fetchJudgesFilterFailed = (error) => ({
  type: FETCH_JUDGES_FILTER.FAILED,
  payload: error,
});

export const fetchTrialLocationsFilter = () => ({
  type: FETCH_TRIAL_LOCATIONS_FILTER.REQUEST,
});

export const fetchTrialLocationsFilterSucceeded = (locations) => ({
  type: FETCH_TRIAL_LOCATIONS_FILTER.SUCCEEDED,
  payload: locations,
});

export const fetchTrialLocationsFilterFailed = (error) => ({
  type: FETCH_TRIAL_LOCATIONS_FILTER.FAILED,
  payload: error,
});
