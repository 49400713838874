import { LOGOUT } from "../actions/types";

const INITIAL_STATE = [
  {
    name: "obstacle-skills",
    children: [
      { name: "double-jump" },
      { name: "wall-jump" },
      { name: "long-jump" },
      { name: "tyre" },
      { name: "a-frame" },
      { name: "dog-walk" },
      { name: "see-saw" },
      { name: "weave-poles" },
      { name: "table" },
      { name: "tunnel" },
      { name: "chute" }
    ]
  },
  {
    name: "obstacle-differentiation",
    children: [
      { name: "double-jump" },
      { name: "wall-jump" },
      { name: "long-jump" },
      { name: "tyre" },
      { name: "a-frame" },
      { name: "dog-walk" },
      { name: "see-saw" },
      { name: "weave-poles" },
      { name: "table" },
      { name: "tunnel" },
      { name: "chute" }
    ]
  },
  { name: "agility-skills-and-cooperation" },
  { name: "dogs-physique" },
  { name: "handlers-physique" },
  { name: "jumping-technique" },
  { name: "dogs-drive" },
  { name: "handlers-mental-factors" }
];

export default (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case LOGOUT.REQUEST:
      return [...INITIAL_STATE];
    default:
      return state;
  }
};
