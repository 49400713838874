import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import PaywallNotification from "../../containers/App/PaywallNotification";
import moment from "moment";
import { withTranslation } from "react-i18next";

const CalendarAddDialog = (props) => {
  const { t, hasActivePlan, setAddOpen, addOpen, addDate, addPosition } = props;

  // eslint-disable-next-line react/prop-types
  const ActionMenuItem = ({ children, to }) => (
    <li className="action-menu__item action-menu__item--center">
      <Link to={to} className="btn btn-primary btn-sm" >
        {children}
      </Link>
    </li>
  );

  const toggleState = addOpen ? "open" : "closed";

  if (addOpen && addPosition) {
    // If mouse click then has x and y but if touch then different.
    let x = addPosition.x ? addPosition.x : addPosition.changedTouches[0].clientX;
    let y = addPosition.y ? addPosition.y : addPosition.changedTouches[0].clientY;
    if (x <= 75 + 16 ) x= 75 + 16;
    // TODO: Mobile is good with 16 but browser with 32 ?? Now compromise.
    if (x >= addPosition.view.innerWidth - 75 - 24 ) x=addPosition.view.innerWidth - 75 - 24;

    const dateString = moment(addDate).format("YYYY-MM-DD");

    return(
      <div>
        <div
          className={`overlay overlay-calendar overlay--${toggleState}`}
          onClick={() => setAddOpen(false)}
        />
        <div className={`add-popup calendar-popup calendar-popup--${toggleState}`} 
          style={{top:`${y}px`, left:`${x}px`}}>
          <p style={{textAlign:"center"}}>{moment(addDate).format("L")}</p>
          {hasActivePlan ? 
            <ul className={"action-menu"}>          
              <ActionMenuItem to={`training-diary/add/date/${dateString}`} >
                {t("actionMenu.addTraining")}
              </ActionMenuItem>
              <ActionMenuItem to={`/trial/add/date/${dateString}`} >
                {t("actionMenu.addTrial")}
              </ActionMenuItem>         
            </ul> 
            :
            <div style={{textAlign:"center"}}>
              <p>{t("actionMenu.addTraining")}
                <br/>{t("actionMenu.addTrial")}
              </p>
              <PaywallNotification
                text=""
                button="subscription.noPlanYet"
              />
            </div>}
        </div>
      </div>
    );
  } 
  else return null;

};

CalendarAddDialog.propTypes = {
  t: PropTypes.func,
  hasActivePlan: PropTypes.bool,
  setAddOpen: PropTypes.func,
  addOpen: PropTypes.bool,
  addPosition: PropTypes.object,
  addDate: PropTypes.object
};

export default withTranslation()(CalendarAddDialog);

