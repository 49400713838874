import { select, takeLatest, put, call } from "redux-saga/effects";

import { getAuthToken } from "./selectors";
import Api from "../helpers/Api";
import {
  fetchCurrentPlanSucceeded,
  fetchCurrentPlanFailed,
  fetchPlansSucceeded,
  fetchPlansFailed,
  subscribeToPlanSucceeded,
  subscribeToPlanFailed,
  cancelSubscriptionSucceeded,
  cancelSubscriptionFailed,
  reactivateSubscriptionSucceeded,
  reactivateSubscriptionFailed,
  updateCreditCardSucceeded,
  updateCreditCardFailed,
  getPaymentsSucceeded,
  getPaymentsFailed,
  createCustomerSucceeded,
  createCustomerFailed,
  createCustomerPortalSessionSucceeded,
  createCustomerPortalSessionFailed,
} from "../actions/SubscriptionActions";
import {
  FETCH_CURRENT_PLAN,
  FETCH_PLANS,
  SUBSCRIBE,
  CANCEL_SUBSCRIBE,
  REACTIVATE_SUBSCRIPTION,
  UPDATE_CREDIT_CARD,
  FETCH_PAYMENTS,
  CREATE_CUSTOMER,
  CREATE_CUSTOMER_PORTAL_SESSION,
} from "../actions/types";

function* fetchCurrentPlan(action) {
  try {
    const auth_token = yield select(getAuthToken);
    const res = yield call(Api({ auth_token }).getCurrentPlan);
    yield put(fetchCurrentPlanSucceeded(res.data));
  } catch (e) {
    yield put(fetchCurrentPlanFailed(null));
  }
}

function* fetchPlans(action) {
  try {
    const auth_token = yield select(getAuthToken);
    const res = yield call(Api({ auth_token }).getPlans);
    yield put(fetchPlansSucceeded(res.data));
  } catch (e) {
    yield put(fetchPlansFailed(null));
  }
}

function* subscribeToPlan(action) {
  try {
    const auth_token = yield select(getAuthToken);
    const res = yield call(
      Api({ auth_token: auth_token }).subscribe,
      action.payload.customerId,
      action.payload.paymentMethodId,
      action.payload.planId
    );
    yield put(subscribeToPlanSucceeded(res.data));
    yield call(fetchCurrentPlan);
    yield call(getPayments);
  } catch (e) {
    let errorMessage = {};
    if (e.response && e.response.data) {
      errorMessage = e.response.data.errors;
    }
    yield put(subscribeToPlanFailed(errorMessage));
  }
}

function* createCustomer(action) {
  try {
    const auth_token = yield select(getAuthToken);
    const res = yield call(
      Api({ auth_token: auth_token }).createCustomer,
      action.payload.email,
      action.payload.firstName,
      action.payload.lastName
    );
    yield put(createCustomerSucceeded(res.data));
  } catch (e) {
    let errorMessage = {};
    if (e.response && e.response.data) {
      errorMessage = e.response.data.errors;
    }
    yield put(createCustomerFailed(errorMessage));
  }
}

function* cancelPlan(action) {
  try {
    const auth_token = yield select(getAuthToken);
    const res = yield call(Api({ auth_token }).cancelPlan, action.payload.id);
    yield put(cancelSubscriptionSucceeded(res.data));
    yield call(fetchCurrentPlan);
  } catch (e) {
    yield put(cancelSubscriptionFailed(e));
  }
}

function* reactivatePlan(action) {
  try {
    const auth_token = yield select(getAuthToken);
    const res = yield call(Api({ auth_token }).reactivatePlan);
    yield put(reactivateSubscriptionSucceeded(res.data));
    yield call(fetchCurrentPlan);
  } catch (e) {
    yield put(reactivateSubscriptionFailed(e));
  }
}

function* updateCreditCard(action) {
  try {
    const auth_token = yield select(getAuthToken);
    const res = yield call(
      Api({ auth_token }).updateCreditCard,
      action.payload.token
    );
    yield put(updateCreditCardSucceeded(res.data));
  } catch (e) {
    let errorMessage = {};
    if (e.response && e.response.data) {
      errorMessage = e.response.data.errors;
    }
    yield put(updateCreditCardFailed(errorMessage));
  }
}

function* getPayments(action) {
  try {
    const auth_token = yield select(getAuthToken);
    const res = yield call(Api({ auth_token }).getPayments);
    yield put(getPaymentsSucceeded(res.data));
  } catch (e) {
    yield put(getPaymentsFailed(e));
  }
}

function* createCustomerPortalSession() {
  try {
    const auth_token = yield select(getAuthToken);
    const res = yield call(Api({ auth_token }).createCustomerPortalSession);
    yield put(createCustomerPortalSessionSucceeded(res.data));
  } catch (e) {
    yield put(createCustomerPortalSessionFailed(e));
  }
}

export default function* subscriptionSaga() {
  yield takeLatest(FETCH_CURRENT_PLAN.REQUEST, fetchCurrentPlan);
  yield takeLatest(FETCH_PLANS.REQUEST, fetchPlans);
  yield takeLatest(SUBSCRIBE.REQUEST, subscribeToPlan);
  yield takeLatest(CANCEL_SUBSCRIBE.REQUEST, cancelPlan);
  yield takeLatest(REACTIVATE_SUBSCRIPTION.REQUEST, reactivatePlan);
  yield takeLatest(UPDATE_CREDIT_CARD.REQUEST, updateCreditCard);
  yield takeLatest(FETCH_PAYMENTS.REQUEST, getPayments);
  yield takeLatest(CREATE_CUSTOMER.REQUEST, createCustomer);
  yield takeLatest(
    CREATE_CUSTOMER_PORTAL_SESSION.REQUEST,
    createCustomerPortalSession
  );
}
