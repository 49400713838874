import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import queryString from "query-string";
import _ from "lodash";
import PropTypes from "prop-types";
import moment from "moment";

import {
  AppContent,
  Container,
  Card,
  SelectGroup,
  Loader,
  RoundButton,
} from "../../common";
import Chart from "../../components/Chart";
import TrialCard from "../../components/TrialCard";
import {
  fetchTrialsPaged,
  fetchTrialsPagesCount,
  fetchTrialsCount,
  fetchTrialListSpeedStats,
  fetchTrialListCleanRunStats,
  fetchTrialListFaultReasonStats,
  fetchTrialListFaultStats,
  fetchTrialListPointStats,
  fetchTrialListTimeStats,
  fetchTrialListDesignatedTimeStats,
} from "../../actions";
import PaywallNotification from "../../containers/App/PaywallNotification";
import { Premium } from "../../helpers/Constants";
import TrialFilter from "../../components/TrialFilter";
const VIEWS = {
  CHARTS: "charts",
  FEED: "feed",
};

//TODO: FIX THIS!!!
const ORGANIZATIONS = {
  ALL: 0,
  FCI: 1,
  WAO: 2,
  IFCS: 3,
  KC: 4,
  UKA: 5,
  UKI: 6,
  USDAA: 7,
  AKC: 8,
  AAC: 9,
  SADAA: 10,
  KUSA: 11,
};

class CompetitionList extends Component {
  constructor(props) {
    super(props);
    let t = props.t;
    this.state = {
      viewState: VIEWS.FEED,
      filters: {
        datesFilter: null,
        organizationsFilter: "",
        sizeClassesFilter: [],
        judgesFilter: [],
        trialLocationsFilter: [],
        resultsFilter: "",
        placementsFilter: [],
      },
      loadFeed: true,
    };
    this.initialState = this.state;
    this.chartNames = {
      cleanRunChart: "CleanRun",
      speedChart: "Speed",
      trialFaultChart: "Fault",
      trialFaultReasonChart: "FaultReason",
      pointChart: "Point",
      timeChart: "Time",
      designatedTimeChart: "DesignatedTime",
    };

    this.orgCharts = {
      [ORGANIZATIONS.ALL]: [
        this.chartNames.cleanRunChart,
        this.chartNames.speedChart,
        this.chartNames.trialFaultChart,
        this.chartNames.trialFaultReasonChart,
        this.chartNames.pointChart,
        this.chartNames.designatedTimeChart,
        this.chartNames.timeChart,
      ],
      [ORGANIZATIONS.FCI]: [
        this.chartNames.cleanRunChart,
        this.chartNames.speedChart,
        this.chartNames.trialFaultChart,
        this.chartNames.trialFaultReasonChart,
      ],
      [ORGANIZATIONS.WAO]: [
        this.chartNames.cleanRunChart,
        this.chartNames.speedChart,
        this.chartNames.trialFaultChart,
        this.chartNames.trialFaultReasonChart,
        this.chartNames.pointChart,
      ],
      [ORGANIZATIONS.IFCS]: [
        this.chartNames.cleanRunChart,
        this.chartNames.speedChart,
        this.chartNames.trialFaultChart,
        this.chartNames.trialFaultReasonChart,
        this.chartNames.pointChart,
        this.chartNames.designatedTimeChart,
      ],
      [ORGANIZATIONS.KC]: [
        this.chartNames.cleanRunChart,
        this.chartNames.speedChart,
        this.chartNames.trialFaultChart,
        this.chartNames.trialFaultReasonChart,
      ],
      [ORGANIZATIONS.UKA]: [
        this.chartNames.cleanRunChart,
        this.chartNames.speedChart,
        this.chartNames.trialFaultChart,
        this.chartNames.trialFaultReasonChart,
        this.chartNames.pointChart,
        this.chartNames.timeChart,
      ],
      [ORGANIZATIONS.UKI]: [
        this.chartNames.cleanRunChart,
        this.chartNames.speedChart,
        this.chartNames.trialFaultChart,
        this.chartNames.trialFaultReasonChart,
        this.chartNames.pointChart,
        this.chartNames.timeChart,
      ],
      [ORGANIZATIONS.USDAA]: [
        this.chartNames.cleanRunChart,
        this.chartNames.speedChart,
        this.chartNames.trialFaultChart,
        this.chartNames.trialFaultReasonChart,
        this.chartNames.pointChart,
        this.chartNames.designatedTimeChart,
      ],
      [ORGANIZATIONS.AKC]: [
        this.chartNames.cleanRunChart,
        this.chartNames.speedChart,
        this.chartNames.trialFaultChart,
        this.chartNames.trialFaultReasonChart,
        this.chartNames.pointChart,
        this.chartNames.timeChart,
      ],
      [ORGANIZATIONS.AAC]: [
        this.chartNames.cleanRunChart,
        this.chartNames.speedChart,
        this.chartNames.trialFaultChart,
        this.chartNames.trialFaultReasonChart,
        this.chartNames.pointChart,
      ],
      [ORGANIZATIONS.SADAA]: [
        this.chartNames.cleanRunChart,
        this.chartNames.speedChart,
        this.chartNames.trialFaultChart,
        this.chartNames.trialFaultReasonChart,
        this.chartNames.pointChart,
      ],
      [ORGANIZATIONS.KUSA]: [
        this.chartNames.cleanRunChart,
        this.chartNames.speedChart,
        this.chartNames.trialFaultChart,
        this.chartNames.trialFaultReasonChart,
      ],
    };
    this.courseTypes = {
      all: {
        name: "all",
        value: 0,
        label: t("chart.all"),
      },
      agility: {
        name: "Agility+course",
        value: 1,
        label: t("chart.agilityCourses"),
      },
      jumping: {
        name: "Jumping+course",
        value: 2,
        label: t("chart.jumpingCourses"),
      },
      speedStakes: {
        name: "Speedstakes+course",
        value: 3,
        label: t("chart.speedstakesCourses"),
      },
      gambler: {
        name: "Gamblers+course",
        value: 4,
        label: t("chart.gamblersCourses"),
      },
      snooker: {
        name: "Snooker+course",
        value: 5,
        label: t("chart.snookerCourses"),
      },
      timeGamble: {
        name: "Time-Gamble+course",
        value: 6,
        label: t("chart.timeGambleCourses"),
      },
      powerAndSpeed: {
        name: "Power+and+speed+course",
        value: 7,
        label: t("chart.powerAndSpeedCourses"),
      },
      FAST: {
        name: "FAST+course",
        value: 8,
        label: t("chart.FASTCourses"),
      },
      time2Beat: {
        name: "Time+2+Beat+course",
        value: 9,
        label: t("chart.time2BeatCourses"),
      },
      snakesAndLadders: {
        name: "Snakes+and+ladders+course",
        value: 10,
        label: t("chart.snakesAndLaddersCourses"),
      },
      timeFaultAndOut: {
        name: "Time+Fault+and+Out+course",
        value: 11,
        label: t("chart.timeFaultAndOutCourses"),
      },
      dogJumping: {
        name: "Dog+jumping+course",
        value: 12,
        label: t("chart.dogJumpingCourses"),
      },
    };
  }

  goToAddTrialPage = () => {
    const { push } = this.props.history;
    push("/trial/add");
  };

  componentDidMount() {
    this.parseFilters();
    for (var chart in this.chartNames) {
      this.setState({
        [this.chartNames[chart]]: {
          courseTypeId: null,
        },
      });
    }

    window.addEventListener("scroll", this.loadTrialsOnScroll.bind(this));
  }

  componentDidUpdate(prevProps, prevState) {
    let currentState = this.state;
    if (
      !_.isEqual(prevState.loadFeed, currentState.loadFeed) &&
      currentState.loadFeed
    ) {
      this.updateFeed(true);
    }
    _.forOwn(prevState.filters, (value, key) => {
      if (!_.isEqual(value, currentState.filters[key])) {
        this.updateTrialsCount();
      }
    });

    for (var chart in this.chartNames) {
      var needsUpdate = this.checkForUpdate(
        this.chartNames[chart],
        prevState,
        this.state
      );
      if (needsUpdate) {
        this.updateChart(this.chartNames[chart]);
      }
    }
  }

  checkForUpdate(chart, prevState, currentState) {
    let needsUpdate = false;
    // Common for all charts
    if (
      prevState.filters.organizationsFilter !==
      this.state.filters.organizationsFilter
    )
      needsUpdate = true;
    if (
      !_.isEqual(prevState.filters.sizeClasses, this.state.filters.sizeClasses)
    )
      needsUpdate = true;

    if (
      !_.isEqual(prevState.loadFeed, currentState.loadFeed) &&
      currentState.loadFeed
    )
      needsUpdate = true;
    // Chart specific
    if (!needsUpdate && prevState[chart] && currentState[chart]) {
      if (prevState[chart].courseTypeId !== currentState[chart].courseTypeId) {
        needsUpdate = true;
      }
    }
    return needsUpdate;
  }

  updateFeed = (reset) => {
    this.props.fetchTrialsPagesCount(this.state.filters);
    this.props.fetchTrialsCount(this.state.filters);
    if (reset) {
      this.props.fetchTrialsPaged(1, this.state.filters);
    } else {
      this.props.fetchTrialsPaged(
        this.props.trialsPage + 1,
        this.state.filters
      );
    }
  };

  updateChart = (chart) => {
    let params = this.state[chart];
    params.filters = this.state.filters;
    // eslint-disable-next-line no-eval
    eval("this.props.fetchTrialList" + chart + "Stats(params)");
  };

  updateTrialsCount = () => {
    this.props.fetchTrialsCount(this.state.filters);
  };

  loadTrialsOnScroll = () => {
    //If all the content loaded
    if (this.props.trialsPage > this.props.trialsPages) return;

    //Get div at the bottom of the content
    //var el = document.getElementById("content-end");
    let elements = document.getElementsByClassName("loader-bounce--trial-list");

    if (elements && elements.length > 0) {
      var element = elements[0];
    }
    if (element) {
      var rect = element.getBoundingClientRect();

      var isAtEnd =
        rect.bottom <=
          (window.innerHeight ||
            document.documentElement.clientHeight) /*or $(window).height() */ &&
        rect.right <=
          (window.innerWidth ||
            document.documentElement.clientWidth); /*or $(window).width() */
    }
    //User at the end of content. load more content
    if (isAtEnd) {
      //If content list is still loading do not request for another content list.
      if (this.props.fetchingTrialsPaged) return;
      this.updateFeed(false);
    }
  };

  componentWillUnmount() {
    window.removeEventListener("scroll", this.loadTrialsOnScroll);
  }

  openCard = (id) => {
    this.props.history.push(`/trial/${id}`);
  };

  getFilterString() {
    return JSON.stringify({
      ...{ viewState: this.state.viewState },
      ...this.state.filters,
    });
  }

  parseFilters() {
    let isFilter = queryString.parse(this.props.location.search);

    if (_.isEmpty(isFilter)) {
      this.setState(this.initialState);
      localStorage.removeItem("trialFilters");
      this.updateFeed(true);

      let organizationId =
        this.props.activeDog.organizations &&
        this.props.activeDog.organizations.length === 1
          ? this.props.activeDog.organizations[0].id
          : 0;

      this.setState((prevState) => ({
        filters: { ...prevState.filters, organizationsFilter: organizationId },
      }));
    } else {
      let storedFilters = JSON.parse(localStorage.getItem("trialFilters"));
      if (storedFilters) {
        const { viewState, ...filters } = storedFilters;
        this.setState((prevState) => ({
          viewState: viewState,
          filters: {
            ...prevState.filters,
            ...filters,
          },
        }));
      }
    }
  }

  setViewState = (e) => {
    this.setState({ viewState: e.target.value });
    let isFilter = queryString.parse(this.props.location.search);
    if (!_.isEmpty(isFilter)) {
      let storedFilters = JSON.parse(localStorage.getItem("trialFilters"));
      if (storedFilters) {
        const { viewState, ...filters } = storedFilters;
        localStorage.setItem(
          "trialFilters",
          JSON.stringify({
            ...{ viewState: e.target.value },
            ...filters,
          })
        );
      }
    }
  };

  render() {
    const { viewState } = this.state;
    const {
      trials,
      fetchingTrialsPaged,
      t,
      trialsCount,
      fetchingTrialsCount,
    } = this.props;

    if (!fetchingTrialsPaged && trials.length === 0) {
      return (
        <AppContent size="medium" noMargin>
          <div className="text-center">
            <p className="placeholder-text">{t("trial.noTrials")}</p>
            <Link to="/trial/add" className="btn btn-primary">
              {t("trial.addButton")}
            </Link>
          </div>
          <RoundButton
            size="large"
            atCorner
            icon="add"
            onClick={this.goToAddTrialPage}
          />
        </AppContent>
      );
    }

    return (
      <AppContent size="medium" noMargin>
        {fetchingTrialsPaged && <Loader name="trial-list" />}
        {!fetchingTrialsPaged && (
          <Container className="trial-tab-container">
            <SelectGroup
              label={t("training.type")}
              type="text"
              name="trainingType"
              value={viewState}
              options={[
                { title: t("trial.feed"), value: VIEWS.FEED },
                { title: t("trial.charts"), value: VIEWS.CHARTS },
              ]}
              onChange={this.setViewState}
            />
            <TrialFilter
              label={t("trial.filter")}
              trialLocationsFilter={this.state.filters.trialLocationsFilter}
              judgesFilter={this.state.filters.judgesFilter}
              datesFilter={this.state.filters.datesFilter}
              resultsFilter={this.state.filters.resultsFilter}
              placementsFilter={this.state.filters.placementsFilter}
              organizationsFilter={this.state.filters.organizationsFilter}
              sizeClassesFilter={this.state.filters.sizeClassesFilter}
              updateTrialLocationsFilter={this.setTrialLocationsFilter}
              updateJudgesFilter={this.setJudgesFilter}
              updateDateFilter={this.setDateFilter}
              updateResultsFilter={this.setResultFilter}
              updatePlacementsFilter={this.setPlacementsFilter}
              updateOrganizationsFilter={this.setOrganizationsFilter}
              updateSizeClassesFilter={this.setSizeClassesFilter}
              // udpdateCourseTypesFilter
              // updateSpeedsFilter
              // updateFaultsFilter
              trialsCount={trialsCount}
              fetchingTrialsCount={fetchingTrialsCount}
              applyFilters={this.applyFilters}
              cancelFilters={this.cancelFilters}
              preventFeedLoading={this.preventFeedLoading}
            />
          </Container>
        )}
        {viewState === VIEWS.FEED && this.renderFeed()}
        {viewState === VIEWS.CHARTS && this.renderCharts()}
        <RoundButton
          size="large"
          atCorner
          icon="add"
          onClick={this.goToAddTrialPage}
        />
      </AppContent>
    );
  }

  renderCharts = () => {
    const { t, trials, statistics } = this.props;
    const { filters } = this.state;
    const organizationId =
      filters && filters.organizationsFilter ? filters.organizationsFilter : 0;

    // const { organizationId, sizeClasses } = this.state;

    let loading =
      statistics.fetchingTrialListSpeeds ||
      statistics.fetchingTrialListCleanRuns ||
      statistics.fetchingTrialListFaultReasons ||
      statistics.fetchingTrialListFaults ||
      statistics.fetchingTrialListPoints ||
      statistics.fetchingTrialListTimes ||
      statistics.fetchingTrialListDesignatedTimes;

    let showCleanRunChart = _.includes(
      this.orgCharts[organizationId],
      this.chartNames.cleanRunChart
    );

    let showSpeedChart = _.includes(
      this.orgCharts[organizationId],
      this.chartNames.speedChart
    );

    let showTrialFaultChart = _.includes(
      this.orgCharts[organizationId],
      this.chartNames.trialFaultChart
    );

    let showTrialFaultReasonChart = _.includes(
      this.orgCharts[organizationId],
      this.chartNames.trialFaultReasonChart
    );

    let showPointChart = _.includes(
      this.orgCharts[organizationId],
      this.chartNames.pointChart
    );

    let showTimeChart = _.includes(
      this.orgCharts[organizationId],
      this.chartNames.timeChart
    );

    let showDesignatedTimeChart = _.includes(
      this.orgCharts[organizationId],
      this.chartNames.designatedTimeChart
    );

    return (
      <Container size="medium">
        <React.Fragment>
          {statistics.trialListCleanRuns && showCleanRunChart && (
            <Card>
              <Container size="small">
                <Chart
                  name={this.chartNames.cleanRunChart}
                  title={this.cleanRunChartTitle()}
                  data={this.getCleanRunStatistics()}
                  type="line"
                  courseTypes={this.getCourseTypes(
                    this.chartNames.cleanRunChart
                  )}
                  courseTypeId={
                    this.state[this.chartNames.cleanRunChart].courseTypeId
                  }
                  onCourseTypeChange={this.handleCourseTypeChange}
                  options={this.getCleanRunOptions()}
                />
              </Container>
            </Card>
          )}

          {statistics.trialListSpeeds && showSpeedChart && (
            <Card>
              <Container size="small">
                <Chart
                  name={this.chartNames.speedChart}
                  title={this.speedChartTitle()}
                  data={this.getSpeedByDateStatistics()}
                  type="line"
                  courseTypes={this.getCourseTypes(this.chartNames.speedChart)}
                  courseTypeId={
                    this.state[this.chartNames.speedChart].courseTypeId
                  }
                  onCourseTypeChange={this.handleCourseTypeChange}
                />
              </Container>
            </Card>
          )}

          {statistics.trialListPoints && showPointChart && (
            <Card>
              <Container size="small">
                <Chart
                  name={this.chartNames.pointChart}
                  title={this.pointChartTitle()}
                  data={this.getPointsByDateStatistics()}
                  type="line"
                  courseTypes={this.getCourseTypes(this.chartNames.pointChart)}
                  courseTypeId={
                    this.state[this.chartNames.pointChart].courseTypeId
                  }
                  onCourseTypeChange={this.handleCourseTypeChange}
                />
              </Container>
            </Card>
          )}

          {statistics.trialListTimes && showTimeChart && (
            <Card>
              <Container size="small">
                <Chart
                  name={this.chartNames.timeChart}
                  title={this.timeChartTitle()}
                  data={this.getTimesByDateStatistics()}
                  type="line"
                  courseTypes={this.getCourseTypes(this.chartNames.timeChart)}
                  courseTypeId={
                    this.state[this.chartNames.timeChart].courseTypeId
                  }
                  onCourseTypeChange={this.handleCourseTypeChange}
                />
              </Container>
            </Card>
          )}

          {statistics.trialListDesignatedTimes && showDesignatedTimeChart && (
            <Card>
              <Container size="small">
                <Chart
                  name={this.chartNames.designatedTimeChart}
                  title={this.designatedTimeChartTitle()}
                  data={this.getDesignatedTimesByDateStatistics()}
                  type="line"
                  courseTypes={this.getCourseTypes(
                    this.chartNames.designatedTimeChart
                  )}
                  courseTypeId={
                    this.state[this.chartNames.designatedTimeChart].courseTypeId
                  }
                  onCourseTypeChange={this.handleCourseTypeChange}
                />
              </Container>
            </Card>
          )}

          {statistics.trialListFaults && showTrialFaultChart && (
            <Card>
              <Container size="small">
                <Chart
                  name={this.chartNames.trialFaultChart}
                  title={t("trial.topCourseFaults")}
                  data={this.getFaultStatistics()}
                  type="polarArea"
                  courseTypes={this.getCourseTypes(
                    this.chartNames.trialFaultChart
                  )}
                  courseTypeId={
                    this.state[this.chartNames.trialFaultChart].courseTypeId
                  }
                  onCourseTypeChange={this.handleCourseTypeChange}
                />
              </Container>
            </Card>
          )}

          {statistics.trialListFaultReasons && showTrialFaultReasonChart && (
            <Card>
              <Container size="small">
                <Chart
                  name={this.chartNames.trialFaultReasonChart}
                  title={t("trial.topCourseFaultReasons")}
                  data={this.getFaultReasonStatistics()}
                  type="polarArea"
                  courseTypes={this.getCourseTypes(
                    this.chartNames.trialFaultReasonChart
                  )}
                  courseTypeId={
                    this.state[this.chartNames.trialFaultReasonChart]
                      .courseTypeId
                  }
                  onCourseTypeChange={this.handleCourseTypeChange}
                />
              </Container>
            </Card>
          )}
        </React.Fragment>

        {trials.length >= Premium.ITEM_LIMIT && (
          <PaywallNotification text="paywall.graph" />
        )}
        {loading && <Loader />}
      </Container>
    );
  };

  getCourseTypes = (chartName) => {
    const organizationId = parseInt(this.state.filters.organizationsFilter, 10);
    let courseTypes = [];
    if (!organizationId || organizationId === ORGANIZATIONS.ALL) {
      courseTypes.push(
        this.courseTypes.all,
        this.courseTypes.agility,
        this.courseTypes.jumping,
        this.courseTypes.speedStakes,
        this.courseTypes.snooker,
        this.courseTypes.gambler,
        this.courseTypes.powerAndSpeed,
        this.courseTypes.timeGamble,
        this.courseTypes.time2Beat,
        this.courseTypes.FAST,
        this.courseTypes.snakesAndLadders,
        this.courseTypes.timeFaultAndOut
      );
    } else if (organizationId === ORGANIZATIONS.FCI) {
      courseTypes.push(
        this.courseTypes.all,
        this.courseTypes.agility,
        this.courseTypes.jumping
      );
    } else if (organizationId === ORGANIZATIONS.WAO) {
      if (chartName !== this.chartNames.pointChart) {
        courseTypes.push(
          this.courseTypes.all,
          this.courseTypes.agility,
          this.courseTypes.jumping,
          this.courseTypes.speedStakes
        );
        if (
          chartName === this.chartNames.trialFaultChart ||
          chartName === this.chartNames.trialFaultReasonChart
        ) {
          courseTypes.push(this.courseTypes.snooker, this.courseTypes.gambler);
        }
      } else if (chartName === this.chartNames.pointChart) {
        courseTypes.push(
          this.courseTypes.all,
          this.courseTypes.snooker,
          this.courseTypes.gambler
        );
      }
    } else if (organizationId === ORGANIZATIONS.IFCS) {
      if (
        chartName !== this.chartNames.pointChart &&
        chartName !== this.chartNames.designatedTimeChart
      ) {
        courseTypes.push(
          this.courseTypes.all,
          this.courseTypes.agility,
          this.courseTypes.jumping,
          this.courseTypes.speedStakes,
          this.courseTypes.powerAndSpeed
        );
        if (
          chartName === this.chartNames.trialFaultChart ||
          chartName === this.chartNames.trialFaultReasonChart
        ) {
          courseTypes.push(
            this.courseTypes.snooker,
            this.courseTypes.gambler,
            this.courseTypes.timeGamble
          );
        }
      } else if (chartName === this.chartNames.pointChart) {
        courseTypes.push(
          this.courseTypes.all,
          this.courseTypes.snooker,
          this.courseTypes.gambler
        );
      } else if (chartName === this.chartNames.designatedTimeChart) {
        courseTypes.push(this.courseTypes.timeGamble);
      }
    } else if (organizationId === ORGANIZATIONS.USDAA) {
      if (
        chartName !== this.chartNames.pointChart &&
        chartName !== this.chartNames.designatedTimeChart
      ) {
        courseTypes.push(
          this.courseTypes.all,
          this.courseTypes.agility,
          this.courseTypes.jumping,
          this.courseTypes.speedStakes,
          this.courseTypes.powerAndSpeed
        );
        if (
          chartName === this.chartNames.trialFaultChart ||
          chartName === this.chartNames.trialFaultReasonChart
        ) {
          courseTypes.push(
            this.courseTypes.snooker,
            this.courseTypes.gambler,
            this.courseTypes.timeGamble
          );
        }
      } else if (chartName === this.chartNames.pointChart) {
        courseTypes.push(
          this.courseTypes.all,
          this.courseTypes.snooker,
          this.courseTypes.gambler
        );
      } else if (chartName === this.chartNames.designatedTimeChart) {
        courseTypes.push(this.courseTypes.timeGamble);
      }
    } else if (organizationId === ORGANIZATIONS.AKC) {
      if (
        chartName !== this.chartNames.pointChart &&
        chartName !== this.chartNames.speedChart &&
        chartName !== this.chartNames.timeChart
      ) {
        courseTypes.push(
          this.courseTypes.all,
          this.courseTypes.agility,
          this.courseTypes.jumping,
          this.courseTypes.speedStakes,
          this.courseTypes.time2Beat
        );
      } else if (chartName === this.chartNames.speedChart) {
        courseTypes.push(
          this.courseTypes.all,
          this.courseTypes.agility,
          this.courseTypes.jumping,
          this.courseTypes.speedStakes
        );
      } else if (chartName === this.chartNames.pointChart) {
        courseTypes.push(this.courseTypes.FAST);
      } else if (chartName === this.chartNames.timeChart) {
        courseTypes.push(
          this.courseTypes.all,
          this.courseTypes.FAST,
          this.courseTypes.time2Beat
        );
      }
    } else if (organizationId === ORGANIZATIONS.KC) {
      courseTypes.push(
        this.courseTypes.all,
        this.courseTypes.agility,
        this.courseTypes.jumping
      );
    } else if (organizationId === ORGANIZATIONS.UKI) {
      if (
        chartName !== this.chartNames.pointChart &&
        chartName !== this.chartNames.timeChart
      ) {
        courseTypes.push(
          this.courseTypes.all,
          this.courseTypes.agility,
          this.courseTypes.jumping,
          this.courseTypes.speedStakes,
          this.courseTypes.powerAndSpeed
        );
        if (
          chartName === this.chartNames.trialFaultChart ||
          chartName === this.chartNames.trialFaultReasonChart
        ) {
          courseTypes.push(this.courseTypes.snooker, this.courseTypes.gambler);
        }
      } else if (chartName === this.chartNames.pointChart) {
        courseTypes.push(
          this.courseTypes.all,
          this.courseTypes.snooker,
          this.courseTypes.gambler,
          this.courseTypes.snakesAndLadders
        );
      } else if (chartName === this.chartNames.timeChart) {
        courseTypes.push(this.courseTypes.snakesAndLadders);
      }
    } else if (organizationId === ORGANIZATIONS.UKA) {
      if (
        chartName !== this.chartNames.pointChart &&
        chartName !== this.chartNames.timeChart
      ) {
        courseTypes.push(
          this.courseTypes.all,
          this.courseTypes.agility,
          this.courseTypes.jumping,
          this.courseTypes.speedStakes,
          this.courseTypes.powerAndSpeed
        );
        if (
          chartName === this.chartNames.trialFaultChart ||
          chartName === this.chartNames.trialFaultReasonChart
        ) {
          courseTypes.push(
            this.courseTypes.snooker,
            this.courseTypes.gambler,
            this.courseTypes.timeFaultAndOut
          );
        }
      } else if (chartName === this.chartNames.pointChart) {
        courseTypes.push(
          this.courseTypes.all,
          this.courseTypes.snooker,
          this.courseTypes.gambler,
          this.courseTypes.snakesAndLadders
        );
      } else if (chartName === this.chartNames.timeChart) {
        courseTypes.push(this.courseTypes.snakesAndLadders);
      }
    } else if (organizationId === ORGANIZATIONS.AAC) {
      if (chartName !== this.chartNames.pointChart) {
        courseTypes.push(
          this.courseTypes.all,
          this.courseTypes.agility,
          this.courseTypes.jumping,
          this.courseTypes.speedStakes
        );
        if (
          chartName === this.chartNames.trialFaultChart ||
          chartName === this.chartNames.trialFaultReasonChart
        ) {
          courseTypes.push(this.courseTypes.snooker, this.courseTypes.gambler);
        }
      } else if (chartName === this.chartNames.pointChart) {
        courseTypes.push(
          this.courseTypes.all,
          this.courseTypes.snooker,
          this.courseTypes.gambler
        );
      }
    } else if (organizationId === ORGANIZATIONS.SADAA) {
      if (chartName !== this.chartNames.pointChart) {
        courseTypes.push(
          this.courseTypes.all,
          this.courseTypes.agility,
          this.courseTypes.jumping
        );
        if (
          chartName === this.chartNames.trialFaultChart ||
          chartName === this.chartNames.trialFaultReasonChart
        ) {
          courseTypes.push(this.courseTypes.snooker, this.courseTypes.gambler);
        }
      } else if (chartName === this.chartNames.pointChart) {
        courseTypes.push(
          this.courseTypes.all,
          this.courseTypes.snooker,
          this.courseTypes.gambler
        );
      }
    } else if (organizationId === ORGANIZATIONS.KUSA) {
      if (chartName !== this.chartNames.pointChart) {
        courseTypes.push(
          this.courseTypes.all,
          this.courseTypes.agility,
          this.courseTypes.jumping,
          this.courseTypes.dogJumping
        );
      }
    }

    return courseTypes;
  };

  getSpeedByDateStatistics() {
    const { statistics, t } = this.props;

    let rawSpeedStatistics = statistics.trialListSpeeds;

    if (!rawSpeedStatistics) {
      return {
        datasets: [
          {
            data: [],
            label: "",
          },
        ],
      };
    }

    const speedStatistics = {
      labels: rawSpeedStatistics.dates,
      datasets: [
        {
          label: t("trial.speed"),
          data: rawSpeedStatistics.speed,
        },
        {
          label: t("trial.winningSpeed"),
          data: rawSpeedStatistics.winningSpeed,
          backgroundColor: "rgba(164, 164, 164, 0.5)",
        },
      ],
    };

    return speedStatistics;
  }

  getCleanRunStatistics() {
    const { statistics } = this.props;
    return statistics.trialListCleanRuns.data;
  }

  getFaultStatistics() {
    const { statistics, t } = this.props;
    let faultStatistics = {
      labels: statistics.trialListFaults.labels
        ? statistics.trialListFaults.labels.map((l) => {
            let split = l.indexOf(":");
            if (split > 0) {
              return (
                t(`misc.${l.substring(0, split)}`) +
                ": " +
                t(`misc.${l.substring(split + 2, l.length)}`)
              );
            } else {
              return t(`misc.${l}`);
            }
          })
        : "",
      datasets: statistics.trialListFaults.datasets
        ? statistics.trialListFaults.datasets
        : [],
    };

    return faultStatistics;
  }

  getFaultReasonStatistics() {
    const { statistics, t } = this.props;
    let faultReasonStatistics = {
      labels: statistics.trialListFaultReasons.labels
        ? statistics.trialListFaultReasons.labels.map((l) => {
            let split = l.indexOf(":");
            if (split > 0) {
              return (
                t(`misc.${l.substring(0, split)}`) +
                ": " +
                t(`misc.${l.substring(split + 2, l.length)}`)
              );
            } else {
              return t(`misc.${l}`);
            }
          })
        : "",
      datasets: statistics.trialListFaultReasons.datasets
        ? statistics.trialListFaultReasons.datasets
        : [],
    };

    return faultReasonStatistics;
  }

  getPointsByDateStatistics() {
    const { statistics, t } = this.props;

    let rawPointStatistics = statistics.trialListPoints;

    if (!rawPointStatistics) {
      return {
        datasets: [
          {
            data: [],
            label: "",
          },
        ],
      };
    }

    const pointStatistics = {
      labels: rawPointStatistics.dates,
      datasets: [
        {
          label: t("trial.points"),
          data: rawPointStatistics.points,
        },
        {
          label: t("trial.winningPoints"),
          data: rawPointStatistics.winningPoints,
          backgroundColor: "rgba(164, 164, 164, 0.5)",
        },
      ],
    };

    return pointStatistics;
  }

  getTimesByDateStatistics() {
    const { statistics, t } = this.props;

    let rawTimeStatistics = statistics.trialListTimes;

    if (!rawTimeStatistics) {
      return {
        datasets: [
          {
            data: [],
            label: "",
          },
        ],
      };
    }

    const timeStatistics = {
      labels: rawTimeStatistics.dates,
      datasets: [
        {
          label: t("trial.times"),
          data: rawTimeStatistics.times,
          borderColor: "rgba(255, 99, 132, .5)",
          borderWidth: 5,
          fill: false,
        },
        {
          label: t("trial.winningTimes"),
          data: rawTimeStatistics.winningTimes,
          backgroundColor: "rgba(164, 164, 164, 0.5)",
          borderColor: "rgba(164, 164, 164, 0.5)",
          borderWidth: 5,
          fill: false,
        },
      ],
    };

    return timeStatistics;
  }

  getDesignatedTimesByDateStatistics() {
    const { statistics, t } = this.props;

    let rawDesignatedTimeStatistics = statistics.trialListDesignatedTimes;

    if (!rawDesignatedTimeStatistics) {
      return {
        datasets: [
          {
            data: [],
            label: "",
          },
        ],
      };
    }

    const designatedTimeStatistics = {
      labels: rawDesignatedTimeStatistics.dates,
      datasets: [
        {
          label: t("trial.designatedTimes"),
          data: rawDesignatedTimeStatistics.times,
        },
      ],
    };

    return designatedTimeStatistics;
  }

  getCleanRunOptions() {
    return {
      legend: {
        display: false,
      },
      scales: {
        yAxes: [
          {
            ticks: {
              min: 0,
              max: 100.0,
            },
          },
        ],
      },
    };
  }

  handleCourseTypeChange = (e) => {
    let { name, value } = e.target;
    this.setState((prevState) => ({
      [name]: {
        ...prevState[name],
        courseTypeId: parseInt(value),
      },
    }));
  };

  setOrganizationsFilter = (e) => {
    const dog = this.props.activeDog;

    // eslint-disable-next-line
    let { name, value } = e.target;
    let org = _.find(dog.organizations, { id: parseInt(value) });
    if (org) {
      this.setState((prevState) => ({
        filters: {
          ...prevState.filters,
          sizeClassesFilter: [],
          organizationsFilter: org.id,
        },
      }));
    } else {
      this.setState((prevState) => ({
        filters: {
          ...prevState.filters,
          sizeClassesFilter: [],
          organizationsFilter: "",
        },
      }));
    }
  };

  setSizeClassesFilter = (e) => {
    const target = e.target;
    const checked = target.checked;
    const name = target.name;
    const sizeClassId = name.substring(name.indexOf("_") + 1, name.length);

    if (checked) {
      this.setState((prevState) => ({
        filters: {
          ...prevState.filters,
          sizeClassesFilter: [
            ...prevState.filters.sizeClassesFilter,
            parseInt(sizeClassId),
          ],
        },
      }));
    } else {
      this.setState((prevState) => ({
        filters: {
          ...prevState.filters,
          sizeClassesFilter: prevState.filters.sizeClassesFilter
            ? prevState.filters.sizeClassesFilter.filter(
                (sizeClass) => parseInt(sizeClass) !== parseInt(sizeClassId)
              )
            : null,
        },
      }));
    }
  };

  speedChartTitle = () => {
    const { t, statistics, user } = this.props;

    let speedStatistics = statistics.trialListSpeeds;

    let averageSpeed =
      speedStatistics.speed && speedStatistics.speed.length > 0
        ? [...speedStatistics.speed].reduce((sum, item) => sum + item, 0) /
          speedStatistics.speed.length
        : 0;

    return (
      <div>
        {t("trial.speed")}{" "}
        {!Number.isNaN(averageSpeed) && averageSpeed !== undefined && (
          <span>
            ({averageSpeed.toFixed(1)}{" "}
            <small>{t("common.unit-speed-" + user.units)}</small>)
          </span>
        )}
      </div>
    );
  };

  cleanRunChartTitle = () => {
    const { t, statistics } = this.props;

    const cleanRunAverage = statistics.trialListCleanRuns.average
      ? statistics.trialListCleanRuns.average
      : 0;
    return (
      <div>
        {t("trial.cleanRuns")}{" "}
        {!Number.isNaN(cleanRunAverage) && (
          <span>({cleanRunAverage.toFixed(1)}%)</span>
        )}
      </div>
    );
  };

  pointChartTitle = () => {
    const { t, statistics } = this.props;

    let pointStatistics = statistics.trialListPoints;

    let averagePoints =
      pointStatistics.points && pointStatistics.points.length > 0
        ? [...pointStatistics.points].reduce((sum, item) => sum + item, 0) /
          pointStatistics.points.length
        : 0;

    return (
      <div>
        {t("trial.points")}{" "}
        {!Number.isNaN(averagePoints) && averagePoints !== undefined && (
          <span>({averagePoints.toFixed(1)})</span>
        )}
      </div>
    );
  };

  timeChartTitle = () => {
    const { t, statistics } = this.props;

    let timeStatistics = statistics.trialListTimes;

    let averageTimes =
      timeStatistics.times && timeStatistics.times.length > 0
        ? [...timeStatistics.times].reduce((sum, item) => sum + item, 0) /
          timeStatistics.times.length
        : 0;

    return (
      <div>
        {t("trial.times")}{" "}
        {!Number.isNaN(averageTimes) && averageTimes !== undefined && (
          <span>({averageTimes.toFixed(1)})</span>
        )}
      </div>
    );
  };

  designatedTimeChartTitle = () => {
    const { t, statistics } = this.props;

    let timeStatistics = statistics.trialListDesignatedTimes;

    let averageDesignatedTimes =
      timeStatistics.designatedTimes &&
      timeStatistics.designatedTimes.length > 0
        ? [...timeStatistics.designatedTimes].reduce(
            (sum, item) => sum + item,
            0
          ) / timeStatistics.designatedTimes.length
        : 0;

    return (
      <div>
        {t("trial.designatedTimes")}{" "}
        {!Number.isNaN(averageDesignatedTimes) &&
          averageDesignatedTimes !== undefined && (
            <span>({averageDesignatedTimes.toFixed(1)})</span>
          )}
      </div>
    );
  };

  setTrialLocationsFilter = (locations) => {
    this.setState((prevState) => ({
      filters: { ...prevState.filters, trialLocationsFilter: locations },
    }));
    this.updateTrialsCount();
  };

  setJudgesFilter = (judges) => {
    this.setState((prevState) => ({
      filters: { ...prevState.filters, judgesFilter: judges },
    }));
    this.updateTrialsCount();
  };

  setDateFilter = (dates) => {
    let startDate = dates.startDate
      ? dates.startDate.format("YYYY-MM-DD")
      : null;
    let endDate = dates.endDate ? dates.endDate.format("YYYY-MM-DD") : null;
    this.setState((prevState) => ({
      filters: { ...prevState.filters, datesFilter: [startDate, endDate] },
    }));
    this.updateTrialsCount();
  };

  setResultFilter = (e) => {
    let { name, value } = e.target;
    this.setState((prevState) => ({
      filters: { ...prevState.filters, resultsFilter: value },
    }));
    this.updateTrialsCount();
  };

  setPlacementsFilter = (placements) => {
    this.setState((prevState) => ({
      filters: { ...prevState.filters, placementsFilter: placements },
    }));
    this.updateTrialsCount();
  };

  cancelFilters = () => {
    this.parseFilters();
  };

  applyFilters = () => {
    this.props.history.push(`/trials/?f`);
    localStorage.setItem("trialFilters", this.getFilterString());
    this.setState({ loadFeed: true });
  };

  preventFeedLoading = () => {
    this.setState({ loadFeed: false });
  };

  renderFeed = () => {
    const {
      trials,
      activeDog,
      trialsPage,
      trialsPages,
      hasActivePlan,
      user,
    } = this.props;

    return (
      <React.Fragment>
        {trials.map((trial) => (
          <TrialCard
            key={trial.id}
            trial={trial}
            dog={activeDog}
            onClick={this.openCard}
            user={user}
          />
        ))}

        {trials.length >= Premium.ITEM_LIMIT && <PaywallNotification />}

        {
          /* Start load more content when this div is visible*/
          trialsPage < trialsPages &&
          trials.length >= Premium.ITEM_LIMIT &&
          hasActivePlan ? (
            <Loader name="trial-list" />
          ) : null
        }
      </React.Fragment>
    );
  };

  // publishFeedItem = (feedId, e) => {
  //   Api({ auth_token: this.props.auth_token }).shareFeedItem({
  //     id: feedId,
  //     dog: this.props.activeDog
  //   });
  // };
}

CompetitionList.propTypes = {
  fetchTrialListSpeedStats: PropTypes.func,
  fetchTrialListCleanRunStats: PropTypes.func,
  fetchTrialListFaultReasonStats: PropTypes.func,
  fetchTrialListFaultStats: PropTypes.func,
  fetchTrialListPointStats: PropTypes.func,
  fetchTrialListTimeStats: PropTypes.func,
  fetchTrialListDesignatedTimeStats: PropTypes.func,
  fetchTrialsPagesCount: PropTypes.func,
  fetchTrialsCount: PropTypes.func,
  fetchingTrialsPaged: PropTypes.bool,
  fetchingTrialsCount: PropTypes.bool,
  statistics: PropTypes.any,
  fetchTrialsPaged: PropTypes.func,
  t: PropTypes.func,
  hasActivePlan: PropTypes.bool,
  history: PropTypes.object,
  trials: PropTypes.array,
  trialsCount: PropTypes.number,
  trialsPage: PropTypes.number,
  trialsPages: PropTypes.number,
  activeDog: PropTypes.object,
  location: PropTypes.object,
};

const mapStateToProps = ({
  trials,
  activeDog,
  auth,
  subscriptions,
  statistics,
  user,
}) => ({
  trials: trials.trials,
  fetchingTrials: trials.fetchingTrials,
  fetchingTrialsPaged: trials.fetchingTrialsPaged,
  fetchingTrialsPagesCount: trials.fetchingTrialsPagesCount,
  trialsCount: trials.trialsCount,
  fetchingTrialsCount: trials.fetchingTrialsCount,
  statistics,
  trialsPages: trials.trialsPages,
  trialsPage: trials.trialsPage,
  activeDog: activeDog,
  auth_token: auth.authToken,
  hasActivePlan:
    subscriptions.currentPlan &&
    moment.utc(subscriptions.currentPlan.paid_until) > moment.utc(),
  user: user.user,
});

const TrialListWithTranslate = withTranslation()(CompetitionList);
export default connect(mapStateToProps, {
  fetchTrialsPaged,
  fetchTrialsPagesCount,
  fetchTrialsCount,
  fetchTrialListSpeedStats,
  fetchTrialListCleanRunStats,
  fetchTrialListFaultReasonStats,
  fetchTrialListFaultStats,
  fetchTrialListPointStats,
  fetchTrialListTimeStats,
  fetchTrialListDesignatedTimeStats,
})(TrialListWithTranslate);
