import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { withTranslation } from "react-i18next";
import ReactPlayer from "react-player";
import InstagramEmbed from "react-instagram-embed";
import _ from "lodash";

import { Card, CardHeader, Image } from "../common";
import { Document, Page, pdfjs } from "react-pdf";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const STANDARD_COURSES = [1, 2, 3, 12]; //Agility, Jumping, Speedstakes, Dog jumping
const POINTS_COURSES = [4, 5]; //WAO: Gamblers, Snooker
const TIME_GAMBLE = [6];
const POWER_AND_SPEED = [7];
const FAST = [8];
const TIME2BEAT = [9];
const POINTS_AND_TIME = [10, 11];

const TrialCard = ({ dog, trial, t, onClick, user, calendar }) => {
  if (!trial) return;
  // const shareLink = `http://www.facebook.com/sharer.php?u=${window.location.origin}/dog/${dog.id}/feed/${feedId}`;

  let showOrganization = dog.organizations && dog.organizations.length > 1;
  let showSizeClass =
    dog.sizeClasses &&
    _.filter(dog.sizeClasses, { organizationId: trial.organizationId }).length >
      1;

  let organizationText = "";
  if (showOrganization && showSizeClass)
    organizationText = " (" + trial.organization + ", " + trial.sizeClass + ")";
  else if (showOrganization) organizationText = " (" + trial.organization + ")";
  else if (showSizeClass) organizationText = " (" + trial.sizeClass + ")";

  return (
    <Card className="trial-card" calendar={calendar}>
      <CardHeader
        image={dog ? dog.photo : ""}
        title={
          (trial.location ? trial.location : "") +
          (" " + moment(trial.date).format("L"))
        }
        subTitle=""
        subTitle2={
          t(
            "trial." +
              (trial.courseType && trial.courseType !== undefined
                ? trial.courseType.toLowerCase()
                : "") +
              "Course"
          ) +
          organizationText +
          (trial.judge ? ", " + trial.judge : "")
        }
        onClick={() => onClick(trial.id)}
        placement={trial.placement}
      />

      {_.includes(STANDARD_COURSES, parseInt(trial.courseTypeId)) && (
        <div className="trial-card__meta">
          {trial.result && !trial.dsq && (
            <div className="trial-card__meta-item">
              <span className="trial-card__meta-label">
                {t("trial.result")}
              </span>
              <span className="trial-card__meta-value">{trial.result}</span>
            </div>
          )}

          {trial.speed && !trial.dsq && (
            <div className="trial-card__meta-item">
              <span className="trial-card__meta-label">{t("trial.speed")}</span>
              <span className="trial-card__meta-value">
                {trial.speed + " " + t("common.unit-speed-" + user.units)}
              </span>
            </div>
          )}

          {trial.dsq && (
            <div className="text-grey">{t("trial.disqualified")}</div>
          )}
        </div>
      )}

      {_.includes(POINTS_COURSES, parseInt(trial.courseTypeId)) && (
        <div className="trial-card__meta">
          {trial.totalPoints && !trial.dsq && (
            <div className="trial-card__meta-item">
              <span className="trial-card__meta-label">
                {t("trial.points")}
              </span>
              <span className="trial-card__meta-value">
                {trial.totalPoints}
              </span>
            </div>
          )}

          {trial.trialTime && !trial.dsq && (
            <div className="trial-card__meta-item">
              <span className="trial-card__meta-label">{t("trial.time")}</span>
              <span className="trial-card__meta-value">{trial.trialTime}</span>
            </div>
          )}

          {trial.dsq && (
            <div className="text-grey">{t("trial.disqualified")}</div>
          )}
        </div>
      )}

      {_.includes(TIME_GAMBLE, parseInt(trial.courseTypeId)) && (
        <div className="trial-card__meta">
          {trial.designatedTime && !trial.dsq && (
            <div className="trial-card__meta-item">
              <span className="trial-card__meta-label">
                {t("trial.designatedTime")}
              </span>
              <span className="trial-card__meta-value">
                {trial.designatedTime}
              </span>
            </div>
          )}
          {trial.trialTime && !trial.dsq && (
            <div className="trial-card__meta-item">
              <span className="trial-card__meta-label">{t("trial.time")}</span>
              <span className="trial-card__meta-value">{trial.trialTime}</span>
            </div>
          )}
          {trial.timeDifference && !trial.dsq && (
            <div className="trial-card__meta-item">
              <span className="trial-card__meta-label">
                {t("trial.timeDifference")}
              </span>
              <span className="trial-card__meta-value">
                {trial.timeDifference}
              </span>
            </div>
          )}
          {trial.dsq && (
            <div className="text-grey">{t("trial.disqualified")}</div>
          )}
        </div>
      )}

      {_.includes(POWER_AND_SPEED, parseInt(trial.courseTypeId)) && (
        <div className="trial-card__meta">
          {trial.result && !trial.dsq && (
            <div className="trial-card__meta-item">
              <span className="trial-card__meta-label">
                {t("trial.result")}
              </span>
              <span className="trial-card__meta-value">{trial.result}</span>
            </div>
          )}

          {trial.speed && !trial.dsq && (
            <div className="trial-card__meta-item">
              <span className="trial-card__meta-label">{t("trial.speed")}</span>
              <span className="trial-card__meta-value">
                {trial.speed + " " + t("common.unit-speed-" + user.units)}
              </span>
            </div>
          )}

          {trial.dsq && (
            <div className="text-grey">{t("trial.disqualified")}</div>
          )}
        </div>
      )}

      {_.includes(FAST, parseInt(trial.courseTypeId)) && (
        <div className="trial-card__meta">
          {trial.totalPoints && !trial.dsq && (
            <div className="trial-card__meta-item">
              <span className="trial-card__meta-label">
                {t("trial.points")}
              </span>
              <span className="trial-card__meta-value">
                {trial.totalPoints}
              </span>
            </div>
          )}

          {trial.trialTime && !trial.dsq && (
            <div className="trial-card__meta-item">
              <span className="trial-card__meta-label">{t("trial.time")}</span>
              <span className="trial-card__meta-value">{trial.trialTime}</span>
            </div>
          )}

          {trial.dsq && (
            <div className="text-grey">{t("trial.disqualified")}</div>
          )}
        </div>
      )}

      {_.includes(TIME2BEAT, parseInt(trial.courseTypeId)) && (
        <div className="trial-card__meta">
          {trial.result && !trial.dsq && (
            <div className="trial-card__meta-item">
              <span className="trial-card__meta-label">
                {t("trial.result")}
              </span>
              <span className="trial-card__meta-value">{trial.result}</span>
            </div>
          )}

          {trial.trialTime && !trial.dsq && (
            <div className="trial-card__meta-item">
              <span className="trial-card__meta-label">{t("trial.time")}</span>
              <span className="trial-card__meta-value">{trial.trialTime}</span>
            </div>
          )}

          {trial.dsq && (
            <div className="text-grey">{t("trial.disqualified")}</div>
          )}
        </div>
      )}

      {_.includes(POINTS_AND_TIME, parseInt(trial.courseTypeId)) && (
        <div className="trial-card__meta">
          {trial.totalPoints && !trial.dsq && (
            <div className="trial-card__meta-item">
              <span className="trial-card__meta-label">
                {t("trial.points")}
              </span>
              <span className="trial-card__meta-value">
                {trial.totalPoints}
              </span>
            </div>
          )}

          {trial.trialTime && !trial.dsq && (
            <div className="trial-card__meta-item">
              <span className="trial-card__meta-label">{t("trial.time")}</span>
              <span className="trial-card__meta-value">{trial.trialTime}</span>
            </div>
          )}

          {trial.dsq && (
            <div className="text-grey">{t("trial.disqualified")}</div>
          )}
        </div>
      )}

      {trial.videoUrl && trial.videoUrl.indexOf("instagram") === -1 && (
        <ReactPlayer url={trial.videoUrl} controls width="100%" />
      )}

      {trial.videoUrl && trial.videoUrl.indexOf("instagram") !== -1 && (
        <InstagramEmbed url={trial.videoUrl} />
      )}

      {!trial.videoUrl &&
        trial.courseMapVariants &&
        trial.courseMapVariants.original &&
        trial.courseMapVariants.original.indexOf(".pdf") !== -1 && (
        <Document file={trial.courseMapVariants.original}>
          <Page pageNumber={1} />
        </Document>
      )}
      {!trial.videoUrl &&
        trial.courseMapVariants &&
        trial.courseMapVariants.original &&
        trial.courseMapVariants.original.indexOf(".pdf") === -1 && (
        <Image src={trial.courseMapVariants.hd} />
      )}

      {/* {feedId && (
        <div className="card__footer">
          <p />

          <a
            className="share-link"
            href={shareLink}
            target="_blank"
            rel="noreferrer noopener"
            onClick={e => {
              onShare(feedId, e);
            }}
          >
            {t("common.share")}
          </a>
        </div>
      )} */}
    </Card>
  );
};

TrialCard.propTypes = {
  dog: PropTypes.object,
  trial: PropTypes.object,
  user: PropTypes.object,
  onClick: PropTypes.func,
  onShare: PropTypes.func,
  calendar: PropTypes.bool,
  t: PropTypes.func.isRequired,
};

TrialCard.propTypes = {
  onClick: () => {},
  onShare: () => {},
};

export default withTranslation()(TrialCard);
