import {
  FETCH_TRIALS,
  ADD_TRIAL,
  UPDATE_TRIAL,
  DELETE_TRIAL,
  FETCH_TRIALS_FILTERED,
  FETCH_TRIALS_PAGED,
  FETCH_TRIALS_PAGES_COUNT,
  FETCH_TRIALS_COUNT,
} from "./types";

export const fetchTrials = () => ({
  type: FETCH_TRIALS.REQUEST,
});

export const fetchTrialsSucceeded = (trials) => ({
  type: FETCH_TRIALS.SUCCEEDED,
  payload: trials,
});

export const fetchTrialsFailed = (error) => ({
  type: FETCH_TRIALS.FAILED,
  payload: error,
});

export const fetchTrialsFiltered = (filters) => ({
  type: FETCH_TRIALS_FILTERED.REQUEST,
  payload: { filters: filters },
});

export const fetchTrialsFilteredSucceeded = (trials) => ({
  type: FETCH_TRIALS_FILTERED.SUCCEEDED,
  payload: trials,
});

export const fetchTrialsFilteredFailed = (error) => ({
  type: FETCH_TRIALS_FILTERED.FAILED,
  payload: error,
});

export const fetchTrialsPaged = (trialsPage, filters) => ({
  type: FETCH_TRIALS_PAGED.REQUEST,
  payload: { trialsPage: trialsPage, filters: filters },
});

export const fetchTrialsPagedSucceeded = (trials, trialsPage) => ({
  type: FETCH_TRIALS_PAGED.SUCCEEDED,
  payload: trials,
  trialsPage,
});

export const fetchTrialsPagedFailed = (error) => ({
  type: FETCH_TRIALS_PAGED.FAILED,
  payload: error,
});

export const fetchTrialsPagesCount = (filters) => ({
  type: FETCH_TRIALS_PAGES_COUNT.REQUEST,
  payload: { filters: filters },
});

export const fetchTrialsPagesCountSucceeded = (trials) => ({
  type: FETCH_TRIALS_PAGES_COUNT.SUCCEEDED,
  payload: trials,
});

export const fetchTrialsPagesCountFailed = (error) => ({
  type: FETCH_TRIALS_PAGES_COUNT.FAILED,
  payload: error,
});

export const fetchTrialsCount = (filters) => ({
  type: FETCH_TRIALS_COUNT.REQUEST,
  payload: { filters: filters },
});

export const fetchTrialsCountSucceeded = (count) => ({
  type: FETCH_TRIALS_COUNT.SUCCEEDED,
  payload: count,
});

export const fetchTrialsCountFailed = (error) => ({
  type: FETCH_TRIALS_COUNT.FAILED,
  payload: error,
});

export const addTrial = ({ trial, dog }) => ({
  type: ADD_TRIAL.REQUEST,
  payload: { trial, dog },
});

export const addTrialSucceeded = (training) => ({
  type: ADD_TRIAL.SUCCEEDED,
  payload: training,
});

export const addTrialFailed = (error) => ({
  type: ADD_TRIAL.FAILED,
  payload: error,
});

export const updateTrial = ({ trial, dog }) => ({
  type: UPDATE_TRIAL.REQUEST,
  payload: { trial, dog },
});

export const updateTrialSucceeded = (trial) => ({
  type: UPDATE_TRIAL.SUCCEEDED,
  payload: trial,
});

export const updateTrialFailed = (error) => ({
  type: UPDATE_TRIAL.FAILED,
  payload: error,
});

export const deleteTrial = (trial) => ({
  type: DELETE_TRIAL.REQUEST,
  payload: trial,
});

export const deleteTrialSucceeded = (id) => ({
  type: DELETE_TRIAL.SUCCEEDED,
  payload: id,
});

export const deleteTrialFailed = (error) => ({
  type: DELETE_TRIAL.FAILED,
  payload: error,
});
