import React, { Component } from "react";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";

import logo from "../../images/logo.png";
import backgroundImage from "../../images/login-background.jpg";
import FacebookAuth from "../../components/FacebookAuth";
import { login } from "../../actions";

class Lobby extends Component {
  render() {
    const { t } = this.props;

    return (
      <React.Fragment>
        <div className="login">
          <div
            className="login__card"
            style={{ backgroundImage: `url(${backgroundImage})` }}
          >
            <h1 className="logo-container">
              <img
                src={logo}
                alt="AgiNotes logo"
                className="app-logo login__logo"
              />
            </h1>
            <div className="login__actions">
              <p>
                <FacebookAuth
                  onSignup={(e) => {
                    console.log(e.authResponse.accessToken);
                    this.props.login({
                      facebookOauthAccessToken: e.authResponse.accessToken,
                    });
                  }}
                  onError={(e) => console.log(e)}
                />
              </p>
              <p>
                <small>{t("lobby.or")}</small>
              </p>
              <p>
                <Link to="/signup" className="btn btn-sm btn-light btn-block">
                  {t("lobby.createAccountButton")}
                </Link>
              </p>
              <p>
                <Link
                  to="/auth/login"
                  className="btn btn-sm btn-light btn-block"
                >
                  {t("lobby.loginButton")}
                </Link>
              </p>
              <p>
                <a href="http://www.aginotes.com">
                  <small className="login__gray">About Aginotes</small>
                </a>
              </p>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const LobbyWithTranslate = withTranslation()(Lobby);
export default connect(null, { login })(LobbyWithTranslate);
