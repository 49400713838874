import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import _ from "lodash";
import PropTypes from "prop-types";
import moment from "moment";
import AddToHomeScreen from "../../components/AddToHomeScreen";
import CheckForUpdate from "../../components/CheckForUpdate";

import { AppContent, Loader } from "../../common";
import DogSelector from "../../components/DogSelector";
import { setActiveDog } from "../../actions/ActiveDogActions";
import ActionMenu from "../../components/ActionMenu";
import Statistics from "../../components/Statistics";
import Placements from "../../components/Placements";
import Chart from "../../components/Chart";
import PaywallNotification from "../../containers/App/PaywallNotification";
import { Premium } from "../../helpers/Constants";
import { Offline, Online } from "react-detect-offline";
import {
  fetchTrialStats,
  fetchTrainingStats,
  fetchActivityStats,
  fetchTrialPositionStats,
  fetchCleanRunStats,
  fetchSpeedStats,
} from "../../actions";

const COURSE_TYPES = {
  AGILITY: 1,
  JUMPING: 2,
  SPEED_STAKES: 3,
  GAMBLERS: 4,
  SNOOKER: 5,
  TIME_GAMBLE: 6,
  POWER_AND_SPEED: 7,
  FAST: 8,
  TIME_2_BEAT: 9,
  SNAKES_AND_LADDERS: 10,
  TIME_FAULT_AND_OUT: 11,
  DOG_JUMPING: 12,
};

class Home extends Component {
  constructor(props) {
    super(props);
    let t = props.t;
    this.state = {
      rangeValue: "",
      startDate: null,
      endDate: null,
    };

    this.timefilters = [
      {
        label: t("chart.lastYear"),
        value: -1,
        unit: "years",
      },
      {
        label: t("chart.last30Days"),
        value: -30,
        unit: "days",
      },
      {
        label: t("chart.all"),
        value: -30,
        unit: "years",
      },
    ];
  }

  handleActiveDogChange = (index) => {
    const { dogs, setActiveDog } = this.props;
    const { startDate, endDate } = this.state;
    const dog = dogs[index];
    setActiveDog(dog);
    this.fetchAll(startDate, endDate);
  };

  componentDidMount() {
    const { startDate, endDate } = this.state;
    if (!startDate || !endDate) {
      let newStartDate = moment().add(-1, "year").format("YYYY-MM-DD");
      let newEndDate = moment().format("YYYY-MM-DD");
      this.setState({ startDate: newStartDate, endDate: newEndDate });
      this.fetchAll(newStartDate, newEndDate);
    }
    this.fetchAll(startDate, endDate);
  }

  componentDidUpdate(prevProps) {
    const { startDate, endDate } = this.state;
    if (prevProps.activeDog.id !== this.props.activeDog.id) {
      this.fetchAll(startDate, endDate);
    }
  }

  fetchAll(startDate, endDate) {
    if (!startDate || !endDate) {
      startDate = moment().add(-1, "year").format("YYYY-MM-DD");
      endDate = moment().format("YYYY-MM-DD");
    }
    this.props.fetchTrialStats(startDate, endDate);
    this.props.fetchTrainingStats(startDate, endDate);
    this.props.fetchActivityStats(startDate, endDate);
    this.props.fetchTrialPositionStats(startDate, endDate);
    this.props.fetchCleanRunStats(startDate, endDate);
    this.props.fetchSpeedStats(startDate, endDate);
  }

  handleChange = (e) => {
    const rangeIndex = this.rangeSelect.value;
    const endDate = moment().format("YYYY-MM-DD");
    let startDate = moment().add(-30, "days").format("YYYY-MM-DD");

    const { value, unit } = this.timefilters[rangeIndex];

    startDate = moment().add(value, unit).format("YYYY-MM-DD");

    this.setState({
      rangeValue: e.target.value,
      startDate: startDate,
      endDate: endDate,
    });
    this.fetchAll(startDate, endDate);
  };

  render() {
    const { dogs, activeDog, statistics, t, user } = this.props;
    const dogIndex = _.findIndex(
      dogs,
      (dog) => parseInt(dog.id) === parseInt(activeDog.id)
    );
    let loading =
      statistics.loadingTrials ||
      statistics.loadingTrainings ||
      statistics.loadingActivity ||
      statistics.loadingPositions ||
      statistics.loadingCleanRuns ||
      statistics.loadingSpeed;

    const trainingStatistics = [
      {
        value: statistics.trainingsIndependent,
        text: t("training.independent"),
      },
      { value: statistics.trainingsCoached, text: t("training.coached") },
    ];

    const trialStatistics = [];
    if (_.includes(activeDog.courseTypes, COURSE_TYPES.AGILITY)) {
      trialStatistics.push({
        value: statistics.trialsAgility,
        text: t("home.agility"),
      });
    }
    if (_.includes(activeDog.courseTypes, COURSE_TYPES.JUMPING)) {
      trialStatistics.push({
        value: statistics.trialsJumping,
        text: t("home.jumping"),
      });
    }
    if (_.includes(activeDog.courseTypes, COURSE_TYPES.SPEED_STAKES)) {
      trialStatistics.push({
        value: statistics.trialsSpeedstakes,
        text: t("home.speedstakes"),
      });
    }
    if (_.includes(activeDog.courseTypes, COURSE_TYPES.GAMBLERS)) {
      trialStatistics.push({
        value: statistics.trialsGamblers,
        text: t("home.gamblers"),
      });
    }
    if (_.includes(activeDog.courseTypes, COURSE_TYPES.SNOOKER)) {
      trialStatistics.push({
        value: statistics.trialsSnooker,
        text: t("home.snooker"),
      });
    }
    if (_.includes(activeDog.courseTypes, COURSE_TYPES.TIME_GAMBLE)) {
      trialStatistics.push({
        value: statistics.trialsTimeGamble,
        text: t("home.timeGamble"),
      });
    }
    if (_.includes(activeDog.courseTypes, COURSE_TYPES.POWER_AND_SPEED)) {
      trialStatistics.push({
        value: statistics.trialsPowerAndSpeed,
        text: t("home.powerAndSpeed"),
      });
    }
    if (_.includes(activeDog.courseTypes, COURSE_TYPES.FAST)) {
      trialStatistics.push({
        value: statistics.trialsFAST,
        text: t("home.FAST"),
      });
    }
    if (_.includes(activeDog.courseTypes, COURSE_TYPES.TIME_2_BEAT)) {
      trialStatistics.push({
        value: statistics.trialsTime2Beat,
        text: t("home.time2Beat"),
      });
    }
    if (_.includes(activeDog.courseTypes, COURSE_TYPES.SNAKES_AND_LADDERS)) {
      trialStatistics.push({
        value: statistics.trialsSnakesAndLadders,
        text: t("home.snakesAndLadders"),
      });
    }
    if (_.includes(activeDog.courseTypes, COURSE_TYPES.TIME_FAULT_AND_OUT)) {
      trialStatistics.push({
        value: statistics.trialsTimeFaultAndOut,
        text: t("home.timeFaultAndOut"),
      });
    }
    if (_.includes(activeDog.courseTypes, COURSE_TYPES.DOG_JUMPING)) {
      trialStatistics.push({
        value: statistics.trialsDogJumping,
        text: t("home.dogJumping"),
      });
    }

    const cleanRunStatistics = [];
    if (_.includes(activeDog.courseTypes, COURSE_TYPES.AGILITY)) {
      cleanRunStatistics.push({
        value: statistics.cleanRunsAgility + " %",
        text: t("home.agility"),
      });
    }
    if (_.includes(activeDog.courseTypes, COURSE_TYPES.JUMPING)) {
      cleanRunStatistics.push({
        value: statistics.cleanRunsJumping + " %",
        text: t("home.jumping"),
      });
    }
    if (_.includes(activeDog.courseTypes, COURSE_TYPES.SPEED_STAKES)) {
      cleanRunStatistics.push({
        value: statistics.cleanRunsSpeedstakes + " %",
        text: t("home.speedstakes"),
      });
    }
    if (_.includes(activeDog.courseTypes, COURSE_TYPES.POWER_AND_SPEED)) {
      cleanRunStatistics.push({
        value: statistics.cleanRunsPowerAndSpeed + " %",
        text: t("home.powerAndSpeed"),
      });
    }
    if (_.includes(activeDog.courseTypes, COURSE_TYPES.TIME_2_BEAT)) {
      cleanRunStatistics.push({
        value: statistics.cleanRunsTime2Beat + " %",
        text: t("home.time2Beat"),
      });
    }
    if (_.includes(activeDog.courseTypes, COURSE_TYPES.SNAKES_AND_LADDERS)) {
      cleanRunStatistics.push({
        value: statistics.cleanRunsSnakesAndLadders + " %",
        text: t("home.snakesAndLadders"),
      });
    }
    if (_.includes(activeDog.courseTypes, COURSE_TYPES.TIME_FAULT_AND_OUT)) {
      cleanRunStatistics.push({
        value: statistics.cleanRunsTimeFaultAndOut + " %",
        text: t("home.timeFaultAndOut"),
      });
    }
    if (_.includes(activeDog.courseTypes, COURSE_TYPES.DOG_JUMPING)) {
      cleanRunStatistics.push({
        value: statistics.cleanRunsDogJumping + " %",
        text: t("home.dogJumping"),
      });
    }

    const speedStatistics = [];
    if (_.includes(activeDog.courseTypes, COURSE_TYPES.AGILITY)) {
      speedStatistics.push({
        value:
          statistics.speedAgility + " " + t("common.unit-speed-" + user.units),
        text: t("home.agility"),
      });
    }
    if (_.includes(activeDog.courseTypes, COURSE_TYPES.JUMPING)) {
      speedStatistics.push({
        value:
          statistics.speedJumping + " " + t("common.unit-speed-" + user.units),
        text: t("home.jumping"),
      });
    }
    if (_.includes(activeDog.courseTypes, COURSE_TYPES.SPEED_STAKES)) {
      speedStatistics.push({
        value:
          statistics.speedSpeedstakes +
          " " +
          t("common.unit-speed-" + user.units),
        text: t("home.speedstakes"),
      });
    }
    if (_.includes(activeDog.courseTypes, COURSE_TYPES.DOG_JUMPING)) {
      speedStatistics.push({
        value:
          statistics.speedDogJumping +
          " " +
          t("common.unit-speed-" + user.units),
        text: t("home.dogJumping"),
      });
    }

    return (
      <AppContent size="medium">
        <CheckForUpdate />
        <AddToHomeScreen />
        <Online>
          <DogSelector
            dogs={dogs}
            onChange={this.handleActiveDogChange}
            index={dogIndex}
          />
          {!loading &&
            statistics.trainingsPerMonth &&
            statistics.trialsPerMonth && (
              <React.Fragment>
                <React.Fragment>
                  <div className="column column--align-top">
                    <span className="p no-margin" />
                    <div className="chart-select-group">
                      <select
                        className="ghost-select chart-select-group__item"
                        onChange={this.handleChange}
                        ref={(element) => (this.rangeSelect = element)}
                        value={this.state.rangeValue}
                      >
                        {this.timefilters.map((filter, index) => (
                          <option key={filter.label} value={index}>
                            {filter.label}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </React.Fragment>
                <h3>{t("home.activity")}</h3>
                <Chart
                  type="bar"
                  data={this.getActivityStatistics()}
                  width="600"
                  height="300"
                  hideAllMenus="true"
                  options={this.getActivityOptions()}
                />
                <Statistics
                  title={t("home.trainingSessions")}
                  total={statistics.trainingsCount}
                  values={trainingStatistics}
                />
                <Statistics
                  title={t("home.competitionRuns")}
                  total={statistics.trialsCount}
                  values={trialStatistics}
                />
                <Placements
                  title={t("home.placements")}
                  positions={statistics.trialPositions}
                />
                <Statistics
                  title={t("home.cleanRuns")}
                  total={statistics.cleanRunsTotal + " %"}
                  values={cleanRunStatistics}
                />
                <Statistics
                  title={t("trial.speed")}
                  total={
                    statistics.speedAll +
                    " " +
                    t("common.unit-speed-" + user.units)
                  }
                  values={speedStatistics}
                />
              </React.Fragment>
            )}

          {!loading &&
            (statistics.trialsCount >= Premium.ITEM_LIMIT ||
              statistics.trainingsCount >= Premium.ITEM_LIMIT) && (
              <PaywallNotification
                text="paywall.homeMessage"
                button="paywall.homeButton"
              />
            )}
          {loading && <Loader />}

          <ActionMenu />
        </Online>
        <Offline>{t("notification.offline")}</Offline>
      </AppContent>
    );
  }

  getActivityStatistics() {
    const { statistics, t } = this.props;
    const { startDate, endDate } = this.state;

    if (
      !startDate ||
      !endDate ||
      !statistics.trainingsPerMonth ||
      !statistics.trialsPerMonth
    ) {
      return {
        datasets: [
          {
            data: [],
            label: "",
          },
        ],
      };
    }

    let trainingStatistics = statistics.trainingsPerMonth;
    let trialStatistics = statistics.trialsPerMonth;

    let dateStart = moment(endDate).subtract(12, "months");
    Object.keys(trainingStatistics).forEach((key) => {
      if (moment(key).isBefore(dateStart)) dateStart = key;
    });

    Object.keys(trialStatistics).forEach((key) => {
      if (moment(key).isBefore(dateStart)) dateStart = key;
    });

    let activityData = this.generateActivityData(
      dateStart,
      endDate,
      trainingStatistics,
      trialStatistics
    );

    return {
      labels: activityData.labels,
      datasets: [
        {
          label: t("home.trainingSessions"),
          data: activityData.trainings,
        },
        {
          label: t("home.competitionRuns"),
          data: activityData.trials,
          backgroundColor: "rgba(164, 164, 164, 0.5)",
        },
      ],
    };
  }

  generateActivityData(
    startDate,
    endDate,
    trainingStatistics,
    trialStatistics
  ) {
    let timeDiff = moment(endDate).diff(moment(startDate), "month");
    let labels = [];
    let trainings = _.range(timeDiff).map(() => 0);
    let trials = _.range(timeDiff).map(() => 0);
    let currentYear = "";
    for (var i = 0; i <= timeDiff; i++) {
      var date = moment(startDate).add(i, "month");
      let label = date.format("M");
      if (moment(date).year() !== currentYear) {
        currentYear = moment(date).year();
        labels.push([label, currentYear]);
      } else {
        labels.push(label);
      }
      let trainingIndex = Object.keys(trainingStatistics).indexOf(
        date.format("YYYY-MM-01")
      );
      if (trainingIndex >= 0) {
        trainings[i] =
          trainingStatistics[Object.keys(trainingStatistics)[trainingIndex]];
      }
      let trialIndex = Object.keys(trialStatistics).indexOf(
        date.format("YYYY-MM-01")
      );
      if (trialIndex >= 0) {
        trials[i] = trialStatistics[Object.keys(trialStatistics)[trialIndex]];
      }
    }
    return { labels: labels, trainings: trainings, trials: trials };
  }

  getActivityOptions() {
    const { statistics } = this.props;

    let trainingStatistics = statistics.trainingsPerMonth;
    let trialStatistics = statistics.trialsPerMonth;

    let littleData = true;

    Object.values(trainingStatistics).forEach((value) => {
      if (value >= 6) littleData = false;
    });

    Object.values(trialStatistics).forEach((value) => {
      if (value >= 6) littleData = false;
    });

    if (littleData) {
      return {
        scales: {
          xAxes: [
            {
              stacked: true,
            },
          ],
          yAxes: [
            {
              stacked: true,
              ticks: {
                suggestedMin: 0,
                suggestedMax: 5,
                stepSize: 1,
              },
            },
          ],
        },
      };
    }
    return {};
  }
}

Home.propTypes = {
  fetchTrialStats: PropTypes.func,
  fetchTrainingStats: PropTypes.func,
  fetchActivityStats: PropTypes.func,
  fetchTrialPositionStats: PropTypes.func,
  fetchCleanRunStats: PropTypes.func,
  fetchSpeedStats: PropTypes.func,
  activeDog: PropTypes.any,
  statistics: PropTypes.any,
  t: PropTypes.func,
  dogs: PropTypes.any,
  setActiveDog: PropTypes.func,
  user: PropTypes.object,
};

const mapStateToProps = ({ dogs, activeDog, statistics, user }) => ({
  dogs: dogs.dogs,
  statistics,
  activeDog,
  user: user.user,
});

const HomeWithTranslate = withTranslation()(Home);
export default connect(mapStateToProps, {
  setActiveDog,
  fetchTrialStats,
  fetchTrainingStats,
  fetchActivityStats,
  fetchTrialPositionStats,
  fetchCleanRunStats,
  fetchSpeedStats,
})(HomeWithTranslate);
