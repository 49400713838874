import React, { Component } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { arrayMove } from "react-sortable-hoc";
import { SubSortableList } from "./SubSortableList";
import { Progress } from "../../common";
import { updateTrainingTopicNoNotification } from "../../actions";

/*
 * Gets a single topic with subtopics as prop and renders subtopics and progress bar.
 * Renders list from props because id of subtopic changes when topic changes. Might slow down
 * sorting and checking, but has correct id for move to editing.
 */
class SubTopicExpandedList extends Component {
  handleCheckChange = (e) => {
    e.stopPropagation();
    const id = parseInt(e.target.value, 10);
    const done = e.target.checked;

    let newSubTopicList = [...this.props.topic.trainingSubTopics];
    let topicIndex = this.props.topic.trainingSubTopics.findIndex(
      (topic) => topic.id === id
    );
    newSubTopicList[topicIndex].done = done;

    // Updates in database.
    this.props.updateTrainingTopicNoNotification({
      ...this.props.topic,
      trainingSubTopics: newSubTopicList,
    });
  };

  handleSubTopicEdit = (id, e) => {
    e.stopPropagation();
    let topicId = this.props.topic.id;
    this.props.history.push(`/training-topic/${topicId}/${id}`);
  };

  onSortEnd = ({ oldIndex, newIndex }) => {
    let newSubTopics = arrayMove(
      [...this.props.topic.trainingSubTopics],
      oldIndex,
      newIndex
    );
    // Puts in order numbers
    newSubTopics = newSubTopics.map((item, index) => {
      item.orderNumber = index + 1;
      return item;
    });
    // Updates in database.
    this.props.updateTrainingTopicNoNotification({
      ...this.props.topic,
      trainingSubTopics: newSubTopics,
    });
  };

  chevron = (expanded) => {
    return (
      <img
        src={require("../../images/icons/chevron-down.svg")}
        className={`${
          expanded
            ? "icon center-icon rotated-icon "
            : "icon center-icon"
        }`}
        alt={"expand-contract"}
      />
    );
  };

  render() {
    const { t } = this.props;
    let trainingSubTopics = this.props.topic.trainingSubTopics;
    // Counting percent of subtopics done
    let allSubTopics = 0;
    let doneSubTopics = 0;
    for (let subTopic of trainingSubTopics) {
      allSubTopics++;
      if (subTopic.done) {
        doneSubTopics++;
      }
    }
    let percentDone = (100 * doneSubTopics) / allSubTopics;

    let sortedTrainingSubTopics = trainingSubTopics.sort(
      (a, b) => a.orderNumber - b.orderNumber
    );

    return (
      <React.Fragment>
        {this.props.topic.expanded ? (
          <div onClick={(e) => e.stopPropagation()}>
            <SubSortableList
              items={sortedTrainingSubTopics}
              onCheck={this.handleCheckChange}
              onSortEnd={this.onSortEnd}
              onItemEdit={this.handleSubTopicEdit}
              topicList={true}
              useDragHandle={true}
              t={t}
            />
          </div>
        ) : (
          <div />
        )}
        <Progress
          percent={percentDone}
          topiclist={true}
          className="progress-sidemargin"
        />
        {this.chevron(this.props.topic.expanded)}
      </React.Fragment>
    );
  }
}

SubTopicExpandedList.propTypes = {
  updateTrainingTopicNoNotification: PropTypes.func,
  t: PropTypes.func,
  topic: PropTypes.object,
  history: PropTypes.object,
};

const mapStateToProps = ({ topics }, props) => {
  const id = props.topicId;
  const topic = topics.topics.find((t) => t.id === Number(id));
  topic.expanded = props.expanded;
  return {
    topic,
  };
};

const SubTopicExpandedListWithTranslate = withTranslation()(
  SubTopicExpandedList
);

export default withRouter(
  connect(mapStateToProps, {
    updateTrainingTopicNoNotification,
  })(SubTopicExpandedListWithTranslate)
);
