import React, { Component } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import Modal from "../common/Modal";
import { withTranslation } from "react-i18next";
import { ElementsConsumer } from "@stripe/react-stripe-js";
import CheckoutForm from "../containers/App/CheckoutForm";
import Loader from "../common/Loader";
import {
  resetPaymentModal,
  setPaymentModalState,
  fetchCurrentPlan,
} from "../actions/SubscriptionActions";
import { PaymentModalStates } from "../helpers/Constants";

class PaymentModal extends Component {
  constructor(props) {
    super(props);
    this.renderPaymentInfo = this.renderCreditCardInfo.bind(this);
    this.renderProcessingPayment = this.renderProcessingPayment.bind(this);
    this.renderPaymentCompleted = this.renderPaymentCompleted.bind(this);
    this.renderPaymentFailed = this.renderPaymentFailed.bind(this);
    this.renderCardUpdated = this.renderCardUpdated.bind(this);
    this.failureScreen = this.failureScreen.bind(this);
    this.renderCardUpdateFailed = this.renderCardUpdateFailed.bind(this);
    this.renderPaymentScreen = this.renderPaymentScreen.bind(this);
    this.renderUpdateCreditCard = this.renderUpdateCreditCard.bind(this);
    this.renderRequiresAction = this.renderRequiresAction.bind(this);

    this.modalStates = {
      [PaymentModalStates.PAYMENT_INFO]: this.renderPaymentScreen,
      [PaymentModalStates.PROCESSING]: this.renderProcessingPayment,
      [PaymentModalStates.SUCCESS]: this.renderPaymentCompleted,
      [PaymentModalStates.FAILURE]: this.renderPaymentFailed,
      [PaymentModalStates.UDPATE_CARD]: this.renderUpdateCreditCard,
      [PaymentModalStates.CARD_UPDATED]: this.renderCardUpdated,
      [PaymentModalStates.CARD_UPDATE_FAILURE]: this.renderCardUpdateFailed,
      [PaymentModalStates.REQUIRES_ACTION]: this.renderRequiresAction,
    };
  }

  componentDidUpdate() {
    const { modalState } = this.props;
    if (modalState === PaymentModalStates.REQUIRES_ACTION) {
      this.handlePaymentThatRequiresAction();
    }
  }

  handlePaymentThatRequiresAction() {
    const { clientSecret, paymentMethod, dispatch, stripe } = this.props;
    if (!stripe) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }
    return stripe
      .confirmCardPayment(clientSecret, {
        payment_method: paymentMethod,
      })
      .then((result) => {
        if (result.error) {
          dispatch(setPaymentModalState(PaymentModalStates.FAILURE));
        } else {
          if (result.paymentIntent.status === "succeeded") {
            dispatch(setPaymentModalState(PaymentModalStates.SUCCESS));
            //Need to update plan status in redux
            dispatch(fetchCurrentPlan());
          }
        }
      });
  }

  renderCreditCardInfo(title, buttonText, onFail, isUpdate) {
    const { plan, user, onPayment } = this.props;
    return (
      <div>
        {title}
        <InjectedCheckoutForm
          plan={plan}
          auth_token={this.props.auth_token}
          user={user}
          onCheckout={(token) => {
            onPayment(token);
          }}
          buttonText={buttonText}
          onFailure={onFail}
          isCardUpdate={isUpdate}
        />
      </div>
    );
  }

  renderPaymentScreen() {
    const { plan, t, dispatch } = this.props;
    return this.renderCreditCardInfo(
      <div>
        <h1>{`${plan.name}`}</h1>
        <hr />
        <h4>{`${plan.price_per_payment} ${plan.currency} ${t(
          "subscription.per"
        )} ${plan.frequency} ${t("subscription.payment." + plan.period)}`}</h4>
        <p className="text-grey" style={{ margin: "10px" }}>
          {t("subscription.payment.description")}
        </p>
      </div>,
      "",
      () => dispatch(setPaymentModalState(PaymentModalStates.FAILURE)),
      false
    );
  }

  renderUpdateCreditCard() {
    const { t, dispatch } = this.props;
    return this.renderCreditCardInfo(
      <h2>{t("subscription.updateCreditCard")}</h2>,
      t("subscription.update"),
      () =>
        dispatch(setPaymentModalState(PaymentModalStates.CARD_UPDATE_FAILURE)),
      true
    );
  }

  renderProcessingPayment() {
    const { t } = this.props;
    return (
      <div style={{ margin: "40px" }}>
        <h2 className="text-grey" style={{ textAlign: "center" }}>
          {" "}
          {t("subscription.payment.processing")}{" "}
        </h2>
        <Loader />
      </div>
    );
  }

  renderPaymentCompleted() {
    const { t, onPaymentCompleted, dispatch } = this.props;
    return (
      <div style={{ margin: "40px" }}>
        <h2 style={{ textAlign: "center" }}>
          {" "}
          {t("subscription.payment.completed")}{" "}
        </h2>
        <h2 className="text-grey" style={{ textAlign: "center" }}>
          {" "}
          {t("subscription.payment.thankYou")}{" "}
        </h2>
        <button
          className="btn btn-primary subscription-container__upgrade"
          onClick={() => {
            dispatch(resetPaymentModal());
            onPaymentCompleted();
          }}
          style={{ marginTop: "30px" }}
        >
          {t("subscription.payment.ok")}
        </button>
      </div>
    );
  }

  failureScreen(message) {
    const { t, onPaymentCompleted, dispatch, errors } = this.props;
    const errorMessage = "";
    const errorKeys = Object.keys(errors);
    return (
      <div>
        <h2 style={{ textAlign: "center" }}> {t(message)} </h2>
        <h2 className="text-grey" style={{ textAlign: "center" }}>
          {errorMessage}
        </h2>
        {errorKeys.length > 0 && (
          <div className="alert alert-danger" role="alert">
            <ul style={{ paddingLeft: "1rem" }}>
              {errorKeys.map((key) => {
                const value = errors[key];
                return <li key={key}>{value}</li>;
              })}
            </ul>
          </div>
        )}
        <button
          className="btn btn-primary subscription-container__upgrade"
          onClick={() => {
            dispatch(resetPaymentModal());
            onPaymentCompleted();
          }}
          style={{ marginTop: "30px" }}
        >
          {t("subscription.payment.ok")}
        </button>
      </div>
    );
  }

  renderPaymentFailed() {
    return this.failureScreen("subscription.payment.failure");
  }

  renderCardUpdateFailed() {
    return this.failureScreen("subscription.payment.updateFailed");
  }

  renderCardUpdated() {
    const { t, onPaymentCompleted, dispatch } = this.props;
    return (
      <div style={{ margin: "40px" }}>
        <h2 style={{ textAlign: "center" }}>
          {" "}
          {t("subscription.payment.cardUpdated")}{" "}
        </h2>
        <h2 className="text-grey" style={{ textAlign: "center" }}>
          {" "}
          {t("subscription.payment.thankYou")}{" "}
        </h2>
        <button
          className="btn btn-primary subscription-container__upgrade"
          onClick={() => {
            dispatch(resetPaymentModal());
            onPaymentCompleted();
          }}
          style={{ marginTop: "30px" }}
        >
          {t("subscription.payment.ok")}
        </button>
      </div>
    );
  }

  renderRequiresAction() {
    return this.renderProcessingPayment();
  }

  render() {
    const { show, onClose, modalState, dispatch } = this.props;
    if (!show) return null;
    const currentState = this.modalStates[modalState];

    return (
      <div>
        <Modal
          className="modal-skinny"
          contentClassName="modal-content"
          show={show}
          hideHorizontalLine
          onClose={() => {
            dispatch(resetPaymentModal());
            onClose();
          }}
        >
          {currentState()}
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = ({ subscriptions }) => ({
  modalState: subscriptions.modalState,
  clientSecret: subscriptions.clientSecret,
  paymentMethod: subscriptions.paymentMethod,
  invoice: subscriptions.invoice,
  errors: subscriptions.error,
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
});

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation()
);

const InjectedCheckoutForm = ({
  plan,
  auth_token,
  user,
  onCheckout,
  buttonText,
  onFail,
  isUpdate,
}) => (
  <ElementsConsumer>
    {({ elements, stripe }) => (
      <CheckoutForm
        stripe={stripe}
        elements={elements}
        plan={plan}
        auth_token={auth_token}
        user={user}
        onCheckout={onCheckout}
        buttonText={buttonText}
        onFailure={onFail}
        isCardUpdate={isUpdate}
      />
    )}
  </ElementsConsumer>
);

export default enhance(PaymentModal);
