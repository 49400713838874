import i18n from "i18next";
import detector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

import backend from "i18next-xhr-backend";

import moment from "moment";
import "moment/locale/fi";
import "moment/locale/sv";
import "moment/locale/fr";
import "moment/locale/nb";
import "moment/locale/es";
import "moment/locale/de";
import "moment/locale/en-gb";
import "moment/locale/da";
import "moment/locale/it";
import "moment/locale/pt";
import "moment/locale/nl";
import "moment/locale/pl";

import { debug as debugENV } from "./env";
const debug = debugENV === "true";

i18n
  .use(backend)
  .use(detector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init(
    {
      fallbackLng: "en",
      debug,
      backend: {
        loadPath: "/locales/{{lng}}/{{ns}}.json",
      },
      react: {
        wait: true,
        useSuspense: false,
      },
    },
    () => {
      moment.locale(i18n.language);
    }
  );

export default i18n;
