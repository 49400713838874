import React, { Component } from "react";
import PropTypes from "prop-types";

class Statistics extends Component {
  render() {
    const { title, total, values } = this.props;
    return (
      <div className="statistic">
        <h3>{title}</h3>
        <div className="statistic__container">
          <span className="statistic__value">{total}</span>
          <div className="statistic__inner">
            <p>
              {values.map((item, index) => (
                <React.Fragment key={title + index}>
                  <span>
                    {item.value} {item.text}
                  </span>
                  <br />
                </React.Fragment>
              ))}
            </p>
          </div>
        </div>
      </div>
    );
  }
}

Statistics.propTypes = {
  title: PropTypes.string,
  total: PropTypes.any,
  values: PropTypes.array
};

export default Statistics;
