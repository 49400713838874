import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { Modal, Loader, FormGroup, Select } from "../common";
import LabeledCheckbox from "../components/LabeledCheckbox";
import ReactSelect from "react-select";
import "react-dates/lib/css/_datepicker.css";
import "react-dates/initialize";
import { DateRangePicker } from "react-dates";
import _ from "lodash";
import moment from "moment";

class TrialFilterModal extends Component {
  state = {
    startDate: "",
    endDate: "",
    focused: null,
  };

  setLast30Days = () => {
    let startDate = moment().add(-30, "day");
    let endDate = moment();
    this.props.updateDateFilter({ startDate, endDate });
  };

  setLastYear = () => {
    let startDate = moment().add(-1, "year");
    let endDate = moment();
    this.props.updateDateFilter({ startDate, endDate });
  };

  onFocusChanged = (focused) => {
    this.setState({ focused });
  };

  render() {
    const {
      t,
      trialLocationsFilter,
      judgesFilter,
      datesFilter,
      resultsFilter,
      placementsFilter,
      organizationsFilter,
      sizeClassesFilter,
      // courseTypesFilter,
      // speedsFilter,
      // faultsFilter,
      updateTrialLocationsFilter,
      updateJudgesFilter,
      updateDateFilter,
      updateResultsFilter,
      updatePlacementsFilter,
      updateOrganizationsFilter,
      updateSizeClassesFilter,
      // udpdateCourseTypesFilter,
      // updateSpeedsFilter,
      // updateFaultsFilter,
      fetchingJudgesFilter,
      fetchingTrialLocationsFilter,
      judges,
      trialLocations,
      showModal,
      onModalClose,
      onApplyFilters,
      trialsCount,
      activeDog,
    } = this.props;

    let loading = fetchingJudgesFilter && fetchingTrialLocationsFilter;
    const dog = activeDog;

    let showOrganizations = false;
    let showSizeClasses = false;

    showOrganizations = dog.organizations.length > 1;

    showSizeClasses =
      organizationsFilter !== "" &&
      organizationsFilter !== null &&
      organizationsFilter > 0 &&
      dog.sizeClasses.filter(
        (sizeClass) =>
          parseInt(sizeClass.organizationId) === parseInt(organizationsFilter)
      ).length > 1;

    let dogSizeClasses = null;
    if (showSizeClasses) {
      dogSizeClasses = _(dog.sizeClasses)
        .filter(
          (s) => parseInt(s.organizationId) === parseInt(organizationsFilter)
        )
        .sortBy((s) => s.id)
        .value();
    }

    let organizationOptions = null;
    if (showOrganizations) {
      organizationOptions = dog.organizations
        .sort((a, b) => a.order - b.order)
        .map((org) => ({
          title: org.name,
          value: org.id + "",
        }));

      organizationOptions.unshift({ title: t("chart.all"), value: "0" });
    }

    let resultOptions = [
      {
        value: "",
        title: t("chart.all"),
      },
      {
        value: "cr",
        title: t("trial.cleanRuns"),
      },
      {
        value: "dq",
        title: t("trial.disqualified"),
      },
    ];

    let filterDate =
      this.state.startDate && this.state.endDate
        ? [this.state.startDate, this.state.endDate]
        : datesFilter;

    return (
      <Modal
        className="modal-medium"
        contentClassName="modal-content"
        show={showModal}
        hideHorizontalLine
        onClose={() => {
          onModalClose();
        }}
      >
        {!loading && (
          <div className={"filter-body"}>
            {showOrganizations && (
              <FormGroup>
                <Select
                  label={t("trial.organization")}
                  name="organizationsFilter"
                  value={organizationsFilter + ""}
                  onChange={updateOrganizationsFilter}
                  options={organizationOptions}
                />
              </FormGroup>
            )}

            {showSizeClasses && (
              <FormGroup>
                <label>{t("trial.sizeClass")}</label>
                <div>
                  {dogSizeClasses.map((sizeClass) => (
                    <LabeledCheckbox
                      label={sizeClass.name}
                      key={sizeClass.id}
                      id={sizeClass.id}
                      name={"sizeClass_" + sizeClass.id}
                      onChange={updateSizeClassesFilter}
                      checked={
                        sizeClassesFilter &&
                        sizeClassesFilter.indexOf(sizeClass.id) !== -1
                      }
                      cssClass="checkbox-label"
                    />
                  ))}
                </div>
              </FormGroup>
            )}

            <div>
              <FormGroup>
                <label>{t("filters.date")}</label>
                <br />
                <DateRangePicker
                  startDate={
                    filterDate && filterDate[0] ? moment(filterDate[0]) : null
                  }
                  startDateId="startDate"
                  endDate={
                    filterDate && filterDate[1] ? moment(filterDate[1]) : null
                  }
                  endDateId="endDate"
                  onDatesChange={updateDateFilter}
                  focusedInput={this.state.focused}
                  onFocusChange={this.onFocusChanged}
                  showClearDates
                  isOutsideRange={() => false}
                  readOnly={false}
                  withPortal={true}
                  numberOfMonths={1}
                  startDatePlaceholderText={t("filters.startDate")}
                  endDatePlaceholderText={t("filters.endDate")}
                />
                <ul className="list list--simple">
                  <li className="list__item-simple">
                    <button
                      onClick={this.setLast30Days}
                      className="trial-link-button"
                    >
                      {t("filters.last30Days")}
                    </button>
                  </li>
                  <li className="list__item-simple">
                    <button
                      onClick={this.setLastYear}
                      className="trial-link-button"
                    >
                      {t("filters.lastYear")}
                    </button>
                  </li>
                </ul>
              </FormGroup>
            </div>
            <div>
              <FormGroup>
                <label>{t("filters.judge")}</label>
                <ReactSelect
                  key="judges"
                  id="judges"
                  value={judgesFilter}
                  onChange={updateJudgesFilter}
                  isMulti={true}
                  options={judges.map((judge) => ({
                    label: judge.name,
                    value: judge.id,
                  }))}
                  placeholder={t("common.choose")}
                />
              </FormGroup>
            </div>
            <div>
              <FormGroup>
                <label>{t("filters.location")}</label>
                <ReactSelect
                  key="trialLocations"
                  id="trialLocations"
                  value={trialLocationsFilter}
                  onChange={updateTrialLocationsFilter}
                  isMulti={true}
                  options={trialLocations.map((location) => ({
                    label: location.name,
                    value: location.id,
                  }))}
                  placeholder={t("common.choose")}
                />
              </FormGroup>
            </div>
            <div>
              <FormGroup>
                <Select
                  label={t("filters.result")}
                  name="resultsFilter"
                  value={resultsFilter + ""}
                  onChange={updateResultsFilter}
                  options={resultOptions}
                />
              </FormGroup>
            </div>
            <div>
              {trialsCount} {t("filters.trialsCount")}
            </div>
            <div>
              <button
                disabled={trialsCount <= 0}
                className="btn btn-outline-primary"
                onClick={() => {
                  onApplyFilters();
                }}
              >
                {t("filters.applyFilters")}
              </button>
              <button className="btn" onClick={() => onModalClose()}>
                {t("common.cancel")}
              </button>
            </div>
          </div>
        )}

        {loading && <Loader />}
      </Modal>
    );
  }
}

TrialFilterModal.propTypes = {
  t: PropTypes.func,
  trialLocationsFilter: PropTypes.array,
  judgesFilter: PropTypes.any,
  datesFilter: PropTypes.array,
  resultsFilter: PropTypes.any,
  organizationsFilter: PropTypes.any,
  sizeClassesFilter: PropTypes.array,
  placementsFilter: PropTypes.array,
  courseTypesFilter: PropTypes.array,
  speedsFilter: PropTypes.array,
  faultsFilter: PropTypes.array,
  updateTrialLocationsFilter: PropTypes.func,
  updateJudgesFilter: PropTypes.func,
  updateDateFilter: PropTypes.func,
  updateResultsFilter: PropTypes.func,
  updateOrganizationsFilter: PropTypes.func,
  updateSizeClassesFilter: PropTypes.func,
  updatePlacementsFilter: PropTypes.func,
  udpdateCourseTypesFilter: PropTypes.func,
  updateSpeedsFilter: PropTypes.func,
  updateFaultsFilter: PropTypes.func,
  fetchJudgesFilter: PropTypes.func,
  fetchTrialLocationsFilter: PropTypes.func,
  fetchingJudgesFilter: PropTypes.bool,
  fetchingTrialLocationsFilter: PropTypes.bool,
  judges: PropTypes.array,
  trialLocations: PropTypes.array,
  showModal: PropTypes.bool,
  onModalClose: PropTypes.func,
  onApplyFilters: PropTypes.func,
  trialsCount: PropTypes.number,
  activeDog: PropTypes.object,
};

const TrialFilterModalWithTranslate = withTranslation()(TrialFilterModal);

export default connect()(TrialFilterModalWithTranslate);
