import { call, put, takeLatest, select } from "redux-saga/effects";

import { FETCH_SETTINGS, REHYDRATE, LOGIN, SIGNUP } from "../actions/types";
import Api from "../helpers/Api";
import { getAuthToken } from "./selectors";
import {
  fetchSettingsSucceeded,
  fetchSettingsFailed,
  afterRehydrate,
  authExpired
} from "../actions";

function* fetchSettings({ type, payload }) {
  try {
    const auth_token = yield select(getAuthToken);
    const response = yield call(Api({ auth_token }).fetchSettings);
    yield put(fetchSettingsSucceeded(response.data));
  } catch (e) {
    const { status, statusText } = e.response;
    if (status === 401) {
      yield put(authExpired());
    }
    yield put(fetchSettingsFailed({ status, statusText }));
  }
}

function* rehydrate() {
  try {
    yield put(afterRehydrate());
  } catch (e) {}
}

function* settingsSaga() {
  yield takeLatest(FETCH_SETTINGS.REQUEST, fetchSettings);
  yield takeLatest(LOGIN.SUCCEEDED, fetchSettings);
  yield takeLatest(SIGNUP.SUCCEEDED, fetchSettings);
  yield takeLatest(REHYDRATE, rehydrate);
}

export default settingsSaga;
