import { call, put, takeLatest, select } from "redux-saga/effects";

import { getAuthToken, getActiveDog } from "./selectors";
import Api from "../helpers/Api";
import {
  fetchTrialsSucceeded,
  fetchTrialsFailed,
  fetchTrialsFilteredSucceeded,
  fetchTrialsFilteredFailed,
  fetchTrialsPagedSucceeded,
  fetchTrialsPagedFailed,
  fetchTrialsPagesCountSucceeded,
  fetchTrialsPagesCountFailed,
  fetchTrialsCountSucceeded,
  fetchTrialsCountFailed,
  addTrialSucceeded,
  addTrialFailed,
  updateTrialSucceeded,
  updateTrialFailed,
  deleteTrialSucceeded,
  deleteTrialFailed,
  authExpired,
} from "../actions";
import {
  FETCH_TRIALS,
  ADD_TRIAL,
  UPDATE_TRIAL,
  DELETE_TRIAL,
  FETCH_TRIALS_FILTERED,
  FETCH_TRIALS_PAGED,
  FETCH_TRIALS_PAGES_COUNT,
  FETCH_TRIALS_COUNT,
} from "../actions/types";

function* fetchTrials({ type, payload }) {
  try {
    const auth_token = yield select(getAuthToken);
    const dog = yield select(getActiveDog);
    const response = yield call(Api({ auth_token }).fetchTrials, { dog });
    yield put(fetchTrialsSucceeded(response.data));
  } catch (e) {
    const { status, statusText } = e.response;
    if (status === 401) {
      yield put(authExpired());
    }
    yield put(fetchTrialsFailed({ status, statusText }));
  }
}

function* fetchTrialsFiltered({ type, payload }) {
  try {
    const auth_token = yield select(getAuthToken);
    const dog = yield select(getActiveDog);
    const response = yield call(Api({ auth_token }).fetchTrialsFiltered, {
      dog,
      filters: payload.filters,
    });
    yield put(
      fetchTrialsFilteredSucceeded({
        trials: response.data,
      })
    );
  } catch (e) {
    const { status, statusText } = e.response;
    if (status === 401) {
      yield put(authExpired());
    }
    yield put(fetchTrialsFilteredFailed({ status, statusText }));
  }
}

function* fetchTrialsPaged({ type, payload }) {
  try {
    const auth_token = yield select(getAuthToken);
    const dog = yield select(getActiveDog);
    const response = yield call(Api({ auth_token }).fetchTrialsPaged, {
      dog,
      trialsPage: payload.trialsPage,
      filters: payload.filters,
    });
    yield put(
      fetchTrialsPagedSucceeded({
        trials: response.data,
        trialsPage: payload.trialsPage,
      })
    );
  } catch (e) {
    const { status, statusText } = e.response;
    if (status === 401) {
      yield put(authExpired());
    }
    yield put(fetchTrialsPagedFailed({ status, statusText }));
  }
}

function* fetchTrialsPagesCount({ type, payload }) {
  try {
    const auth_token = yield select(getAuthToken);
    const dog = yield select(getActiveDog);
    const response = yield call(Api({ auth_token }).fetchTrialsPagesCount, {
      dog,
      filters: payload.filters,
    });
    yield put(fetchTrialsPagesCountSucceeded(response.data, payload));
  } catch (e) {
    const { status, statusText } = e.response;
    if (status === 401) {
      yield put(authExpired());
    }
    yield put(fetchTrialsPagesCountFailed({ status, statusText }));
  }
}

function* fetchTrialsCount({ type, payload }) {
  try {
    const auth_token = yield select(getAuthToken);
    const dog = yield select(getActiveDog);
    const response = yield call(Api({ auth_token }).fetchTrialsCount, {
      dog,
      filters: payload.filters,
    });
    yield put(fetchTrialsCountSucceeded(response.data, payload));
  } catch (e) {
    const { status, statusText } = e.response;
    if (status === 401) {
      yield put(authExpired());
    }
    yield put(fetchTrialsCountFailed({ status, statusText }));
  }
}

function* addTrial({ action, payload }) {
  try {
    const auth_token = yield select(getAuthToken);
    const response = yield call(
      Api({
        auth_token,
        "content-type": +"multipart/form-data; boundary=----WebKitFormBoundary7MA4YWxkTrZu0gW",
      }).addTrial,
      payload
    );
    yield put(addTrialSucceeded(response.data));
  } catch (e) {
    yield put(addTrialFailed(e.message));
  }
}

function* updateTrial({ action, payload }) {
  try {
    const auth_token = yield select(getAuthToken);
    const response = yield call(
      Api({
        auth_token,
        "content-type": +"multipart/form-data; boundary=----WebKitFormBoundary7MA4YWxkTrZu0gW",
      }).updateTrial,
      payload
    );
    yield put(updateTrialSucceeded(response.data));
  } catch (e) {
    yield put(updateTrialFailed(e.response.data.errors));
  }
}

function* deleteTrial({ action, payload }) {
  try {
    const auth_token = yield select(getAuthToken);
    yield call(Api({ auth_token }).deleteTrial, payload);
    yield put(deleteTrialSucceeded(payload.id));
  } catch (e) {
    yield put(deleteTrialFailed(e.response.data.errors));
  }
}

function* trialSaga() {
  yield takeLatest(FETCH_TRIALS.REQUEST, fetchTrials);
  yield takeLatest(ADD_TRIAL.REQUEST, addTrial);
  yield takeLatest(UPDATE_TRIAL.REQUEST, updateTrial);
  yield takeLatest(DELETE_TRIAL.REQUEST, deleteTrial);
  yield takeLatest(FETCH_TRIALS_FILTERED.REQUEST, fetchTrialsFiltered);
  yield takeLatest(FETCH_TRIALS_PAGED.REQUEST, fetchTrialsPaged);
  yield takeLatest(FETCH_TRIALS_PAGES_COUNT.REQUEST, fetchTrialsPagesCount);
  yield takeLatest(FETCH_TRIALS_COUNT.REQUEST, fetchTrialsCount);
}

export default trialSaga;
