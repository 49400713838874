import React, { Component } from "react";
import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";

import { Avatar } from "../common";

class DogSelector extends Component {
  componentDidMount() {
    this.setState({ index: this.props.index });
  }

  next = () => {
    this.props.onChange(this.props.index + 1);
  };

  prev = () => {
    this.props.onChange(this.props.index - 1);
  };

  handleChange = index => {
    this.props.onChange(index);
    this.setState({ index });
  };

  render() {
    const { dogs, index } = this.props;

    if (dogs.length === 0 || index < 0) return null;

    return (
      <div className="dog-selector">
        <SwipeableViews
          enableMouseEvents
          onChangeIndex={this.handleChange}
          index={index}
        >
          {dogs.map(dog => this.renderDog(dog))}
        </SwipeableViews>
        {this.renderControls()}
      </div>
    );
  }

  renderDog = dog => {
    return (
      <div key={dog.id} className="dog-selector__slide">
        <h2 className="dog-selector__title">{dog.name}</h2>
        <h3 className="dog-selector__sub-title">{dog.officialName}</h3>
        <Avatar image={dog.photo} size="large" />
      </div>
    );
  };

  renderControls = () => {
    const { dogs, index } = this.props;

    const nextClasses =
      index >= dogs.length - 1 ? "dog-selector__control--disabled" : "";
    const prevClasses = index <= 0 ? "dog-selector__control--disabled" : "";
    return (
      <div>
        <button
          className={`dog-selector__next dog-selector__control ${nextClasses}`}
          onClick={this.next}
        >
          &rarr;
        </button>
        <button
          className={`dog-selector__prev dog-selector__control ${prevClasses}`}
          onClick={this.prev}
        >
          &larr;
        </button>
      </div>
    );
  };
}

DogSelector.propTypes = {
  dogs: PropTypes.array,
  onChange: PropTypes.func,
  index: PropTypes.number
};

export default DogSelector;
