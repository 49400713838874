import React, { Component } from "react";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import "react-toastify/dist/ReactToastify.css";
import Routes from "./config/Routes";
import { Loader } from "./common";

import { store, persistor, history } from "./config/store";
// import PersistedConnectedRouter from "./helpers/PersistedConnectedRouter";
import { ConnectedRouter } from "connected-react-router";
import Notifications from "./containers/Notifications";
import ErrorBoundary from "./common/ErrorBoundary";

class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <PersistGate loading={<Loader />} persistor={persistor}>
          <ConnectedRouter history={history}>
            <ErrorBoundary>
              <Routes history={history} />
              <Notifications />
            </ErrorBoundary>
          </ConnectedRouter>
        </PersistGate>
      </Provider>
    );
  }
}

export default App;
