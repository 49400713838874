import {
  FETCH_CURRENT_PLAN,
  FETCH_PLANS,
  SUBSCRIBE,
  RESET_PAYMENT_MODAL,
  CANCEL_SUBSCRIBE,
  REACTIVATE_SUBSCRIPTION,
  UPDATE_CREDIT_CARD,
  CREATE_CUSTOMER,
  FETCH_PAYMENTS,
  SET_PAYMENT_MODAL_STATE,
  CREATE_CUSTOMER_PORTAL_SESSION,
  CLEAR_CUSTOMER_PORTAL_SESSION,
} from "./types";

export const fetchCurrentPlan = () => ({
  type: FETCH_CURRENT_PLAN.REQUEST,
});

export const fetchCurrentPlanSucceeded = (currentPlan) => ({
  type: FETCH_CURRENT_PLAN.SUCCEEDED,
  payload: currentPlan,
});

export const fetchCurrentPlanFailed = (currentPlan) => ({
  type: FETCH_CURRENT_PLAN.FAILED,
  payload: currentPlan,
});

export const fetchPlans = () => ({
  type: FETCH_PLANS.REQUEST,
});

export const fetchPlansSucceeded = (plans) => ({
  type: FETCH_PLANS.SUCCEEDED,
  payload: plans,
});

export const fetchPlansFailed = (plans) => ({
  type: FETCH_PLANS.FAILED,
  payload: plans,
});

export const subscribeToPlan = (customerId, paymentMethodId, planId) => ({
  type: SUBSCRIBE.REQUEST,
  payload: {
    customerId,
    paymentMethodId,
    planId,
  },
});

export const subscribeToPlanSucceeded = (payload) => ({
  type: SUBSCRIBE.SUCCEEDED,
  payload,
});

export const subscribeToPlanFailed = (payload) => ({
  type: SUBSCRIBE.FAILED,
  payload,
});

export const resetPaymentModal = () => ({
  type: RESET_PAYMENT_MODAL,
});

export const cancelSubscription = (payload) => ({
  type: CANCEL_SUBSCRIBE.REQUEST,
  payload,
});

export const cancelSubscriptionSucceeded = (payload) => ({
  type: CANCEL_SUBSCRIBE.SUCCEEDED,
  payload,
});

export const cancelSubscriptionFailed = (payload) => ({
  type: CANCEL_SUBSCRIBE.FAILED,
  payload,
});

export const reactivateSubscription = (payload) => ({
  type: REACTIVATE_SUBSCRIPTION.REQUEST,
  payload,
});

export const reactivateSubscriptionSucceeded = (payload) => ({
  type: REACTIVATE_SUBSCRIPTION.SUCCEEDED,
  payload,
});

export const reactivateSubscriptionFailed = (payload) => ({
  type: REACTIVATE_SUBSCRIPTION.FAILED,
  payload,
});

export const createCustomer = (email, firstName, lastName) => ({
  type: CREATE_CUSTOMER.REQUEST,
  payload: {
    email,
    firstName,
    lastName,
  },
});

export const createCustomerSucceeded = (payload) => ({
  type: CREATE_CUSTOMER.SUCCEEDED,
  payload,
});

export const createCustomerFailed = (payload) => ({
  type: CREATE_CUSTOMER.FAILED,
  payload,
});

export const updateCreditCard = (token) => ({
  type: UPDATE_CREDIT_CARD.REQUEST,
  payload: {
    token,
  },
});

export const updateCreditCardSucceeded = (payload) => ({
  type: UPDATE_CREDIT_CARD.SUCCEEDED,
  payload,
});

export const updateCreditCardFailed = (payload) => ({
  type: UPDATE_CREDIT_CARD.FAILED,
  payload,
});

export const getPayments = () => ({
  type: FETCH_PAYMENTS.REQUEST,
});

export const getPaymentsSucceeded = (payments) => ({
  type: FETCH_PAYMENTS.SUCCEEDED,
  payload: payments,
});

export const getPaymentsFailed = (error) => ({
  type: FETCH_PAYMENTS.FAILED,
  payload: error,
});

export const setPaymentModalState = (state) => ({
  type: SET_PAYMENT_MODAL_STATE,
  payload: state,
});

export const createCustomerPortalSession = (payload) => ({
  type: CREATE_CUSTOMER_PORTAL_SESSION.REQUEST,
  payload,
});

export const createCustomerPortalSessionSucceeded = (payload) => ({
  type: CREATE_CUSTOMER_PORTAL_SESSION.SUCCEEDED,
  payload,
});

export const createCustomerPortalSessionFailed = (payload) => ({
  type: CREATE_CUSTOMER_PORTAL_SESSION.FAILED,
  payload,
});

export const clearCustomerPortalSession = () => ({
  type: CLEAR_CUSTOMER_PORTAL_SESSION,
});
