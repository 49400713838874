export const SubscriptionStatus = {
  PAID_AND_RECURRING: "paid_and_recurring",
  CANCELLED: "cancelled",
  REQUIRES_ACTION: "requires_action",
};

export const PaymentModalStates = {
  PAYMENT_INFO: "PAYMENT_INFO",
  PROCESSING: "PROCESSING",
  SUCCESS: "SUCCESS",
  FAILURE: "FAILURE",
  UDPATE_CARD: "UPDATE_CARD",
  CARD_UPDATED: "CARD_UPDATED",
  CARD_UPDATE_FAILURE: "CARD_UPDATE_FAILURE",
  REQUIRES_ACTION: "REQUIRES_ACTION",
};

export const Premium = {
  ITEM_LIMIT: 10,
};
