export const recaptcha = {
  sitekey: process.env.REACT_APP_RECAPTCHA_SITE_KEY || "not implemented yet",
};

export const facebook = {
  id: process.env.REACT_APP_FACEBOOK_ID,
};

export const api = {
  url: process.env.REACT_APP_API_URL || "http://localhost:3001",
};

export const stripeConf = {
  public_key: process.env.REACT_APP_STRIPE_PUBLIC_KEY,
};

export const debug = process.env.REACT_APP_DEBUG || true;

export const ga = process.env.REACT_APP_GA || "";

export const isStaging = process.env.REACT_APP_IS_STAGING || false;

export const toastDelay = process.env.REACT_APP_TOAST_DELAY || 2000;

//14 days by default = 1209600000
export const addToHomeDelay =
  process.env.REACT_APP_ADD_TO_HOME_DELAY || 1000 * 60 * 60 * 24 * 14;
