import React, { Component } from "react";
import PropTypes from "prop-types";

class LabeledCheckbox extends Component {
  render() {
    const { label, id, name, value, onChange, checked, cssClass } = this.props;
    return (
      <React.Fragment>
        <span>{label}</span>
        <input
          type="checkbox"
          key={id}
          id={id}
          name={name}
          value={value}
          onChange={onChange}
          checked={checked}
          className={cssClass}
        />
      </React.Fragment>
    );
  }
}

LabeledCheckbox.propTypes = {
  label: PropTypes.string,
  id: PropTypes.number,
  name: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  checked: PropTypes.bool,
  cssClass: PropTypes.string
};

export default LabeledCheckbox;
