import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import moment from "moment";
import {
  AppContent,
  Progress,
} from "../../common";
import {
  addTrial,
  updateTrial,
  fetchCourseTypes,
} from "../../actions";
import TrialForm from "./TrialForm/TrialForm";
import RunForm from "./TrialForm/RunForm";
import AdditionalForm from "./TrialForm/AdditionalForm";

const STEPS = {
  TRIAL: 1,
  RUN: 2,
  ADDITIONAL: 3,
  COMPLETED: 4,
};

class TrialEdit extends Component {
  constructor(props) {
    super(props);
    const t = props.t;
    this.courseTypeOptions = [
      { title: t("trial.agilityCourse"), value: "Agility course" },
      { title: t("trial.jumpingCourse"), value: "Jumping course" },
    ];

    this.disqualifiedOptions = [
      { title: t("trial.result"), value: false },
      { title: t("trial.disqualified"), value: true },
    ];
    this.state = {
      currentStep: STEPS.TRIAL,
      edit: false,
      errors: {},
      submitted: false,
      locationSuggestions: [],
      judgeSuggestions: [],
      form: {
        // TrialForm
        location: "",
        locationId: "",
        judge: "",
        judgeId: "",
        date: null,
        courseType: this.courseTypeOptions[0].value,
        organizationId: "",
        sizeClassId: "",
        courseTypeId: "",
        // RunForm
        result: null,
        faults: [],
        faultReasons: [],
        speed: null,
        winningSpeed: null,
        timeFaults: null,
        placement: null,
        dsq: false,
        courseLength: null,
        trialTime: null,
        winningTime: null,
        openingPoints: "",
        closingPoints: "",
        winningPoints: "",
        totalPoints: "",
        designatedTime: null,
        timeDifference: null,
        cleanRun: false,
        // Additional form
        courseMap: null,
        notes: null,
        videoUrl: null,
        certs: null,
      },
    };
  }

  componentDidMount() {
    const { id, date } = this.props.match.params;

    if (id) {
      const date = moment(this.props.trial.date).format("YYYY-MM-DD");
      this.setState({ form: { ...this.props.trial, date }, edit: true });
      this.fetchCourseTypes(this.props.trial.organizationId);
    } else if (date) {
      this.setState((prevState) => ({
        form: {
          ...prevState.form,
          organizationId: this.props.dog.organizations[0].id,
          date,
        },
      }));
      this.fetchCourseTypes(this.props.dog.organizations[0].id);
    } else {
      const date = moment().format("YYYY-MM-DD");
      this.setState((prevState) => ({
        form: {
          ...prevState.form,
          organizationId: this.props.dog.organizations[0].id,
          date,
        },
      }));
      this.fetchCourseTypes(this.props.dog.organizations[0].id);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { submitted, edit, form } = this.state;
    const { saving, error, history, courseTypes } = this.props;

    const justAdded =
      prevProps.saving === true && saving === false && !error && !edit;
    const justUpdated = !error && submitted !== prevState.submitted && edit;

    if (justUpdated) {
      // Quick and dirty. React Player bugs when changing video on fly
      setTimeout(
        () => history.push(`/trial/${this.props.match.params.id}`),
        1000
      );
    }

    if (justAdded) {
      if (localStorage.getItem("trialFilters")) {
        history.push("/trials/?f");
      } else {
        history.push("/trials/");
      }
    }

    if (!form.courseTypeId && courseTypes.length > 0) {
      this.setState({
        form: {
          ...form,
          courseTypeId: courseTypes[0].id,
        },
      });
    }

    if (
      prevProps.trial &&
      prevProps.trial.courseTypeId &&
      this.props.trial &&
      this.props.trial.courseTypeId &&
      prevProps.trial.courseTypeId !== this.props.trial.courseTypeId
    ) {
      this.setState((prevState) => ({
        form: {
          ...prevState.form,
          courseTypeId: this.props.trial.courseTypeId,
        },
      }));
    }

    if (
      prevProps.trial &&
      prevProps.trial.courseTypeId &&
      this.props.trial &&
      this.props.trial.courseTypeId &&
      prevProps.trial.organizationId !== this.props.trial.organizationId
    ) {
      this.setState((prevState) => ({
        form: {
          ...prevState.form,
          organizationId: this.props.trial.organizationId,
        },
      }));
    }
  }

  submitTrialForm = (data) => {
    this.setState((prevState) => ({
      currentStep: STEPS.RUN, 
      form: {
        ...prevState.form,
        ...data
      },
    }));
  }

  submitRunForm = (data) => {
    this.setState((prevState) => ({
      currentStep: STEPS.ADDITIONAL, 
      form: {
        ...prevState.form,
        ...data
      },
    }));
  }

  /* Saving returned form data with additional data - setting state not really needed. */
  submitAdditionalForm = (formdata) => {
    this.setState({
      form: {
        ...formdata
      },
    });
    
    this.handleSubmit(formdata);
  }

  handleSubmit = (form) => {
    const { dog } = this.props;

    let showOrganizations = false;
    let showSizeClasses = false;
    let organizationId = form.organizationId ? form.organizationId : 1;
    showOrganizations = dog.organizations.length > 1;

    let sizeClasses = dog.sizeClasses.filter(
      (sizeClass) =>
        parseInt(sizeClass.organizationId) === parseInt(organizationId)
    );
    showSizeClasses = sizeClasses.length > 1;
    let sizeClassId =
      sizeClasses && sizeClasses.length > 0 ? sizeClasses[0].id : "";
    if (this.state.edit) {
      if (showOrganizations && showSizeClasses) {
        this.props.updateTrial({ dog, trial: { ...form } });
      } else if (!showOrganizations && !showSizeClasses) {
        this.props.updateTrial({
          dog,
          trial: {
            ...form,
            organizationId: organizationId,
            sizeClassId: sizeClassId,
          },
        });
      } else if (!showOrganizations) {
        this.props.updateTrial({
          dog,
          trial: { ...form, organizationId: organizationId },
        });
      } else if (!showSizeClasses) {
        this.props.updateTrial({
          dog,
          trial: { ...form, sizeClassId: sizeClassId },
        });
      }
    } else {
      if (showOrganizations && showSizeClasses) {
        this.props.addTrial({
          trial: { ...form },
          dog,
        });
      } else if (!showOrganizations && !showSizeClasses) {
        this.props.addTrial({
          dog,
          trial: {
            ...form,
            organizationId: organizationId,
            sizeClassId: sizeClassId,
          },
        });
      } else if (!showOrganizations) {
        this.props.addTrial({
          dog,
          trial: { ...form, organizationId: organizationId },
        });
      } else if (!showSizeClasses) {
        this.props.addTrial({
          dog,
          trial: { ...form, sizeClassId: sizeClassId },
        });
      }
    }
    this.setState({ submitted: true });
  };

  render() {
    const { currentStep } = this.state;
    const progressPercent = (currentStep / 4) * 100;

    return (
      <AppContent size="medium">
        <Progress percent={progressPercent} />

        {currentStep === STEPS.TRIAL && 
        <>
          {this.state.form.date && 
            <TrialForm form={this.state.form} submitTrialForm={this.submitTrialForm} />
          }       
        </>
        }
        {currentStep === STEPS.RUN && 
          <RunForm 
            form={this.state.form} 
            submitRunForm={this.submitRunForm} 
            runFormBack={() => this.setState({ currentStep: STEPS.TRIAL })} />}

        {currentStep === STEPS.ADDITIONAL && 
          <AdditionalForm 
            form={this.state.form}
            submitAdditionalForm={this.submitAdditionalForm}
            additionalFormBack={() => this.setState({ currentStep: STEPS.RUN })} />}
      </AppContent>
    );
  }

  fetchCourseTypes = (orgId) => {
    const { fetchCourseTypes } = this.props;
    fetchCourseTypes(orgId);
  };
}

TrialEdit.propTypes = {
  t: PropTypes.func,
  auth_token: PropTypes.any,
  dog: PropTypes.object,
  trial: PropTypes.object,
  courseTypes: PropTypes.array,
  fetchCourseTypes: PropTypes.func,
  fetchingCourseTypes: PropTypes.bool,
  saving: PropTypes.bool,
  error: PropTypes.any,
  addTrial: PropTypes.func,
  updateTrial: PropTypes.func,
  history: PropTypes.object,
  match: PropTypes.object
};

const mapStateToProps = (
  { activeDog, trials, auth, settings, courseTypes, user },
  props
) => {
  const { id } = props.match.params;
  const trial = trials.trials.find((t) => t.id === Number(id));
  return {
    dog: activeDog,
    trial,
    fetchingTrials: trials.fetchingTrials,
    saving: trials.saving,
    error: trials.error,
    auth_token: auth.authToken,
    faultCategories: settings.trialFaultCategories,
    faultReasonCategories: settings.trialFaultReasonCategories,
    courseTypes: courseTypes.courseTypes,
    fetchingCourseTypes: courseTypes.fetchingCourseTypes,
    user: user.user,
  };
};

const TrialEditWithTranslate = withTranslation()(TrialEdit);
export default connect(mapStateToProps, {
  addTrial,
  updateTrial,
  fetchCourseTypes,
})(TrialEditWithTranslate);