// Auth
export const LOGIN = generateRequestObject("LOGIN");
export const SIGNUP = generateRequestObject("SIGNUP");
export const LOGOUT = generateRequestObject("LOGOUT");
export const RETURN_PASSWORD = generateRequestObject("RETURN_PASSWORD");
export const AUTH_EXPIRED = "AUTH_EXPIRED";

// User
export const FETCH_USER = generateRequestObject("FETCH_USER");
export const UPDATE_USER = generateRequestObject("UPDATE_USER");
export const DELETE_USER = generateRequestObject("DELETE_USER");
export const SAVE_SETTINGS = generateRequestObject("SAVE_SETTINGS");

// Dogs
export const FETCH_DOGS = generateRequestObject("FETCH_DOGS");
export const UPDATE_DOG = generateRequestObject("UPDATE_DOG");
export const ADD_DOG = generateRequestObject("ADD_DOG");
export const DELETE_DOG = generateRequestObject("DELETE_DOG");
export const FETCH_DOG = generateRequestObject("FETCH_DOG");

// Organizations
export const FETCH_ORGANIZATIONS = generateRequestObject("FETCH_ORGANIZATIONS");

// SizeClasses
export const FETCH_SIZE_CLASSES = generateRequestObject("FETCH_SIZE_CLASSES");
export const CLEAR_SIZE_CLASSES = "CLEAR_SIZE_CLASSES";

// TrialFilters
export const FETCH_JUDGES_FILTER = generateRequestObject("FETCH_JUDGES_FILTER");
export const FETCH_TRIAL_LOCATIONS_FILTER = generateRequestObject(
  "FETCH_TRIAL_LOCATIONS_FILTER"
);

// TrainingFilters
export const FETCH_COACHES_FILTER = generateRequestObject(
  "FETCH_COACHES_FILTER"
);

// CourseTypes
export const FETCH_COURSE_TYPES = generateRequestObject("FETCH_COURSE_TYPES");
export const CLEAR_COURSE_TYPES = "CLEAR_COURSE_TYPES";

// Active dog
export const SET_ACTIVE_DOG = "SET_ACTIVE_DOG";

// Trainings
export const FETCH_TRAININGS = generateRequestObject("FETCH_TRAININGS");
export const ADD_TRAINING = generateRequestObject("ADD_TRAINING");
export const UPDATE_TRAINING = generateRequestObject("UPDATE_TRAINING");
export const DELETE_TRAINING = generateRequestObject("DELETE_TRAINING");
export const FETCH_TRAININGS_FILTERED = generateRequestObject(
  "FETCH_TRAININGS_FILTERED"
);
export const FETCH_TRAININGS_PAGED = generateRequestObject(
  "FETCH_TRAININGS_PAGED"
);
export const FETCH_TRAININGS_PAGES_COUNT = generateRequestObject(
  "FETCH_TRAININGS_PAGES_COUNT"
);
export const FETCH_TRAININGS_COUNT = generateRequestObject(
  "FETCH_TRAININGS_COUNT"
);

// Trials
export const FETCH_TRIALS = generateRequestObject("FETCH_TRIALS");
export const ADD_TRIAL = generateRequestObject("ADD_TRIAL");
export const UPDATE_TRIAL = generateRequestObject("UPDATE_TRIAL");
export const DELETE_TRIAL = generateRequestObject("DELETE_TRIAL");
export const FETCH_TRIALS_FILTERED = generateRequestObject("FETCH_TRIALS_FILTERED");
export const FETCH_TRIALS_PAGED = generateRequestObject("FETCH_TRIALS_PAGED");
export const FETCH_TRIALS_PAGES_COUNT = generateRequestObject(
  "FETCH_TRIALS_PAGES_COUNT"
);
export const FETCH_TRIALS_COUNT = generateRequestObject("FETCH_TRIALS_COUNT");

// Topics
export const NOTE_SHARED_TOPIC = generateRequestObject("NOTE_SHARED_TOPIC");
export const FETCH_SHARED_TOPICS = generateRequestObject("FETCH_SHARED_TOPICS");
export const GET_SHARED_TOPIC = generateRequestObject("GET_SHARED_TOPIC");
export const COPY_SHARED_TOPIC = generateRequestObject("COPY_SHARED_TOPIC");

export const FETCH_TRAINING_TOPICS = generateRequestObject(
  "FETCH_TRAINING_TOPICS"
);
export const ADD_TRAINING_TOPIC_FORM = generateRequestObject(
  "ADD_TRAINING_TOPIC_FORM"
);
export const ADD_TRAINING_TOPIC = generateRequestObject("ADD_TRAINING_TOPIC");
export const UPDATE_TRAINING_TOPIC = generateRequestObject(
  "UPDATE_TRAINING_TOPIC"
);
export const UPDATE_TRAINING_TOPIC_NONOTIFICATION = generateRequestObject(
  "UPDATE_TRAINING_TOPIC_NONOTIFICATION"
);
export const DELETE_TRAINING_TOPIC = generateRequestObject(
  "DELETE_TRAINING_TOPIC"
);
export const COPY_TRAINING_TOPIC = generateRequestObject("COPY_TRAINING_TOPIC");
export const SHARE_TRAINING_TOPIC = generateRequestObject(
  "SHARE_TRAINING_TOPIC"
);
export const UNSHARE_TRAINING_TOPIC = generateRequestObject(
  "UNSHARE_TRAINING_TOPIC"
);
// SubTopics
export const ADD_TRAINING_SUBTOPIC = generateRequestObject(
  "ADD_TRAINING_SUBTOPIC"
);
export const UPDATE_TRAINING_SUBTOPIC = generateRequestObject(
  "UPDATE_TRAINING_SUBTOPIC"
);
export const DELETE_TRAINING_SUBTOPIC = generateRequestObject(
  "DELETE_TRAINING_SUBTOPIC"
);
// Exercises
export const ADD_TRAINING_EXERCISE = generateRequestObject(
  "ADD_TRAINING_EXERCISE"
);
export const UPDATE_TRAINING_EXERCISE = generateRequestObject(
  "UPDATE_TRAINING_EXERCISE"
);
export const UPDATE_TRAINING_EXERCISE_NONOTIFICATION = generateRequestObject(
  "UPDATE_TRAINING_EXERCISE_NONOTIFICATION"
);
export const DELETE_TRAINING_EXERCISE = generateRequestObject(
  "DELETE_TRAINING_EXERCISE"
);

// Settings
export const FETCH_SETTINGS = generateRequestObject("FETCH_SETTINGS");
export const AFTER_REHYDRATE = "AFTER_REHYDRATE";
export const REHYDRATE = "persist/REHYDRATE";

// Admin
export const FETCH_ADMIN_INFO = generateRequestObject("FETCH_ADMIN_INFO");

// Subscriptions
export const FETCH_CURRENT_PLAN = generateRequestObject("FETCH_CURRENT_PLAN");
export const FETCH_PLANS = generateRequestObject("FETCH_PLANS");
export const SUBSCRIBE = generateRequestObject("SUBSCRIBE");
export const RESET_PAYMENT_MODAL = "RESET_PAYMENT_MODAL";
export const SET_PAYMENT_MODAL_STATE = "SET_PAYMENT_MODAL_STATE";
export const CANCEL_SUBSCRIBE = generateRequestObject("CANCEL_SUBSCRIBE");
export const REACTIVATE_SUBSCRIPTION = generateRequestObject(
  "REACTIVATE_SUBSCRIPTION"
);
export const UPDATE_CREDIT_CARD = generateRequestObject("UPDATE_CREDIT_CARD");
export const FETCH_PAYMENTS = generateRequestObject("FETCH_PAYMENTS");
export const CREATE_CUSTOMER = generateRequestObject("CREATE_CUSTOMER");
export const CREATE_CUSTOMER_PORTAL_SESSION = generateRequestObject(
  "CREATE_CUSTOMER_PORTAL_SESSION"
);
export const CLEAR_CUSTOMER_PORTAL_SESSION = "CLEAR_CUSTOMER_PORTAL_SESSION";

//Coupons
export const FETCH_COUPON = generateRequestObject("FETCH_COUPON");
export const VALIDATE_COUPON = generateRequestObject("VALIDATE_COUPON");
export const UPDATE_COUPON = generateRequestObject("UPDATE_COUPON");
export const ADD_COUPON = generateRequestObject("ADD_COUPON");
export const FETCH_COUPON_AVAILABLE = generateRequestObject(
  "FETCH_COUPON_AVAILABLE"
);
export const CLEAR_COUPON_ERRORS = generateRequestObject("CLEAR_COUPON_ERRORS");

// Statistics
export const FETCH_TRIAL_STATS = generateRequestObject("FETCH_TRIAL_STATS");
export const FETCH_TRAINING_STATS = generateRequestObject(
  "FETCH_TRAINING_STATS"
);
export const FETCH_TRAINING_FOCUS_STATS = generateRequestObject(
  "FETCH_TRAINING_FOCUS_STATS"
);
export const FETCH_ACTIVITY_STATS = generateRequestObject(
  "FETCH_ACTIVITY_STATS"
);
export const FETCH_TRIAL_POSITION_STATS = generateRequestObject(
  "FETCH_TRIAL_POSITION_STATS"
);
export const FETCH_CLEAN_RUN_STATS = generateRequestObject(
  "FETCH_CLEAN_RUN_STATS"
);
export const FETCH_SPEED_STATS = generateRequestObject("FETCH_SPEED_STATS");
export const FETCH_TRIAL_LIST_SPEED_STATS = generateRequestObject(
  "FETCH_TRIAL_LIST_SPEED_STATS"
);
export const FETCH_TRIAL_LIST_CLEAN_RUN_STATS = generateRequestObject(
  "FETCH_TRIAL_LIST_CLEAN_RUN_STATS"
);
export const FETCH_TRIAL_LIST_FAULT_REASON_STATS = generateRequestObject(
  "FETCH_TRIAL_LIST_FAULT_REASON_STATS"
);
export const FETCH_TRIAL_LIST_FAULT_STATS = generateRequestObject(
  "FETCH_TRIAL_LIST_FAULT_STATS"
);
export const FETCH_TRIAL_LIST_POINT_STATS = generateRequestObject(
  "FETCH_TRIAL_LIST_POINT_STATS"
);
export const FETCH_TRIAL_LIST_TIME_STATS = generateRequestObject(
  "FETCH_TRIAL_LIST_TIME_STATS"
);
export const FETCH_TRIAL_LIST_DESIGNATED_TIME_STATS = generateRequestObject(
  "FETCH_TRIAL_LIST_DESIGNATED_TIME_STATS"
);
export const UPDATE_DOG_TRIAL_SIZE_CLASS = generateRequestObject(
  "UPDATE_DOG_TRIAL_SIZE_CLASS"
);

function generateRequestObject(name) {
  return {
    REQUEST: `${name}_REQUEST`,
    SUCCEEDED: `${name}_SUCCEEDED`,
    FAILED: `${name}_FAILED`,
  };
}
