import React from "react";
import PropTypes from "prop-types";

import { Loader } from "./";

const icons = {
  add: require("../images/icons/plus.svg"),
  edit: require("../images/icons/edit.svg"),
  continue: require("../images/icons/arrow-right.svg"),
  complete: require("../images/icons/check.svg")
};

const RoundButton = ({ size, icon, atCorner, className, onClick, ...rest }) => {
  let classes = [
    "round-button",
    `round-button--${size}`,
    `round-button--${icon}`
  ];
  classes.push(...className.split(" "));
  if (atCorner) classes.push("round-button--at-corner");
  if (icon === "loader") classes.push("round-button--loading");

  return (
    <button className={classes.join(" ")} onClick={onClick} {...rest}>
      {icon === "loader" ? (
        <Loader inverted />
      ) : (
        <img src={icons[icon]} alt="Open action menu" />
      )}
    </button>
  );
};

RoundButton.propTypes = {
  size: PropTypes.oneOf(["large", "medium", "small"]),
  icon: PropTypes.oneOf(["edit", "add", "continue", "complete", "loader"]),
  atCorner: PropTypes.bool
};

RoundButton.defaultProps = {
  size: "medium",
  icon: "edit",
  className: ""
};

export default RoundButton;
