import React, { Component } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import TrainingFilterModal from "./TrainingFilterModal";
import { fetchCoachesFilter } from "../actions";
import _ from "lodash";
import moment from "moment";
import confirm from "./Confirm";

class TrainingFilter extends Component {
  state = {
    filtersText: "",
    showModal: false,
  };

  componentDidMount() {
    this.props.fetchCoachesFilter();
  }

  handleModalCloseClick = () => {
    const { cancelFilters } = this.props;
    this.setState({ showModal: false });
    cancelFilters();
  };

  handleApplyFiltersClick = () => {
    const { applyFilters } = this.props;
    this.setState({ showModal: false });
    applyFilters();
  };

  handleClearFiltersClick = () => {
    const { clearFilters } = this.props;
    this.setState({ showModal: false });
    clearFilters();
  };

  showModal = (e) => {
    const { preventFeedLoading } = this.props;
    preventFeedLoading();
    e.preventDefault();
    this.setState({ showModal: true });
  };

  getPremium = (e) => {
    const { t } = this.props;
    e.stopPropagation();
    e.preventDefault();
    confirm(t("subscription.noPlanYet"), {
      title: t("paywall.getPremium"),
      ok: t("common.ok"),
      cancel: t("common.cancel"),
    }).then(
      () => {
        this.props.history.push("/subscription");
      },
      () => {}
    );
  };


  render() {
    const {
      coachesFilter,
      datesFilter,
      trainingTypeFilter,
      updateCoachesFilter,
      updateDateFilter,
      updateTrainingTypeFilter,
      fetchingCoachesFilter,
      coaches,
      trainingsCount,
      fetchingTrainingsCount,
      t,
    } = this.props;

    let filtersText = "";

    if (datesFilter && datesFilter[0] && datesFilter[1]) {
      filtersText =
        filtersText +
        " " +
        moment(datesFilter[0]).format("L") +
        " - " +
        moment(datesFilter[1]).format("L");
    }

    coaches.forEach((coach) => {
      if (
        _.includes(
          coachesFilter.map((c) => c.value),
          coach.id
        )
      ) {
        filtersText = filtersText + " " + coach.name;
      }
    });
    if (trainingTypeFilter) {
      filtersText =
        filtersText + " " + t("filters.trainingType_" + trainingTypeFilter);
    }

    return (
      <>
        <div className="column-tight column-tight--align-top">
          <div className="flex-item-extra-wide">
            {!fetchingTrainingsCount && (
              <h3 className="filter-title">
                {trainingsCount + t("filters.trainingsCount")}
              </h3>
            )}
            {filtersText && (
              <>
                <h3 className="filter-title">{t("filters.currentFilters")}:</h3>
                <div>
                  <span>{filtersText}</span>
                </div>
              </>
            )}
          </div>
          <div className="flex-item-right-align float-right">
            <span>
              {this.props.hasActivePlan ? 
                <button
                  className="btn btn-outline-primary"
                  onClick={this.showModal}
                >
                  {t("filters.setFilters")}
                </button>
                :
                <button
                  className="btn btn-outline-secondary"
                  onClick={this.getPremium}
                >
                  {t("filters.setFilters")}
                </button>}
            </span>
          </div>
        </div>
        <TrainingFilterModal
          coachesFilter={coachesFilter}
          datesFilter={datesFilter}
          trainingTypeFilter={trainingTypeFilter}
          updateCoachesFilter={updateCoachesFilter}
          updateDateFilter={updateDateFilter}
          updateTrainingTypeFilter={updateTrainingTypeFilter}
          fetchingCoachesFilter={fetchingCoachesFilter}
          coaches={coaches}
          showModal={this.state.showModal}
          onModalClose={this.handleModalCloseClick}
          onApplyFilters={this.handleApplyFiltersClick}
          onClearFilters={this.handleClearFiltersClick}
          trainingsCount={trainingsCount}
        />
      </>
    );
  }
}

TrainingFilter.propTypes = {
  t: PropTypes.func,
  coachesFilter: PropTypes.array,
  datesFilter: PropTypes.array,
  trainingTypeFilter: PropTypes.any,
  updateCoachesFilter: PropTypes.func,
  updateDateFilter: PropTypes.func,
  updateTrainingTypeFilter: PropTypes.func,
  fetchCoachesFilter: PropTypes.func,
  applyFilters: PropTypes.func,
  cancelFilters: PropTypes.func,
  fetchingCoachesFilter: PropTypes.bool,
  coaches: PropTypes.array,
  trainingsCount: PropTypes.number,
  fetchingTrainingsCount: PropTypes.bool,
  preventFeedLoading: PropTypes.func,
  clearFilters: PropTypes.func,
  hasActivePlan: PropTypes.bool,
  history: PropTypes.object,
};
const mapStateToProps = ({ trainingFilters, subscriptions }) => ({
  coaches: trainingFilters.coaches,
  fetchingCoachesFilter: trainingFilters.fetchingCoachesFilter,
  hasActivePlan:
      subscriptions.currentPlan &&
      moment(subscriptions.currentPlan.paid_until) > moment(),
});

const TrainingFilterWithTranslate = withTranslation()(TrainingFilter);

export default withRouter(connect(mapStateToProps, {
  fetchCoachesFilter,
})(TrainingFilterWithTranslate));
