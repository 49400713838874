import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import _ from "lodash";
import moment from "moment";

import { addTraining, updateTraining } from "../../actions";
import Api from "../../helpers/Api";
import {
  AppContent,
  FormGroup,
  Input,
  TextArea,
  RoundButton,
  Stars,
  SuggestInput,
  Error,
  SelectGroup,
  ImageUploader,
} from "../../common";
import { TrainingFocusesExpanded } from "../TrainingTopic/TrainingFocusesExpanded";
import SelectFocusList from "../../components/SelectFocusList";
import { castToNestedFocusArray } from "../../helpers/Transformer";

const VIEWS = {
  FORM: "form",
  FOCUSES: "focuses",
};

class TrainingDiaryEdit extends Component {
  constructor(props) {
    super(props);
    const t = props.t;
    this.trainingTypeOptions = [
      { title: t("training.independentTraining"), value: "independent" },
      { title: t("training.coachedTraining"), value: "coached" },
    ];

    this.state = {
      submitted: false,
      errors: {},
      edit: false,
      view: VIEWS.FORM,
      videoPlayed: 0,
      videoLoaded: 0,
      coachSuggestions: [],
      form: {
        coach: "",
        coachId: null,
        trainingType: this.trainingTypeOptions[0].value,
        date: null,
        focuses: [],
        notes: "",
        videoUrl: "",
        courseMap: "",
        rating: 0,
      },
    };
  }

  componentDidMount() {
    const { id, exerciseid, date } = this.props.match.params;
    if (id) {
      const date = moment(this.props.training.date).format("YYYY-MM-DD");
      this.setState({ form: { ...this.props.training, date }, edit: true });
    } 
    else if (date) {
      this.setState((prevState) => ({
        form: {
          ...prevState.form,
          date,
        },
      }));
    }
    else {
      const date = moment().format("YYYY-MM-DD");
      this.setState((prevState) => ({
        form: {
          ...prevState.form,
          date,
        },
      }));
    }
    // If moving exercise from training topics.
    if (exerciseid) {
      const date = moment().format("YYYY-MM-DD");
      this.setState({
        form: {
          ...this.props.exercise,
          rating: 0,
          coachId: null,
          trainingType: this.trainingTypeOptions[0].value,
          exerciseId: exerciseid,
          date
        },
        edit: false,
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { submitted, edit } = this.state;
    const { saving, error, history } = this.props;

    const justAdded =
      prevProps.saving === true && saving === false && !error && !edit;
    const justUpdated = !error && submitted !== prevState.submitted && edit;

    if (justUpdated) {
      // Quick and dirty. React Player bugs when changing video on fly
      setTimeout(
        () => history.push(`/training-diary/${this.props.match.params.id}`),
        1000
      );
    }
    if (justAdded) {
      if (localStorage.getItem("trainingFilters")) {
        history.push("/training-diary/?f");
      } else {
        history.push("/training-diary/");
      }
    }
  }

  render() {
    return (
      <AppContent size="medium">
        {this.state.view === VIEWS.FORM
          ? this.renderForm()
          : this.renderFocusList()}
      </AppContent>
    );
  }

  handleFocusToggle = (item) => {
    const { focuses } = this.state.form;
    const items = Array.isArray(item) ? item : [item];
    let newFocuses = [...focuses];
    items.forEach((item) => {
      const isSelected = _.some(focuses, { key: item.key });

      if (isSelected) {
        newFocuses = newFocuses.filter((i) => i.key !== item.key);
      } else {
        newFocuses = [...newFocuses, item];
      }
    });

    this.setState((prevState) => ({
      form: { ...prevState.form, focuses: newFocuses },
    }));
  };

  renderFocusList() {
    const { t, focusCategories } = this.props;

    return (
      <React.Fragment>
        <SelectFocusList
          list={castToNestedFocusArray(focusCategories)}
          renderName={(name) => t(`misc.${name}`)}
          selectedItems={this.state.form.focuses}
          onChange={this.handleFocusToggle}
        />
        <RoundButton
          icon={"complete"}
          size="large"
          atCorner
          onClick={() => this.setState({ view: VIEWS.FORM })}
        />
      </React.Fragment>
    );
  }

  renderForm() {
    const { errors } = this.state;
    const {
      trainingType,
      date,
      notes,
      videoUrl,
      rating,
      courseMap,
      coach,
    } = this.state.form;

    const { t, saving } = this.props;

    return (
      <React.Fragment>
        <FormGroup>
          <SelectGroup
            label={t("training.type")}
            type="text"
            name="trainingType"
            value={trainingType}
            options={this.trainingTypeOptions}
            onChange={this.handleInputChange}
          />
        </FormGroup>
        {trainingType === "coached" && (
          <FormGroup>
            <SuggestInput
              value={coach || ""}
              onChange={this.handleCoachChange}
              onBlur={this.setCoachId}
              suggestions={this.state.coachSuggestions}
              name="coach"
              label={t("training.coach")}
              onFetch={this.fetchCoaches}
              onClear={() => this.setState({ coachSuggestions: [] })}
              suggestionValue={(suggestion) => suggestion.name}
              renderSuggestion={(suggestion) => <div>{suggestion.name}</div>}
            />
          </FormGroup>
        )}
        <FormGroup>
          <Input
            label={t("common.date")}
            type="date"
            name="date"
            value={date}
            onChange={this.handleInputChange}
          />
          <Error show={!!errors.date}>{errors.date}</Error>
        </FormGroup>
        <FormGroup>
          <label>{t("training.focuses")}</label>
          <p>
            <button
              className="btn btn-secondary btn-block"
              onClick={(e) => {
                e.preventDefault();
                this.setState({ view: VIEWS.FOCUSES });
              }}
            >
              {t("training.focusesButton")}
            </button>
          </p>
          <p>{t("training.focusesNote")}</p>
          <TrainingFocusesExpanded 
            trainingFocuses={this.state.form.focuses} 
            handleFocusToggle={this.handleFocusToggle} 
            t={t} />
        </FormGroup>

        <FormGroup>
          <ImageUploader
            buttonLabel={t("training.courseMapButton")}
            imageUrl={courseMap}
            onUpload={this.handleCourseMapUpload}
          />
        </FormGroup>
        <FormGroup>
          <TextArea
            label={t("training.notes")}
            type="text"
            name="notes"
            value={notes}
            onChange={this.handleInputChange}
          />
        </FormGroup>

        <FormGroup>
          <Input
            label={t("training.videoUrl")}
            type="url"
            name="videoUrl"
            value={videoUrl}
            onChange={this.handleInputChange}
          />
        </FormGroup>

        <FormGroup>
          <label>{t("training.howDid")}</label>
          <Stars
            editable
            value={rating}
            onChange={(rating) =>
              this.setState((prevState) => ({
                form: { ...prevState.form, rating },
              }))
            }
          />
        </FormGroup>

        <RoundButton
          atCorner
          size="large"
          icon={saving ? "loader" : "complete"}
          onClick={this.handleSubmit}
        />
      </React.Fragment>
    );
  }

  handleSubmit = () => {
    const { dog, addTraining, updateTraining } = this.props;
    const { edit } = this.state;
    const {
      trainingType,
      date,
      notes,
      videoUrl,
      rating,
      focuses,
      courseMapFile,
      coach,
      coachId,
      exerciseId,
    } = this.state.form;
    const isValid = this.validate();

    if (!isValid) return false;

    const training = {
      trainingType,
      date,
      notes,
      videoUrl,
      rating,
      focuses,
      courseMapFile,
      coach: trainingType === "independent" ? "" : coach,
      coachId: trainingType === "independent" ? "" : coachId,
    };

    // If moving exercise to training diary.
    if (exerciseId) training.exerciseId = exerciseId;

    if (edit) {
      const id = this.state.form.id;
      updateTraining({
        training,
        dog,
        id,
      });
    } else {
      addTraining({ training, dog });
    }

    this.setState({ submitted: true });
  };

  handleCoachChange = (e, { newValue }) => {
    const name = "coach";
    const value = newValue;
    this.setState((prevState) => ({
      form: { ...prevState.form, coachId: null },
    }));
    this.handleInputChange({ target: { value, name } });
  };

  setCoachId = () => {
    const { coach } = this.state.form;
    const { coachSuggestions } = this.state;
    const suggestion = coachSuggestions.find((l) => l.name === coach);

    if (suggestion) {
      console.log("CoachId: " + suggestion.id);
      this.setState((prevState) => ({
        form: { ...prevState.form, coachId: suggestion.id },
      }));
    }
  };

  handleInputChange = (e) => {
    const { name, value } = e.target;
    // console.log("name: " + name + " value: " + value);
    this.setState((prevState) => ({
      form: { ...prevState.form, [name]: value },
    }));
  };

  handleCourseMapUpload = (image) => {
    this.setState({
      form: {
        ...this.state.form,
        courseMap: image.imagePreviewUrl,
        courseMapFile: image.file,
      },
    });
  };

  validate() {
    const { t } = this.props;
    const { date } = this.state.form;
    let errors = {};

    if (!date) errors.date = t("error.required");

    this.setState({ errors });
    return _.isEmpty(errors);
  }

  fetchCoaches = (e) => {
    const { auth_token } = this.props;
    Api({ auth_token })
      .fetchCoaches(e.value)
      .then((res) =>
        this.setState({
          coachSuggestions: res.data || [],
        })
      );
  };
}

TrainingDiaryEdit.propTypes = {
  t: PropTypes.func,
  auth_token: PropTypes.any,
};

const mapStateToProps = (
  { activeDog, trainings, topics, auth, settings },
  props
) => {
  const { id, exerciseid } = props.match.params;
  const training = trainings.trainings.find((t) => t.id === Number(id));

  // Finding the exercise to be made into a training
  let exercise;
  for (let topic of topics.topics) {
    for (let ex of topic.exercises) {
      if (ex.id === Number(exerciseid)) {
        let notes = topic.name + " | " + ex.name + ": " + ex.notes;
        exercise = { ...ex, notes: notes, focuses: topic.trainingFocuses };
      }
    }
    for (let subtopic of topic.trainingSubTopics) {
      for (let ex of subtopic.exercises) {
        if (ex.id === Number(exerciseid)) {
          let notes =
            topic.name +
            " | " +
            subtopic.name +
            " | " +
            ex.name +
            ": " +
            ex.notes;
          exercise = { ...ex, notes: notes, focuses: topic.trainingFocuses };
        }
      }
    }
  }

  return {
    dog: activeDog,
    training,
    exercise,
    saving: trainings.saving,
    error: trainings.error,
    auth_token: auth.authToken,
    focusCategories: settings.trainingFocusCategories,
  };
};

const TrainingDiaryEditWithTranslate = withTranslation()(TrainingDiaryEdit);
export default connect(mapStateToProps, { addTraining, updateTraining })(
  TrainingDiaryEditWithTranslate
);
