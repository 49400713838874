import React from "react";

const FormGroup = ({ children, className, ...rest }) => {
  const classNames = "form-group" + (className ? ` ${className}` : "");
  return (
    <div className={classNames} {...rest}>
      {children}
    </div>
  );
};

export default FormGroup;
