import React, { Component } from "react";
import PropTypes from "prop-types";

import star from "../images/icons/star.svg";
import starEmpty from "../images/icons/star-o.svg";

class Stars extends Component {
  state = {
    ratingValues: [1, 2, 3, 4, 5],
    mouseEnterRating: null
  };

  handleClick = (rating, e) => {
    e.preventDefault();
    const { editable, onChange } = this.props;
    if (editable) onChange(rating);
  };

  handleMouseEnter = mouseEnterRating => {
    // const { editable } = this.props;
    // if (editable) this.setState({ mouseEnterRating });
  };

  handleMouseLeave = () => {
    // const { editable } = this.props;
    // if (editable) this.setState({ mouseEnterRating: null });
  };

  render() {
    const { ratingValues, mouseEnterRating } = this.state;
    const { value, size } = this.props;
    let classes = ["stars"];
    classes.push(`stars--${size}`);
    return (
      <div className={classes.join(" ")}>
        {ratingValues.map(r => (
          <button
            className="stars__star"
            key={r}
            onClick={e => this.handleClick(r, e)}
            onMouseEnter={() => this.handleMouseEnter(r)}
            onMouseLeave={this.handleMouseLeave}
          >
            {r > (mouseEnterRating || value) ? (
              <img className="stars__image" src={starEmpty} alt="Empty star" />
            ) : (
              <img className="stars__image" src={star} alt="Full star" />
            )}
          </button>
        ))}
      </div>
    );
  }
}

Stars.propTypes = {
  value: PropTypes.number,
  onChange: PropTypes.func,
  editable: PropTypes.bool,
  size: PropTypes.oneOf(["medium", "small"])
};

Stars.defaultProps = {
  size: "medium"
};

export default Stars;
