import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { Trans, withTranslation } from "react-i18next";
import ReCAPTCHA from "react-google-recaptcha";

import { recaptcha } from "../../config/env";
import {
  Container,
  RoundButton,
  FormGroup,
  Input,
  CheckboxConfirm,
} from "../../common";
import { signup } from "../../actions/AuthActions";

const INITIAL_CREDENTIAL_STATE = {
  firstName: "",
  lastName: "",
  email: "",
  password: "",
  agreedTerms: false,
  allowEmails: false,
  recaptcha: "",
  form: {
    valid: false,
  },
};

class Signup extends Component {
  state = {
    redirect: false,
    ...INITIAL_CREDENTIAL_STATE,
  };

  handleSubmit = (e) => {
    const { recaptcha } = this.state;
    e.preventDefault();

    if (recaptcha) {
      this.handleSignup(recaptcha);
    } else {
      this.recaptcha.execute();
    }
  };

  handleRecaptchaChange = (recaptcha) => {
    this.setState({ recaptcha });
    this.handleSignup(recaptcha);
  };

  handleSignup = (recaptcha) => {
    const { signup } = this.props;
    const {
      firstName,
      lastName,
      email,
      password,
      agreedTerms,
      allowEmails,
    } = this.state;
    const user = {
      firstName,
      lastName,
      email,
      password,
      agreedTerms,
      recaptcha,
      allowEmails,
    };
    signup(user);
    this.setState({ redirect: true });
  };

  handleInputChange = (e) => {
    let { name, value } = e.target;

    if (name === "agreedTerms") value = e.target.checked;
    if (name === "allowEmails") value = e.target.checked;
    this.setState({ [name]: value });
  };

  render() {
    const { isAuthenticated, waiting, t } = this.props;
    const {
      firstName,
      lastName,
      email,
      password,
      agreedTerms,
      redirect,
      allowEmails,
    } = this.state;

    if (redirect && isAuthenticated) {
      this.setState({ ...INITIAL_CREDENTIAL_STATE });
      return <Redirect to="/signup/add-dog" />;
    }

    return (
      <Container size="small">
        <h1>{t("signup.title")}</h1>

        <form onSubmit={this.handleSubmit}>
          <FormGroup>
            <Input
              label={t("common.firstname")}
              type="text"
              name="firstName"
              placeholder={t("common.firstname-placeholder")}
              onChange={this.handleInputChange}
              value={firstName}
              required
              error={t("common.firstname-invalid")}
            />
          </FormGroup>

          <FormGroup>
            <Input
              label={t("common.lastname")}
              type="text"
              name="lastName"
              placeholder={t("common.lastname-placeholder")}
              onChange={this.handleInputChange}
              value={lastName}
              required
              error={t("common.lastname-invalid")}
            />
          </FormGroup>

          <FormGroup>
            <Input
              label={t("common.email")}
              type="email"
              name="email"
              placeholder={t("common.email-placeholder")}
              onChange={this.handleInputChange}
              value={email}
              required
              error={t("common.email-invalid")}
              autoCapitalize="off"
            />
          </FormGroup>

          <FormGroup>
            <Input
              label={t("common.password")}
              type="password"
              name="password"
              placeholder={t("common.password-placeholder")}
              onChange={this.handleInputChange}
              value={password}
              required
              error={t("common.password-invalid")}
              autoCapitalize="off"
            />
          </FormGroup>

          <FormGroup>
            <CheckboxConfirm
              label={t("settings.allowEmails")}
              name="allowEmails"
              onChange={this.handleInputChange}
              value={allowEmails}
            />
          </FormGroup>

          <FormGroup>
            <CheckboxConfirm
              label={
                <Trans i18nKey="signup.terms">
                  <a
                    href="https://www.aginotes.com/terms-and-conditions"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    ...
                  </a>
                  <a
                    href="https://www.aginotes.com/privacy-policy"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    ...
                  </a>
                  ...
                </Trans>
              }
              name="agreedTerms"
              onChange={this.handleInputChange}
              value={agreedTerms}
              required
              error={t("signup.terms-invalid")}
            />
          </FormGroup>

          <FormGroup>
            <ReCAPTCHA
              ref={(el) => (this.recaptcha = el)}
              sitekey={recaptcha.sitekey}
              onChange={this.handleRecaptchaChange}
              size="invisible"
              badge="inline"
            />
          </FormGroup>

          <p>
            <RoundButton
              atCorner
              icon={waiting ? "loader" : "continue"}
              size="large"
            />
          </p>
        </form>
      </Container>
    );
  }
}

const mapStateToProps = ({ auth }) => ({
  isAuthenticated: auth.isAuthenticated,
  waiting: auth.waiting,
});

const SignupWithTranslate = withTranslation()(Signup);
export default connect(mapStateToProps, { signup })(SignupWithTranslate);
