import React from "react";
import { withRouter } from "react-router";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import TrialCard from "../../components/TrialCard";
import TrainingCard from "../../components/TrainingCard";
import { withTranslation } from "react-i18next";

const CalendarEventPopper = (props) => {
  const { setEventOpen, setEventInfo, eventOpen, eventPosition, eventInfo, activeDog, user } = props;

  if (eventInfo) {
    const toggleState = eventOpen ? "open" : "closed";
    return (
      <div>    
        <div
          className={`overlay overlay-calendar overlay--${toggleState}`}
          onClick={() => {setEventOpen(false);
            setEventInfo(null);}}
        />
        <div className={`calendar-popup calendar-popup--${toggleState}`} style={{top:`${eventPosition.y+10}px`}}>
          {eventInfo.type === "training" && 
              <TrainingCard
                key={eventInfo.training.id}
                training={eventInfo.training}
                dog={activeDog}
                onClick={() => props.history.push(`/training-diary/${eventInfo.training.id}`)}
                user={user}
                calendar={true}
              />
          }
          {eventInfo.type === "trial" &&
              <TrialCard
                key={eventInfo.trial.id}
                trial={eventInfo.trial}
                dog={activeDog}
                onClick={() => props.history.push(`/trial/${eventInfo.trial.id}`)}
                user={user}
                calendar={true}
              />
          }              
        </div>    
      </div>    
    );
  }      
  else return null;
};

CalendarEventPopper.propTypes = {
  t: PropTypes.func,
  hasActivePlan: PropTypes.bool,
  setEventOpen: PropTypes.func,
  setEventInfo: PropTypes.func,
  eventOpen: PropTypes.bool,
  eventPosition: PropTypes.object,
  eventInfo: PropTypes.object,
  activeDog: PropTypes.object,
  user: PropTypes.object
};

const mapStateToProps = ({ user, activeDog, }) => ({
  user: user.user,
  activeDog: activeDog,
});

const CalendarEventPopperWithTranslate = withTranslation()(CalendarEventPopper);
export default withRouter(connect(mapStateToProps, { })(CalendarEventPopperWithTranslate));