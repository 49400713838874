import { LOGIN, SIGNUP, LOGOUT, AFTER_REHYDRATE } from "../actions/types";

const INITIAL_STATE = {
  onboarded: false,
  isAuthenticated: false,
  authToken: null,
  authId: null,
  loginError: null,
  signupError: null,
  logoutError: null,
  waiting: false
};

export default function (state = INITIAL_STATE, { type, payload }) {
  switch (type) {
    case LOGIN.REQUEST:
      return { ...state, loginError: INITIAL_STATE.loginError };
    case LOGIN.SUCCEEDED:
      return {
        ...state,
        authToken: payload.auth_token,
        authId: payload.auth_id,
        isAuthenticated: true
      };
    case LOGIN.FAILED:
      return { ...state, loginError: payload };

    case SIGNUP.REQUEST:
      return {
        ...state,
        waiting: true,
        signupError: INITIAL_STATE.signupError
      };
    case SIGNUP.SUCCEEDED:
      return {
        ...state,
        waiting: false,
        authToken: payload.auth_token,
        user: payload.user,
        isAuthenticated: true
      };
    case SIGNUP.FAILED:
      return { ...state, waiting: false, signupError: payload };

    //TODO: Should have a token and then logout.succeeded without data and maybe failed just to clear stuff
    // case LOGOUT.REQUEST:
    //   debugger;
    //   return {
    //     ...state,
    //     authToken: payload.auth_token
    //   };

    case LOGOUT.REQUEST:
      return INITIAL_STATE;

    case AFTER_REHYDRATE:
      return { ...state, waiting: false };
    default:
      return state;
  }
}
