import {
  NOTE_SHARED_TOPIC,
  FETCH_SHARED_TOPICS,
  SHARE_TRAINING_TOPIC,
  UNSHARE_TRAINING_TOPIC,
  GET_SHARED_TOPIC,
  COPY_SHARED_TOPIC,
  FETCH_TRAINING_TOPICS,
  ADD_TRAINING_TOPIC_FORM,
  ADD_TRAINING_TOPIC,
  UPDATE_TRAINING_TOPIC,
  UPDATE_TRAINING_TOPIC_NONOTIFICATION,
  DELETE_TRAINING_TOPIC,
  COPY_TRAINING_TOPIC,
  ADD_TRAINING_SUBTOPIC,
  UPDATE_TRAINING_SUBTOPIC,
  DELETE_TRAINING_SUBTOPIC,
  ADD_TRAINING_EXERCISE,
  UPDATE_TRAINING_EXERCISE,
  DELETE_TRAINING_EXERCISE,
  UPDATE_TRAINING_EXERCISE_NONOTIFICATION
} from "./types";
export const noteSharedTopic = id => ({
  type: NOTE_SHARED_TOPIC.REQUEST,
  payload: id
});
export const noteSharedTopicSucceeded = id => ({
  type: NOTE_SHARED_TOPIC.SUCCEEDED,
  payload: id
});
export const noteSharedTopicFailed = id => ({
  type: NOTE_SHARED_TOPIC.FAILED,
  payload: id
});

export const fetchSharedTopics = () => ({
  type: FETCH_SHARED_TOPICS.REQUEST
});

export const fetchSharedTopicsSucceeded = topics => ({
  type: FETCH_SHARED_TOPICS.SUCCEEDED,
  payload: topics
});

export const fetchSharedTopicsFailed = error => ({
  type: FETCH_SHARED_TOPICS.FAILED,
  payload: error
});

export const shareTopic = (topicId, dog) => ({
  type: SHARE_TRAINING_TOPIC.REQUEST,
  payload: { topicId, dog }
});

export const shareTopicSucceeded = sharedTopic => ({
  type: SHARE_TRAINING_TOPIC.SUCCEEDED,
  payload: { sharedTopic }
});

export const shareTopicFailed = error => ({
  type: SHARE_TRAINING_TOPIC.FAILED,
  payload: error
});

export const unShareTopic = (topicId, dog) => ({
  type: UNSHARE_TRAINING_TOPIC.REQUEST,
  payload: { topicId, dog }
});

export const unShareTopicSucceeded = () => ({
  type: UNSHARE_TRAINING_TOPIC.SUCCEEDED
});

export const unShareTopicFailed = error => ({
  type: UNSHARE_TRAINING_TOPIC.FAILED,
  payload: error
});

export const getSharedTopic = topicId => ({
  type: GET_SHARED_TOPIC.REQUEST,
  payload: { topicId }
});

export const getSharedTopicSucceeded = topic => ({
  type: GET_SHARED_TOPIC.SUCCEEDED,
  payload: topic
});

export const getSharedTopicFailed = error => ({
  type: GET_SHARED_TOPIC.FAILED,
  payload: error
});

export const copySharedTopic = payload => ({
  // payload = {topicId: topic.id, topicDogID: fromDog, dogIdToCopy: toDog }
  type: COPY_SHARED_TOPIC.REQUEST,
  payload: payload
});

export const copySharedTopicSucceeded = payload => ({
  type: COPY_SHARED_TOPIC.SUCCEEDED,
  payload: payload
});

export const copySharedTopicFailed = error => ({
  type: COPY_SHARED_TOPIC.FAILED,
  payload: error
});

export const fetchTrainingTopics = () => ({
  type: FETCH_TRAINING_TOPICS.REQUEST
});

export const fetchTrainingTopicsSucceeded = topics => ({
  type: FETCH_TRAINING_TOPICS.SUCCEEDED,
  payload: topics
});

export const fetchTrainingTopicsFailed = error => ({
  type: FETCH_TRAINING_TOPICS.FAILED,
  payload: error
});

export const addTrainingTopicForm = topic => ({
  type: ADD_TRAINING_TOPIC_FORM.REQUEST,
  payload: topic
});

export const addTrainingTopicFormSucceeded = topic => ({
  type: ADD_TRAINING_TOPIC_FORM.SUCCEEDED,
  payload: topic
});

export const addTrainingTopicFormFailed = error => ({
  type: ADD_TRAINING_TOPIC_FORM.FAILED,
  payload: error
});

export const addTrainingTopic = topic => ({
  type: ADD_TRAINING_TOPIC.REQUEST,
  payload: topic
});

export const addTrainingTopicSucceeded = topic => ({
  type: ADD_TRAINING_TOPIC.SUCCEEDED,
  payload: topic
});

export const addTrainingTopicFailed = error => ({
  type: ADD_TRAINING_TOPIC.FAILED,
  payload: error
});

// export const updateTopics = ({ topic, dog, id }) => ({
//   type: UPDATE_TOPICS.REQUEST,
//   payload: { topic, dog, id }
// });

// export const updateTopicsSucceeded = topic => ({
//   type: UPDATE_TOPICS.SUCCEEDED,
//   payload: topic
// });

// export const updateTopicsFailed = error => ({
//   type: UPDATE_TOPICS.FAILED,
//   payload: error
// });

export const updateTrainingTopic = topic => ({
  type: UPDATE_TRAINING_TOPIC.REQUEST,
  payload: topic
});

export const updateTrainingTopicSucceeded = topic => ({
  type: UPDATE_TRAINING_TOPIC.SUCCEEDED,
  payload: topic
});

export const updateTrainingTopicFailed = error => ({
  type: UPDATE_TRAINING_TOPIC.FAILED,
  payload: error
});

// Update without notification
export const updateTrainingTopicNoNotification = topic => ({
  type: UPDATE_TRAINING_TOPIC_NONOTIFICATION.REQUEST,
  payload: topic
});

export const updateTrainingTopicNoNotificationSucceeded = topic => ({
  type: UPDATE_TRAINING_TOPIC_NONOTIFICATION.SUCCEEDED,
  payload: topic
});

export const updateTrainingTopicNoNotificationFailed = error => ({
  type: UPDATE_TRAINING_TOPIC_NONOTIFICATION.FAILED,
  payload: error
});

export const deleteTrainingTopic = id => ({
  type: DELETE_TRAINING_TOPIC.REQUEST,
  payload: id
});

export const deleteTrainingTopicSucceeded = id => ({
  type: DELETE_TRAINING_TOPIC.SUCCEEDED,
  payload: id
});

export const deleteTrainingTopicFailed = error => ({
  type: DELETE_TRAINING_TOPIC.FAILED,
  payload: error
});

export const copyTrainingTopic = payload => ({
  // payload = {topicId: topic.id, dogIdToCopy: chosenOtherDog }
  type: COPY_TRAINING_TOPIC.REQUEST,
  payload: payload
});

export const copyTrainingTopicSucceeded = payload => ({
  type: COPY_TRAINING_TOPIC.SUCCEEDED,
  payload: payload
});

export const copyTrainingTopicFailed = error => ({
  type: COPY_TRAINING_TOPIC.FAILED,
  payload: error
});

// SubTopics

export const addTrainingSubTopic = subtopic => ({
  type: ADD_TRAINING_SUBTOPIC.REQUEST,
  payload: subtopic
});

export const addTrainingSubTopicSucceeded = subtopic => ({
  type: ADD_TRAINING_SUBTOPIC.SUCCEEDED,
  payload: subtopic
});

export const addTrainingSubTopicFailed = error => ({
  type: ADD_TRAINING_SUBTOPIC.FAILED,
  payload: error
});

export const updateTrainingSubTopic = subtopic => ({
  type: UPDATE_TRAINING_SUBTOPIC.REQUEST,
  payload: subtopic
});

export const updateTrainingSubTopicSucceeded = subtopic => ({
  type: UPDATE_TRAINING_SUBTOPIC.SUCCEEDED,
  payload: subtopic
});

export const updateTrainingSubTopicFailed = error => ({
  type: UPDATE_TRAINING_SUBTOPIC.FAILED,
  payload: error
});

export const deleteTrainingSubTopic = ids => ({
  // ids = {id: subtopic.id, trainingTopicId: subtopic.trainingTopicId} Need training topic id for reducer.
  type: DELETE_TRAINING_SUBTOPIC.REQUEST,
  payload: ids
});

export const deleteTrainingSubTopicSucceeded = payload => ({
  type: DELETE_TRAINING_SUBTOPIC.SUCCEEDED,
  payload: payload
});

export const deleteTrainingSubTopicFailed = error => ({
  type: DELETE_TRAINING_SUBTOPIC.FAILED,
  payload: error
});

// Exercises

export const addTrainingExercise = exercise => ({
  type: ADD_TRAINING_EXERCISE.REQUEST,
  payload: exercise
});

export const addTrainingExerciseSucceeded = (
  exercise,
  trainingTopicId,
  subTopicId
) => ({
  type: ADD_TRAINING_EXERCISE.SUCCEEDED,
  payload: { exercise, trainingTopicId, subTopicId }
});

export const addTrainingExerciseFailed = error => ({
  type: ADD_TRAINING_EXERCISE.FAILED,
  payload: error
});

export const updateTrainingExercise = exercise => ({
  type: UPDATE_TRAINING_EXERCISE.REQUEST,
  payload: exercise
});

export const updateTrainingExerciseSucceeded = (
  exercise,
  trainingTopicId,
  subTopicId
) => ({
  type: UPDATE_TRAINING_EXERCISE.SUCCEEDED,
  payload: { exercise, trainingTopicId, subTopicId }
});

export const updateTrainingExerciseFailed = error => ({
  type: UPDATE_TRAINING_EXERCISE.FAILED,
  payload: error
});

// No notification for exercise update for list reordering in subtopic edit
export const updateTrainingExerciseNoNotification = exercise => ({
  type: UPDATE_TRAINING_EXERCISE_NONOTIFICATION.REQUEST,
  payload: exercise
});

export const updateTrainingExerciseNoNotificationSucceeded = (
  exercise,
  trainingTopicId,
  subTopicId
) => ({
  type: UPDATE_TRAINING_EXERCISE_NONOTIFICATION.SUCCEEDED,
  payload: { exercise, trainingTopicId, subTopicId }
});

export const updateTrainingExerciseNoNotificationFailed = error => ({
  type: UPDATE_TRAINING_EXERCISE_NONOTIFICATION.FAILED,
  payload: error
});

export const deleteTrainingExercise = ids => ({
  // ids = {id: exercise.id, subtopiId: possiblesubtopic.id, trainingTopicId: subtopic.trainingTopicId} Need (sub)topic ids for reducer.
  type: DELETE_TRAINING_EXERCISE.REQUEST,
  payload: ids
});

export const deleteTrainingExerciseSucceeded = payload => ({
  type: DELETE_TRAINING_EXERCISE.SUCCEEDED,
  payload: payload
});

export const deleteTrainingExerciseFailed = error => ({
  type: DELETE_TRAINING_EXERCISE.FAILED,
  payload: error
});
