import { call, put, takeLatest, select } from "redux-saga/effects";

import Api from "../helpers/Api";
import { getAuthToken } from "./selectors";
import { FETCH_ORGANIZATIONS } from "../actions/types";
import {
  fetchOrganizationsFailed,
  fetchOrganizationsSucceeded
} from "../actions";

function* fetchOrganizations() {
  try {
    const auth_token = yield select(getAuthToken);
    const organizations = yield call(Api({ auth_token }).fetchOrganizations);
    yield put(fetchOrganizationsSucceeded(organizations.data));
  } catch (e) {
    yield put(fetchOrganizationsFailed(e.message));
  }
}

function* organizationSaga() {
  yield takeLatest(FETCH_ORGANIZATIONS.REQUEST, fetchOrganizations);
}

export default organizationSaga;
