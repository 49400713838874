import { call, put, takeEvery, select } from "redux-saga/effects";

import Api from "../helpers/Api";
import { getAuthToken } from "./selectors";
import { FETCH_SIZE_CLASSES } from "../actions/types";
import {
  fetchSizeClassesFailed,
  fetchSizeClassesSucceeded
} from "../actions";

function* fetchSizeClasses(payload) {
  try {
    const auth_token = yield select(getAuthToken);
    const sizeClasses = yield call(Api({ auth_token }).fetchSizeClasses, payload.payload.organizationId);
    yield put(fetchSizeClassesSucceeded(sizeClasses.data));
  } catch (e) {
    yield put(fetchSizeClassesFailed(e.message));
  }
}

function* sizeClassSaga() {
  yield takeEvery(FETCH_SIZE_CLASSES.REQUEST, fetchSizeClasses);
}

export default sizeClassSaga;
