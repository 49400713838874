export function isVisible(e) {
  return !!(e.offsetWidth || e.offsetHeight || e.getClientRects().length);
}

export const isIos = () => {
  const userAgent = window.navigator.userAgent.toLowerCase();
  return /iphone|ipad|ipod/.test(userAgent);
};

export const isInStandaloneMode = () =>
  ("standalone" in window.navigator) && window.navigator.standalone;


export function countTopicStats(topics) {
  // Counting statistics from props as state does not update properly. TODO: Check updating.
  let exerciseTotal = 0;
  let exerciseDone = 0;
  let subTopicsTotal = 0;
  let subTopicsDone = 0;
  let archivedExerciseTotal = 0;
  let archivedExerciseDone = 0;
  let archivedSubTopicsTotal = 0;
  let archivedSubTopicsDone = 0;
  for (let topic of topics) {
    if (!topic.done) {
      if (topic.trainingSubTopics) {
        subTopicsTotal += topic.trainingSubTopics.length;
        for (let subtopic of topic.trainingSubTopics) {
          if (subtopic.exercises) {
            exerciseTotal += subtopic.exercises.length;
            for (let subexercise of subtopic.exercises) {
              if (subexercise.done) {
                exerciseDone += 1;
              }
            }
          }
          if (subtopic.done) {
            subTopicsDone += 1;
          }
        }
      }
      if (topic.exercises) {
        exerciseTotal += topic.exercises.length;
        for (let exercise of topic.exercises) {
          if (exercise.done) {
            exerciseDone += 1;
          }
        }
      }
    } else {
      if (topic.trainingSubTopics) {
        archivedSubTopicsTotal += topic.trainingSubTopics.length;
        for (let subtopic of topic.trainingSubTopics) {
          if (subtopic.exercises) {
            archivedExerciseTotal += subtopic.exercises.length;
            for (let subexercise of subtopic.exercises) {
              if (subexercise.done) {
                archivedExerciseDone += 1;
              }
            }
          }
          if (subtopic.done) {
            archivedSubTopicsDone += 1;
          }
        }
      }
      if (topic.exercises) {
        archivedExerciseTotal += topic.exercises.length;
        for (let exercise of topic.exercises) {
          if (exercise.done) {
            archivedExerciseDone += 1;
          }
        }
      }
    }
  }
  return {
    exerciseTotal,
    exerciseDone,
    subTopicsTotal,
    subTopicsDone,
    archivedExerciseTotal,
    archivedExerciseDone,
    archivedSubTopicsTotal,
    archivedSubTopicsDone,
  };
}