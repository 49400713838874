import React from "react";
import ReactDOM from "react-dom";

import * as serviceWorker from "./config/serviceWorker";

import "./config/i18n";
import App from "./App";
import "./index.css";


serviceWorker.register();

const appElement = document.getElementById("root");

if (appElement) {
  ReactDOM.render(<App />, appElement);
}
