import { call, put, takeLatest, select } from "redux-saga/effects";

import Api from "../helpers/Api";
import { getAuthToken } from "./selectors";
import { FETCH_COURSE_TYPES } from "../actions/types";
import {
  fetchCourseTypesFailed,
  fetchCourseTypesSucceeded
} from "../actions";

function* fetchCourseTypes(payload) {
  try {
    const auth_token = yield select(getAuthToken);
    const courseTypes = yield call(Api({ auth_token }).fetchCourseTypes, payload.payload.organizationId);
    yield put(fetchCourseTypesSucceeded(courseTypes.data));
  } catch (e) {
    yield put(fetchCourseTypesFailed(e.message));
  }
}

function* courseTypeSaga() {
  yield takeLatest(FETCH_COURSE_TYPES.REQUEST, fetchCourseTypes);
}

export default courseTypeSaga;
