import React from "react";
import PropTypes from "prop-types";
import { castToNestedFocusArray } from "../../helpers/Transformer";

export function TrainingFocusesExpanded ({trainingFocuses, handleFocusToggle, t}) {
  return (
    <div className="my-3">
      {castToNestedFocusArray(trainingFocuses).map((focus) => (
        <div className="alert alert-info py-2 px-3 mb-2" key={focus.key}>
          {t(`misc.${focus.name}`)}
          {focus.children && focus.children.length > 0 && (
            <span>
                  : {focus.children.map((f) => t(`misc.${f.name}`)).join(", ")}
            </span>
          )}
          <button
            type="button"
            className="close"
            data-dismiss="alert"
            aria-label="Close"
          >
            <span
              aria-hidden="true"
              onClick={() =>
                handleFocusToggle(
                  focus.children ? focus.children : focus
                )
              }
            >
                  &times;
            </span>
          </button>
        </div>
      ))}
    </div>
  );
}

TrainingFocusesExpanded.propTypes = {
  trainingFocuses: PropTypes.array.isRequired,
  handleFocusToggle: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired
};