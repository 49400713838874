import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import moment from "moment";
import confirm from "../../components/Confirm";
import { SubSortableList } from "./SubSortableList";
import { ExerciseSortableList } from "./ExerciseSortableList";
import { TrainingFocusesSmall } from "./TrainingFocusesSmall";
import { TrainingFocusesExpanded } from "./TrainingFocusesExpanded";
import { arrayMove } from "react-sortable-hoc";
import { Redirect } from "react-router-dom";

import {
  AppContent,
  Input,
  FormGroup,
  Icon,
  RoundButton,
  Card,
  TextArea,
} from "../../common";
import SelectFocusList from "../../components/SelectFocusList";
import { castToNestedFocusArray } from "../../helpers/Transformer";
import PaywallNotification from "../../containers/App/PaywallNotification";
import {
  updateTrainingTopic,
  deleteTrainingTopic,
  copyTrainingTopic,
  shareTopic,
  unShareTopic,
} from "../../actions";

const VIEWS = {
  FORM: "form",
  FOCUSES: "focuses",
};

class TrainingTopicEdit extends Component {
  state = {
    submitted: false,
    edited: false,
    errors: {},
    editName: false,
    editNotes: false,
    showDogList: false,
    focusesExpanded: false,
    exercisesExpanded: true,
    subtopicsExpanded: true,
    showExercises: false,
    showSubtopics: false,
    showShared: false,
    view: VIEWS.FORM,
    form: {
      name: "",
      notes: "",
      trainingFocuses: [],
      trainingSubTopics: [],
      exercises: [],
      newSubTopic: "",
      share: "",
    },
  };

  componentDidMount() {
    // const { id } = this.props.match.params;
    const hasExercises = this.props.topic.exercises.length > 0;
    const hasSubtopics = this.props.topic.trainingSubTopics.length > 0;
    const noNotes = !this.props.topic.notes;
    const noFocuses = !this.props.topic.trainingFocuses?.length ? true : false;

    this.setState({
      form: { ...this.props.topic, newSubTopic: "" },
      showExercises: hasExercises,
      showSubtopics: hasSubtopics,
      editNotes: noNotes,
      focusesExpanded: noFocuses,
      showShared: this.props.topic.share ? true : false,
    });
  }

  // getDerivedStateFromProps is invoked right before calling the render method, both on the
  // initial mount and on subsequent updates. It should return an object to update the state,
  // or null to update nothing.
  // Have to get topic again once a save has been done. So submitted, not edited and not saving anymore.
  // Then update submitted to false so does not continue updating state.
  static getDerivedStateFromProps(props, state) {
    if (state.submitted && !state.edited && !props.saving) {
      return {
        form: { ...props.topic, newSubTopic: "" },
        submitted: false,
      };
    }
    if (state.showShared && !state.form.share && props.newShare.id === props.topic.id) {
      return {
        form: { ...state.form, share: props.newShare.share}
      };
    }
    return null;
  }

  getPremium = (e) => {
    const { t } = this.props;
    e.stopPropagation();
    e.preventDefault();
    confirm(t("subscription.noPlanYet"), {
      title: t("paywall.getPremium"),
      ok: t("common.ok"),
      cancel: t("common.cancel"),
    }).then(
      () => {
        this.props.history.push("/subscription");
      },
      () => {}
    );
  };

  toggleEditName = () => {
    this.setState({ editName: !this.state.editName });
  };

  toggleEditNotes = () => {
    this.setState({ editNotes: !this.state.editNotes });
  };

  handleInputChange = (e) => {
    const { name, value } = e.target;
    // Subtopic field editing is caught when "Add" is pressed.
    const nameOrNotes = name !== "newSubTopic";
    this.setState((prevState, props) => ({
      ...prevState,
      form: {
        ...prevState.form,
        [name]: value,
      },
      edited: nameOrNotes,
    }));
  };

  onExpand = (section) => {
    this.setState({ [section]: !this.state[section] });
  };

  onExpandExercise = (id) => {
    let exerciseIndex = this.state.form.exercises.findIndex(
      (exercise) => exercise.id === id
    );
    let newExerciseList = [...this.state.form.exercises];
    newExerciseList[exerciseIndex].expanded = !newExerciseList[exerciseIndex]
      .expanded;
    this.setState({ ...this.state.form, exercises: newExerciseList });
  };

  openExercises = () => {
    this.setState({ showExercises: true });
  };

  openSubtopics = () => {
    this.setState({ showSubtopics: true });
  };

  /* *********************
   * SubTopicList editing *
   ********************** */
  handleNewSubTopic = (e) => {
    e.preventDefault();
    // Random int for initial id in state before correct one from database.
    function getRandomInt(min, max) {
      min = Math.ceil(min);
      max = Math.floor(max);
      return Math.floor(Math.random() * (max - min)) + min;
    }
    if (this.state.form.newSubTopic) {
      let topicName = this.state.form.newSubTopic;

      let subTopic = {
        id: getRandomInt(1000, 10000),
        done: false,
        name: topicName,
        orderNumber: this.state.form.trainingSubTopics.length + 1,
        notes: "",
        notSaved: true, // if not saved then can't edit further...
      };
      let newSubTopicList = [...this.state.form.trainingSubTopics];
      newSubTopicList.push(subTopic);
      this.setState((prevState, props) => ({
        ...prevState,
        form: {
          ...prevState.form,
          trainingSubTopics: newSubTopicList,
          newSubTopic: "",
        },
        edited: true,
      }));
    } else return; // Empty subtopic name
    // Updates in database on Save.
  };

  handleDeleteSubTopic = (id) => {
    // Deletes from state - can only delete new added subtopics.
    let topicIndex = this.state.form.trainingSubTopics.findIndex(
      (topic) => topic.id === id
    );
    let newSubTopicList = [...this.state.form.trainingSubTopics];
    newSubTopicList.splice(topicIndex, 1);
    this.setState({
      form: {
        ...this.state.form,
        trainingSubTopics: newSubTopicList,
      },
      edited: true,
    });
    // Updates in database on Save.
  };

  // Change in check of subtopic.
  handleCheckChange = (e) => {
    const id = parseInt(e.target.value, 10);
    const done = e.target.checked;
    // Updates in state.
    let topicIndex = this.state.form.trainingSubTopics.findIndex(
      (topic) => topic.id === id
    );
    let newSubTopicList = [...this.state.form.trainingSubTopics];
    newSubTopicList[topicIndex].done = done;
    this.setState({
      form: {
        ...this.state.form,
        trainingSubTopics: newSubTopicList,
      },
      edited: true,
    });
    // Updates in database on Save.
  };

  handleSubTopicEdit = (id, e) => {
    let topicId = this.props.topic.id;
    // this.handleSave(e);
    if (!this.props.saving)
      this.props.history.push(`/training-topic/${topicId}/${id}`);
  };

  onSortEnd = ({ oldIndex, newIndex }) => {
    let newSubTopicList = arrayMove(
      [...this.state.form.trainingSubTopics],
      oldIndex,
      newIndex
    );
    // Puts in order numbers.
    newSubTopicList = newSubTopicList.map((item, index) => {
      item.orderNumber = index + 1;
      return item;
    });
    // Updates in state.
    this.setState({
      form: {
        ...this.state.form,
        trainingSubTopics: newSubTopicList,
      },
      edited: true,
    });
    // Updates in database on Save.
  };

  /* *********************
   * Exercise editing    *
   ********************** */
  handleAddExercise = (e) => {
    let topicId = this.props.topic.id;
    this.handleSave(e);
    this.props.history.push(`/training-topic/${topicId}/add`);
  };

  handleExerciseCheckChange = (e) => {
    e.stopPropagation();
    const id = parseInt(e.target.value, 10);
    const done = e.target.checked;

    // Updates in state.
    let exerciseIndex = this.state.form.exercises.findIndex(
      (exercise) => exercise.id === id
    );
    let newExerciseList = [...this.state.form.exercises];
    newExerciseList[exerciseIndex].done = done;

    this.setState({
      form: {
        ...this.state.form,
        exercises: newExerciseList,
      },
      edited: true,
    });
    // Updates in database on Save.
  };

  handleExerciseEdit = (id, e) => {
    e.stopPropagation();
    let topicId = this.props.topic.id;
    if (!this.props.saving)
      this.props.history.push(`/training-topic/${topicId}/edit/${id}`);
  };

  handleExerciseView = (id, e) => {
    e.stopPropagation();
    let topicId = this.props.topic.id;
    if (!this.props.saving)
      this.props.history.push(`/training-topic/${topicId}/exercise/${id}`);
  };

  handleTrainingView = (id, e) => {
    e.stopPropagation();
    this.props.history.push(`/training-diary/${id}`);
  };

  handleMoveToTrainingDiary = (id, e) => {
    e.stopPropagation();
    if (!this.props.saving)
      this.props.history.push(`/training-diary/add/${id}`);
  };

  onExerciseSortEnd = ({ oldIndex, newIndex }) => {
    // Updates in state.
    let newExerciseList = arrayMove(
      [...this.state.form.exercises],
      oldIndex,
      newIndex
    );
    // Actually give them new orderNumbers
    newExerciseList = newExerciseList.map((item, index) => {
      item.orderNumber = index + 1;
      return item;
    });

    this.setState({
      form: {
        ...this.state.form,
        exercises: newExerciseList,
      },
      edited: true,
    });
    // Updates in database on Save? Needs backend support?
  };

  /* *************************************
   * Save, delete and copy of whole topic *
   ************************************** */
  handleSave = (e) => {
    e.preventDefault();
    if (this.state.edited) {
      this.props.updateTrainingTopic({ ...this.state.form });
      this.setState({
        edited: false,
        submitted: true,
        editNotes: false,
        editName: false,
      });
    } else {
      this.setState({
        editNotes: false,
        editName: false,
      });
    }
  };

  handleDelete = (e) => {
    e.preventDefault();
    const { t } = this.props;

    confirm(t("dog.deleteDogConfirmTitle"), {
      title: t("common.delete") + " " + t("topics.trainingTopic") + "?",
      ok: t("common.delete"),
      cancel: t("common.cancel"),
    }).then(
      () => {
        this.props.deleteTrainingTopic(this.props.topic.id);
      },
      () => {}
    );
  };

  /* *************************************
   * Copying topic to other dog           *
   ************************************** */

  openDogList = (e) => {
    e.preventDefault();
    this.setState({ showDogList: !this.state.showDogList });
  };

  share = (e) => {
    e.preventDefault();
    this.props.shareTopic(this.props.topic.id, this.props.activeDog);
    this.setState({ showShared: true });
  };
  unshare = (e) => {
    e.preventDefault();
    this.props.unShareTopic(this.props.topic.id, this.props.activeDog);
    this.setState({ showShared: false });
    this.setState({form: {...this.state.form, share: ""} });
  };
  copyShareLink = (e) => {
    e.preventDefault();
    const shareLinkText = this.state.form.share ? 
      window.location.origin + "/shared/training-topic/" + this.state.form.share.guid 
      : "";
    navigator.clipboard.writeText(shareLinkText);
  }

  handleCopy = (e, id) => {
    e.preventDefault();
    let chosenOtherDog = id;
    this.props.copyTrainingTopic({
      topicId: this.props.topic.id,
      dogIdToCopy: chosenOtherDog,
    });
    this.openDogList(e);
  };

  renderDogList() {
    let dogs = this.props.dogs;
    let activeDog = this.props.activeDog;
    let show = this.state.showDogList;
    let dogRender = dogs.map((dog) => {
      if (dog.id !== activeDog.id) {
        return (
          <li
            key={dog.id}
            onClick={(e) => this.handleCopy(e, dog.id)}
            className="settings-menu__link"
          >
            {dog.name}
          </li>
        );
      }
      return null;
    });
    return (
      <React.Fragment>
        {show ? (
          <ul className="list w-50 mx-auto mt-1">{dogRender}</ul>
        ) : (
          <div />
        )}
      </React.Fragment>
    );
  }

  /* *******************************
   * Rendering                     *
   ******************************* */

  render() {
    if (!this.props.topic) return <Redirect to="/training-topics" />;

    return (
      <AppContent size="medium">
        {this.state.view === VIEWS.FORM
          ? this.renderForm()
          : this.renderFocusList()}
      </AppContent>
    );
  }

  renderForm() {
    const {
      editName,
      edited,
      showExercises,
      showSubtopics,
      showShared,
    } = this.state;
    const { name, share } = this.state.form;

    const { t, hasActivePlan, user } = this.props;
    let isAdmin = false;
    if (user) {
      isAdmin = user.admin;
    }

    let shareLink = share
      ? window.location.origin + "/shared/training-topic/" + share.guid
      : "";

    return (
      <React.Fragment>
        <button
          className="trial-back-button"
          onClick={() => this.props.history.push("/training-topics/")}
        >
          <strong>&larr;</strong> {t("common.back")}
        </button>
        <p className="py-2" />
        {editName ? (
          <React.Fragment>
            <FormGroup className="justify-content-center align-items-end mx-2">
              <Input
                type="text"
                name="name"
                label="none"
                placeholder={t("topics.addNew-placeholder")}
                onChange={this.handleInputChange}
                value={name}
              />
              {/* <button className="btn btn-primary" onClick={this.toggleEditName}>
                {t("common.ok")}
              </button> */}
            </FormGroup>
          </React.Fragment>
        ) : (
          <h2 className="text-center mx-3">
            {name}
            <button
              className="todo-list__delete btn--link text-danger"
              onClick={this.toggleEditName}
            >
              <Icon icon="editGrey" />
            </button>
          </h2>
        )}

        {this.renderTrainingFocuses()}
        {this.renderNotes()}

        {!showExercises && !showSubtopics && (
          <Card>
            {hasActivePlan ? (
              <div className="d-flex justify-content-center">
                <button
                  className="btn btn-primary mr-2"
                  onClick={this.openExercises}
                >
                  {t("topics.addExercises")}
                </button>
                <button
                  className="btn btn-primary"
                  onClick={this.openSubtopics}
                >
                  {t("topics.addSubtopics")}
                </button>
              </div>
            ) : (
              <div className="d-flex justify-content-center">
                <button
                  className="btn btn-outline-secondary mr-2"
                  onClick={(e) => this.getPremium(e)}
                >
                  {t("topics.addExercises")}
                </button>
                <button
                  className="btn btn-outline-secondary"
                  onClick={(e) => this.getPremium(e)}
                >
                  {t("topics.addSubtopics")}
                </button>
              </div>
            )}
          </Card>
        )}

        {showExercises ? this.renderExercises() : <div />}

        {showSubtopics ? this.renderSubtopics() : <div />}

        <Card>
          {this.props.dogs.length > 1 && (
            <React.Fragment>
              <div className="d-flex justify-content-center">
                {edited ? (
                  <button
                    className="btn btn-outline-secondary"
                    onClick={(e) => e.preventDefault()}
                  >
                    {t("topics.saveBefore")}
                  </button>
                ) : (
                  <button
                    className="btn btn-outline-primary"
                    onClick={this.openDogList}
                  >
                    {t("topics.copyOtherDog")}
                  </button>
                )}
              </div>
              {this.renderDogList()}
            </React.Fragment>
          )}
          <div className="d-flex justify-content-center mt-3">
            {hasActivePlan && !showShared && 
            <>{edited ? 
              (
                <button
                  className="btn btn-outline-secondary"
                  onClick={(e) => e.preventDefault()}
                >
                  {t("common.saveBeforeShare")}
                </button>
              ) : (
                <button
                  className="btn btn-outline-primary"
                  onClick={this.share}
                >
                  {t("common.share-friend")}
                </button>
              )}</>}
            {hasActivePlan && showShared && (
              <FormGroup className="input-and-button-group">
                <Input type="text" value={shareLink} readOnly />
                <button
                  className="btn btn-outline-primary mr-2"
                  onClick={this.copyShareLink}
                >
                  {t("common.shareInstruction")}
                </button>
                <button
                  className="btn btn-outline-primary"
                  onClick={this.unshare}
                >
                  {t("common.unshare")}
                </button>
              </FormGroup>
            )}
          </div>
          <p />
          <button className="btn btn-link" onClick={this.handleDelete}>
            {t("common.delete")}
          </button>
        </Card>

        {!hasActivePlan && (
          <PaywallNotification
            text="subscription.noPlanYet"
            button="paywall.getPremium"
          />
        )}

        <RoundButton
          icon={"complete"}
          size="large"
          atCorner
          onClick={this.handleSave}
        />
      </React.Fragment>
    );
  }

  renderTrainingFocuses() {
    const { t } = this.props;
    const { trainingFocuses } = this.state.form;

    return (
      <Card>
        <h2 onClick={() => this.onExpand("focusesExpanded")}>
          {t("training.focuses")}
        </h2>
        {this.state.focusesExpanded ? (
          <FormGroup>
            <TrainingFocusesExpanded trainingFocuses={trainingFocuses} handleFocusToggle={this.handleFocusToggle} t={t} />
            <button
              className="btn btn-secondary btn-block mb-3"
              onClick={(e) => {
                e.preventDefault();
                this.setState({ view: VIEWS.FOCUSES });
              }}
            >
              {t("training.focusesButton")}
            </button>
            <p>{t("training.focusesNote")}.</p>
          </FormGroup>
        ) : (
          <TrainingFocusesSmall trainingFocuses={trainingFocuses} t={t} />
        )}
        <img
          src={require("../../images/icons/chevron-down.svg")}
          className={`${
            this.state.focusesExpanded
              ? "icon center-icon rotated-icon "
              : "icon center-icon mt-2"
          }`}
          alt={"expand-contract"}
          onClick={() => this.onExpand("focusesExpanded")}
        />
      </Card>
    );
  }

  renderNotes() {
    const { t } = this.props;
    const { editNotes } = this.state;
    const { notes } = this.state.form;

    return (
      <Card>
        <h2>
          {t("training.notes")}
          <button
            className="todo-list__delete btn--link text-danger"
            onClick={this.toggleEditNotes}
          >
            <Icon icon="editGrey" />
          </button>
        </h2>
        {editNotes ? (
          <React.Fragment>
            <FormGroup>
              <TextArea
                type="text"
                name="notes"
                label="none"
                placeholder={""}
                onChange={this.handleInputChange}
                value={notes}
              />
              {/* <button className="btn btn-primary" onClick={this.toggleEditNotes}>
                {t("common.ok")}
              </button> */}
            </FormGroup>
          </React.Fragment>
        ) : (
          <p className="multiLine">{notes}</p>
        )}
      </Card>
    );
  }

  renderSubtopics() {
    const { trainingSubTopics, newSubTopic } = this.state.form;
    const { t, hasActivePlan } = this.props;

    let sortedTrainingSubTopics = trainingSubTopics.sort(
      (a, b) => a.orderNumber - b.orderNumber
    );

    return (
      <Card>
        <h2 onClick={(e) => this.onExpand("subtopicsExpanded")}>
          {t("topics.subtopicsTitle")}
        </h2>
        {this.state.subtopicsExpanded ? (
          <React.Fragment>
            <form onSubmit={this.handleNewSubTopic}>
              <FormGroup className="input-and-button-group">
                <Input
                  type="text"
                  label={t("topics.subtopicInstruction")}
                  name="newSubTopic"
                  placeholder={t("topics.subtopicPlaceholder")}
                  onChange={this.handleInputChange}
                  value={newSubTopic}
                />
                {hasActivePlan ? (
                  <button className="btn btn-primary">
                    {t("topics.addNewButton")}
                  </button>
                ) : (
                  <button
                    className="btn btn-outline-secondary"
                    onClick={(e) => this.getPremium(e)}
                  >
                    {t("topics.addNewButton")}
                  </button>
                )}
              </FormGroup>
            </form>
            <SubSortableList
              items={sortedTrainingSubTopics}
              onItemDelete={this.handleDeleteSubTopic}
              onCheck={this.handleCheckChange}
              onSortEnd={this.onSortEnd}
              onItemEdit={this.handleSubTopicEdit}
              useDragHandle={true}
              t={t}
            />
          </React.Fragment>
        ) : (
          <span className="todo-list__focus">
            {trainingSubTopics.map((subtopic, i, arr) => (
              <span key={subtopic.id}>
                {subtopic.name}
                {arr.length - 1 === i ? <span /> : <span>, </span>}
              </span>
            ))}
          </span>
        )}

        <img
          src={require("../../images/icons/chevron-down.svg")}
          className={`${
            this.state.subtopicsExpanded
              ? "icon center-icon rotated-icon "
              : "icon center-icon"
          }`}
          alt={"expand-contract"}
          onClick={(e) => this.onExpand("subtopicsExpanded")}
        />
      </Card>
    );
  }

  renderExercises() {
    const { t, hasActivePlan } = this.props;
    const { exercises } = this.state.form;

    let sortedExercises = exercises.sort(
      (a, b) => a.orderNumber - b.orderNumber
    );

    return (
      <Card>
        <h2 onClick={(e) => this.onExpand("exercisesExpanded")}>
          {t("topics.exercisesTitle")}
        </h2>
        {this.state.exercisesExpanded ? (
          <React.Fragment>
            <ExerciseSortableList
              items={sortedExercises}
              onItemDelete={this.handleDeleteExercise}
              onMoveToTraining={this.handleMoveToTrainingDiary}
              onSortEnd={this.onExerciseSortEnd}
              onItemEdit={this.handleExerciseEdit}
              onItemView={this.handleExerciseView}
              onExpand={this.onExpandExercise}
              onViewTraining={this.handleTrainingView}
              onCheck={this.handleExerciseCheckChange}
              useDragHandle={true}
              t={t}
              hasActivePlan={hasActivePlan}
            />
            <p />
            {hasActivePlan ? (
              <button
                className="btn btn-primary"
                onClick={(e) => this.handleAddExercise(e)}
              >
                {t("topics.addNewButton") + "..."}
              </button>
            ) : (
              <button
                className="btn btn-outline-secondary"
                onClick={(e) => this.getPremium(e)}
              >
                {t("topics.addNewButton") + "..."}
              </button>
            )}
          </React.Fragment>
        ) : (
          <span className="todo-list__focus">
            <span>
              {exercises.length} {t("topics.exercisesList")}:{" "}
            </span>
            {exercises.map((exercise, i, arr) => (
              <span key={exercise.id}>
                {exercise.name}
                {arr.length - 1 === i ? <span /> : <span>, </span>}
              </span>
            ))}
          </span>
        )}

        <img
          src={require("../../images/icons/chevron-down.svg")}
          className={`${
            this.state.exercisesExpanded
              ? "icon center-icon rotated-icon "
              : "icon center-icon"
          }`}
          alt={"expand-contract"}
          onClick={(e) => this.onExpand("exercisesExpanded")}
        />
      </Card>
    );
  }

  renderFocusList() {
    const { t, focusCategories } = this.props;

    return (
      <React.Fragment>
        <SelectFocusList
          list={castToNestedFocusArray(focusCategories)}
          renderName={(name) => t(`misc.${name}`)}
          selectedItems={this.state.form.trainingFocuses}
          onChange={this.handleFocusToggle}
        />
        <RoundButton
          icon={"complete"}
          size="large"
          atCorner
          onClick={() => this.setState({ view: VIEWS.FORM })}
        />
      </React.Fragment>
    );
  }

  handleFocusToggle = (item) => {
    const { trainingFocuses } = this.state.form;
    const items = Array.isArray(item) ? item : [item];
    let newFocuses = [...trainingFocuses];
    items.forEach((item) => {
      const isSelected = trainingFocuses.some((focus) => focus.key === item.key );

      if (isSelected) {
        newFocuses = newFocuses.filter((i) => i.key !== item.key);
      } else {
        newFocuses = [...newFocuses, item];
      }
    });

    this.setState((prevState) => ({
      form: { ...prevState.form, trainingFocuses: newFocuses },
      edited: true,
    }));
  };
}

TrainingTopicEdit.propTypes = {
  t: PropTypes.func,
  history: PropTypes.object,
  topics: PropTypes.object,
  topic: PropTypes.object,
  dogs: PropTypes.array,
  activeDog: PropTypes.object,
  hasActivePlan: PropTypes.bool,
  focusCategories: PropTypes.array,
  updateTrainingTopic: PropTypes.func,
  deleteTrainingTopic: PropTypes.func,
  copyTrainingTopic: PropTypes.func,
  shareTopic: PropTypes.func,
  unShareTopic: PropTypes.func,
  newShare: PropTypes.object,
  user: PropTypes.object,
  saving: PropTypes.bool,
};

const mapStateToProps = (
  { topics, dogs, activeDog, settings, subscriptions, user },
  props
) => {
  const { id } = props.match.params;
  let topic = topics.topics.find((t) => t.id === Number(id));
  // Empty topic also on delete redirects to training-topics

  return {
    topic,
    newShare: topics.sharedTopic,
    dogs: dogs.dogs,
    activeDog,
    focusCategories: settings.trainingFocusCategories,
    saving: topics.saving,
    deleting: topics.deleting,
    hasActivePlan:
      subscriptions.currentPlan &&
      moment(subscriptions.currentPlan.paid_until) > moment(),
    user: user.user,
  };
};

const TrainingTopicEditWithTranslate = withTranslation()(TrainingTopicEdit);

export default connect(mapStateToProps, {
  updateTrainingTopic,
  deleteTrainingTopic,
  copyTrainingTopic,
  shareTopic,
  unShareTopic,
})(TrainingTopicEditWithTranslate);
