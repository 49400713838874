import React from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";

const AuthRoute = ({
  component: Component,
  isAuthenticated,
  dogs,
  user,
  hasNoDogs,
  shouldAcceptTerms,
  dogsfetched,
  ...rest
}) => {
  if (shouldAcceptTerms && rest.location.pathname !== "/accept-terms")
    return <Redirect to="/accept-terms" />;
  if (hasNoDogs && dogsfetched && user.user.id && user.user.termsAgreed)
    return <Redirect to="/signup/add-dog" />;

  return (
    <Route
      {...rest}
      render={(props) => {
        if (isAuthenticated) {
          return <Component {...props} />;
        } else {
          return (
            <Redirect
              to={{
                pathname: "/auth",
                state: { from: props.location },
              }}
            />
          );
        }
      }}
    />
  );
};

const mapStateToProps = ({ auth: { isAuthenticated }, dogs, user }) => ({
  isAuthenticated,
  dogs,
  user,
  dogsfetched: !dogs.fetching,
  hasNoDogs: dogs.dogs.length === 0,
  shouldAcceptTerms: !user.user.termsAgreed && user.user.id,
});

export default connect(mapStateToProps)(AuthRoute);
