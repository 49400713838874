import { call, put, takeEvery, select } from "redux-saga/effects";

import Api from "../helpers/Api";
import { getAuthToken, getActiveDog } from "./selectors";
import { FETCH_COACHES_FILTER } from "../actions/types";
import {
  fetchCoachesFilterFailed,
  fetchCoachesFilterSucceeded,
} from "../actions";

function* fetchCoachesFilter() {
  try {
    const auth_token = yield select(getAuthToken);
    const dog = yield select(getActiveDog);
    const coaches = yield call(Api({ auth_token }).fetchCoachesFilter, {
      dog,
    });
    yield put(fetchCoachesFilterSucceeded(coaches.data));
  } catch (e) {
    yield put(fetchCoachesFilterFailed(e.message));
  }
}

function* trainingFilterSaga() {
  yield takeEvery(FETCH_COACHES_FILTER.REQUEST, fetchCoachesFilter);
}

export default trainingFilterSaga;
