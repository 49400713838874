import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import moment from "moment";

import {
  addDog,
  updateDog,
  deleteDog,
  updateDogTrialSizeClass,
  fetchOrganizations,
  fetchSizeClasses,
  clearSizeClasses,
} from "../../actions";
import { AppContent, RoundButton } from "../../common";
import confirm from "../../components/Confirm";
import DogForm from "./DogForm";
import INTIAL_DOG_STATE from "./initial-dog-state";

class DogEdit extends Component {
  state = {
    dog: { ...INTIAL_DOG_STATE },
    deleteConfirmed: false,
    redirect: false,
    redirectTo: "/dogs/",
    edit: false,
  };

  componentDidMount() {
    const { dogs, match, onboarded, fetchOrganizations } = this.props;

    this.clearSizeClasses();
    if (match.params.id) {
      const id = parseInt(match.params.id, 10);
      const dog = dogs.find((dog) => dog.id === id);
      dog.dayOfBirth = dog.dayOfBirth
        ? moment(dog.dayOfBirth).format("YYYY-MM-DD")
        : "";
      this.setState({ dog: dog, edit: true });
      dog.organizations.forEach((org) => {
        this.fetchSizeClasses(org.id);
      });
    } else {
      this.fetchSizeClasses(this.state.dog.organizations[0].id);
    }
    fetchOrganizations();

    const redirectTo = onboarded ? "/" : "/dogs/";
    this.setState({ redirectTo });
  }

  fetchSizeClasses = (orgId) => {
    const { fetchSizeClasses } = this.props;
    fetchSizeClasses(orgId);
  };

  clearSizeClasses = (orgId) => {
    const { clearSizeClasses } = this.props;
    clearSizeClasses(orgId);
  };

  updateDogTrialSizeClass = (dog) => {
    const { updateDogTrialSizeClass } = this.props;
    updateDogTrialSizeClass(dog);
  };

  submit = () => {
    const { dog } = this.state;
    const { addDog, updateDog } = this.props;

    if (dog.id) {
      updateDog({ ...dog });
      this.setState({ redirect: true });
    } else {
      addDog({ ...dog });
      this.setState({ redirect: true });
    }
  };

  handleDogEdit = (dog) => {
    this.setState({ dog });
  };

  deleteDog = () => {
    const { dog } = this.state;
    const { deleteDog, t } = this.props;

    confirm(t("dog.deleteDogCofirm"), {
      title: t("dog.deleteDogConfirmTitle"),
      ok: t("dog.deleteDog"),
      cancel: t("common.cancel"),
    }).then(
      () => {
        this.setState({ deleteConfirmed: true });
        deleteDog(dog.id);
      },
      () => {}
    );
  };

  componentDidUpdate(prevProps, prevState) {
    const { redirect, redirectTo, deleteConfirmed } = this.state;
    const { saving, deleting, history } = this.props;

    const justSaved =
      redirect !== prevState.redirect || saving !== prevProps.saving;
    const justDeleted =
      deleteConfirmed !== prevState.deleteConfirmed ||
      deleting !== prevProps.deleting;

    if (redirect && !saving && justSaved) history.push(redirectTo);
    if (deleteConfirmed && !deleting && justDeleted) history.push(redirectTo);
  }

  render() {
    const { dog, edit } = this.state;
    const {
      saving,
      deleting,
      t,
      organizations,
      sizeClasses,
      user,
    } = this.props;

    return (
      <AppContent size="small">
        <DogForm
          dog={dog}
          onChange={this.handleDogEdit}
          organizations={organizations}
          sizeClasses={sizeClasses}
          fetchSizeClasses={this.fetchSizeClasses}
          clearSizeClasses={this.clearSizeClasses}
          updateDogTrialSizeClass={this.updateDogTrialSizeClass}
          user={user}
          edit={edit}
        />

        <br />
        <br />

        {edit && (
          <button className="btn--link text-danger" onClick={this.deleteDog}>
            {t("dog.deleteDog")}
          </button>
        )}

        <RoundButton
          atCorner
          size="large"
          icon={saving || deleting ? "loader" : "complete"}
          onClick={this.submit}
        />
      </AppContent>
    );
  }
}

const mapStateToProps = ({ auth, dogs, organizations, sizeClasses, user }) => {
  return {
    dogs: dogs.dogs,
    saving: dogs.saving,
    deleting: dogs.deleting,
    onboarded: auth.onboarded,
    organizations: organizations.organizations,
    fetchingOrganizations: organizations.fetchingOrganizations,
    sizeClasses: sizeClasses.sizeClasses,
    fetchingSizeClasses: sizeClasses.fetchingSizeClasses,
    updatingSizeClass: dogs.updatingSizeClass,
    user: user.user,
  };
};

const DogEditWithTranslate = withTranslation()(DogEdit);

export default connect(mapStateToProps, {
  addDog,
  updateDog,
  deleteDog,
  fetchOrganizations,
  fetchSizeClasses,
  clearSizeClasses,
  updateDogTrialSizeClass,
})(DogEditWithTranslate);
