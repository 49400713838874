import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import moment from "moment";

import { AppContent } from "../../common";
import {
  fetchCurrentPlan,
  fetchPlans,
  cancelSubscription,
  reactivateSubscription,
  updateCreditCard,
  subscribeToPlan,
  getPayments,
  setPaymentModalState,
  createCustomer,
  createCustomerPortalSession,
  clearCustomerPortalSession,
} from "../../actions/SubscriptionActions";
import { loadStripe } from "@stripe/stripe-js";
import { Elements, ElementsConsumer } from "@stripe/react-stripe-js";
import { stripeConf } from "../../config/env";
import SubscriptionPlan from "../../components/SubscriptionPlan";
import PaymentModal from "../../components/PaymentModal";
import ActiveSubscription from "../../components/ActiveSubscription";
import { PaymentModalStates } from "../../helpers/Constants";
import ReceiptModal from "../../components/ReceiptModal";

class Subscription extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showModal: false,
      selectedPlan: null,
      showPayments: false,
      receipt: {
        show: false,
        payment: null,
        transaction: null,
      },
    };

    this.onSubscribe = this.onSubscribe.bind(this);
    this.onCloseModal = this.onCloseModal.bind(this);
    this.onPaymentCompleted = this.onPaymentCompleted.bind(this);
    this.onCancelSubscribe = this.onCancelSubscribe.bind(this);
    this.onReactivateSubscription = this.onReactivateSubscription.bind(this);
    this.onCreateCustomerPortalSession = this.onCreateCustomerPortalSession.bind(
      this
    );
    this.togglePayments = this.togglePayments.bind(this);
    this.toggleReceipt = this.toggleReceipt.bind(this);

    this.stripePromise = loadStripe(stripeConf.public_key);
  }

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(fetchCurrentPlan());
    dispatch(fetchPlans());
    dispatch(getPayments());
  }

  componentDidUpdate() {
    const { dispatch, portalUrl } = this.props;
    if (portalUrl) window.location.replace(portalUrl);
    dispatch(clearCustomerPortalSession());
  }

  onSubscribe(plan) {
    const { dispatch, user } = this.props;
    dispatch(createCustomer(user.email, user.firstName, user.lastName));
    dispatch(setPaymentModalState(PaymentModalStates.PAYMENT_INFO));
    this.setState((state) => ({
      showModal: !state.showModal,
      selectedPlan: plan,
    }));
  }

  onCancelSubscribe(plan) {
    const { dispatch } = this.props;
    dispatch(cancelSubscription(plan));
  }

  onReactivateSubscription(plan) {
    const { dispatch } = this.props;
    dispatch(reactivateSubscription(plan));
  }

  onCreateCustomerPortalSession() {
    const { dispatch } = this.props;
    dispatch(createCustomerPortalSession());
  }

  onCloseModal() {
    this.setState({ showModal: false });
  }

  onPaymentCompleted() {
    this.onCloseModal();
  }

  togglePayments() {
    this.setState((state) => ({ showPayments: !state.showPayments }));
  }

  toggleReceipt(payment, transaction) {
    this.setState((state) => ({
      receipt: {
        show: !state.receipt.show,
        payment: payment,
        transaction,
      },
    }));
  }

  renderAvailablePlans() {
    const { user, auth_token, availablePlans, dispatch, customer } = this.props;
    const { showModal, selectedPlan } = this.state;
    return (
      <div>
        {availablePlans.map((plan) => (
          <SubscriptionPlan
            key={plan.id}
            plan={plan}
            onSubscribe={this.onSubscribe}
          />
        ))}
        <ElementsConsumer>
          {({ elements, stripe }) => (
            <PaymentModal
              stripe={stripe}
              elements={elements}
              showTitle
              show={showModal}
              onClose={this.onCloseModal}
              plan={selectedPlan}
              user={user}
              auth_token={auth_token}
              onPaymentCompleted={this.onPaymentCompleted}
              onPayment={(paymentMethodId) => {
                dispatch(
                  subscribeToPlan(customer.id, paymentMethodId, selectedPlan.id)
                );
              }}
            />
          )}
        </ElementsConsumer>
      </div>
    );
  }

  renderActivePlan() {
    const { currentPlan, user, auth_token, dispatch } = this.props;
    const { showModal, selectedPlan } = this.state;
    return (
      <div>
        <ActiveSubscription
          plan={currentPlan}
          onCancel={this.onCancelSubscribe}
          onReactivate={this.onReactivateSubscription}
          onCreateCustomerPortalSession={this.onCreateCustomerPortalSession}
          showModal={() => {
            dispatch(setPaymentModalState(PaymentModalStates.UDPATE_CARD));
            this.setState((state) => ({ showModal: true }));
          }}
        />
        <PaymentModal
          showTitle={false}
          show={showModal}
          onClose={this.onCloseModal}
          plan={currentPlan}
          user={user}
          auth_token={auth_token}
          onPaymentCompleted={this.onPaymentCompleted}
          onPayment={(token) => {
            dispatch(updateCreditCard(token));
          }}
        />
      </div>
    );
  }

  render() {
    const { t, currentPlan, payments } = this.props;
    const { showPayments, receipt } = this.state;
    const showAvailablePlans = !currentPlan;

    return (
      <Elements stripe={this.stripePromise}>
        <AppContent className="subscription-container" size="medium">
          <h1 className="subscription-container__title">
            {t("subscription.subscriptions")}
          </h1>
          {showAvailablePlans
            ? this.renderAvailablePlans()
            : this.renderActivePlan()}
          {payments.length > 0 && (
            <div>
              <div className="subscription-item-link-button">
                <a
                  href=""
                  onClick={(e) => {
                    e.preventDefault();
                    this.togglePayments();
                  }}
                  className="subscription-item-link-button-text"
                >
                  {t("subscription.showPayments")}
                </a>
              </div>

              <ReceiptModal
                show={receipt.show}
                payment={receipt.payment}
                transaction={receipt.transaction}
                onClose={this.toggleReceipt}
              />

              {showPayments && (
                <div style={{ marginTop: "20px" }}>
                  <table className="table">
                    <thead>
                      <tr>
                        <th scope="col">{t("subscription.date")}</th>
                        <th scope="col">{t("subscription.plan")}</th>
                        <th scope="col">{t("subscription.amount")}</th>
                        <th scope="col">{t("subscription.transactionId")}</th>
                        <th scope="col">{t("subscription.receipt.receipt")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {payments.map((payment) => {
                        return (
                          <React.Fragment key={payment.id}>
                            {payment.payment_transactions.map((transaction) => {
                              return (
                                <tr key={transaction.id}>
                                  <td>
                                    {moment(transaction.payment_date).format(
                                      "DD-MM-YYYY"
                                    )}
                                  </td>
                                  <td>{payment.plan_name}</td>
                                  <td>{`${transaction.amount} ${transaction.currency}`}</td>
                                  <td>{transaction.stripe_transaction_id}</td>
                                  <td>
                                    <a
                                      href="#"
                                      onClick={() =>
                                        this.toggleReceipt(payment, transaction)
                                      }
                                    >
                                      {t("subscription.receipt.receipt")}
                                    </a>
                                  </td>
                                </tr>
                              );
                            })}
                          </React.Fragment>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
          )}
        </AppContent>
      </Elements>
    );
  }
}

const mapStateToProps = ({ auth, subscriptions, user }) => ({
  auth_token: auth.authToken,
  user: user.user,
  currentPlan: subscriptions.currentPlan,
  availablePlans: subscriptions.availablePlans,
  payments: subscriptions.payments,
  customer: subscriptions.customer,
  portalUrl: subscriptions.portalUrl,
});

const SubscriptionWithTranslate = withTranslation()(Subscription);
export default connect(mapStateToProps)(SubscriptionWithTranslate);
