import React from "react";
import PropTypes from "prop-types";
import SubTopicExpandedList from "./SubTopicExpandedList";
import ExerciseExpandedList from "./ExerciseExpandedList";
import { TrainingFocusesSmall } from "./TrainingFocusesSmall";
import { Icon } from "../../common";
import { SortableHandle } from "react-sortable-hoc";

const SortableHandleHelper = SortableHandle(() => (
  <div className="todo-list__sort-handle">
    <Icon icon="arrows" />
  </div>
));

export function TrainingTopic ({topic, onItemEdit, onCheck, greyIndex, onExpand, t}) {
  return (
    <li
      key={topic.id}
      className={`${greyIndex % 2 === 0 ? "bg-grey" : ""}`}
      onClick={() => onExpand(topic.id)}
    >
      <div className="column column--align-top p-1">
        <SortableHandleHelper />
        <input
          type="checkbox"
          className="todo-list__input"
          value={topic.id}
          checked={topic.done}
          onChange={onCheck}
          onClick={(e) => e.stopPropagation()}
        />
        {topicLabel(topic, t)}
        {topicEditButton(topic, onItemEdit, t)}
      </div>
      {topic.trainingSubTopics.length > 0 && (
        <SubTopicExpandedList topicId={topic.id} expanded={topic.expanded} />
      )}
      {topic.exercises.length > 0 && (
        <ExerciseExpandedList topicId={topic.id} expanded={topic.expanded} />
      )}
    </li>
  );
}

TrainingTopic.propTypes = {
  topic: PropTypes.object.isRequired,
  onItemEdit: PropTypes.func.isRequired,
  onCheck: PropTypes.func.isRequired,
  greyIndex: PropTypes.number.isRequired,
  onExpand: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

const topicLabel = (topic, t) => {
  return (
    <label className="todo-list__label">
      <span className="todo-list__name">{topic.name}</span>
      <TrainingFocusesSmall trainingFocuses={topic.trainingFocuses} t={t} />
    </label>
  );
};

const topicEditButton = (topic, onItemEdit, t) => {
  return (
    <button
      className="todo-list__delete btn--link p-2"
      onClick={(e) => onItemEdit(topic.id, e)}
    >
      {topic.exercises && topic.exercises.length > 0 && (
        <span className="text-info text-small mr-3">
          {topic.exercises.length}{" "}
          {topic.exercises.length === 1 ? (
            <span>{t("topics.exerciseLower")}</span>
          ) : (
            <span>{t("topics.exercisesList")}</span>
          )}
        </span>
      )}
      {topic.trainingSubTopics && topic.trainingSubTopics.length > 0 && (
        <span className="text-info text-small mr-3">
          {topic.trainingSubTopics.length}{" "}
          {topic.trainingSubTopics.length === 1 ? (
            <span>{t("topics.subtopicLower")}</span>
          ) : (
            <span>{t("topics.subtopicsList")}</span>
          )}
        </span>
      )}
      <Icon icon="editGrey" />
    </button>
  );
};