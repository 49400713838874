import React, { useState } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import {
  FormGroup,
  Input,
  RoundButton,
} from "../../../common";
import FaultView from "./../FaultView";
import _ from "lodash";
import Switch from "react-switch";
import { castToNestedFocusArray } from "../../../helpers/Transformer";

const RunForm = (props) => {
  const { t, courseTypes, user, form, submitRunForm, runFormBack, faultCategories, faultReasonCategories } = props;
  const STEPS = {
    RUN: 2,
    FAULTS: "faults",
    FAULT_REASONS: "fault_reasons",
  };

  const [currentStep, setCurrentStep] = useState(STEPS.RUN);
  const [runForm, setRunForm] = useState({
    result: form.result,
    faults: form.faults,
    faultReasons: form.faultReasons,
    speed: form.speed,
    winningSpeed: form.winningSpeed,
    timeFaults: form.timeFaults,
    placement: form.placement,
    dsq: form.dsq,
    courseLength: form.courseLength,
    trialTime: form.trialTime,
    winningTime: form.winningTime,
    openingPoints: form.openingPoints,
    closingPoints: form.closingPoints,
    winningPoints: form.winningPoints,
    totalPoints: form.totalPoints,
    designatedTime: form.designatedTime,
    timeDifference: form.timeDifference,
    cleanRun: form.cleanRun,
  });

  const STANDARD_COURSES = [1, 2, 3, 12]; //Agility, Jumping, Speedstakes, Dog jumping
  const POINTS_COURSES = [4, 5]; //WAO: Gamblers, Snooker
  const TIME_GAMBLE = [6]; // IFCS
  const POWER_AND_SPEED = [7]; // IFCS
  const FAST = [8]; // AKC
  const TIME2BEAT = [9]; // AKC
  const POINTS_AND_TIME = [10, 11]; // UKA

  let courseType = courseTypes.find((ct) => ct.id === parseInt(form.courseTypeId));
  let titleText = t("trial." + (courseType ? courseType.name.toLowerCase() : "") + "Course");

  const handleInputChange = (e) => {
    let { name, value } = e.target;
    setRunForm({ ...runForm, [name]: value });
  };

  const handleDisqualifiedChange = (checked) => {
    setRunForm({ ...runForm, dsq: checked, cleanRun: false });
  };

  const handleCleanRunChange = (checked) => {
    // TODO: Pitäisikö fault arvotkin tyhjentää? - Eivät näy enää mutta jäävät talteen.
    setRunForm({ ...runForm, dsq: false, cleanRun: checked });
  };

  const handleResultChange = (e) => {
    let { name, value } = e.target;
    if ((value !== "" && value <= 0) || value.toLowerCase() === "q" || value.toLowerCase() === "qq") {
      setRunForm({ ...runForm, cleanRun: true, [name]: value });     
    } else {
      setRunForm({ ...runForm, cleanRun: false, [name]: value });
    }
  };

  const removeFaults = (item) => {
    let items = Array.isArray(item) ? item : [item];
    items = [
      ...items.map((fault) => {
        fault.count = 0;
        return fault;
      }),
    ];
    handleFaultToggle(items);
  };

  const handleFaultToggle = (item) => {
    const items = Array.isArray(item) ? item : [item];
    let newFaults = [...runForm.faults];
    items.forEach((item) => {
      const isSelected = _.some(runForm.faults, { key: item.key });
      if (isSelected && item.count === 0) {
        newFaults = newFaults.filter((i) => i.key !== item.key);
      } else if (isSelected) {
        newFaults = newFaults.map((i) => (i.key === item.key ? item : i));
      } else {
        newFaults = [...newFaults, item];
      }
    });
    setRunForm({ ...runForm, faults: newFaults });
  };

  const removeFaultReasons = (item) => {
    let items = Array.isArray(item) ? item : [item];
    items = [
      ...items.map((fault) => {
        fault.count = 0;
        return fault;
      }),
    ];
    handleFaultReasonToggle(items);
  };

  const handleFaultReasonToggle = (item) => {
    const items = Array.isArray(item) ? item : [item];
    let newFaults = [...runForm.faultReasons];
    items.forEach((item) => {
      const isSelected = _.some(runForm.faultReasons, { key: item.key });
      if (isSelected) {
        newFaults = newFaults.filter((i) => i.key !== item.key);
      } else {
        newFaults = [...newFaults, item];
      }
    });
    setRunForm({ ...runForm, faultReasons: newFaults });
  };

  const handleCourseLengthChange = (e) => {
    let { name, value } = e.target;
    let newValue = value <= 0 ? 1 : value;
    const trialSpeed = calculateSpeed(runForm.trialTime, newValue);
    const winSpeed = calculateSpeed(runForm.winningTime, newValue);
    setRunForm({ ...runForm,  speed: trialSpeed, winningSpeed: winSpeed, [name]: value });
  };

  const handleSpeedChange = (e) => {
    let { name, value } = e.target;
    let newValue = value <= 0 ? 1 : value;
    const time = calculateTime(newValue);
    if (name === "speed")
      if (runForm.courseLength) {
        setRunForm({ ...runForm, trialTime: time, [name]: value });
      } else {
        setRunForm({ ...runForm, [name]: value });
      }
    if (name === "winningSpeed")
      if (runForm.courseLength) {
        setRunForm({ ...runForm, winningTime: time, [name]: value });
      }
      else {
        setRunForm({ ...runForm, [name]: value });
      }
  };

  const handleTimeChange = (e) => {
    let { name, value } = e.target;
    let newValue = value <= 0 ? 1 : value;
    const speed = calculateSpeed(newValue);
    if (name === "trialTime")
      if (runForm.courseLength) {
        setRunForm({ ...runForm,  speed: speed, [name]: value });
      } else {
        setRunForm({ ...runForm, [name]: value });
      }     
    if (name === "winningTime")
      if (runForm.courseLength) {
        setRunForm({ ...runForm,  winningSpeed: speed, [name]: value });
      } else {
        setRunForm({ ...runForm, [name]: value });
      }
  };

  const roundToTwo = (value) => {
    if (value) {
      return Math.round(value * 100) / 100;
    }
  };

  const calculateSpeed = (time, changedCourseLength) => {
    let courseLength = changedCourseLength
      ? formatNumber(changedCourseLength)
      : runForm.courseLength;
    let newTime = formatNumber(time);
    let speed = roundToTwo((courseLength * 1.0) / newTime);
    return speed;
  };

  const calculateTime = (speed) => {
    let courseLength = runForm.courseLength;
    let newSpeed = formatNumber(speed);
    let time = roundToTwo((courseLength * 1.0) / newSpeed);
    return time;
  };

  // Points course
  const handlePointsChange = (e) => {
    let { name, value } = e.target;
    const { openingPoints, closingPoints } = runForm;
    let totalPoints = 0;
    if (name === "openingPoints") {     
      totalPoints =
        (isNaN(value) || value === "" ? 0 : parseInt(value)) +
        (isNaN(closingPoints) || closingPoints === ""
          ? 0
          : parseInt(closingPoints));
    } else if (name === "closingPoints") {
      totalPoints =
        (isNaN(value) || value === "" ? 0 : parseInt(value)) +
        (isNaN(openingPoints) || closingPoints === ""
          ? 0
          : parseInt(openingPoints));
    }
    setRunForm({ ...runForm, totalPoints: totalPoints, [name]: value });
  };

  // FAST
  const handlePointsChangeFAST = (e) => {
    let { name, value } = e.target;
    const { openingPoints, closingPoints, timeFaults } = runForm;
    let calculatedPoints = 0;
    let calculatedTime = timeFaults;
    if (name === "openingPoints") {
      calculatedPoints =
        (isNaN(value) || value === "" ? 0 : parseInt(value)) +
        (isNaN(closingPoints) || closingPoints === ""
          ? 0
          : parseInt(closingPoints));
    } else if (name === "closingPoints") {
      calculatedPoints =
        (isNaN(value) || value === "" ? 0 : parseInt(value)) +
        (isNaN(openingPoints) || closingPoints === ""
          ? 0
          : parseInt(openingPoints));
    } else if (name === "timeFaults") {
      calculatedPoints = openingPoints + closingPoints;
      calculatedTime = Math.floor(value);
    }
    setRunForm({ ...runForm, totalPoints: calculatedPoints - calculatedTime, [name]: value });
  };

  // Time gamble
  const handleDesignatedTimeChange = (e) => {
    let { name, value } = e.target;
    const { designatedTime, trialTime } = runForm;
    let timeDifference = 0;
    if (name === "designatedTime") {
      timeDifference =
        (isNaN(value) || value === "" || value === null ? 0 : parseInt(value)) -
        (isNaN(trialTime) || trialTime === "" || trialTime === null
          ? 0
          : parseInt(trialTime));
    } else if (name === "trialTime") {
      timeDifference =
        (isNaN(designatedTime) ||
        designatedTime === "" ||
        designatedTime === null
          ? 0
          : parseInt(designatedTime)) -
        (isNaN(value) || value === "" || value === null ? 0 : parseInt(value));
    }
    setRunForm({ ...runForm, timeDifference: timeDifference, [name]: value });
  };

  const formatNumber = (value) => {
    if (value) {
      if (value.replace) {
        return Number(
          "" +
            value
              .replace(/ /g, "")
              .replace(",", ".")
              .replace(/[^\d.,-]/g, "")
        );
      } else return value;
    }
  };

  // On blur validoi numerot
  const validateNumber = (e) => {
    const { name, value } = e.target;
    if (value === "") {
      setRunForm({ ...runForm, [name]: null  });
      return;
    }
    var newValue = Number(
      value
        .replace(/ /g, "")
        .replace(",", ".")
        .replace(/[^\d.,-]/g, "")
    );
    setRunForm({ ...runForm, [name]: newValue });
  };

  const renderStandardForm = () => {
    return (
      <>
        <div className="column-tight column-tight--align-top">
          <div className="flex-item-narrow">
            {renderCleanRunSwitch()}
          </div>
          <div className="flex-item-right">
            {renderDisqualifiedSwitch()}
          </div>
        </div>
        {!runForm.dsq && (
          <div className="column column--align-top">
            <div className="flex-item">
              <FormGroup>
                <Input
                  name="result"
                  label={t("trial.result")}
                  placeholder={t("trial.result-placeholder")}
                  value={runForm.result}
                  onChange={handleResultChange}
                />
              </FormGroup>
            </div>
          </div>
        )}
        {!runForm.cleanRun && (
          <>            
            {renderFaults()}
            {renderFaultReasons()}
          </>
        )}
        {!runForm.dsq && (
          <>
            <FormGroup>
              <Input
                name="courseLength"
                label={
                  t("trial.courseLength") +
                  " (" +
                  t("common.unit-length-course-" + user.units) +
                  ")"
                }
                placeholder=""
                value={runForm.courseLength}
                onBlur={validateNumber}
                onChange={handleCourseLengthChange}
              />
            </FormGroup>
            <div className="column column--align-top">
              <div className="flex-item">
                <FormGroup>
                  <Input
                    name="trialTime"
                    label={t("trial.time") + " (s)"}
                    placeholder=""
                    value={runForm.trialTime}
                    onBlur={validateNumber}
                    onChange={handleTimeChange}
                  />
                </FormGroup>
              </div>
              <div className="flex-item-right">
                <FormGroup>
                  <Input
                    name="speed"
                    label={t("trial.speed") + " (" +
                      t("common.unit-speed-" + user.units) + ")"}
                    placeholder=""
                    value={runForm.speed}
                    onBlur={validateNumber}
                    onChange={handleSpeedChange}
                  />
                </FormGroup>
              </div>
            </div>
            <div className="column column--align-top">
              <div className="flex-item">
                <FormGroup>
                  <Input
                    name="winningTime"
                    label={t("trial.winningTime") + " (s)"}
                    placeholder=""
                    value={runForm.winningTime}
                    className="text-gold"
                    onBlur={validateNumber}
                    onChange={handleTimeChange}
                  />
                </FormGroup>
              </div>
              <div className="flex-item-right">
                <FormGroup>
                  <Input
                    name="winningSpeed"
                    label={t("trial.winningSpeed") + " (" +
                      t("common.unit-speed-" + user.units) + ")"}
                    placeholder=""
                    value={runForm.winningSpeed}
                    className="text-gold"
                    onBlur={validateNumber}
                    onChange={handleSpeedChange}
                  />
                </FormGroup>
              </div>
            </div>
          </>
        )}
        {!runForm.dsq && !runForm.cleanRun && (
          <FormGroup>
            <Input
              name="timeFaults"
              label={t("trial.timeFaults")}
              placeholder=""
              value={runForm.timeFaults}
              onBlur={validateNumber}
              onChange={handleInputChange}
            />
          </FormGroup>
        )}
        {!runForm.dsq && (
          <div className="column column--align-top">
            <div className="flex-item">
              {renderPlacementInput()}
            </div>
          </div>
        )}
      </>
    );
  };

  const renderPointsForm = () => {
    return (
      <>
        <div className="column column--align-top">
          <div className="flex-item">
            {renderPlacementInput()}
          </div>
        </div>
        <div className="column column--align-top">
          <div className="flex-item">
            <FormGroup>
              <Input
                name="openingPoints"
                type="number"
                label={t("trial.openingPoints")}
                value={runForm.openingPoints}
                onBlur={validateNumber}
                onChange={handlePointsChange}
              />
            </FormGroup>
          </div>
          <div className="flex-item-right">
            <FormGroup>
              <Input
                name="closingPoints"
                type="number"
                label={t("trial.closingPoints")}
                value={runForm.closingPoints}
                onBlur={validateNumber}
                onChange={handlePointsChange}
              />
            </FormGroup>
          </div>
        </div>
        <div className="column column--align-top">
          <div className="flex-item">
            {renderTotalPointsInput("trial.totalPoints")}
          </div>
          <div className="flex-item-right">
            {renderWinningPointsInput()}
          </div>
        </div>
        <div className="column column--align-top">
          <div className="flex-item">
            {renderTrialTimeInput()}
          </div>
          <div className="flex-item-right">
            {renderWinningTimeInput()}
          </div>
        </div>
        {renderFaults()}
        {renderFaultReasons()}
      </>
    );
  };
  
  const renderTimeGambleForm = () => {
    return (
      <>
        <div className="column column--align-top">
          <div className="flex-item">
            {renderPlacementInput()}
          </div>
        </div>
        <div className="column column--align-top">
          <div className="flex-item">
            <FormGroup>
              <Input
                name="designatedTime"
                label={t("trial.designatedTime") + " (s)"}
                placeholder=""
                value={runForm.designatedTime}
                onBlur={validateNumber}
                onChange={handleDesignatedTimeChange}
              />
            </FormGroup>
          </div>
          <div className="flex-item-right">
            <FormGroup>
              <Input
                name="trialTime"
                label={t("trial.time") + " (s)"}
                placeholder=""
                value={runForm.trialTime}
                onBlur={validateNumber}
                onChange={handleDesignatedTimeChange}
              />
            </FormGroup>
          </div>
        </div>
        <div className="column column--align-top">
          <div className="flex-item">
            <FormGroup>
              <Input
                name="timeDifference"
                label={t("trial.timeDifference")}
                placeholder=""
                value={runForm.timeDifference}
                onBlur={validateNumber}
                onChange={handleInputChange}
              />
            </FormGroup>
          </div>
        </div>
        {renderFaults()}
        {renderFaultReasons()}
      </>
    );
  };

  const renderPowerForm = () => {
    return (
      <>
        <div className="column-tight column-tight--align-top">
          <div className="flex-item-narrow">
            {renderCleanRunSwitch()}
          </div>
          <div className="flex-item-right">
            {renderDisqualifiedSwitch()}
          </div>
        </div>
        {!runForm.dsq && (
          <>
            <div className="column column--align-top">
              <div className="flex-item">
                {renderTrialTimeInput()}
              </div>
              <div className="flex-item-right">
                {renderWinningTimeInput()}
              </div>
            </div>
            <div className="column column--align-top">
              <div className="flex-item">
                <FormGroup>
                  <Input
                    name="result"
                    label={t("trial.result")}
                    placeholder={t("trial.result-placeholder")}
                    value={runForm.result}
                    // onBlur={this.validateNumber}
                    onChange={handleResultChange}
                  />
                </FormGroup>
              </div>
            </div>
          </>
        )}
        {!runForm.cleanRun && (
          <>
            {renderFaults()}
            {renderFaultReasons()}
          </>
        )}
        {!runForm.dsq && (
          <div className="column column--align-top">
            <div className="flex-item">
              {renderPlacementInput()}
            </div>
          </div>
        )}
      </>
    );
  };

  const renderFastForm = () => {
    return (
      <>
        <div className="column column--align-top">
          <div className="flex-item">
            {renderPlacementInput()}
          </div>
          <div className="flex-item-right">
            <FormGroup>
              <Input
                name="openingPoints"
                type="number"
                label={t("trial.points")}
                value={runForm.openingPoints}
                onBlur={validateNumber}
                onChange={handlePointsChangeFAST}
              />
            </FormGroup>
          </div>
        </div>
        <div className="column column--align-top">
          <div className="flex-item">
            <FormGroup>
              <Input
                name="closingPoints"
                type="number"
                label={t("trial.sendBonus")}
                value={runForm.closingPoints}
                onBlur={validateNumber}
                onChange={handlePointsChangeFAST}
              />
            </FormGroup>
          </div>
          <div className="flex-item-right">
            <FormGroup>
              <Input
                name="timeFaults"
                label={t("trial.timeFaults")}
                placeholder=""
                value={runForm.timeFaults}
                onBlur={validateNumber}
                onChange={handlePointsChangeFAST}
              />
            </FormGroup>
          </div>
        </div>
        <div className="column column--align-top">
          <div className="flex-item">
            {renderTrialTimeInput()}
          </div>
          <div className="flex-item-right">
            {renderWinningTimeInput()}
          </div>
        </div>
        <div className="column column--align-top">
          <div className="flex-item">
            {renderTotalPointsInput("trial.totalPoints")}
          </div>
          <div className="flex-item-right">
            {renderWinningPointsInput()}
          </div>
        </div>
        {renderFaults()}
        {renderFaultReasons()}
      </>
    );
  };

  const renderTime2BeatForm = () => {
    return (
      <>
        <div className="column-tight column-tight--align-top">
          <div className="flex-item-narrow">
            {renderCleanRunSwitch()}
          </div>
          <div className="flex-item-right">
            {renderDisqualifiedSwitch()}
          </div>
        </div>
        <div className="column column--align-top">
          {!runForm.dsq && (
            <>
              <div className="flex-item">
                {renderTrialTimeInput()}
              </div>
              <div className="flex-item-right">
                {renderWinningTimeInput()}
              </div>
            </>
          )}
        </div>
        <div className="column column--align-top">
          {!runForm.dsq && (
            <div className="flex-item">
              <FormGroup>
                <Input
                  name="result"
                  label={t("trial.result")}
                  placeholder={t("trial.result-placeholder")}
                  value={runForm.result}
                  // onBlur={validateNumber}
                  onChange={handleResultChange}
                />
              </FormGroup>
            </div>
          )}
        </div>
        {!runForm.cleanRun && (
          <>
            {renderFaults()}
            {renderFaultReasons()}
          </>
        )}
        {!runForm.dsq && (
          <div className="column column--align-top">
            <div className="flex-item">
              {renderPlacementInput()}
            </div>
          </div>
        )}
      </>
    );
  };

  const renderPointsAndTimeForm = () => {
    return (
      <>
        <div className="column-tight column-tight--align-top">
          <div className="flex-item-narrow">
            {renderCleanRunSwitch()}
          </div>
          <div className="flex-item-right">
            {renderDisqualifiedSwitch()}
          </div>
        </div>
        {!runForm.dsq && (
          <>
            <div className="column column--align-top">
              <div className="flex-item">
                {renderTotalPointsInput("trial.points")}
              </div>
              <div className="flex-item-right">
                {renderWinningPointsInput()}
              </div>
            </div>
            <div className="column column--align-top">
              <div className="flex-item">
                {renderTrialTimeInput()}
              </div>
              <div className="flex-item-right">
                {renderWinningTimeInput()}
              </div>
            </div>
          </>
        )}
        {!runForm.cleanRun && (
          <>
            {renderFaults()}
            {renderFaultReasons()}
          </>
        )}
        {!runForm.dsq && (
          <div className="column column--align-top">
            <div className="flex-item">
              {renderPlacementInput()}
            </div>
          </div>
        )}
      </>
    );
  };

  const renderCleanRunSwitch = () => {
    return (
      <FormGroup>
        <label>{t("trial.cleanRun")}</label>
        <div>
          <Switch
            onChange={handleCleanRunChange}
            checked={runForm.cleanRun}
            offColor="#868e96"
            onColor="#28a745"
            uncheckedIcon={false}
            checkedIcon={false}
          />
        </div>
      </FormGroup>
    );
  };

  const renderDisqualifiedSwitch = () => {
    return (
      <FormGroup>
        <label>{t("trial.disqualified")}</label>
        <div>
          <Switch
            onChange={handleDisqualifiedChange}
            checked={runForm.dsq}
            offColor="#868e96"
            onColor="#c52c2d"
            uncheckedIcon={false}
            checkedIcon={false}
          />
        </div>
      </FormGroup>
    );
  };

  const renderPlacementInput = () => {
    return (
      <FormGroup>
        <Input
          name="placement"
          label={t("trial.placement")}
          type="number"
          placeholder=""
          value={runForm.placement}
          onChange={handleInputChange}
        />
      </FormGroup>
    );
  };

  const renderTrialTimeInput = () => {
    return (
      <FormGroup>
        <Input
          name="trialTime"
          label={t("trial.time") + " (s)"}
          placeholder=""
          value={runForm.trialTime}
          onBlur={validateNumber}
          onChange={handleInputChange}
        />
      </FormGroup>
    );
  };

  const renderWinningTimeInput = () => {
    return (
      <FormGroup>
        <Input
          name="winningTime"
          label={t("trial.winningTime") + " (s)"}
          placeholder=""
          value={runForm.winningTime}
          onBlur={validateNumber}
          onChange={handleInputChange}
        />
      </FormGroup>
    );
  };

  const renderTotalPointsInput = (labeltext) => {
    return (
      <FormGroup>
        <Input
          name="totalPoints"
          type="number"
          label={t(labeltext)}
          value={runForm.totalPoints}
          onBlur={validateNumber}
          onChange={handleInputChange}
        />
      </FormGroup>
    );
  };

  const renderWinningPointsInput = () => {
    return (
      <FormGroup>
        <Input
          name="winningPoints"
          type="number"
          label={t("trial.winningPoints")}
          value={runForm.winningPoints}
          onBlur={validateNumber}
          onChange={handleInputChange}
        />
      </FormGroup>
    );
  };

  const renderFaults = () => {
    return (
      <FormGroup>
        <label>{t("trial.courseFaults")}</label>
        <p>
          <button
            className="btn btn-secondary"
            onClick={() => setCurrentStep(STEPS.FAULTS)}>
            {t("trial.courseFaultsButton")}
          </button>
        </p>
        {castToNestedFocusArray(runForm.faults).map((focus) => (
          <div className="alert alert-info" key={focus.key}>
            {t(`misc.${focus.name}`)}
            {!focus.children && <span> × {focus.count}</span>}
            {focus.children && focus.children.length > 0 && (
              <span>
                :{" "}{focus.children.map((f) => t(`misc.${f.name}`) + ` × ${f.count}`).join(", ")}
              </span>
            )}
            <button
              type="button"
              className="close"
              data-dismiss="alert"
              aria-label="Close">
              <span aria-hidden="true" onClick={() => removeFaults(focus.children ? focus.children : focus)}>
                &times;
              </span>
            </button>
          </div>
        ))}
      </FormGroup>
    );
  };

  const renderFaultReasons = () => {
    return (
      <FormGroup>
        <label>{t("trial.courseFaultReasons")}</label>
        <p>
          <button
            className="btn btn-secondary"
            onClick={() => setCurrentStep(STEPS.FAULT_REASONS)}>
            {t("trial.courseFaultReasonsButton")}
          </button>
        </p>
        {castToNestedFocusArray(runForm.faultReasons).map((focus) => (
          <div className="alert alert-info" key={focus.key}>
            {t(`misc.${focus.name}`)}
            {focus.children && focus.children.length > 0 && (
              <span>
                :{" "}{focus.children.map((f) => t(`misc.${f.name}`)).join(", ")}
              </span>
            )}
            <button
              type="button"
              className="close"
              data-dismiss="alert"
              aria-label="Close">
              <span aria-hidden="true" onClick={() => removeFaultReasons(focus.children ? focus.children : focus)}>
                &times;
              </span>
            </button>
          </div>
        ))}
      </FormGroup>
    );
  };

  return (
    <>
      {currentStep === STEPS.RUN &&
        <>
          <button className="trial-back-button" onClick={() => runFormBack()} >
            <strong>&larr;</strong> {t("common.back")}
          </button>
          <h2 className="text-center">{titleText}</h2>
          {_.includes(STANDARD_COURSES, parseInt(form.courseTypeId)) && renderStandardForm()}
          {_.includes(POINTS_COURSES, parseInt(form.courseTypeId)) && renderPointsForm()}
          {_.includes(TIME_GAMBLE, parseInt(form.courseTypeId)) && renderTimeGambleForm()}
          {_.includes(POWER_AND_SPEED, parseInt(form.courseTypeId)) && renderPowerForm()}
          {_.includes(FAST, parseInt(form.courseTypeId)) && renderFastForm()}
          {_.includes(TIME2BEAT, parseInt(form.courseTypeId)) && renderTime2BeatForm()}
          {_.includes(POINTS_AND_TIME, parseInt(form.courseTypeId)) && renderPointsAndTimeForm()}
          <RoundButton
            atCorner
            size="large"
            icon="continue"
            onClick={() => submitRunForm(runForm)}
          />
        </>
      }
      {currentStep === STEPS.FAULTS && (
        <FaultView
          faultCategories={faultCategories}
          faults={runForm.faults}
          onFaultChange={handleFaultToggle}
          onComplete={() => setCurrentStep(STEPS.RUN)}
          multiple
        />
      )}
      {currentStep === STEPS.FAULT_REASONS && (
        <FaultView
          faultCategories={faultReasonCategories}
          faults={runForm.faultReasons}
          onFaultChange={handleFaultReasonToggle}
          onComplete={() => setCurrentStep(STEPS.RUN)}
        />
      )}
    </>
  );
};

RunForm.propTypes = {
  t: PropTypes.func,
  auth_token: PropTypes.any,
  dog: PropTypes.object,
  user: PropTypes.object,
  courseTypes: PropTypes.array,
  form: PropTypes.object,
  submitRunForm: PropTypes.func,
  runFormBack: PropTypes.func,
  faultCategories: PropTypes.array,
  faultReasonCategories: PropTypes.array,
};

const mapStateToProps = (
  { activeDog, auth, settings, courseTypes, user }
) => {
  return {
    dog: activeDog,
    auth_token: auth.authToken,
    courseTypes: courseTypes.courseTypes,
    user: user.user,
    faultCategories: settings.trialFaultCategories,
    faultReasonCategories: settings.trialFaultReasonCategories,
  };
};

const RunFormWithTranslate = withTranslation()(RunForm);
export default connect(mapStateToProps, {})(RunFormWithTranslate);