import { call, put, takeLatest, select } from "redux-saga/effects";

import Api from "../helpers/Api";
import { getAuthToken, getActiveDog, getDogs } from "./selectors";
import {
  FETCH_DOGS,
  ADD_DOG,
  UPDATE_DOG,
  DELETE_DOG,
  FETCH_DOG,
  UPDATE_DOG_TRIAL_SIZE_CLASS
} from "../actions/types";
import {
  fetchDogs as fetchDogsAction,
  fetchDogsSucceeded,
  fetchDogsFailed,
  addDogSucceeded,
  addDogFailed,
  updateDogSucceeded,
  updateDogFailed,
  setActiveDog,
  deleteDogSucceeded,
  deleteDogFailed,
  fetchDogFailed,
  fetchDogSucceeded,
  updateDogTrialSizeClassFailed,
  updateDogTrialSizeClassSucceeded,
  authExpired
} from "../actions";

function* fetchDogs(action) {
  try {
    const auth_token = yield select(getAuthToken);
    const dogs = yield call(Api({ auth_token }).fetchDogs);
    yield put(fetchDogsSucceeded(dogs.data));
    if (dogs.data.length > 0) yield put(setActiveDog(dogs.data[0]));
  } catch (e) {
    yield put(fetchDogsFailed(e.message));
  }
}

function* addDog({ type, payload }) {
  try {
    const auth_token = yield select(getAuthToken);
    const dog = yield call(
      Api({
        auth_token,
        "content-type": +"multipart/form-data; boundary=----WebKitFormBoundary7MA4YWxkTrZu0gW"
      }).addDog,
      payload
    );
    yield put(addDogSucceeded(dog.data));
  } catch (e) {
    yield put(addDogFailed(e.message));
  }
}

function* updateDog({ type, payload }) {
  try {
    const auth_token = yield select(getAuthToken);
    const dog = yield call(
      Api({
        auth_token,
        "content-type": +"multipart/form-data; boundary=----WebKitFormBoundary7MA4YWxkTrZu0gW"
      }).updateDog,
      payload
    );
    yield put(updateDogSucceeded(dog.data));
  } catch (e) {
    yield put(updateDogFailed(e.message));
  }
}

function* deleteDog({ type, payload }) {
  try {
    const auth_token = yield select(getAuthToken);
    const res = yield call(Api({ auth_token }).deleteDog, payload);
    const activeDog = yield select(getActiveDog);
    const dogs = yield select(getDogs);
    const newActiveDog = dogs.filter(d => d.id !== payload)[0];
    if (payload === activeDog.id) yield put(setActiveDog(newActiveDog));

    yield put(deleteDogSucceeded(res.data));
    yield put(fetchDogsAction());
  } catch (e) {
    yield put(deleteDogFailed(e.message));
  }
}

function* fetchDog({ type, payload }) {
  try {
    const auth_token = yield select(getAuthToken);
    const dog = yield call(Api({ auth_token }).fetchDog, payload);
    yield put(fetchDogSucceeded(dog.data));
  } catch (e) {
    yield put(fetchDogFailed(e.message));
  }
}

function* updateDogTrialSizeClass({ type, payload }) {
  try {
    const auth_token = yield select(getAuthToken);
    const result = yield call(
      Api({ auth_token }).updateDogTrialSizeClass,
      payload
    );
    yield put(updateDogTrialSizeClassSucceeded(result));
  } catch (e) {
    const { status, statusText } = e.response;
    if (status === 401) {
      yield put(authExpired());
    }
    yield put(updateDogTrialSizeClassFailed({ status, statusText }));
  }
}

function* dogSaga() {
  yield takeLatest(FETCH_DOGS.REQUEST, fetchDogs);
  yield takeLatest(ADD_DOG.REQUEST, addDog);
  yield takeLatest(UPDATE_DOG.REQUEST, updateDog);
  yield takeLatest(DELETE_DOG.REQUEST, deleteDog);
  yield takeLatest(FETCH_DOG.REQUEST, fetchDog);
  yield takeLatest(
    UPDATE_DOG_TRIAL_SIZE_CLASS.REQUEST,
    updateDogTrialSizeClass
  );
}

export default dogSaga;
