import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { saveSettings } from "../../actions/";
import confirm from "../../components/Confirm";
import i18n from "../../config/i18n";

import moment from "moment";
import "moment/locale/fi";
import "moment/locale/sv";
import "moment/locale/fr";
import "moment/locale/nb";
import "moment/locale/es";
import "moment/locale/de";
import "moment/locale/en-gb";
import "moment/locale/da";
import "moment/locale/it";
import "moment/locale/pt";
import "moment/locale/nl";
import "moment/locale/pl";

import {
  AppContent,
  RoundButton,
  FormGroup,
  Input,
  CheckboxConfirm,
  Error,
  Select,
} from "../../common";

class Settings extends Component {
  state = {
    password: "",
    passwordAgain: "",
    allowEmails: false,
    language: "en",
    units: "metric",
    error: "",
  };

  componentDidMount() {
    this.setState({
      allowEmails: !!this.props.allowEmails,
      language: this.props.language,
      units: this.props.units,
    });
  }

  save = (e) => {
    e.preventDefault();
    let hasErrors = false;
    const { t, userId } = this.props;
    const {
      password,
      passwordAgain,
      allowEmails,
      language,
      units,
    } = this.state;

    let settings = {
      userId,
      allowEmails: allowEmails,
      language: language,
      units: units,
    };

    if ((!!password || !!passwordAgain) && password !== passwordAgain) {
      this.setState({ error: t("common.passwordNotMatch") });
      hasErrors = true;
    } else if ((!!password || !!passwordAgain) && password.length < 6) {
      this.setState({ error: t("common.password-invalid") });
      hasErrors = true;
    } else {
      this.setState({ error: "" });
    }

    if (password && password === passwordAgain) {
      settings = { ...settings, password };
    }

    if (!hasErrors) {
      if (i18n.language !== settings.language) {
        i18n.changeLanguage(settings.language);
        moment.locale(settings.language);
      }
      this.props.saveSettings(settings);
    }
    this.setState({ password: "", passwordAgain: "" });
  };

  deleteAccount = () => {
    const { t } = this.props;
    confirm(t("settings.deleteAccountConfirm"), {
      title: t("settings.deleteAccountConfirmTitle"),
      ok: t("settings.deleteAccount"),
      cancel: t("common.cancel"),
    }).then(
      () => {
        /* TODO: Delete account */
      },
      () => {}
    );
  };

  handleInputChange = (e) => {
    let { name, value } = e.target;
    if (name === "allowEmails") value = e.target.checked;
    this.setState({ [name]: value });
  };

  reloadLocation = () => {
    window.location.reload(true);
  };

  render() {
    const { t } = this.props;
    const {
      password,
      passwordAgain,
      allowEmails,
      language,
      units,
      error,
    } = this.state;
    const icon = require("../../images/icons/reload.svg");

    return (
      <div>
        <AppContent size="small">
          <div className="column column--align-top">
            <div className="flex-item-wide">
              <h2>{t("settings.title")}</h2>
              <FormGroup>
                <Select
                  label={t("settings.languageTitle")}
                  name="language"
                  onChange={this.handleInputChange}
                  value={language || ""}
                  options={this.getLanguageSelectOptions()}
                />
              </FormGroup>
              <FormGroup>
                <Select
                  label={t("settings.unitsTitle")}
                  name="units"
                  onChange={this.handleInputChange}
                  value={units || ""}
                  options={this.getUnitOptions()}
                />
              </FormGroup>
            </div>
            <div>
              <FormGroup>
                <button
                  className="btn btn-outline-secondary"
                  onClick={this.reloadLocation}
                >
                  <img
                    src={icon}
                    alt="Reload location"
                    className="btn-normal"
                  />
                </button>
              </FormGroup>
            </div>
          </div>

          <FormGroup>
            <CheckboxConfirm
              label={t("settings.allowEmails")}
              name="allowEmails"
              onChange={this.handleInputChange}
              value={allowEmails}
            />
          </FormGroup>

          <h2>{t("settings.newPassword")}</h2>

          <FormGroup>
            <Input
              label={t("common.password")}
              type="password"
              name="password"
              value={password}
              onChange={this.handleInputChange}
            />
          </FormGroup>

          <FormGroup>
            <Input
              label={t("common.passwordAgain")}
              type="password"
              name="passwordAgain"
              value={passwordAgain}
              onChange={this.handleInputChange}
            />
          </FormGroup>

          <Error show={!!error}>{error}</Error>

          <br />
          <br />
          <br />
          {/* <button
            className="btn--link text-danger"
            onClick={this.deleteAccount}
          >
            {t("settings.deleteAccount")}
          </button> */}
          <RoundButton
            atCorner
            size="large"
            icon="complete"
            onClick={this.save}
          />
        </AppContent>
      </div>
    );
  }

  getLanguageSelectOptions = () => {
    const { t } = this.props;

    return [
      {
        title: t("common.choose"),
        value: "",
      },
      {
        title: "Dansk",
        value: "da",
      },
      {
        title: "Deutsch",
        value: "de",
      },
      {
        title: "English",
        value: "en",
      },
      {
        title: "Español",
        value: "es",
      },
      {
        title: "Français",
        value: "fr",
      },
      {
        title: "Italiano",
        value: "it",
      },
      {
        title: "Nederlands",
        value: "nl",
      },
      {
        title: "Norsk",
        value: "nb",
      },
      {
        title: "Polski",
        value: "pl",
      },
      {
        title: "Português",
        value: "pt",
      },
      {
        title: "Suomi",
        value: "fi",
      },
      {
        title: "Svenska",
        value: "sv",
      },
    ];
  };

  getUnitOptions = () => {
    const { t } = this.props;

    return [
      {
        title: t("common.choose"),
        value: "",
      },
      {
        title: t("settings.metric"),
        value: "metric",
      },
      {
        title: t("settings.imperial"),
        value: "imperial",
      },
    ];
  };
}

Settings.propTypes = {
  allowEmails: PropTypes.bool,
  t: PropTypes.func,
  userId: PropTypes.number,
  saveSettings: PropTypes.func,
  language: PropTypes.string,
  units: PropTypes.string,
};

const mapStateToProps = ({ user }) => {
  return {
    allowEmails: user.user.allowEmails,
    language: user.user.language,
    userId: user.user.id,
    units: user.user.units,
  };
};

const SettingsWithTranslate = withTranslation()(Settings);
export default connect(mapStateToProps, { saveSettings })(
  SettingsWithTranslate
);
