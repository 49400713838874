import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import ReCAPTCHA from "react-google-recaptcha";

import { recaptcha } from "../../config/env";
import { AppContent, FormGroup, Input } from "../../common";
import { returnPassword } from "../../actions";

class ForgotPassword extends Component {
  state = {
    email: "",
  };

  handlePasswordReturn = (recaptcha) => {
    this.props.returnPassword({ email: this.state.email, recaptcha });
    this.setState({ email: "" });
  };

  handleSubmit = (e) => {
    const { recaptcha } = this.state;
    e.preventDefault();

    if (recaptcha) {
      this.handlePasswordReturn(recaptcha);
    } else {
      this.recaptcha.execute();
    }
  };

  handleInputChange = (e) => {
    const { name, value } = e.target;

    this.setState((prevState, props) => ({
      ...prevState,
      [name]: value,
    }));
  };

  handleRecaptchaChange = (recaptcha) => {
    this.setState({ recaptcha });
    this.handlePasswordReturn(recaptcha);
  };

  render() {
    const { t } = this.props;
    const { email } = this.state;

    return (
      <AppContent size="small">
        <form onSubmit={this.handleSubmit}>
          <FormGroup>
            <Input
              label={t("common.email")}
              type="email"
              name="email"
              placeholder={t("common.email-placeholder")}
              onChange={this.handleInputChange}
              value={email}
              autoCapitalize="off"
            />
          </FormGroup>

          <FormGroup>
            <ReCAPTCHA
              ref={(el) => (this.recaptcha = el)}
              sitekey={recaptcha.sitekey}
              onChange={this.handleRecaptchaChange}
              size="invisible"
              badge="inline"
            />
          </FormGroup>

          <button className="btn btn-primary">
            {t("login.returnPassword")}
          </button>
        </form>
      </AppContent>
    );
  }
}

const ForgotPasswordWithTranslate = withTranslation()(ForgotPassword);
export default connect(null, { returnPassword })(ForgotPasswordWithTranslate);
