import React, { Component } from "react";
import PropTypes from "prop-types";

class Modal extends Component {
  render() {
    const {
      children,
      title,
      footer,
      show,
      onClose,
      hideHorizontalLine,
      className,
      contentClassName
    } = this.props;

    const openClasses = show ? "modal--show overlay--open" : "";
    const headerClasses = `modal-header ${
      hideHorizontalLine ? "modal--no-border" : ""
    }`;

    return (
      <div
        className={`modal overlay ${openClasses}`}
        tabIndex="-1"
        role="dialog"
      >
        <div className={`modal-dialog ${className}`} role="document">
          <div className={`modal-content ${contentClassName}`}>
            <div className={headerClasses}>
              <h3 className="modal-title">{title}</h3>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={onClose}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">{children}</div>
            {footer && <div className="modal-footer">{footer}</div>}
          </div>
        </div>
      </div>
    );
  }
}

Modal.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  show: PropTypes.bool,
  onClose: PropTypes.func,
  children: PropTypes.any,
  header: PropTypes.any,
  footer: PropTypes.any
};

export default Modal;
