import { createStore, applyMiddleware, compose } from "redux";
import createSagaMiddleware from "redux-saga";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { createBrowserHistory } from "history";
import { routerMiddleware } from "connected-react-router";
import createRootReducer from "../reducers";

import sagas from "../sagas";

const persistConfig = {
  key: "root",
  storage,
  blacklist: ["notification"]
};

export const history = createBrowserHistory();

const middlewares = [];

const persistedReducer = persistReducer(
  persistConfig,
  createRootReducer(history)
);

const sagaMiddleware = createSagaMiddleware();
middlewares.push(sagaMiddleware);

middlewares.push(routerMiddleware(history));

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

if (process.env.NODE_ENV === "development") {
  const { logger } = require("redux-logger");

  middlewares.push(logger);
}

export const store = createStore(
  persistedReducer,
  /* preloadedState, */ composeEnhancers(applyMiddleware(...middlewares))
);
window.store = store;

export const persistor = persistStore(store);

sagaMiddleware.run(sagas);
