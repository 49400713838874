import React, { useState } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import Api from "../../../helpers/Api";
import _ from "lodash";
import {
  FormGroup,
  Input,
  RoundButton,
  SuggestInput,
  Select,
  Error,
} from "../../../common";
import LabeledRadioButton from "../../../components/LabeledRadioButton";
import {
  fetchCourseTypes,
  clearCourseTypes,
} from "../../../actions";

const TrialForm = (props) => {
  const { t, dog, courseTypes, fetchingCourseTypes, submitTrialForm, form } = props;

  const [trialForm, setTrialForm] = useState({
    location: form.location,
    locationId: form.locationId,
    judge: form.judge,
    judgeId: form.judgeId,
    date: form.date,
    courseType: form.courseType,
    organizationId: form.organizationId,
    sizeClassId: form.sizeClassId,
    courseTypeId: form.courseTypeId,
  });
  
  const [errors, setErrors] = useState({});
  const [locationSuggestions, setLocationSuggestions] = useState([]);
  const [judgeSuggestions, setJudgeSuggestions] = useState([]);

  let showOrganizations = false;
  let showSizeClasses = false;
  let organizationId =
      trialForm && trialForm.organizationId ? trialForm.organizationId : "1";
  let courseTypeId =
      trialForm && trialForm.courseTypeId
        ? trialForm.courseTypeId
        : !fetchingCourseTypes && courseTypes.length > 0
          ? courseTypes[0].id
          : "";
  showOrganizations = dog.organizations.length > 1;

  showSizeClasses =
      dog.sizeClasses.filter(
        (sizeClass) =>
          parseInt(sizeClass.organizationId) === parseInt(organizationId)
      ).length > 1;
  let courseTypeOptions = courseTypes.map((ct) => ({
    title: t("trial." + (ct.name ? ct.name.toLowerCase() : "") + "Course"),
    value: ct.id + "",
  }));

  const handleInputChange = (e) => {
    let { name, value } = e.target;
    setTrialForm({ ...trialForm, [name]: value });
  };

  // Course type methods
  const handleOrganizationChange = (e) => {
    // eslint-disable-next-line no-unused-vars
    let { name, value } = e.target;
    let org = _.find(dog.organizations, { id: parseInt(value) });
    if (org) {
      props.clearCourseTypes();
      setTrialForm({ ...trialForm, sizeClassId: "", courseTypeId: "" });
      props.fetchCourseTypes(org.id);
    }
    handleInputChange(e);
  };

  // Location methods
  const handleLocationChange = (e, { newValue }) => {
    setTrialForm({ ...trialForm, location: newValue, locationId: null });
  };

  const setLocationId = () => {
    const { location } = trialForm;
    const suggestion = locationSuggestions.find((l) => l.name === location);

    if (suggestion)
      setTrialForm({ ...trialForm, locationId: suggestion.id });
  };

  const fetchLocations = (e) => {
    const { auth_token } = props;
    Api({ auth_token })
      .fetchLocations(e.value)
      .then((res) =>
        setLocationSuggestions(res.data || [])
      );
  };

  // Judge methods
  const handleJudgeChange = (e, { newValue }) => {
    setTrialForm({ ...trialForm, judge: newValue, judgeId: null });
  };

  const setJudgeId = () => {
    const { judge } = trialForm;
    const suggestion = judgeSuggestions.find((l) => l.name === judge);

    if (suggestion)
      setTrialForm({ ...trialForm, judgeId: suggestion.id });
  };

  const fetchJudges = (e) => {
    const { auth_token } = props;
    Api({ auth_token })
      .fetchJudges(e.value)
      .then((res) =>
        setJudgeSuggestions(res.data || [])
      );
  };

  // Submit form
  const validateAndSubmitTrialForm = () => {
    const isValid = validateTrialForm();
    if (!isValid) return false;

    submitTrialForm(trialForm);
  };

  const validateTrialForm = () => {
    const { date } = trialForm;
    let errors = {};

    if (!date || date === "Invalid date") errors.date = t("error.required");

    setErrors(errors);
    return _.isEmpty(errors);
  };

  return (
    <React.Fragment>
      <h2 className="text-center">{t("trial.trial")}</h2>

      {showOrganizations && (
        <FormGroup>
          <Select
            label={t("trial.organization")}
            name="organizationId"
            value={trialForm.organizationId + ""}
            onChange={handleOrganizationChange}
            options={dog.organizations.map((org) => ({
              title: org.name,
              value: org.id + "",
            }))}
          />
        </FormGroup>
      )}

      {showSizeClasses && (
        <FormGroup>
          <label>{t("trial.sizeClass")}</label>
          <div>
            {_(dog.sizeClasses)
              .filter(
                (s) => parseInt(s.organizationId) === parseInt(organizationId)
              )
              .sortBy((s) => s.id)
              .value()
              .map((sizeClass) => (
                <LabeledRadioButton
                  label={t(sizeClass.name)}
                  key={sizeClass.id}
                  id={sizeClass.id}
                  name="sizeClassId"
                  value={sizeClass.id + ""}
                  onChange={handleInputChange}
                  checked={
                    parseInt(trialForm.sizeClassId) === parseInt(sizeClass.id)
                  }
                  cssClass="checkbox-label"
                />
              ))}
          </div>
        </FormGroup>
      )}

      {!fetchingCourseTypes && (
        <React.Fragment>
          <FormGroup>
            <Select
              label={t("trial.courseType")}
              name="courseTypeId"
              value={courseTypeId.toString()}
              options={courseTypeOptions}
              onChange={handleInputChange}
            />
          </FormGroup>

          <FormGroup>
            <Input
              name="date"
              label={t("common.date")}
              type="date"
              value={trialForm.date}
              onChange={handleInputChange}
            />
            <Error show={!!errors.date}>{errors.date}</Error>
          </FormGroup>

          <FormGroup>
            <SuggestInput
              value={trialForm.location || ""}
              onChange={handleLocationChange}
              onBlur={setLocationId}
              suggestions={locationSuggestions}
              name="location"
              label={t("common.location")}
              onFetch={fetchLocations}
              onClear={() => setLocationSuggestions([])}
              suggestionValue={(suggestion) => suggestion.name}
              renderSuggestion={(suggestion) => <div>{suggestion.name}</div>}
            />
          </FormGroup>

          <FormGroup>
            <SuggestInput
              value={trialForm.judge || ""}
              onChange={handleJudgeChange}
              onBlur={setJudgeId}
              suggestions={judgeSuggestions}
              name="judge"
              label={t("trial.judge")}
              onFetch={fetchJudges}
              onClear={() => setJudgeSuggestions([])}
              suggestionValue={(suggestion) => suggestion.name}
              renderSuggestion={(suggestion) => <div>{suggestion.name}</div>}
            />
          </FormGroup>
        </React.Fragment>
      )}

      <RoundButton
        atCorner
        size="large"
        icon="continue"
        onClick={validateAndSubmitTrialForm}
      />
    </React.Fragment>
  );
};

TrialForm.propTypes = {
  t: PropTypes.func,
  auth_token: PropTypes.any,
  dog: PropTypes.object,
  courseTypes: PropTypes.array,
  fetchingCourseTypes: PropTypes.bool,
  fetchCourseTypes: PropTypes.func,
  clearCourseTypes: PropTypes.func,
  form: PropTypes.object,
  submitTrialForm: PropTypes.func
};

const mapStateToProps = (
  { activeDog, auth, courseTypes, }
) => {
  return {
    dog: activeDog,
    auth_token: auth.authToken,
    courseTypes: courseTypes.courseTypes,
    fetchingCourseTypes: courseTypes.fetchingCourseTypes,
  };
};

const TrialFormWithTranslate = withTranslation()(TrialForm);
export default connect(mapStateToProps, {
  fetchCourseTypes,
  clearCourseTypes,
})(TrialFormWithTranslate);