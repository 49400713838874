import React from "react";
import PropTypes from "prop-types";

const Medal = ({ placement, size }) => {
  let classes = ["medal"];
  if (placement === 1) classes.push("medal--gold");
  else if (placement === 2) classes.push("medal--silver");
  else if (placement === 3) classes.push("medal--bronze");
  if (size) classes.push(`medal--${size}`);

  return <div className={classes.join(" ")}>{placement}</div>;
};

Medal.propTypes = {
  placement: PropTypes.number,
  size: PropTypes.oneOf(["extrasmall", "small", "medium"])
};

Medal.defaultProps = {
  size: "medium"
};

export default Medal;
