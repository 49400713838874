import {
  FETCH_SIZE_CLASSES,
  CLEAR_SIZE_CLASSES,
  SET_ACTIVE_DOG
} from "../actions/types";

const INITIAL_STATE = {
  sizeClasses: [],
  fetchingSizeClasses: false,
  errorInSizeClasses: null
};

export default (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case FETCH_SIZE_CLASSES.SUCCEEDED:
      return {
        ...state,
        sizeClasses: [...state.sizeClasses, ...payload],
        fetchingSizeClasses: false
      };

    case FETCH_SIZE_CLASSES.REQUEST:
      return {
        ...state,
        ...payload,
        fetchingSizeClasses: true
      };

    case FETCH_SIZE_CLASSES.FAILED:
      return {
        ...state,
        errorInSizeClasses: payload,
        fetchingSizeClasses: false
      };

    case CLEAR_SIZE_CLASSES:
      let list = "";
      if (payload.organizationId && state.sizeClasses) {
        list = state.sizeClasses.filter(
          item => item.orgId !== payload.organizationId
        );
      }
      return {
        ...state,
        sizeClasses: list,
        fetchingSizeClasses: false
      };

    case SET_ACTIVE_DOG:
      return INITIAL_STATE;

    default:
      return state;
  }
};
