import {
  NOTE_SHARED_TOPIC,
  FETCH_SHARED_TOPICS,
  SHARE_TRAINING_TOPIC,
  UNSHARE_TRAINING_TOPIC,
  GET_SHARED_TOPIC,
  COPY_SHARED_TOPIC,
  FETCH_TRAINING_TOPICS,
  ADD_TRAINING_TOPIC,
  ADD_TRAINING_TOPIC_FORM,
  UPDATE_TRAINING_TOPIC,
  UPDATE_TRAINING_TOPIC_NONOTIFICATION,
  DELETE_TRAINING_TOPIC,
  COPY_TRAINING_TOPIC,
  ADD_TRAINING_SUBTOPIC,
  UPDATE_TRAINING_SUBTOPIC,
  DELETE_TRAINING_SUBTOPIC,
  ADD_TRAINING_EXERCISE,
  UPDATE_TRAINING_EXERCISE,
  UPDATE_TRAINING_EXERCISE_NONOTIFICATION,
  DELETE_TRAINING_EXERCISE,
  LOGOUT,
  AFTER_REHYDRATE
} from "../actions/types";

const EXAMPLETOPIC = {
  id: 6,
  name: "Shared topic not found",
  notes: "",
  trainingFocuses: [],
  trainingSubTopics: [],
  exercises: []
};

const INITIAL_STATE = {
  topics: [],
  sharedTopics: [],
  fetchingSharedTopics: false,
  fetching: false,
  saving: false,
  deleting: false,
  error: null,
  sharedTopicId: null,
  sharedTopic: EXAMPLETOPIC
};

export default (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
  // Shared topic
  case NOTE_SHARED_TOPIC.SUCCEEDED:
    return { ...state, sharedTopicId: payload };

    // Fetch all shared
  case FETCH_SHARED_TOPICS.REQUEST:
    return {
      ...state,
      fetchingSharedTopics: true,
      error: null,
      sharedTopics: []
    };
  case FETCH_SHARED_TOPICS.SUCCEEDED:
    return {
      ...state,
      fetchingSharedTopics: false,
      sharedTopics: [...state.sharedTopics, ...payload]
    };
  case FETCH_SHARED_TOPICS.FAILED:
    return { ...state, fetchingSharedTopics: false, error: payload };

  case SHARE_TRAINING_TOPIC.REQUEST:
    return { ...state, error: null };
  case SHARE_TRAINING_TOPIC.SUCCEEDED:
    return { ...state,
      sharedTopic: payload.sharedTopic };
  case SHARE_TRAINING_TOPIC.FAILED:
    return {
      ...state,
      error: payload,
      sharedTopic: EXAMPLETOPIC
    };

  case UNSHARE_TRAINING_TOPIC.REQUEST:
    return { ...state, error: null };
  case UNSHARE_TRAINING_TOPIC.SUCCEEDED:
    return { ...state,
      sharedTopic: EXAMPLETOPIC };
  case UNSHARE_TRAINING_TOPIC.FAILED:
    return {
      ...state,
      error: payload
    };

  case GET_SHARED_TOPIC.REQUEST:
    return { ...state, fetching: true, error: null };
  case GET_SHARED_TOPIC.SUCCEEDED:
    return { ...state, fetching: false, sharedTopic: payload };
  case GET_SHARED_TOPIC.FAILED:
    return {
      ...state,
      fetching: false,
      error: payload,
      sharedTopic: EXAMPLETOPIC
    };

    // (Addition into state not needed as topics are later fetched for each dog.)
  case COPY_SHARED_TOPIC.REQUEST:
    return { ...state, saving: true, error: null };
  case COPY_SHARED_TOPIC.SUCCEEDED:
    return { ...state, saving: false };
  case COPY_SHARED_TOPIC.FAILED:
    return { ...state, saving: false, error: payload };

    // Fetch
  case FETCH_TRAINING_TOPICS.REQUEST:
    return { ...state, fetching: true, error: null, topics: [] };
  case FETCH_TRAINING_TOPICS.SUCCEEDED:
    return { ...state, fetching: false, topics: payload };
  case FETCH_TRAINING_TOPICS.FAILED:
    return { ...state, fetching: false, error: payload };

    // Add form type
  case ADD_TRAINING_TOPIC_FORM.REQUEST:
    return { ...state, saving: true, error: null };
  case ADD_TRAINING_TOPIC_FORM.SUCCEEDED:
    return { ...state, saving: false, topics: [...state.topics, payload] };
  case ADD_TRAINING_TOPIC_FORM.FAILED:
    return { ...state, saving: false, error: payload };

    // Add
  case ADD_TRAINING_TOPIC.REQUEST:
    return { ...state, saving: true, error: null };
  case ADD_TRAINING_TOPIC.SUCCEEDED:
    return { ...state, saving: false, topics: [...state.topics, payload] };
  case ADD_TRAINING_TOPIC.FAILED:
    return { ...state, saving: false, error: payload };

    // Update
  case UPDATE_TRAINING_TOPIC.FAILED:
    return { ...state, saving: false };
  case UPDATE_TRAINING_TOPIC.REQUEST:
    return { ...state, saving: true };
  case UPDATE_TRAINING_TOPIC.SUCCEEDED:
    return {
      ...state,
      saving: false,
      topics: [
        ...state.topics.map(t => {
          return t.id === payload.id ? payload : t;
        })
      ]
    };

    // Update without notification
  case UPDATE_TRAINING_TOPIC_NONOTIFICATION.FAILED:
    return { ...state, saving: false };
  case UPDATE_TRAINING_TOPIC_NONOTIFICATION.REQUEST:
    return { ...state, saving: true };
  case UPDATE_TRAINING_TOPIC_NONOTIFICATION.SUCCEEDED:
    return {
      ...state,
      saving: false,
      topics: [
        ...state.topics.map(t => {
          return t.id === payload.id ? payload : t;
        })
      ]
    };

    // Delete
  case DELETE_TRAINING_TOPIC.REQUEST:
    return { ...state, deleting: true };
  case DELETE_TRAINING_TOPIC.SUCCEEDED:
    return {
      ...state,
      deleting: false,
      topics: state.topics.filter(t => t.id !== payload)
    };
  case DELETE_TRAINING_TOPIC.FAILED:
    return { ...state, deleting: false };

    // Copy
    // (Addition into state not needed as topics are fetched for each dog.)
  case COPY_TRAINING_TOPIC.REQUEST:
    return { ...state, saving: true, error: null };
  case COPY_TRAINING_TOPIC.SUCCEEDED:
    return { ...state, saving: false };
  case COPY_TRAINING_TOPIC.FAILED:
    return { ...state, saving: false, error: payload };

    // SubTopics
    // Add
  case ADD_TRAINING_SUBTOPIC.REQUEST:
    return { ...state, saving: true, error: null };
  case ADD_TRAINING_SUBTOPIC.SUCCEEDED:
    // Add new subtopic in state under specific topic
    return {
      ...state,
      saving: false,
      topics: [
        ...state.topics.map(t => {
          if (t.id === payload.trainingTopicId) {
            t.trainingSubTopics = [...t.trainingSubTopics, payload];
          }
          return t;
        })
      ]
    };
  case ADD_TRAINING_SUBTOPIC.FAILED:
    return { ...state, saving: false, error: payload };

    // Update
  case UPDATE_TRAINING_SUBTOPIC.FAILED:
    return { ...state, saving: false };
  case UPDATE_TRAINING_SUBTOPIC.REQUEST:
    return { ...state, saving: true };
  case UPDATE_TRAINING_SUBTOPIC.SUCCEEDED:
    // Update the training subtopic under specified topic.
    return {
      ...state,
      saving: false,
      topics: [
        ...state.topics.map(t => {
          if (t.id === payload.trainingTopicId) {
            t.trainingSubTopics = t.trainingSubTopics.map(st => {
              return st.id === payload.id ? payload : st;
            });
          }
          return t;
        })
      ]
    };

    // Delete
  case DELETE_TRAINING_SUBTOPIC.REQUEST:
    return { ...state, deleting: true };
  case DELETE_TRAINING_SUBTOPIC.SUCCEEDED:
    // Must filter out subtopic of specific topic. (payload.id & payload.trainingTopicId)
    return {
      ...state,
      deleting: false,
      topics: [
        ...state.topics.map(t => {
          if (t.id === payload.trainingTopicId) {
            t.trainingSubTopics = t.trainingSubTopics.filter(
              st => st.id !== payload.id
            );
          }
          return t;
        })
      ]
    };
  case DELETE_TRAINING_SUBTOPIC.FAILED:
    return { ...state, deleting: false };

    // Exercises
    // Add exercise
  case ADD_TRAINING_EXERCISE.REQUEST:
    return { ...state, saving: true, error: null };
  case ADD_TRAINING_EXERCISE.SUCCEEDED:
    // Add new exercise in state under specific topic or subtopic.
    // payload: { exercise, trainingTopicId, subTopicId }
    return {
      ...state,
      saving: false,
      topics: [
        ...state.topics.map(t => {
          // Checking for correct topic.
          if (t.id === payload.trainingTopicId) {
            if (payload.subTopicId === 0) {
              // Exercise belongs to topic.
              t.exercises = [...t.exercises, payload.exercise];
            } else {
              // Exercise belongs to subtopic.
              t.trainingSubTopics = t.trainingSubTopics.map(st => {
                if (st.id === payload.subTopicId) {
                  if (!st.exercises) st.exercises = [];
                  st.exercises = [...st.exercises, payload.exercise];
                }
                return st;
              });
            }
          }
          return t;
        })
      ]
    };
  case ADD_TRAINING_EXERCISE.FAILED:
    return { ...state, saving: false, error: payload };

    // Update exercise
  case UPDATE_TRAINING_EXERCISE.FAILED:
    return { ...state, saving: false };
  case UPDATE_TRAINING_EXERCISE.REQUEST:
    return { ...state, saving: true };
  case UPDATE_TRAINING_EXERCISE.SUCCEEDED:
    // Update the exercise under specified topic or subtopic.
    // payload: { exercise, trainingTopicId, subTopicId }
    return {
      ...state,
      saving: false,
      topics: [
        ...state.topics.map(t => {
          // Checking for correct topic.
          if (t.id === payload.trainingTopicId) {
            if (payload.subTopicId === 0) {
              // Exercise belongs to topic.
              t.exercises = t.exercises.map(ex => {
                return ex.id === payload.exercise.id ? payload.exercise : ex;
              });
            } else {
              // Exercise belongs to subtopic.
              t.trainingSubTopics = t.trainingSubTopics.map(st => {
                if (st.id === payload.subTopicId) {
                  if (!st.exercises) st.exercises = [];
                  st.exercises = st.exercises.map(ex => {
                    return ex.id === payload.exercise.id
                      ? payload.exercise
                      : ex;
                  });
                }
                return st;
              });
            }
          }
          return t;
        })
      ]
    };

    // Update exercise - no notification
  case UPDATE_TRAINING_EXERCISE_NONOTIFICATION.FAILED:
    return { ...state, saving: false };
  case UPDATE_TRAINING_EXERCISE_NONOTIFICATION.REQUEST:
    return { ...state, saving: true };
  case UPDATE_TRAINING_EXERCISE_NONOTIFICATION.SUCCEEDED:
    // Update the exercise under specified topic or subtopic.
    // payload: { exercise, trainingTopicId, subTopicId }
    return {
      ...state,
      saving: false,
      topics: [
        ...state.topics.map(t => {
          // Checking for correct topic.
          if (t.id === payload.trainingTopicId) {
            if (payload.subTopicId === 0) {
              // Exercise belongs to topic.
              t.exercises = t.exercises.map(ex => {
                return ex.id === payload.exercise.id ? payload.exercise : ex;
              });
            } else {
              // Exercise belongs to subtopic.
              t.trainingSubTopics = t.trainingSubTopics.map(st => {
                if (st.id === payload.subTopicId) {
                  if (!st.exercises) st.exercises = [];
                  st.exercises = st.exercises.map(ex => {
                    return ex.id === payload.exercise.id
                      ? payload.exercise
                      : ex;
                  });
                }
                return st;
              });
            }
          }
          return t;
        })
      ]
    };

    // Delete exercise
  case DELETE_TRAINING_EXERCISE.REQUEST:
    return { ...state, deleting: true };
  case DELETE_TRAINING_EXERCISE.SUCCEEDED:
    // Must filter out exercise of specific topic. (payload.id & payload.trainingTopicId & payload.subTopicId)
    return {
      ...state,
      deleting: false,
      topics: [
        ...state.topics.map(t => {
          // Checking for correct topic.
          if (t.id === payload.trainingTopicId) {
            if (payload.subTopicId === 0) {
              // Exercise belongs to topic.
              t.exercises = t.exercises.filter(ex => ex.id !== payload.id);
            } else {
              // Exercise belongs to subtopic. Check for correct subtopic and filter.
              t.trainingSubTopics = t.trainingSubTopics.map(st => {
                if (st.id === payload.subTopicId) {
                  if (!st.exercises) st.exercises = [];
                  st.exercises = st.exercises.filter(
                    ex => ex.id !== payload.id
                  );
                }
                return st;
              });
            }
          }
          return t;
        })
      ]
    };
  case DELETE_TRAINING_EXERCISE.FAILED:
    return { ...state, deleting: false };

  case LOGOUT.REQUEST:
    return { ...INITIAL_STATE };

  case AFTER_REHYDRATE:
    return { ...state, saving: false, fetching: false };

  default:
    return state;
  }
};
