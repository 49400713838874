import React from "react";
import PropTypes from "prop-types";

const Error = ({ children, show }) => {
  return show ? <div className="error">{children}</div> : null;
};

Error.propTypes = {
  children: PropTypes.any,
  show: PropTypes.bool.isRequired
};

export default Error;
