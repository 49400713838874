import React, { Component } from "react";
import PropTypes from "prop-types";

class Input extends Component {
  state = {
    wasValidated: false
  };

  render() {
    const { label, error, value, className, type, ...rest } = this.props;
    return (
      <div className={this.state.wasValidated ? "was-validated" : ""}>
        {label !== "none" && (
          <label className={className ? className : ""}>{label}</label>
        )}
        {type === "text" ? (
          <input
            className="form-control"
            //onBlur={() => this.setState({ wasValidated: true })}
            value={value === undefined || value === null ? "" : value}
            type={type}
            {...rest}
          />
        ) : (
          <input
            className="form-control"
            onBlur={() => this.setState({ wasValidated: true })}
            value={value === undefined || value === null ? "" : value}
            type={type}
            {...rest}
          />
        )}
        <div className="invalid-feedback">{error}</div>
      </div>
    );
  }
}

Input.propTypes = {
  label: PropTypes.any,
  type: PropTypes.string,
  name: PropTypes.string,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  error: PropTypes.string
};

export default Input;
