import { FETCH_COURSE_TYPES, CLEAR_COURSE_TYPES } from "./types";

export const fetchCourseTypes = (organizationId) => ({
  type: FETCH_COURSE_TYPES.REQUEST,
  payload: { organizationId: organizationId }
});

export const fetchCourseTypesSucceeded = courseTypes => ({
  type: FETCH_COURSE_TYPES.SUCCEEDED,
  payload: courseTypes
});

export const fetchCourseTypesFailed = error => ({
  type: FETCH_COURSE_TYPES.FAILED,
  payload: error
});

export const clearCourseTypes = () => ({
  type: CLEAR_COURSE_TYPES
});