import React, { Component } from "react";
import PropTypes from "prop-types";

class CheckboxConfirm extends Component {
  state = {
    wasValidated: false
  };

  render() {
    const { label, error, value, ...rest } = this.props;
    const className =
      "form-check" + (this.state.wasValidated ? " was-validated" : "");
    return (
      <div className={className}>
        <label>
          <input
            type="checkbox"
            className="form-check-input"
            onBlur={() => this.setState({ wasValidated: true })}
            checked={value}
            {...rest}
          />
          {label}
        </label>
        <div className="invalid-feedback">{error}</div>
      </div>
    );
  }
}

CheckboxConfirm.propTypes = {
  label: PropTypes.any,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.bool,
  onChange: PropTypes.func,
  error: PropTypes.string
};

export default CheckboxConfirm;
