import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import _ from "lodash";
import PropTypes from "prop-types";

import {
  FormGroup,
  Input,
  TextArea,
  Select,
  ImageUploader,
} from "../../common";

import LabeledSwitch from "../../components/LabeledSwitch";
import LabeledSelect from "../../components/LabeledSelect";
import confirm from "../../components/Confirm";

class DogForm extends Component {
  propTypes = {
    onChange: PropTypes.func,
    fetchSizeClasses: PropTypes.func,
    clearSizeClasses: PropTypes.func,
    updateDogTrialSizeClass: PropTypes.func,
    dog: PropTypes.object,
    t: PropTypes.func,
    organizations: PropTypes.array,
    sizeClasses: PropTypes.array,
    edit: PropTypes.bool,
  };
  handleInputChange = (e) => {
    const { name, value } = e.target;
    const { onChange, dog } = this.props;
    onChange({ ...dog, [name]: value });
  };

  handleImageUpload = (image) => {
    const { onChange, dog } = this.props;

    onChange({ ...dog, photoFile: image.file });
  };

  handleOrganizationChange = (checked, event, id) => {
    const {
      onChange,
      organizations,
      dog,
      fetchSizeClasses,
      clearSizeClasses,
    } = this.props;

    let dogOrganizations = dog.organizations;
    let dogSizeClasses = dog.sizeClasses;
    let org = _.find(organizations, { id: parseInt(id) });
    if (org) {
      if (checked) {
        dogOrganizations.push(org);
        fetchSizeClasses(org.id);
      } else {
        _.remove(dogOrganizations, { id: id });
        _.remove(
          dogSizeClasses,
          (sizeClass) => sizeClass.organizationId === id
        );
        clearSizeClasses(id);
      }
      onChange({
        ...dog,
        organizations: dogOrganizations,
        sizeClasses: dogSizeClasses,
      });
    }
  };

  handleSizeClassChange = (selectedOptions, option) => {
    const { onChange, sizeClasses, dog } = this.props;
    let orgSizes = _.find(sizeClasses, { orgId: option.name });
    //THIS SHOULD BE _.filter!!! (check others also)

    let dogSizeClasses = dog.sizeClasses;
    if (option.action === "clear") {
      _.remove(
        dogSizeClasses,
        (sizeClass) => sizeClass.organizationId === option.name
      );
    } else if (option.action === "select-option") {
      let sizeClass = _.find(orgSizes.data, {
        id: parseInt(option.option.value),
      });
      if (sizeClass) {
        dogSizeClasses.push(sizeClass);
      }
    } else {
      let sizeClass = _.find(orgSizes.data, {
        id: parseInt(option.removedValue.value),
      });
      if (sizeClass) {
        _.remove(dogSizeClasses, { id: option.removedValue.value });
      }
    }
    onChange({ ...dog, sizeClasses: dogSizeClasses });
  };

  setSizeClassToAllTrials = () => {
    const { dog, updateDogTrialSizeClass, t } = this.props;

    confirm(
      t("dog.sizeClassConfirm", {
        dogName: dog.name,
        organizationName: _.find(dog.organizations, {
          id: dog.sizeClasses[0].organizationId,
        }).name,
      }),
      {
        title: t("common.areyousure"),
        ok: t("dog.sizeClassOk"),
        cancel: t("common.cancel"),
      }
    ).then(
      () => {
        updateDogTrialSizeClass(dog);
      },
      () => {}
    );
  };

  render() {
    const {
      t,
      dog,
      organizations,
      fetchingOrganizations,
      sizeClasses,
      fetchingSizeClasses,
      user,
      edit,
    } = this.props;
    if (fetchingOrganizations || !organizations || organizations.length <= 0)
      return null;
    let renderSizeClasses = true;
    if (fetchingSizeClasses || sizeClasses.length <= 0)
      renderSizeClasses = false;

    return (
      <React.Fragment>
        <ImageUploader
          imageUrl={dog.photo}
          onUpload={this.handleImageUpload}
          useAvatar
        />

        <FormGroup>
          <Input
            label={t("dog.name")}
            type="text"
            name="name"
            placeholder={t("dog.name-placeholder")}
            onChange={this.handleInputChange}
            value={dog.name || ""}
          />
        </FormGroup>

        <FormGroup>
          <Input
            label={t("dog.officialName")}
            type="text"
            name="officialName"
            placeholder={t("dog.officialName-placeholder")}
            onChange={this.handleInputChange}
            value={dog.officialName || ""}
          />
        </FormGroup>

        <h2>{t("dog.additional")}</h2>

        <FormGroup>
          <Input
            label={t("dog.breed")}
            name="race"
            onChange={this.handleInputChange}
            value={dog.race || ""}
          />
        </FormGroup>

        <FormGroup>
          <Select
            label={t("dog.gender")}
            name="sex"
            value={dog.sex || ""}
            onChange={this.handleInputChange}
            options={this.getGenderSelectOptions()}
          />
        </FormGroup>

        <FormGroup>
          <Input
            label={t("dog.birthDay")}
            type="date"
            name="dayOfBirth"
            onChange={this.handleInputChange}
            value={dog.dayOfBirth}
          />
        </FormGroup>

        <FormGroup>
          <Input
            label={
              !user || !user.units
                ? t("dog.height")
                : t("dog.height") +
                  " (" +
                  t("common.unit-length-dog-" + user.units) +
                  ")"
            }
            type="number"
            name="height"
            onChange={this.handleInputChange}
            value={dog.height || ""}
          />
        </FormGroup>

        {
          <FormGroup>
            <label>{t("dog.organizations")}</label>
            <div className="column column--align-top column--direction-column">
              {organizations.map((organization) => (
                <div className="flex-item-small" key={organization.id}>
                  <LabeledSwitch
                    key={organization.id}
                    text={organization.name}
                    id={organization.id}
                    checked={dog.organizations.find(
                      (org) => org.id === organization.id
                    )}
                    onChange={this.handleOrganizationChange}
                  />
                </div>
              ))}
            </div>
          </FormGroup>
        }

        {renderSizeClasses && (
          <FormGroup>
            <label>{t("dog.sizeClasses")}</label>
            <div>
              {_.sortBy(
                sizeClasses.map((sizeClass) => ({
                  ...sizeClass,
                  orgOrder: organizations.find(
                    (org) => org.id === sizeClass.orgId
                  ).order,
                })),
                (s) => s.orgOrder
              ).map((sizeClass) => (
                <LabeledSelect
                  key={sizeClass.orgId}
                  label={
                    organizations.find((org) => org.id === sizeClass.orgId).name
                  }
                  id={sizeClass.orgId}
                  sizeClasses={sizeClass.data}
                  selectedSizeClasses={dog.sizeClasses}
                  onChange={this.handleSizeClassChange}
                  placeholder={t("common.choose")}
                />
              ))}
            </div>
          </FormGroup>
        )}
        {dog.sizeClasses.length === 1 && edit && (
          <React.Fragment>
            <button
              className="btn--link text-info"
              onClick={this.setSizeClassToAllTrials}
            >
              {t("dog.setSizeClass", {
                organizationName: _.find(dog.organizations, {
                  id: dog.sizeClasses[0].organizationId,
                }).name,
              })}
            </button>
            <br></br>
            <br></br>
          </React.Fragment>
        )}

        <FormGroup>
          <TextArea
            label={t("dog.description")}
            name="description"
            onChange={this.handleInputChange}
            value={dog.description}
          />
        </FormGroup>
      </React.Fragment>
    );
  }

  getGenderSelectOptions = () => {
    const { t } = this.props;

    return [
      {
        title: t("common.choose"),
      },
      {
        title: t("dog.female"),
        value: "bitch",
      },
      {
        title: t("dog.male"),
        value: "dog",
      },
    ];
  };

  getCompetitionClassSelectOptions = () => {
    const { t } = this.props;

    return [
      {
        title: t("common.choose"),
      },
      {
        title: t("dog.class.mini"),
        value: "mini",
      },
      {
        title: t("dog.class.med"),
        value: "med",
      },
      {
        title: t("dog.class.max"),
        value: "max",
      },
    ];
  };
}

export default withTranslation()(DogForm);
