import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";

import { AppContent } from "../common";
import image from "../images/running-dog.png";

import Api from "../helpers/Api";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    this.setState({ hasError: true });
    this.logError(error, info);
  }

  handleButtonClick() {
    this.props.history.push("/");
    this.setState({ hasError: false });
  }

  render() {
    const { t } = this.props;
    if (this.state.hasError) {
      return (
        <AppContent size="small">
          <div className="text-center">
            <h2>{t("errorBoundary.title")}</h2>
            <img src={image} alt={t("errorBoundary.image-alt")} />
            <p>{t("errorBoundary.content")}</p>
            <p>
              <button
                type="button"
                className="btn btn-primary"
                aria-label={t("errorBoundary.buttonLabel")}
                onClick={() => this.handleButtonClick()}
              >
                {t("errorBoundary.buttonLabel")}
              </button>
            </p>
          </div>
        </AppContent>
      );
    }

    return this.props.children;
  }

  logError(error, info) {
    let path = window.location.pathname;

    let browser = {
      "Browser CodeName": navigator.appCodeName,
      "Browser Name": navigator.appName,
      "Browser Version": navigator.appVersion,
      "Cookies Enabled": navigator.cookieEnabled,
      "Browser Language": navigator.language,
      "Browser Online": navigator.onLine,
      Platform: navigator.platform,
      "User-agent header": navigator.userAgent,
    };

    let errorToLog = {
      error: error,
      path: path,
      info: info,
      location: window.location.href,
      browser: browser,
    };

    if (window.store && window.store.getState()) {
      let currentState = window.store.getState();
      let user = currentState.user;
      let dog = currentState.activeDog;
      errorToLog = {
        error: error,
        path: path,
        info: JSON.stringify(info),
        location: window.location.href,
        browser: JSON.stringify(browser),
        user_id: user.user.id,
        dog_id: dog.id,
      };
    }

    const { auth_token } = this.props;
    Api({ auth_token }).postErrorLog(errorToLog);
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.any,
  auth_token: PropTypes.any,
  t: PropTypes.any,
};

const mapStateToProps = ({ auth }) => ({
  auth_token: auth.authToken,
});

const ErrorBoundaryWithTranslate = withTranslation()(ErrorBoundary);
const WithHistory = withRouter(ErrorBoundaryWithTranslate);
export default connect(mapStateToProps)(WithHistory);
