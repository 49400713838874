import React from "react";
import PropTypes from "prop-types";

const Loader = ({ inverted, size, name }) => {
  let classes = ["loader-bounce"];
  if (inverted) classes.push("loader-bounce--inverted");
  if (size) classes.push(`loader-bounce--${size}`);
  if (name) classes.push(`loader-bounce--${name}`);

  return (
    <div className={classes.join(" ")}>
      <div className="loader-bounce__ball loader-bounce__ball--1" />
      <div className="loader-bounce__ball loader-bounce__ball--2" />
      <div className="loader-bounce__ball loader-bounce__ball--3" />
    </div>
  );
};

Loader.propTypes = {
  inverted: PropTypes.bool,
  size: PropTypes.oneOf(["medium", "small"])
};

Loader.defaultProps = {
  size: "medium"
};

export default Loader;
