import { call, put, takeLatest, select } from "redux-saga/effects";

import {
  FETCH_TRAININGS,
  ADD_TRAINING,
  UPDATE_TRAINING,
  DELETE_TRAINING,
  FETCH_TRAININGS_FILTERED,
  FETCH_TRAININGS_PAGED,
  FETCH_TRAININGS_PAGES_COUNT,
  FETCH_TRAININGS_COUNT,
} from "../actions/types";
import {
  fetchTrainingsSucceeded,
  fetchTrainingsFailed,
  addTrainingSucceeded,
  addTrainingFailed,
  updateTrainingSucceeded,
  updateTrainingFailed,
  deleteTrainingSucceeded,
  deleteTrainingFailed,
  authExpired,
  fetchTrainingsFilteredSucceeded,
  fetchTrainingsFilteredFailed,
  fetchTrainingsPagedSucceeded,
  fetchTrainingsPagedFailed,
  fetchTrainingsPagesCountSucceeded,
  fetchTrainingsPagesCountFailed,
  fetchTrainingsCountSucceeded,
  fetchTrainingsCountFailed,
} from "../actions";
import Api from "../helpers/Api";
import { getAuthToken, getActiveDog } from "./selectors";

function* fetchTrainings({ action, payload }) {
  try {
    const auth_token = yield select(getAuthToken);
    const dog = yield select(getActiveDog);
    const trainings = yield call(Api({ auth_token }).fetchTrainings, { dog });
    yield put(fetchTrainingsSucceeded(trainings.data));
  } catch (e) {
    const { status, statusText } = e.response;
    if (status === 401) {
      yield put(authExpired());
    }
    yield put(fetchTrainingsFailed({ status, statusText }));
  }
}

function* fetchTrainingsFiltered({ action, payload }) {
  try {
    const auth_token = yield select(getAuthToken);
    const dog = yield select(getActiveDog);
    const trainings = yield call(Api({ auth_token }).fetchTrainingsFiltered, {
      dog,
      filters: payload.filters,
    });
    yield put(
      fetchTrainingsFilteredSucceeded({
        trainings: trainings.data,
      })
    );
  } catch (e) {
    const { status, statusText } = e.response;
    if (status === 401) {
      yield put(authExpired());
    }
    yield put(fetchTrainingsFilteredFailed({ status, statusText }));
  }
}

function* fetchTrainingsPaged({ action, payload }) {
  try {
    const auth_token = yield select(getAuthToken);
    const dog = yield select(getActiveDog);
    const trainings = yield call(Api({ auth_token }).fetchTrainingsPaged, {
      dog,
      trainingsPage: payload.trainingsPage,
      filters: payload.filters,
    });
    yield put(
      fetchTrainingsPagedSucceeded({
        trainings: trainings.data,
        trainingsPage: payload.trainingsPage,
      })
    );
  } catch (e) {
    const { status, statusText } = e.response;
    if (status === 401) {
      yield put(authExpired());
    }
    yield put(fetchTrainingsPagedFailed({ status, statusText }));
  }
}

function* fetchTrainingsPagesCount({ action, payload }) {
  try {
    const auth_token = yield select(getAuthToken);
    const dog = yield select(getActiveDog);
    const trainings = yield call(Api({ auth_token }).fetchTrainingsPagesCount, {
      dog,
      filters: payload.filters,
    });
    yield put(fetchTrainingsPagesCountSucceeded(trainings.data, payload));
  } catch (e) {
    const { status, statusText } = e.response;
    if (status === 401) {
      yield put(authExpired());
    }
    yield put(fetchTrainingsPagesCountFailed({ status, statusText }));
  }
}

function* fetchTrainingsCount({ action, payload }) {
  try {
    const auth_token = yield select(getAuthToken);
    const dog = yield select(getActiveDog);
    const trainings = yield call(Api({ auth_token }).fetchTrainingsCount, {
      dog,
      filters: payload.filters,
    });
    yield put(fetchTrainingsCountSucceeded(trainings.data, payload));
  } catch (e) {
    const { status, statusText } = e.response;
    if (status === 401) {
      yield put(authExpired());
    }
    yield put(fetchTrainingsCountFailed({ status, statusText }));
  }
}

function* addTraining({ action, payload }) {
  try {
    const auth_token = yield select(getAuthToken);
    const training = yield call(
      Api({
        auth_token,
        "content-type":
          "multipart/form-data; boundary=----WebKitFormBoundary7MA4YWxkTrZu0gW",
      }).addTraining,
      payload
    );
    yield put(addTrainingSucceeded(training.data));
  } catch (e) {
    yield put(addTrainingFailed(e.response.data.errors));
  }
}

function* updateTraining({ action, payload }) {
  try {
    const auth_token = yield select(getAuthToken);
    const response = yield call(
      Api({
        auth_token,
        "content-type":
          "multipart/form-data; boundary=----WebKitFormBoundary7MA4YWxkTrZu0gW",
      }).updateTraining,
      payload
    );
    yield put(updateTrainingSucceeded(response.data));
  } catch (e) {
    yield put(updateTrainingFailed(e.response.data.errors));
  }
}

function* deleteTraining({ action, payload }) {
  try {
    const auth_token = yield select(getAuthToken);
    yield call(Api({ auth_token }).deleteTraining, payload);
    yield put(deleteTrainingSucceeded(payload.id));
  } catch (e) {
    yield put(deleteTrainingFailed(e.response.data.errors));
  }
}

function* trainingSaga() {
  yield takeLatest(FETCH_TRAININGS.REQUEST, fetchTrainings);
  yield takeLatest(ADD_TRAINING.REQUEST, addTraining);
  yield takeLatest(UPDATE_TRAINING.REQUEST, updateTraining);
  yield takeLatest(DELETE_TRAINING.REQUEST, deleteTraining);
  yield takeLatest(FETCH_TRAININGS_FILTERED.REQUEST, fetchTrainingsFiltered);
  yield takeLatest(FETCH_TRAININGS_PAGED.REQUEST, fetchTrainingsPaged);
  yield takeLatest(
    FETCH_TRAININGS_PAGES_COUNT.REQUEST,
    fetchTrainingsPagesCount
  );
  yield takeLatest(FETCH_TRAININGS_COUNT.REQUEST, fetchTrainingsCount);
}

export default trainingSaga;
