import { FETCH_ORGANIZATIONS } from "./types";

export const fetchOrganizations = () => ({
  type: FETCH_ORGANIZATIONS.REQUEST,
});

export const fetchOrganizationsSucceeded = organizations => ({
  type: FETCH_ORGANIZATIONS.SUCCEEDED,
  payload: organizations
});

export const fetchOrganizationsFailed = error => ({
  type: FETCH_ORGANIZATIONS.FAILED,
  payload: error
});
