import React from "react";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { Icon } from "../common";
import { addToHomeDelay } from "../config/env";

import { isIos, isInStandaloneMode } from "../helpers/Utilities";

const Msg = ({ onClose, addToHome, addToHome2 }) => {
  return (
    <div className="arrow-box">
      <div className="content">
        <span>
          {addToHome}
          <Icon icon="upload" />
          {addToHome2}
        </span>
      </div>
      <div className="header">
        <button className="card-header__actions button" onClick={onClose}>
          x
        </button>
      </div>
    </div>
  );
};

Msg.propTypes = {
  onClose: PropTypes.func,
  addToHome: PropTypes.any,
  addToHome2: PropTypes.any,
};

class AddToHomescreen extends React.Component {
  state = {
    bannerVisible: true,
  };

  handleCloseBannerBtnClick() {
    this.setState({ bannerVisible: false });
    localStorage.setItem("additionAsked", JSON.stringify(new Date().getTime()));
  }

  render() {
    const { bannerVisible } = this.state;
    const { t } = this.props;
    let askedDate = localStorage.getItem("additionAsked");
    let now = new Date().getTime().toString();
    let displayAgain = true;
    if (askedDate) {
      displayAgain = false;
      if (now - askedDate > addToHomeDelay) {
        displayAgain = true;
      }
    }

    const shouldRender =
      bannerVisible && displayAgain && isIos() && !isInStandaloneMode();
    return shouldRender ? (
      <Msg
        onClose={() => {
          this.handleCloseBannerBtnClick();
        }}
        addToHome={t("notification.addToHome")}
        addToHome2={" " + t("notification.addToHome2")}
      />
    ) : null;
  }
}

AddToHomescreen.propTypes = {
  t: PropTypes.any,
};

export default withTranslation()(AddToHomescreen);
