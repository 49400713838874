import {
  FETCH_COURSE_TYPES,
  CLEAR_COURSE_TYPES
} from "../actions/types";

const INITIAL_STATE = {
  courseTypes: [],
  fetchingCourseTypes: false,
  errorInCourseTypes: null
};

export default (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case FETCH_COURSE_TYPES.SUCCEEDED:
      return {
        ...state,
        courseTypes: [...payload],
        fetchingCourseTypes: false
      };

    case FETCH_COURSE_TYPES.REQUEST:
      return {
        ...state,
        ...payload,
        fetchingCourseTypes: true
      };

    case FETCH_COURSE_TYPES.FAILED:
      return {
        ...state,
        errorInCourseTypes: payload,
        fetchingCourseTypes: false
      };

    case CLEAR_COURSE_TYPES:
      return {
        ...state,
        courseTypes: [],
        fetchingCourseTypes: false
      };

    default:
      return state;
  }
};
