import {
  FETCH_CURRENT_PLAN,
  FETCH_PLANS,
  SUBSCRIBE,
  RESET_PAYMENT_MODAL,
  UPDATE_CREDIT_CARD,
  CREATE_CUSTOMER,
  FETCH_PAYMENTS,
  SET_PAYMENT_MODAL_STATE,
  CREATE_CUSTOMER_PORTAL_SESSION,
  CLEAR_CUSTOMER_PORTAL_SESSION,
  LOGOUT,
} from "../actions/types";
import { PaymentModalStates, SubscriptionStatus } from "../helpers/Constants";

const INITIAL_STATE = {
  currentPlan: undefined,
  availablePlans: [],
  modalState: PaymentModalStates.PAYMENT_INFO,
  payments: [],
  error: {},
  customer: undefined,
  clientSecret: undefined,
  invoice: undefined,
  paymentMethod: undefined,
  portalUrl: undefined,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_CURRENT_PLAN.SUCCEEDED:
      return { ...state, currentPlan: action.payload };
    case FETCH_CURRENT_PLAN.FAILED:
      return { ...state, currentPlan: INITIAL_STATE.currentPlan };
    case FETCH_PLANS.SUCCEEDED:
      return { ...state, availablePlans: action.payload };
    case FETCH_PLANS.FAILED:
      return { ...state, availablePlans: INITIAL_STATE.availablePlans };
    case SUBSCRIBE.REQUEST:
      return {
        ...state,
        modalState: PaymentModalStates.PROCESSING,
        error: INITIAL_STATE.error,
      };
    case SUBSCRIBE.SUCCEEDED:
      return {
        ...state,
        modalState:
          action.payload.status === SubscriptionStatus.PAID_AND_RECURRING
            ? PaymentModalStates.SUCCESS
            : PaymentModalStates.REQUIRES_ACTION,
        clientSecret: action.payload.client_secret,
        invoice: action.payload.invoice,
        paymentMethod: action.payload.payment_method,
      };
    case SUBSCRIBE.FAILED:
      return {
        ...state,
        modalState: PaymentModalStates.FAILURE,
        error: action.payload,
      };
    case UPDATE_CREDIT_CARD.REQUEST:
      return {
        ...state,
        modalState: PaymentModalStates.PROCESSING,
        error: INITIAL_STATE.error,
      };
    case UPDATE_CREDIT_CARD.SUCCEEDED:
      return { ...state, modalState: PaymentModalStates.CARD_UPDATED };
    case UPDATE_CREDIT_CARD.FAILED:
      return {
        ...state,
        modalState: PaymentModalStates.CARD_UPDATE_FAILURE,
        error: action.payload,
      };

    case RESET_PAYMENT_MODAL:
      return { ...state, modalState: INITIAL_STATE.modalState };
    case FETCH_PAYMENTS.SUCCEEDED:
      return { ...state, payments: action.payload };
    case FETCH_PAYMENTS.FAILED:
      return { ...state, payments: INITIAL_STATE.payments };
    case SET_PAYMENT_MODAL_STATE:
      return { ...state, modalState: action.payload };

    case CREATE_CUSTOMER.REQUEST:
      return {
        ...state,
      };

    case CREATE_CUSTOMER.SUCCEEDED:
      return {
        ...state,
        customer: action.payload,
      };

    case CREATE_CUSTOMER.FAILED:
      return {
        ...state,
        error: action.payload,
      };

    case CREATE_CUSTOMER_PORTAL_SESSION.REQUEST:
      return {
        ...state,
      };

    case CREATE_CUSTOMER_PORTAL_SESSION.SUCCEEDED:
      return {
        ...state,
        portalUrl: action.payload.url,
      };

    case CREATE_CUSTOMER_PORTAL_SESSION.FAILED:
      return {
        ...state,
        error: action.payload,
      };

    case CLEAR_CUSTOMER_PORTAL_SESSION:
      return { ...state, portalUrl: undefined };

    // LOGOUT
    case LOGOUT.REQUEST:
      return { ...INITIAL_STATE };

    default:
      return state;
  }
};
