import { call, put, takeLatest, takeEvery, select } from "redux-saga/effects";

import {
  FETCH_SHARED_TOPICS,
  SHARE_TRAINING_TOPIC,
  UNSHARE_TRAINING_TOPIC,
  NOTE_SHARED_TOPIC,
  GET_SHARED_TOPIC,
  COPY_SHARED_TOPIC,
  ADD_TRAINING_TOPIC_FORM,
  ADD_TRAINING_TOPIC,
  FETCH_TRAINING_TOPICS,
  UPDATE_TRAINING_TOPIC,
  UPDATE_TRAINING_TOPIC_NONOTIFICATION,
  DELETE_TRAINING_TOPIC,
  COPY_TRAINING_TOPIC,
  ADD_TRAINING_SUBTOPIC,
  UPDATE_TRAINING_SUBTOPIC,
  DELETE_TRAINING_SUBTOPIC,
  ADD_TRAINING_EXERCISE,
  UPDATE_TRAINING_EXERCISE,
  DELETE_TRAINING_EXERCISE,
  UPDATE_TRAINING_EXERCISE_NONOTIFICATION
} from "../actions/types";
import Api from "../helpers/Api";
import { getAuthToken, getActiveDog } from "./selectors";
import {
  noteSharedTopicSucceeded,
  noteSharedTopicFailed,
  fetchSharedTopicsSucceeded,
  fetchSharedTopicsFailed,
  shareTopicSucceeded,
  shareTopicFailed,
  unShareTopicSucceeded,
  unShareTopicFailed,
  getSharedTopicSucceeded,
  getSharedTopicFailed,
  copySharedTopicSucceeded,
  copySharedTopicFailed,
  addTrainingTopicFormSucceeded,
  addTrainingTopicFormFailed,
  addTrainingTopicSucceeded,
  addTrainingTopicFailed,
  fetchTrainingTopicsSucceeded,
  fetchTrainingTopicsFailed,
  updateTrainingTopicSucceeded,
  updateTrainingTopicFailed,
  updateTrainingTopicNoNotificationSucceeded,
  updateTrainingTopicNoNotificationFailed,
  deleteTrainingTopicSucceeded,
  deleteTrainingTopicFailed,
  copyTrainingTopicSucceeded,
  copyTrainingTopicFailed,
  addTrainingSubTopicSucceeded,
  addTrainingSubTopicFailed,
  updateTrainingSubTopicSucceeded,
  updateTrainingSubTopicFailed,
  deleteTrainingSubTopicSucceeded,
  deleteTrainingSubTopicFailed,
  addTrainingExerciseSucceeded,
  addTrainingExerciseFailed,
  updateTrainingExerciseSucceeded,
  updateTrainingExerciseFailed,
  updateTrainingExerciseNoNotificationSucceeded,
  updateTrainingExerciseNoNotificationFailed,
  deleteTrainingExerciseSucceeded,
  deleteTrainingExerciseFailed
} from "../actions";

function* noteSharedTopic({ type, payload }) {
  try {
    yield put(noteSharedTopicSucceeded(payload));
  } catch (e) {
    yield put(noteSharedTopicFailed(payload));
  }
}
function* getSharedTopic({ type, payload }) {
  try {
    const auth_token = yield select(getAuthToken);
    const res = yield call(Api({ auth_token }).getSharedTopic, {
      topicId: payload.topicId
    });

    yield put(getSharedTopicSucceeded(res.data));
  } catch (e) {
    yield put(getSharedTopicFailed(e.message));
  }
}

function* fetchSharedTopics() {
  try {
    const auth_token = yield select(getAuthToken);
    const res = yield call(Api({ auth_token }).fetchSharedTopics, {});

    yield put(fetchSharedTopicsSucceeded(res.data));
  } catch (e) {
    yield put(fetchSharedTopicsFailed(e.message));
  }
}

function* shareTopic({ type, payload }) {
  try {
    const auth_token = yield select(getAuthToken);
    const res = yield call(Api({ auth_token }).shareTopic, {
      topicId: payload.topicId,
      dog: payload.dog
    });
    yield put(shareTopicSucceeded(res.data));
  } catch (e) {
    yield put(shareTopicFailed(e.message));
  }
}

function* unShareTopic({ type, payload }) {
  try {
    const auth_token = yield select(getAuthToken);
    const res = yield call(Api({ auth_token }).unShareTopic, {
      topicId: payload.topicId,
      dog: payload.dog
    });
    yield put(unShareTopicSucceeded(res.data));
  } catch (e) {
    yield put(unShareTopicFailed(e.message));
  }
}

function* copySharedTopic({ type, payload }) {
  try {
    const auth_token = yield select(getAuthToken);
    const res = yield call(Api({ auth_token }).copySharedTopic, {
      topicId: payload.topicId,
      dogId: payload.topicDogId, // from
      dogIdToCopy: payload.dogIdToCopy // to
    });

    yield put(copySharedTopicSucceeded(res.data));
  } catch (e) {
    yield put(copySharedTopicFailed(e.message));
  }
}

function* addTrainingTopic({ type, payload }) {
  try {
    const auth_token = yield select(getAuthToken);
    const dog = yield select(getActiveDog);
    const res = yield call(Api({ auth_token }).addTrainingTopic, {
      topic: payload,
      dogId: dog.id
    });

    yield put(addTrainingTopicSucceeded(res.data));
  } catch (e) {
    yield put(addTrainingTopicFailed(e.message));
  }
}

function* addTrainingTopicForm({ type, payload }) {
  try {
    const auth_token = yield select(getAuthToken);
    const dog = yield select(getActiveDog);
    const res = yield call(Api({ auth_token }).addTrainingTopicForm, {
      topic: payload,
      dogId: dog.id
    });

    yield put(addTrainingTopicFormSucceeded(res.data));
  } catch (e) {
    yield put(addTrainingTopicFormFailed(e.message));
  }
}

function* fetchTrainingTopics({ type, payload }) {
  try {
    const auth_token = yield select(getAuthToken);
    const dog = yield select(getActiveDog);
    const res = yield call(Api({ auth_token }).fetchTrainingTopics, {
      dogId: dog.id
    });

    yield put(fetchTrainingTopicsSucceeded(res.data));
  } catch (e) {
    yield put(fetchTrainingTopicsFailed(e.message));
  }
}

function* updateTrainingTopic({ type, payload }) {
  try {
    const auth_token = yield select(getAuthToken);
    const dog = yield select(getActiveDog);
    const res = yield call(Api({ auth_token }).updateTrainingTopic, {
      dogId: dog.id,
      topic: payload
    });

    yield put(updateTrainingTopicSucceeded(res.data));
  } catch (e) {
    yield put(updateTrainingTopicFailed(e.message));
  }
}

function* updateTrainingTopicNoNoNotification({ type, payload }) {
  try {
    const auth_token = yield select(getAuthToken);
    const dog = yield select(getActiveDog);
    const res = yield call(Api({ auth_token }).updateTrainingTopic, {
      dogId: dog.id,
      topic: payload
    });

    yield put(updateTrainingTopicNoNotificationSucceeded(res.data));
  } catch (e) {
    yield put(updateTrainingTopicNoNotificationFailed(e.message));
  }
}

function* deleteTrainingTopic({ type, payload }) {
  try {
    const auth_token = yield select(getAuthToken);
    const dog = yield select(getActiveDog);
    yield call(Api({ auth_token }).deleteTrainingTopic, {
      dogId: dog.id,
      topicId: payload
    });

    yield put(deleteTrainingTopicSucceeded(payload));
  } catch (e) {
    yield put(deleteTrainingTopicFailed(e.message));
  }
}

function* copyTrainingTopic({ type, payload }) {
  try {
    const auth_token = yield select(getAuthToken);
    const dog = yield select(getActiveDog);
    const res = yield call(Api({ auth_token }).copyTrainingTopic, {
      topicId: payload.topicId,
      dogId: dog.id,
      dogIdToCopy: payload.dogIdToCopy
    });

    yield put(copyTrainingTopicSucceeded(res.data));
  } catch (e) {
    yield put(copyTrainingTopicFailed(e.message));
  }
}

// SubTopics
function* addTrainingSubTopic({ type, payload }) {
  try {
    const auth_token = yield select(getAuthToken);
    const dog = yield select(getActiveDog);
    const res = yield call(Api({ auth_token }).addTrainingSubTopic, {
      subTopic: payload,
      topicId: payload.trainingTopicId,
      dogId: dog.id
    });

    yield put(addTrainingSubTopicSucceeded(res.data));
  } catch (e) {
    yield put(addTrainingSubTopicFailed(e.message));
  }
}

function* updateTrainingSubTopic({ type, payload }) {
  try {
    const auth_token = yield select(getAuthToken);
    const dog = yield select(getActiveDog);
    const res = yield call(Api({ auth_token }).updateTrainingSubTopic, {
      dogId: dog.id,
      topicId: payload.trainingTopicId, // subTopic Also has "training_topic_id": 253,
      subTopic: payload
    });

    yield put(updateTrainingSubTopicSucceeded(res.data));
  } catch (e) {
    yield put(updateTrainingSubTopicFailed(e.message));
  }
}

function* deleteTrainingSubTopic({ type, payload }) {
  try {
    const auth_token = yield select(getAuthToken);
    const dog = yield select(getActiveDog);
    yield call(Api({ auth_token }).deleteTrainingSubTopic, {
      // Topic and subTopic ID needed.
      dogId: dog.id,
      topicId: payload.trainingTopicId,
      subTopicId: payload.id
    });

    yield put(deleteTrainingSubTopicSucceeded(payload));
  } catch (e) {
    yield put(deleteTrainingSubTopicFailed(e.message));
  }
}

// Exercises
function* addTrainingExercise({ type, payload }) {
  try {
    const auth_token = yield select(getAuthToken);
    const dog = yield select(getActiveDog);
    const res = yield call(Api({ auth_token }).addTrainingExercise, {
      dogId: dog.id,
      topicId: payload.trainingTopicId,
      subTopicId: payload.subTopicId,
      exercise: payload
    });

    yield put(
      addTrainingExerciseSucceeded(
        res.data,
        payload.trainingTopicId,
        payload.subTopicId
      )
    );
  } catch (e) {
    yield put(addTrainingExerciseFailed(e.message));
  }
}

function* updateTrainingExercise({ type, payload }) {
  try {
    const auth_token = yield select(getAuthToken);
    const dog = yield select(getActiveDog);
    const res = yield call(Api({ auth_token }).updateTrainingExercise, {
      dogId: dog.id,
      topicId: payload.trainingTopicId,
      subTopicId: payload.subTopicId,
      exercise: payload
    });

    yield put(
      updateTrainingExerciseSucceeded(
        res.data,
        payload.trainingTopicId,
        payload.subTopicId
      )
    );
  } catch (e) {
    yield put(updateTrainingExerciseFailed(e.message));
  }
}

function* updateTrainingExerciseNoNotification({ type, payload }) {
  try {
    const auth_token = yield select(getAuthToken);
    const dog = yield select(getActiveDog);
    const res = yield call(Api({ auth_token }).updateTrainingExercise, {
      dogId: dog.id,
      topicId: payload.trainingTopicId,
      subTopicId: payload.subTopicId,
      exercise: payload
    });

    yield put(
      updateTrainingExerciseNoNotificationSucceeded(
        res.data,
        payload.trainingTopicId,
        payload.subTopicId
      )
    );
  } catch (e) {
    yield put(updateTrainingExerciseNoNotificationFailed(e.message));
  }
}

function* deleteTrainingExercise({ type, payload }) {
  try {
    const auth_token = yield select(getAuthToken);
    const dog = yield select(getActiveDog);
    yield call(Api({ auth_token }).deleteTrainingExercise, {
      // Topic and subTopic ID needed.
      dogId: dog.id,
      topicId: payload.trainingTopicId,
      subTopicId: payload.subTopicId,
      exerciseId: payload.id
    });

    yield put(deleteTrainingExerciseSucceeded(payload));
  } catch (e) {
    yield put(deleteTrainingExerciseFailed(e.message));
  }
}

function* topicSaga() {
  yield takeLatest(NOTE_SHARED_TOPIC.REQUEST, noteSharedTopic);
  yield takeLatest(FETCH_SHARED_TOPICS.REQUEST, fetchSharedTopics);
  yield takeLatest(SHARE_TRAINING_TOPIC.REQUEST, shareTopic);
  yield takeLatest(UNSHARE_TRAINING_TOPIC.REQUEST, unShareTopic);
  yield takeLatest(GET_SHARED_TOPIC.REQUEST, getSharedTopic);
  yield takeLatest(COPY_SHARED_TOPIC.REQUEST, copySharedTopic);
  yield takeLatest(ADD_TRAINING_TOPIC.REQUEST, addTrainingTopic);
  yield takeLatest(ADD_TRAINING_TOPIC_FORM.REQUEST, addTrainingTopicForm);
  yield takeLatest(FETCH_TRAINING_TOPICS.REQUEST, fetchTrainingTopics);
  yield takeEvery(UPDATE_TRAINING_TOPIC.REQUEST, updateTrainingTopic);
  yield takeEvery(
    UPDATE_TRAINING_TOPIC_NONOTIFICATION.REQUEST,
    updateTrainingTopicNoNoNotification
  );
  yield takeLatest(DELETE_TRAINING_TOPIC.REQUEST, deleteTrainingTopic);
  yield takeLatest(COPY_TRAINING_TOPIC.REQUEST, copyTrainingTopic);
  yield takeLatest(ADD_TRAINING_SUBTOPIC.REQUEST, addTrainingSubTopic);
  yield takeEvery(UPDATE_TRAINING_SUBTOPIC.REQUEST, updateTrainingSubTopic);
  yield takeLatest(DELETE_TRAINING_SUBTOPIC.REQUEST, deleteTrainingSubTopic);
  yield takeLatest(ADD_TRAINING_EXERCISE.REQUEST, addTrainingExercise);
  yield takeEvery(UPDATE_TRAINING_EXERCISE.REQUEST, updateTrainingExercise);
  yield takeEvery(
    UPDATE_TRAINING_EXERCISE_NONOTIFICATION.REQUEST,
    updateTrainingExerciseNoNotification
  );
  yield takeLatest(DELETE_TRAINING_EXERCISE.REQUEST, deleteTrainingExercise);
}

export default topicSaga;
