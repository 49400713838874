import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import _ from "lodash";
import confirm from "../../components/Confirm";
import { Redirect } from "react-router-dom";
import {
  addTrainingExercise,
  updateTrainingExercise,
  deleteTrainingExercise,
} from "../../actions";

import {
  AppContent,
  FormGroup,
  Input,
  TextArea,
  RoundButton,
  Icon,
  ImageUploader,
} from "../../common";

class ExerciseEdit extends Component {
  constructor(props) {
    super(props);

    this.state = {
      submitted: false,
      errors: {},
      editName: false,
      edit: false,
      videoPlayed: 0,
      videoLoaded: 0,
      form: {
        name: "",
        notes: "",
        videoUrl: "",
        courseMap: "",
        orderNumber: 100,
      },
    };
  }

  componentDidMount() {
    console.log(this.props.match.params);
    const { id } = this.props.match.params;
    const { t } = this.props;

    // If there is an id then we are editing an existing exercise, if not then it is a new one.
    if (id) {
      this.setState({ form: { ...this.props.exercise }, edit: true });
    } else {
      this.setState({
        form: {
          ...this.state.form,
          name: t("topics.exercise") + " " + this.props.number,
          orderNumber: this.props.number,
        },
        editName: true,
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { submitted, edit } = this.state;
    const { saving, error, history } = this.props;

    const justAdded =
        prevProps.saving === true && saving === false && !error && !edit;
    const justUpdated = !error && submitted !== prevState.submitted && edit;

    if (justUpdated) {
    // Quick and dirty. React Player bugs when changing video on fly
      setTimeout(
        () => history.goBack(),
        1000
      );
    }
    if (justAdded) history.goBack();
  }

  render() {
    const { editName, edit } = this.state;
    const { name, notes, videoUrl, courseMap } = this.state.form;

    const { t, saving, exercise } = this.props;

    if (!this.props.topic ) return <Redirect to="/training-topics" />;
    if (!exercise && edit) { // deleted
      const { topicid, subtopicid } = this.props.match.params;
      if (!subtopicid) {
        return <Redirect to={`/training-topic/${topicid}`} />;
      } else {
        return <Redirect to={`/training-topic/${topicid}/${subtopicid}`} />;
      }
    }

    let label = this.props.topic.name + " | ";
    if (this.props.subtopic) {
      label = label + this.props.subtopic.name + " | ";
    }

    return (
      <AppContent size="medium">
        <button className="trial-back-button" onClick={() => this.props.history.goBack()}>
          <strong>&larr;</strong> {t("common.back")}
        </button>
        <p className="py-1" />
        <React.Fragment>
          {edit ? (
            <h1 className="text-center">
              {t("common.edit")} {t("topics.exercise")}
            </h1>
          ) : (
            <h1 className="text-center">
              {t("topics.addNewButton")} {t("topics.exercise")}
            </h1>
          )}
          {editName ? (
            <React.Fragment>
              <h2>
                <FormGroup className="form-inline justify-content-center align-items-end">
                  <Input
                    type="text"
                    name="name"
                    placeholder={t("topics.addNew-placeholder")}
                    onChange={this.handleInputChange}
                    value={name}
                    label={label}
                    className="d-inline-block"
                  />
                </FormGroup>
              </h2>
            </React.Fragment>
          ) : (
            <h2 className="text-center">
              {label} {this.state.form.name}
              <button
                className="todo-list__delete btn--link text-danger"
                onClick={() => this.setState({ editName: !this.state.editName })}
              >
                <Icon icon="editGrey" />
              </button>
            </h2>
          )}
          <p />

          <FormGroup>
            <TextArea
              label={t("training.notes")}
              type="text"
              name="notes"
              value={notes}
              onChange={this.handleInputChange}
            />
          </FormGroup>

          <FormGroup>
            <ImageUploader
              buttonLabel={t("training.courseMapButton")}
              imageUrl={courseMap}
              onUpload={this.handleCourseMapUpload}
            />
          </FormGroup>

          <FormGroup>
            <Input
              label={t("training.videoUrl")}
              type="url"
              name="videoUrl"
              value={videoUrl}
              onChange={this.handleInputChange}
            />
          </FormGroup>

          <FormGroup className="column">
            <button
              className="btn btn-link"
              onClick={this.handleDeleteExercise}
            >
              {t("common.delete")}
            </button>
          </FormGroup>

          <RoundButton
            atCorner
            size="large"
            icon={saving ? "loader" : "complete"}
            onClick={this.handleSubmit}
          />
        </React.Fragment>
      </AppContent>
    );
  }

  handleSubmit = () => {
    const { addTrainingExercise, updateTrainingExercise } = this.props;
    const { edit } = this.state;

    const trainingTopicId = this.props.topic.id;
    let subTopicId = 0;
    if (this.props.subtopic) subTopicId = this.props.subtopic.id;

    const isValid = this.validate();
    if (!isValid) return false;

    const exercise = { ...this.state.form, trainingTopicId, subTopicId };

    if (edit) {
      const id = this.state.form.id;
      updateTrainingExercise({
        ...exercise,
        id,
      });
    } else {
      addTrainingExercise(exercise);
    }

    this.setState({ submitted: true });
  };

  handleDeleteExercise = () => {
    const { deleteTrainingExercise, t } = this.props;
    const { edit } = this.state;
    const { name, notes, videoUrl, courseMapFile } = this.state.form;

    const trainingTopicId = this.props.topic.id;
    let subTopicId = 0;
    if (this.props.subtopic) subTopicId = this.props.subtopic.id;

    const exercise = {
      name,
      notes,
      videoUrl,
      courseMapFile,
      trainingTopicId,
      subTopicId,
    };
    confirm(t("dog.deleteDogConfirmTitle"), {
      title: t("common.delete") + " " + t("topics.exercise") + "?",
      ok: t("common.delete"),
      cancel: t("common.cancel"),
    }).then(
      () => {
        if (edit) {
          const id = this.state.form.id;
          deleteTrainingExercise({
            ...exercise,
            id,
          });
        } else { // Not saved at all.
          this.props.history.goBack();
        }
      },
      () => {}
    );
  };

  handleInputChange = (e) => {
    const { name, value } = e.target;
    this.setState((prevState) => ({
      form: { ...prevState.form, [name]: value },
    }));
  };

  handleCourseMapUpload = (image) => {
    this.setState({
      form: {
        ...this.state.form,
        courseMap: image.imagePreviewUrl,
        courseMapFile: image.file,
      },
    });
  };

  validate() {
    //const { t } = this.props;
    let errors = {};

    // TODO: Add in whatever validation needed.

    this.setState({ errors });
    return _.isEmpty(errors);
  }
}

ExerciseEdit.propTypes = {
  t: PropTypes.func,
  history: PropTypes.object,
  auth_token: PropTypes.any,
  topic: PropTypes.object,
  subtopic: PropTypes.object,
  exercise: PropTypes.object,
  number: PropTypes.number,
  saving: PropTypes.bool,
  deleting: PropTypes.bool,
  addTrainingExercise: PropTypes.func,
  updateTrainingExercise: PropTypes.func,
  deleteTrainingExercise: PropTypes.func,
  hasActivePlan: PropTypes.bool
};

const mapStateToProps = ({ activeDog, topics, auth }, props) => {
  // Have to find the exercise if it exists from either the topic or the subtopic.
  const { topicid, subtopicid, id } = props.match.params;
  let topic = topics.topics.find((t) => t.id === Number(topicid));
  let subtopic = null;
  let exercise = null;
  let number = 1;
  if (topic && subtopicid) {
    // Exercise belongs to subtopic.
    subtopic = topic.trainingSubTopics.find(
      (st) => st.id === Number(subtopicid)
    );
    if (subtopic.exercises) {
      exercise = subtopic.exercises.find((ex) => ex.id === Number(id));
      number = subtopic.exercises.length + 1;
    }
  } else if (topic && !subtopicid) {
    // Exercise belongs to topic.
    if (topic && topic.exercises) {
      exercise = topic.exercises.find((ex) => ex.id === Number(id));
      number = topic.exercises.length + 1;
    }
  }

  return {
    dog: activeDog,
    topic,
    subtopic,
    exercise,
    number,
    deleting: topics.deleting,
    saving: topics.saving,
    error: topics.error,
    auth_token: auth.authToken,
  };
};

const ExerciseEditWithTranslate = withTranslation()(ExerciseEdit);
export default connect(mapStateToProps, {
  addTrainingExercise,
  updateTrainingExercise,
  deleteTrainingExercise,
})(ExerciseEditWithTranslate);
