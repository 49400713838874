import {
  FETCH_TRAININGS,
  ADD_TRAINING,
  UPDATE_TRAINING,
  DELETE_TRAINING,
  FETCH_TRAININGS_FILTERED,
  FETCH_TRAININGS_PAGED,
  FETCH_TRAININGS_PAGES_COUNT,
  FETCH_TRAININGS_COUNT,
} from "./types";

export const fetchTrainings = () => ({
  type: FETCH_TRAININGS.REQUEST,
});

export const fetchTrainingsSucceeded = (trainings) => ({
  type: FETCH_TRAININGS.SUCCEEDED,
  payload: trainings,
});

export const fetchTrainingsFailed = (error) => ({
  type: FETCH_TRAININGS.FAILED,
  payload: error,
});

export const fetchTrainingsFiltered = (filters) => ({
  type: FETCH_TRAININGS_FILTERED.REQUEST,
  payload: { filters: filters },
});

export const fetchTrainingsFilteredSucceeded = (trainings) => ({
  type: FETCH_TRAININGS_FILTERED.SUCCEEDED,
  payload: trainings,
});

export const fetchTrainingsFilteredFailed = (error) => ({
  type: FETCH_TRAININGS_FILTERED.FAILED,
  payload: error,
});

export const fetchTrainingsPaged = (trainingsPage, filters) => ({
  type: FETCH_TRAININGS_PAGED.REQUEST,
  payload: { trainingsPage: trainingsPage, filters: filters },
});

export const fetchTrainingsPagedSucceeded = (trainings, trainingsPage) => ({
  type: FETCH_TRAININGS_PAGED.SUCCEEDED,
  payload: trainings,
  trainingsPage,
});

export const fetchTrainingsPagedFailed = (error) => ({
  type: FETCH_TRAININGS_PAGED.FAILED,
  payload: error,
});

export const fetchTrainingsPagesCount = (filters) => ({
  type: FETCH_TRAININGS_PAGES_COUNT.REQUEST,
  payload: { filters: filters },
});

export const fetchTrainingsPagesCountSucceeded = (trainings) => ({
  type: FETCH_TRAININGS_PAGES_COUNT.SUCCEEDED,
  payload: trainings,
});

export const fetchTrainingsPagesCountFailed = (error) => ({
  type: FETCH_TRAININGS_PAGES_COUNT.FAILED,
  payload: error,
});

export const fetchTrainingsCount = (filters) => ({
  type: FETCH_TRAININGS_COUNT.REQUEST,
  payload: { filters: filters },
});

export const fetchTrainingsCountSucceeded = (count) => ({
  type: FETCH_TRAININGS_COUNT.SUCCEEDED,
  payload: count,
});

export const fetchTrainingsCountFailed = (error) => ({
  type: FETCH_TRAININGS_COUNT.FAILED,
  payload: error,
});

export const addTraining = ({ training, dog }) => ({
  type: ADD_TRAINING.REQUEST,
  payload: { training, dog },
});

export const addTrainingSucceeded = (training) => ({
  type: ADD_TRAINING.SUCCEEDED,
  payload: training,
});

export const addTrainingFailed = (error) => ({
  type: ADD_TRAINING.FAILED,
  payload: error,
});

export const updateTraining = ({ training, dog, id }) => ({
  type: UPDATE_TRAINING.REQUEST,
  payload: { training, dog, id },
});

export const updateTrainingSucceeded = (training) => ({
  type: UPDATE_TRAINING.SUCCEEDED,
  payload: training,
});

export const updateTrainingFailed = (error) => ({
  type: UPDATE_TRAINING.FAILED,
  payload: error,
});

export const deleteTraining = ({ dog, id }) => ({
  type: DELETE_TRAINING.REQUEST,
  payload: { dog, id },
});

export const deleteTrainingSucceeded = (id) => ({
  type: DELETE_TRAINING.SUCCEEDED,
  payload: id,
});

export const deleteTrainingFailed = (error) => ({
  type: DELETE_TRAINING.FAILED,
  payload: error,
});
