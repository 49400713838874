import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import queryString from "query-string";
import _ from "lodash";
import moment from "moment";

import { AppContent, Container, Loader, RoundButton } from "../../common";
import Chart from "../../components/Chart";
import TrainingCard from "../../components/TrainingCard";
import {
  fetchTrainingsPaged,
  fetchTrainingsPagesCount,
  fetchTrainingFocusStats,
  fetchTrainingsCount,
} from "../../actions";
import PaywallNotification from "../../containers/App/PaywallNotification";
import { Premium } from "../../helpers/Constants";
import PropTypes from "prop-types";
import TrainingFilter from "../../components/TrainingFilter";

class TrainingDiaryList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filters: {
        datesFilter: null,
        coachesFilter: [],
        trainingTypeFilter: "",
      },
      loadFeed: true,
    };
    this.initialState = this.state;
  }

  goToAddTrainingPage = () => {
    const { push } = this.props.history;
    push("/training-diary/add");
  };

  componentDidMount() {
    this.parseFilters();
    window.addEventListener("scroll", this.loadTrainingsOnScroll.bind(this));
  }

  componentDidUpdate(prevProps, prevState) {
    let currentState = this.state;
    if (
      !_.isEqual(prevState.loadFeed, currentState.loadFeed) &&
      currentState.loadFeed
    ) {
      this.updateFeed(true);
    }
    _.forOwn(prevState.filters, (value, key) => {
      if (!_.isEqual(value, currentState.filters[key])) {
        this.updateTrainingsCount();
      }
    });
  }

  updateFeed = (reset) => {
    this.props.fetchTrainingsPagesCount(this.state.filters);
    this.props.fetchTrainingsCount(this.state.filters);
    this.props.fetchTrainingFocusStats({ filters: this.state.filters });
    if (reset) {
      this.props.fetchTrainingsPaged(1, this.state.filters);
    } else {
      this.props.fetchTrainingsPaged(
        this.props.trainingsPage + 1,
        this.state.filters
      );
    }
  };

  updateTrainingsCount = () => {
    this.props.fetchTrainingsCount(this.state.filters);
  };

  loadTrainingsOnScroll = () => {
    //If all the content loaded
    if (this.props.trainingsPage > this.props.trainingsPagesCount) return;

    //Get div at the bottom of the content
    let elements = document.getElementsByClassName(
      "loader-bounce--training-diary"
    );

    if (elements && elements.length > 0) {
      var element = elements[0];
    }
    if (element) {
      var rect = element.getBoundingClientRect();

      var isAtEnd =
        rect.bottom <=
          (window.innerHeight ||
            document.documentElement.clientHeight) /*or $(window).height() */ &&
        rect.right <=
          (window.innerWidth ||
            document.documentElement.clientWidth); /*or $(window).width() */
    }
    //User at the end of content. load more content
    if (isAtEnd) {
      //If content list is still loading do not request for another content list.
      if (this.props.fetchingTrainingsPaged) return;
      this.updateFeed(false);
    }
  };

  componentWillUnmount() {
    window.removeEventListener("scroll", this.loadTrainingsOnScroll);
  }

  openCard = (id) => {
    this.props.history.push(`/training-diary/${id}`);
  };

  getFilterString() {
    return JSON.stringify(this.state.filters);
  }

  parseFilters() {
    let isFilter = queryString.parse(this.props.location.search);

    if (_.isEmpty(isFilter)) {
      this.setState(this.initialState);
      localStorage.removeItem("trainingFilters");
      this.updateFeed(true);
    } else {
      let storedFilters = JSON.parse(localStorage.getItem("trainingFilters"));
      if (storedFilters) {
        this.setState((prevState) => ({
          filters: {
            ...prevState.filters,
            ...storedFilters,
          },
        }));
      }
    }
  }

  render() {
    const {
      trainings,
      fetchingTrainingsPaged,
      activeDog,
      t,
      trainingsPage,
      trainingsPagesCount,
      hasActivePlan,
      statistics,
      trainingsCount,
      fetchingTrainingsCount,
    } = this.props;

    let loading = fetchingTrainingsPaged || statistics.fetchingTrainingFocuses;

    return (
      <AppContent size="medium" noMargin className="container-top-padding">
        {!fetchingTrainingsPaged && trainings.length > 0 && (
          <Container className="trial-tab-container">
            <TrainingFilter
              coachesFilter={this.state.filters.coachesFilter}
              datesFilter={this.state.filters.datesFilter}
              trainingTypeFilter={this.state.filters.trainingTypeFilter}
              updateCoachesFilter={this.setCoachesFilter}
              updateDateFilter={this.setDateFilter}
              updateTrainingTypeFilter={this.setTrainingTypeFilter}
              trainingsCount={trainingsCount}
              applyFilters={this.applyFilters}
              cancelFilters={this.cancelFilters}
              clearFilters={this.clearFilters}
              fetchingTrainingsCount={fetchingTrainingsCount}
              preventFeedLoading={this.preventFeedLoading}
            />
          </Container>
        )}
        {!fetchingTrainingsPaged && trainings.length === 0 && (
          <div className="text-center">
            <p className="placeholder-text">{t("training.noTrainings")}</p>
            <Link to="/training-diary/add" className="btn btn-primary">
              {t("training.addButton")}
            </Link>
          </div>
        )}

        {!loading && statistics.trainingFocuses && (
          <Container size="small">
            <Chart
              title={t("training.mostTrained")}
              name={"trainingFocuses"}
              data={this.getTrainingFocusStatistics()}
              type="polarArea"
              onChange={this.props.fetchTrainingFocusStats}
            />
          </Container>
        )}

        {!loading &&
          trainings.map((training) => (
            <TrainingCard
              key={training.id}
              training={training}
              dog={activeDog}
              onClick={this.openCard}
            />
          ))}

        {trainings.length >= Premium.ITEM_LIMIT && <PaywallNotification />}

        {
          /* Start load more content when this div is visible*/
          trainingsPage < trainingsPagesCount &&
          trainings.length >= Premium.ITEM_LIMIT &&
          hasActivePlan ? (
            <Loader name="training-diary" />
          ) : null
        }
        <RoundButton
          size="large"
          atCorner
          icon="add"
          onClick={this.goToAddTrainingPage}
        />
      </AppContent>
    );
  }

  getTrainingFocusStatistics() {
    const { statistics, t } = this.props;
    let trainingFocuses = {
      labels: statistics.trainingFocuses.labels
        ? statistics.trainingFocuses.labels.map((l) => {
            let split = l.indexOf(":");
            if (split > 0) {
              return (
                t(`misc.${l.substring(0, split)}`) +
                ": " +
                t(`misc.${l.substring(split + 2, l.length)}`)
              );
            } else {
              return t(`misc.${l}`);
            }
          })
        : "",
      datasets: statistics.trainingFocuses.datasets
        ? statistics.trainingFocuses.datasets
        : [],
    };

    return trainingFocuses;
  }

  setCoachesFilter = (coaches) => {
    this.setState((prevState) => ({
      filters: { ...prevState.filters, coachesFilter: coaches },
    }));
    this.updateTrainingsCount();
  };

  setDateFilter = (dates) => {
    let startDate = dates.startDate
      ? dates.startDate.format("YYYY-MM-DD")
      : null;
    let endDate = dates.endDate ? dates.endDate.format("YYYY-MM-DD") : null;
    this.setState((prevState) => ({
      filters: { ...prevState.filters, datesFilter: [startDate, endDate] },
    }));
    this.updateTrainingsCount();
  };

  setTrainingTypeFilter = (e) => {
    let { name, value } = e.target;
    this.setState((prevState) => ({
      filters: { ...prevState.filters, trainingTypeFilter: value },
    }));
    this.updateTrainingsCount();
  };

  cancelFilters = () => {
    this.parseFilters();
  };

  applyFilters = () => {
    this.props.history.push(`/training-diary/?f`);
    localStorage.setItem("trainingFilters", this.getFilterString());
    this.setState({ loadFeed: true });
  };

  preventFeedLoading = () => {
    this.setState({ loadFeed: false });
  };

  clearFilters = () => {
    this.setState(this.initialState);
  };
}

TrainingDiaryList.propTypes = {
  fetchTrainingsPaged: PropTypes.func,
  fetchTrainingsPagesCount: PropTypes.func,
  fetchTrainingFocusStats: PropTypes.func,
  statistics: PropTypes.any,
  t: PropTypes.func,
  fetchTrainingsCount: PropTypes.func,
  history: PropTypes.object,
  trainingsPage: PropTypes.number,
  trainingsPagesCount: PropTypes.number,
  fetchingTrainingsPagesCount: PropTypes.bool,
  fetchingTrainingsPaged: PropTypes.bool,
  fetchingTrainingsCount: PropTypes.bool,
  trainingsCount: PropTypes.number,
  hasActivePlan: PropTypes.bool,
  activeDog: PropTypes.object,
  trainings: PropTypes.array,
  location: PropTypes.object,
};

const mapStateToProps = ({
  trainings,
  activeDog,
  auth,
  subscriptions,
  statistics,
}) => ({
  trainings: trainings.trainings
    ? _.sortBy(trainings.trainings, (t) => t.date).reverse()
    : [],
  fetchingTrainings: trainings.fetchingTrainings,
  fetchingTrainingsPaged: trainings.fetchingTrainingsPaged,
  fetchingTrainingsPagesCount: trainings.fetchingTrainingsPagesCount,
  trainingsPagesCount: trainings.trainingsPagesCount,
  trainingsPage: trainings.trainingsPage,
  trainingsCount: trainings.trainingsCount,
  fetchingTrainingsCount: trainings.fetchingTrainingsCount,
  statistics: statistics,
  error: trainings.error,
  activeDog,
  auth_token: auth.authToken,
  hasActivePlan:
    subscriptions.currentPlan &&
    moment(subscriptions.currentPlan.paid_until) > moment(),
});

const TrainingDiaryListWithTranslate = withTranslation()(TrainingDiaryList);
export default connect(mapStateToProps, {
  fetchTrainingsPaged,
  fetchTrainingsPagesCount,
  fetchTrainingsCount,
  fetchTrainingFocusStats,
})(TrainingDiaryListWithTranslate);
