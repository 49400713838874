import React from "react";
import PropTypes from "prop-types";
import { castToNestedFocusArray } from "../../helpers/Transformer";

export function TrainingFocusesSmall ({trainingFocuses, t}) {
  return (
    <span className="todo-list__focus">
      {castToNestedFocusArray(trainingFocuses).map((focus, i, arr) => (
        <span key={focus.key}>
          {t(`misc.${focus.name}`)}
          {focus.children && focus.children.length > 0 && (
            <span>
              {": "}
              {focus.children.map((f) => t(`misc.${f.name}`)).join(", ")}
            </span>
          )}
          {arr.length - 1 === i ? <span /> : <span>{", "}</span>}
        </span>
      ))}
    </span>
  );
}

TrainingFocusesSmall.propTypes = {
  trainingFocuses: PropTypes.array.isRequired,
  t: PropTypes.func.isRequired,
};