import { FETCH_SETTINGS, AFTER_REHYDRATE } from "../actions/types";

export const fetchSettings = () => ({
  type: FETCH_SETTINGS.REQUEST
});

export const fetchSettingsSucceeded = settings => ({
  type: FETCH_SETTINGS.SUCCEEDED,
  payload: settings
});

export const fetchSettingsFailed = error => ({
  type: FETCH_SETTINGS.FAILED,
  payload: error
});

export const afterRehydrate = () => ({
  type: AFTER_REHYDRATE
});
