import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";

import INTIAL_DOG_STATE from "./initial-dog-state";
import { AppContent, RoundButton } from "../../common";
import DogForm from "./DogForm";
import {
  addDog,
  fetchOrganizations,
  fetchSizeClasses,
  clearSizeClasses,
  updateDogTrialSizeClass,
} from "../../actions";

class DogOnboarding extends Component {
  state = {
    dog: { ...INTIAL_DOG_STATE },
    redirect: false,
    redirectTo: "/",
  };

  submit = () => {
    const { dog } = this.state;
    const { addDog } = this.props;
    addDog({ ...dog });
    this.setState({ redirect: true });
  };

  componentDidMount() {
    const { fetchOrganizations } = this.props;
    this.clearSizeClasses();
    fetchOrganizations();
    this.fetchSizeClasses(this.state.dog.organizations[0].id);
  }

  handleDogChange = (dog) => {
    this.setState({ dog });
  };

  fetchSizeClasses = (orgId) => {
    const { fetchSizeClasses } = this.props;
    fetchSizeClasses(orgId);
  };

  clearSizeClasses = (orgId) => {
    const { clearSizeClasses } = this.props;
    clearSizeClasses(orgId);
  };

  updateDogTrialSizeClass = (dog) => {
    const { updateDogTrialSizeClass } = this.props;
    updateDogTrialSizeClass(dog);
  };

  render() {
    const { dog, redirect, redirectTo } = this.state;

    // eslint-disable-next-line no-unused-vars
    const { saving, t, organizations, sizeClasses } = this.props;

    if (redirect && !saving) return <Redirect to={redirectTo} />;

    return (
      <AppContent size="small">
        <h1 className="text-center">{t("dog.onboarding.title")}</h1>

        <DogForm
          dog={dog}
          onChange={this.handleDogChange}
          organizations={organizations}
          sizeClasses={sizeClasses}
          fetchSizeClasses={this.fetchSizeClasses}
          clearSizeClasses={this.clearSizeClasses}
          updateDogTrialSizeClass={this.updateDogTrialSizeClass}
        />

        <RoundButton
          atCorner
          icon={saving ? "loader" : "complete"}
          size="large"
          onClick={this.submit}
        />
      </AppContent>
    );
  }
}

const mapStateToProps = ({ dogs, organizations, sizeClasses }) => ({
  saving: dogs.saving,
  organizations: organizations.organizations,
  fetchingOrganizations: organizations.fetchingOrganizations,
  sizeClasses: sizeClasses.sizeClasses,
  fetchingSizeClasses: sizeClasses.fetchingSizeClasses,
});

const DogOnboardingWithTranslate = withTranslation()(DogOnboarding);
export default connect(mapStateToProps, {
  addDog,
  fetchOrganizations,
  fetchSizeClasses,
  clearSizeClasses,
  updateDogTrialSizeClass,
})(DogOnboardingWithTranslate);
