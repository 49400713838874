export default {
  id: null,
  name: "",
  officialName: "",
  sex: "",
  race: "",
  dayOfBirth: "",
  image: null,
  description: "",
  competitionClass: "",
  height: null,
  organizations: [{ id: 1, name: "FCI" }],
  sizeClasses: []
};
