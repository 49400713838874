import { FETCH_USER, UPDATE_USER, SAVE_SETTINGS } from "./types";

export const fetchUser = id => ({
  type: FETCH_USER.REQUEST,
  payload: id
});

export const fetchUserSucceeded = user => ({
  type: FETCH_USER.SUCCEEDED,
  payload: user
});

export const fetchUserFailed = error => ({
  type: FETCH_USER.FAILED,
  payload: error
});

export const updateUser = user => ({
  type: UPDATE_USER.REQUEST,
  payload: user
});

export const updateUserSucceeded = user => ({
  type: UPDATE_USER.SUCCEEDED,
  payload: user
});

export const updateUserFailed = error => ({
  type: UPDATE_USER.FAILED,
  payload: error
});

export const saveSettings = settings => ({
  type: SAVE_SETTINGS.REQUEST,
  payload: settings
});

export const saveSettingsSucceeded = payload => ({
  type: SAVE_SETTINGS.SUCCEEDED,
  payload: payload
});

export const saveSettingsFailed = error => ({
  type: SAVE_SETTINGS.FAILED,
  payload: error
});
