import React from "react";
import PropTypes from "prop-types";

const Select = ({
  label,
  type,
  name,
  placeholder,
  value,
  onChange,
  options
}) => {
  return (
    <div>
      <label>{label}</label>
      <select
        type={type}
        name={name}
        className="form-control"
        placeholder={placeholder}
        onChange={onChange}
        value={value || ""}
      >
        {options.map(({ value, title }) => (
          <option key={title} value={value}>
            {title}
          </option>
        ))}
      </select>
    </div>
  );
};

Select.propTypes = {
  label: PropTypes.string,
  type: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({ title: PropTypes.string, value: PropTypes.string })
  )
};

export default Select;
