import { call, put, takeLatest, select } from "redux-saga/effects";

import Api from "../helpers/Api";
import { getAuthToken, getActiveDog } from "./selectors";
import {
  FETCH_TRIAL_STATS,
  FETCH_TRAINING_STATS,
  FETCH_TRAINING_FOCUS_STATS,
  FETCH_ACTIVITY_STATS,
  FETCH_TRIAL_POSITION_STATS,
  FETCH_CLEAN_RUN_STATS,
  FETCH_SPEED_STATS,
  FETCH_TRIAL_LIST_SPEED_STATS,
  FETCH_TRIAL_LIST_CLEAN_RUN_STATS,
  FETCH_TRIAL_LIST_FAULT_REASON_STATS,
  FETCH_TRIAL_LIST_FAULT_STATS,
  FETCH_TRIAL_LIST_POINT_STATS,
  FETCH_TRIAL_LIST_TIME_STATS,
  FETCH_TRIAL_LIST_DESIGNATED_TIME_STATS,
} from "../actions/types";
import {
  fetchTrialStatsFailed,
  fetchTrialStatsSucceeded,
  fetchTrainingStatsFailed,
  fetchTrainingStatsSucceeded,
  fetchTrainingFocusStatsFailed,
  fetchTrainingFocusStatsSucceeded,
  fetchActivityStatsFailed,
  fetchActivityStatsSucceeded,
  fetchTrialPositionStatsFailed,
  fetchTrialPositionStatsSucceeded,
  fetchCleanRunStatsFailed,
  fetchCleanRunStatsSucceeded,
  fetchSpeedStatsFailed,
  fetchSpeedStatsSucceeded,
  fetchTrialListSpeedStatsFailed,
  fetchTrialListSpeedStatsSucceeded,
  fetchTrialListCleanRunStatsFailed,
  fetchTrialListCleanRunStatsSucceeded,
  fetchTrialListFaultReasonStatsFailed,
  fetchTrialListFaultReasonStatsSucceeded,
  fetchTrialListFaultStatsFailed,
  fetchTrialListFaultStatsSucceeded,
  fetchTrialListPointStatsFailed,
  fetchTrialListPointStatsSucceeded,
  fetchTrialListTimeStatsFailed,
  fetchTrialListTimeStatsSucceeded,
  fetchTrialListDesignatedTimeStatsFailed,
  fetchTrialListDesignatedTimeStatsSucceeded,
  authExpired,
} from "../actions";

function* fetchTrialStats({ action, payload }) {
  try {
    const auth_token = yield select(getAuthToken);
    const dog = yield select(getActiveDog);
    const trialStats = yield call(
      Api({ auth_token }).statistics.fetchTrialStats,
      {
        dog,
        startDate: payload.startDate,
        endDate: payload.endDate,
      }
    );
    yield put(fetchTrialStatsSucceeded(trialStats.data));
  } catch (e) {
    const { status, statusText } = e.response;
    if (status === 401) {
      yield put(authExpired());
    }
    yield put(fetchTrialStatsFailed({ status, statusText }));
  }
}

function* fetchTrainingStats({ action, payload }) {
  try {
    const auth_token = yield select(getAuthToken);
    const dog = yield select(getActiveDog);
    const trainings = yield call(
      Api({ auth_token }).statistics.fetchTrainingStats,
      {
        dog,
        startDate: payload.startDate,
        endDate: payload.endDate,
      }
    );
    yield put(fetchTrainingStatsSucceeded(trainings.data));
  } catch (e) {
    const { status, statusText } = e.response;
    if (status === 401) {
      yield put(authExpired());
    }
    yield put(fetchTrainingStatsFailed({ status, statusText }));
  }
}

function* fetchTrainingFocusStats({ action, payload }) {
  try {
    const auth_token = yield select(getAuthToken);
    const dog = yield select(getActiveDog);
    const trainingFocuses = yield call(
      Api({ auth_token }).statistics.trainingFocuses,
      {
        dog,
        startDate: payload.startDate,
        endDate: payload.endDate,
        filters: payload.filters,
      }
    );
    yield put(fetchTrainingFocusStatsSucceeded(trainingFocuses.data));
  } catch (e) {
    const { status, statusText } = e.response;
    if (status === 401) {
      yield put(authExpired());
    }
    yield put(fetchTrainingFocusStatsFailed({ status, statusText }));
  }
}

function* fetchActivityStats({ action, payload }) {
  try {
    const auth_token = yield select(getAuthToken);
    const dog = yield select(getActiveDog);
    const activities = yield call(
      Api({ auth_token }).statistics.fetchActivityStats,
      {
        dog,
        startDate: payload.startDate,
        endDate: payload.endDate,
      }
    );
    yield put(fetchActivityStatsSucceeded(activities.data));
  } catch (e) {
    const { status, statusText } = e.response;
    if (status === 401) {
      yield put(authExpired());
    }
    yield put(fetchActivityStatsFailed({ status, statusText }));
  }
}

function* fetchTrialPositionStats({ action, payload }) {
  try {
    const auth_token = yield select(getAuthToken);
    const dog = yield select(getActiveDog);
    const positions = yield call(
      Api({ auth_token }).statistics.fetchTrialPositionStats,
      {
        dog,
        startDate: payload.startDate,
        endDate: payload.endDate,
      }
    );
    yield put(fetchTrialPositionStatsSucceeded(positions.data));
  } catch (e) {
    const { status, statusText } = e.response;
    if (status === 401) {
      yield put(authExpired());
    }
    yield put(fetchTrialPositionStatsFailed({ status, statusText }));
  }
}

function* fetchCleanRunStats({ action, payload }) {
  try {
    const auth_token = yield select(getAuthToken);
    const dog = yield select(getActiveDog);
    const cleanRuns = yield call(
      Api({ auth_token }).statistics.fetchCleanRunStats,
      {
        dog,
        startDate: payload.startDate,
        endDate: payload.endDate,
      }
    );
    yield put(fetchCleanRunStatsSucceeded(cleanRuns.data));
  } catch (e) {
    const { status, statusText } = e.response;
    if (status === 401) {
      yield put(authExpired());
    }
    yield put(fetchCleanRunStatsFailed({ status, statusText }));
  }
}

function* fetchSpeedStats({ action, payload }) {
  try {
    const auth_token = yield select(getAuthToken);
    const dog = yield select(getActiveDog);
    const speedStats = yield call(
      Api({ auth_token }).statistics.fetchSpeedStats,
      {
        dog,
        startDate: payload.startDate,
        endDate: payload.endDate,
      }
    );
    yield put(fetchSpeedStatsSucceeded(speedStats.data));
  } catch (e) {
    const { status, statusText } = e.response;
    if (status === 401) {
      yield put(authExpired());
    }
    yield put(fetchSpeedStatsFailed({ status, statusText }));
  }
}

function* fetchTrialListSpeedStats({ action, payload }) {
  try {
    const auth_token = yield select(getAuthToken);
    const dog = yield select(getActiveDog);
    const speedStats = yield call(
      Api({ auth_token }).statistics.fetchTrialListSpeedStats,
      {
        dog,
        startDate: payload.startDate,
        endDate: payload.endDate,
        courseTypeId: payload.courseTypeId,
        runType: payload.runType,
        organizationId: payload.organizationId,
        sizeClassIds: payload.sizeClassIds,
        filters: payload.filters,
      }
    );
    yield put(fetchTrialListSpeedStatsSucceeded(speedStats.data));
  } catch (e) {
    const { status, statusText } = e.response;
    if (status === 401) {
      yield put(authExpired());
    }
    yield put(fetchTrialListSpeedStatsFailed({ status, statusText }));
  }
}

function* fetchTrialListCleanRunStats({ action, payload }) {
  try {
    const auth_token = yield select(getAuthToken);
    const dog = yield select(getActiveDog);
    const cleanRunStats = yield call(
      Api({ auth_token }).statistics.fetchTrialListCleanRunStats,
      {
        dog,
        startDate: payload.startDate,
        endDate: payload.endDate,
        courseTypeId: payload.courseTypeId,
        organizationId: payload.organizationId,
        sizeClassIds: payload.sizeClassIds,
        filters: payload.filters,
      }
    );
    yield put(fetchTrialListCleanRunStatsSucceeded(cleanRunStats.data));
  } catch (e) {
    const { status, statusText } = e.response;
    if (status === 401) {
      yield put(authExpired());
    }
    yield put(fetchTrialListCleanRunStatsFailed({ status, statusText }));
  }
}

function* fetchTrialListFaultReasonStats({ action, payload }) {
  try {
    const auth_token = yield select(getAuthToken);
    const dog = yield select(getActiveDog);
    const faultReasons = yield call(
      Api({ auth_token }).statistics.fetchTrialListFaultReasonStats,
      {
        dog,
        startDate: payload.startDate,
        endDate: payload.endDate,
        courseTypeId: payload.courseTypeId,
        organizationId: payload.organizationId,
        sizeClassIds: payload.sizeClassIds,
        filters: payload.filters,
      }
    );
    yield put(fetchTrialListFaultReasonStatsSucceeded(faultReasons.data));
  } catch (e) {
    const { status, statusText } = e.response;
    if (status === 401) {
      yield put(authExpired());
    }
    yield put(fetchTrialListFaultReasonStatsFailed({ status, statusText }));
  }
}

function* fetchTrialListFaultStats({ action, payload }) {
  try {
    const auth_token = yield select(getAuthToken);
    const dog = yield select(getActiveDog);
    const faults = yield call(
      Api({ auth_token }).statistics.fetchTrialListFaultStats,
      {
        dog,
        startDate: payload.startDate,
        endDate: payload.endDate,
        courseTypeId: payload.courseTypeId,
        organizationId: payload.organizationId,
        sizeClassIds: payload.sizeClassIds,
        filters: payload.filters,
      }
    );
    yield put(fetchTrialListFaultStatsSucceeded(faults.data));
  } catch (e) {
    const { status, statusText } = e.response;
    if (status === 401) {
      yield put(authExpired());
    }
    yield put(fetchTrialListFaultStatsFailed({ status, statusText }));
  }
}

function* fetchTrialListPointStats({ action, payload }) {
  try {
    const auth_token = yield select(getAuthToken);
    const dog = yield select(getActiveDog);
    const pointStats = yield call(
      Api({ auth_token }).statistics.fetchTrialListPointStats,
      {
        dog,
        startDate: payload.startDate,
        endDate: payload.endDate,
        courseTypeId: payload.courseTypeId,
        runType: payload.runType,
        organizationId: payload.organizationId,
        sizeClassIds: payload.sizeClassIds,
        filters: payload.filters,
      }
    );
    yield put(fetchTrialListPointStatsSucceeded(pointStats.data));
  } catch (e) {
    const { status, statusText } = e.response;
    if (status === 401) {
      yield put(authExpired());
    }
    yield put(fetchTrialListPointStatsFailed({ status, statusText }));
  }
}

function* fetchTrialListTimeStats({ action, payload }) {
  try {
    const auth_token = yield select(getAuthToken);
    const dog = yield select(getActiveDog);
    const timeStats = yield call(
      Api({ auth_token }).statistics.fetchTrialListTimeStats,
      {
        dog,
        startDate: payload.startDate,
        endDate: payload.endDate,
        courseTypeId: payload.courseTypeId,
        runType: payload.runType,
        organizationId: payload.organizationId,
        sizeClassIds: payload.sizeClassIds,
        filters: payload.filters,
      }
    );
    yield put(fetchTrialListTimeStatsSucceeded(timeStats.data));
  } catch (e) {
    const { status, statusText } = e.response;
    if (status === 401) {
      yield put(authExpired());
    }
    yield put(fetchTrialListTimeStatsFailed({ status, statusText }));
  }
}

//TODO: Clear stuff that's already in filters
function* fetchTrialListDesignatedTimeStats({ action, payload }) {
  try {
    const auth_token = yield select(getAuthToken);
    const dog = yield select(getActiveDog);
    const timeStats = yield call(
      Api({ auth_token }).statistics.fetchTrialListDesignatedTimeStats,
      {
        dog,
        startDate: payload.startDate,
        endDate: payload.endDate,
        courseTypeId: payload.courseTypeId,
        runType: payload.runType,
        organizationId: payload.organizationId,
        sizeClassIds: payload.sizeClassIds,
        filters: payload.filters,
      }
    );
    yield put(fetchTrialListDesignatedTimeStatsSucceeded(timeStats.data));
  } catch (e) {
    const { status, statusText } = e.response;
    if (status === 401) {
      yield put(authExpired());
    }
    yield put(fetchTrialListDesignatedTimeStatsFailed({ status, statusText }));
  }
}

function* statisticSaga() {
  yield takeLatest(FETCH_TRIAL_STATS.REQUEST, fetchTrialStats);
  yield takeLatest(FETCH_TRAINING_STATS.REQUEST, fetchTrainingStats);
  yield takeLatest(FETCH_TRAINING_FOCUS_STATS.REQUEST, fetchTrainingFocusStats);
  yield takeLatest(FETCH_ACTIVITY_STATS.REQUEST, fetchActivityStats);
  yield takeLatest(FETCH_TRIAL_POSITION_STATS.REQUEST, fetchTrialPositionStats);
  yield takeLatest(FETCH_CLEAN_RUN_STATS.REQUEST, fetchCleanRunStats);
  yield takeLatest(FETCH_SPEED_STATS.REQUEST, fetchSpeedStats);
  yield takeLatest(
    FETCH_TRIAL_LIST_SPEED_STATS.REQUEST,
    fetchTrialListSpeedStats
  );
  yield takeLatest(
    FETCH_TRIAL_LIST_CLEAN_RUN_STATS.REQUEST,
    fetchTrialListCleanRunStats
  );
  yield takeLatest(
    FETCH_TRIAL_LIST_FAULT_REASON_STATS.REQUEST,
    fetchTrialListFaultReasonStats
  );
  yield takeLatest(
    FETCH_TRIAL_LIST_FAULT_STATS.REQUEST,
    fetchTrialListFaultStats
  );
  yield takeLatest(
    FETCH_TRIAL_LIST_POINT_STATS.REQUEST,
    fetchTrialListPointStats
  );
  yield takeLatest(
    FETCH_TRIAL_LIST_TIME_STATS.REQUEST,
    fetchTrialListTimeStats
  );
  yield takeLatest(
    FETCH_TRIAL_LIST_DESIGNATED_TIME_STATS.REQUEST,
    fetchTrialListDesignatedTimeStats
  );
}

export default statisticSaga;
