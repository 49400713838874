import React from "react";
import PropTypes from "prop-types";

const AppContent = ({ children, size, className, noMargin }) => {
  const marginClass = noMargin ? "app-content--no-margin" : "";
  return (
    <div
      className={`app-content app-content--${size} ${className} ${marginClass}`}
    >
      {children}
    </div>
  );
};

AppContent.propTypes = {
  children: PropTypes.any,
  size: PropTypes.oneOf(["full", "large", "medium", "small"]),
  noMargin: PropTypes.bool
};

AppContent.defaultProps = {
  size: "large"
};

export default AppContent;
