import { call, put, takeLatest, select } from "redux-saga/effects";

import Api from "../helpers/Api";
import { getAuthToken } from "./selectors";
import { UPDATE_USER, FETCH_USER, SAVE_SETTINGS } from "../actions/types";
import {
  fetchUserSucceeded,
  fetchUserFailed,
  updateUserSucceeded,
  updateUserFailed,
  saveSettingsFailed,
  saveSettingsSucceeded
} from "../actions";

function* fetchUser(action) {
  try {
    const auth_token = yield select(getAuthToken);
    const id = action.payload;
    const user = yield call(Api({ auth_token }).fetchUser, id);
    yield put(fetchUserSucceeded(user.data));
  } catch (e) {
    yield put(fetchUserFailed(e.message));
  }
}

function* updateUser(action) {
  try {
    const auth_token = yield select(getAuthToken);
    const user = yield call(
      Api({
        auth_token,
        "content-type": +"multipart/form-data; boundary=----WebKitFormBoundary7MA4YWxkTrZu0gW"
      }).updateUser,
      action.payload
    );
    yield put(updateUserSucceeded(user.data));
  } catch (e) {
    yield put(updateUserFailed(e.message));
  }
}

function* saveSettings(action) {
  try {
    const auth_token = yield select(getAuthToken);
    const user = yield call(
      Api({
        auth_token
      }).saveSettings,
      action.payload
    );
    yield put(saveSettingsSucceeded(user.data));
  } catch (e) {
    yield put(saveSettingsFailed(e.message));
  }
}

function* userSaga() {
  yield takeLatest(FETCH_USER.REQUEST, fetchUser);
  yield takeLatest(UPDATE_USER.REQUEST, updateUser);
  yield takeLatest(SAVE_SETTINGS.REQUEST, saveSettings);
}

export default userSaga;
