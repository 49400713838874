import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import _ from "lodash";

import chevronDown from "../images/icons/chevron-down.svg";

class SelectFocusList extends Component {
  state = {
    list: [],
    showList: true
  };

  componentDidMount() {
    this.setState({ showList: !this.props.hasToggle });
  }

  toggle = () => {
    this.setState((prevState, prevProps) => ({
      showList: !prevState.showList
    }));
  };

  render() {
    const { list, title, hasToggle, renderName } = this.props;
    const { showList } = this.state;

    return (
      <Fragment>
        {title && (
          <Fragment>
            {hasToggle && (
              <button className="select-list__button" onClick={this.toggle}>
                <span className="select-list__title">
                  {renderName ? renderName(title) : title}
                </span>
                <img
                  className="select-list__chevron"
                  src={chevronDown}
                  alt="chevron"
                />
              </button>
            )}
          </Fragment>
        )}

        {showList && (
          <ul className="select-list">
            {list.map(item => this.renderItem(item))}
          </ul>
        )}
      </Fragment>
    );
  }

  renderItem = item => {
    const { isSubList, selectedItems, renderName, multiple } = this.props;
    const isSelected = _.some(selectedItems, { key: item.key });
    const selectedItem = _.find(selectedItems, i => i.key === item.key);
    const itemClasses = ["select-list__item"];
    const buttonClasses = ["select-list__button"];
    const actionClasses = ["select-list__actions"];

    let count = 0;
    if (multiple) {
      count = isSelected ? selectedItem.count : 0;
    } else {
      count = isSelected ? 0 : 1;
    }

    if (isSelected) buttonClasses.push("select-list__button--selected");
    if (isSelected) actionClasses.push("select-list__actions--selected");
    if (isSubList) buttonClasses.push("select-list__button--secondary");
    return (
      <li key={item.id || item.name} className={itemClasses.join(" ")}>
        {item.children ? (
          <SelectFocusList
            list={item.children}
            isSubList
            hasToggle
            title={item.name}
            selectedItems={selectedItems}
            onChange={this.props.onChange}
            renderName={renderName}
            multiple={multiple}
          />
        ) : (
          <div className={actionClasses.join(" ")}>
            <button
              onClick={() => this.selectItem(item, count)}
              className={buttonClasses.join(" ")}
            >
              {renderName ? renderName(item.name) : item.name}
              {multiple && selectedItem && ` × ${count}`}
            </button>
            {multiple &&
              isSelected && (
                <React.Fragment>
                  <button
                    onClick={() => this.addItem(item, count + 1)}
                    className="select-list__btn"
                  >
                    +
                  </button>
                  <button
                    onClick={() => this.addItem(item, count - 1)}
                    className="select-list__btn"
                  >
                    -
                  </button>
                </React.Fragment>
              )}
          </div>
        )}
      </li>
    );
  };

  selectItem = (item, prevCount) => {
    const { multiple } = this.props;
    let count;

    if (multiple) {
      count = prevCount === 0 ? 1 : prevCount;
    } else {
      count = prevCount === 0 ? 1 : 0;
    }
    this.addItem(item, count);
  };

  addItem = (item, count) => {
    const newItem = { ...item, count };
    this.props.onChange(newItem);
  };
}

SelectFocusList.propTypes = {
  list: PropTypes.arrayOf(PropTypes.shape({ name: PropTypes.string })),
  title: PropTypes.string,
  hasToggle: PropTypes.bool,
  isSubList: PropTypes.bool,
  onItemChange: PropTypes.func,
  selectedItems: PropTypes.array,
  renderName: PropTypes.func,
  multiple: PropTypes.bool
};

SelectFocusList.defaultProps = {
  onItemChange: () => {},
  multiple: false
};

export default SelectFocusList;
