import React from "react";
import PropTypes from "prop-types";

import Avatar from "./Avatar";

const CardHeader = ({
  image,
  title,
  subTitle,
  subTitle2,
  onClick,
  placement,
}) => {
  return (
    <header className="card-header" onClick={onClick}>
      <Avatar
        image={image}
        size="small"
        className="card-header__avatar"
        placement={placement}
      />

      <div className="card-header__titles">
        <p className="card-header__title">{title}</p>
        <p>
          <span>
            <small className="card-header__sub-title">{subTitle}</small>
          </span>
          <span>
            <small className="card-header__sub-title2">{subTitle2}</small>
          </span>
        </p>
      </div>

      <button className="card-header__actions">&rarr;</button>
    </header>
  );
};

CardHeader.propTypes = {
  image: PropTypes.string,
  placement: PropTypes.number,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  subTitle2: PropTypes.string,
  onClick: PropTypes.func,
};

export default CardHeader;
