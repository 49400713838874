import { FETCH_COACHES_FILTER, SET_ACTIVE_DOG } from "../actions/types";

const INITIAL_STATE = {
  coaches: [],
  fetchingCoachesFilter: false,
  errorInCoachesFilter: null,
};

export default (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case FETCH_COACHES_FILTER.SUCCEEDED:
      return {
        ...state,
        coaches: payload,
        fetchingCoachesFilter: false,
      };

    case FETCH_COACHES_FILTER.REQUEST:
      return {
        ...state,
        ...payload,
        fetchingCoachesFilter: true,
      };

    case FETCH_COACHES_FILTER.FAILED:
      return {
        ...state,
        errorInCoachesFilter: payload,
        fetchingCoachesFilter: false,
      };

    case SET_ACTIVE_DOG:
      return INITIAL_STATE;

    default:
      return state;
  }
};
