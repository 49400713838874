import React, { Component } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import TrialFilterModal from "./TrialFilterModal";
import { fetchJudgesFilter, fetchTrialLocationsFilter } from "../actions";
import _ from "lodash";
import moment from "moment";
import confirm from "./Confirm";

class TrialFilter extends Component {
  state = {
    filtersText: "",
    showModal: false,
  };

  componentDidMount() {
    this.props.fetchJudgesFilter();
    this.props.fetchTrialLocationsFilter();
  }

  handleModalCloseClick = () => {
    const { cancelFilters } = this.props;
    this.setState({ showModal: false });
    cancelFilters();
  };

  handleApplyFiltersClick = () => {
    const { applyFilters } = this.props;
    this.setState({ showModal: false });
    applyFilters();
  };

  showModal = (e) => {
    const { preventFeedLoading } = this.props;
    preventFeedLoading();
    e.preventDefault();
    this.setState({ showModal: true });
  };

  getPremium = (e) => {
    const { t } = this.props;
    e.stopPropagation();
    e.preventDefault();
    confirm(t("subscription.noPlanYet"), {
      title: t("paywall.getPremium"),
      ok: t("common.ok"),
      cancel: t("common.cancel"),
    }).then(
      () => {
        this.props.history.push("/subscription");
      },
      () => {}
    );
  };

  render() {
    const {
      trialLocationsFilter,
      judgesFilter,
      datesFilter,
      resultsFilter,
      organizationsFilter,
      sizeClassesFilter,
      placementsFilter,
      courseTypesFilter,
      speedsFilter,
      faultsFilter,
      updateTrialLocationsFilter,
      updateJudgesFilter,
      updateDateFilter,
      updateResultsFilter,
      updateOrganizationsFilter,
      updateSizeClassesFilter,
      updatePlacementsFilter,
      udpdateCourseTypesFilter,
      updateSpeedsFilter,
      updateFaultsFilter,
      fetchingJudgesFilter,
      fetchingTrialLocationsFilter,
      judges,
      trialLocations,
      trialsCount,
      fetchingTrialsCount,
      t,
      activeDog,
    } = this.props;

    let filtersText = "";
    activeDog.organizations.forEach((organization) => {
      if (organizationsFilter === organization.id) {
        filtersText = filtersText + " " + organization.name;
      }
    });

    activeDog.sizeClasses
      .filter(
        (sizeClass) =>
          parseInt(sizeClass.organizationId) === parseInt(organizationsFilter)
      )
      .forEach((sizeClass) => {
        if (_.includes(sizeClassesFilter, sizeClass.id)) {
          filtersText = filtersText + " " + sizeClass.name;
        }
      });

    if (datesFilter && datesFilter[0] && datesFilter[1]) {
      filtersText =
        filtersText +
        " " +
        moment(datesFilter[0]).format("L") +
        " - " +
        moment(datesFilter[1]).format("L");
    }

    judges.forEach((judge) => {
      if (
        _.includes(
          judgesFilter.map((j) => j.value),
          judge.id
        )
      ) {
        filtersText = filtersText + " " + judge.name;
      }
    });
    trialLocations.forEach((location) => {
      if (
        _.includes(
          trialLocationsFilter.map((l) => l.value),
          location.id
        )
      ) {
        filtersText = filtersText + " " + location.name;
      }
    });

    if (resultsFilter) {
      filtersText = filtersText + " " + t("filters.result_" + resultsFilter);
    }

    return (
      <>
        <div className="column-tight column-tight--align-top">
          <div className="flex-item-extra-wide">
            {!fetchingTrialsCount && (
              <h3 className="filter-title">
                {trialsCount + t("filters.trialsCount")}
              </h3>
            )}
            {filtersText && (
              <>
                <h3 className="filter-title">{t("filters.currentFilters")}:</h3>
                <div>
                  <span>{filtersText}</span>
                </div>
              </>
            )}
          </div>
          <div className="flex-item-right-align float-right">
            <span>
              {this.props.hasActivePlan ? 
                <button
                  className="btn btn-outline-primary"
                  onClick={this.showModal}
                >
                  {t("filters.setFilters")}
                </button>
                :
                <button
                  className="btn btn-outline-secondary"
                  onClick={this.getPremium}
                >
                  {t("filters.setFilters")}
                </button>}
            </span>
          </div>
        </div>
        <TrialFilterModal
          trialLocationsFilter={trialLocationsFilter}
          judgesFilter={judgesFilter}
          datesFilter={datesFilter}
          resultsFilter={resultsFilter}
          organizationsFilter={organizationsFilter}
          sizeClassesFilter={sizeClassesFilter}
          placementsFilter={placementsFilter}
          courseTypesFilter={courseTypesFilter}
          speedsFilter={speedsFilter}
          faultsFilter={faultsFilter}
          updateTrialLocationsFilter={updateTrialLocationsFilter}
          updateJudgesFilter={updateJudgesFilter}
          updateDateFilter={updateDateFilter}
          updateResultsFilter={updateResultsFilter}
          updateOrganizationsFilter={updateOrganizationsFilter}
          updateSizeClassesFilter={updateSizeClassesFilter}
          updatePlacementsFilter={updatePlacementsFilter}
          udpdateCourseTypesFilter={udpdateCourseTypesFilter}
          updateSpeedsFilter={updateSpeedsFilter}
          updateFaultsFilter={updateFaultsFilter}
          fetchingJudgesFilter={fetchingJudgesFilter}
          fetchingTrialLocationsFilter={fetchingTrialLocationsFilter}
          judges={judges}
          trialLocations={trialLocations}
          showModal={this.state.showModal}
          onModalClose={this.handleModalCloseClick}
          onApplyFilters={this.handleApplyFiltersClick}
          trialsCount={trialsCount}
          activeDog={activeDog}
        />
      </>
    );
  }
}

TrialFilter.propTypes = {
  t: PropTypes.func,
  trialLocationsFilter: PropTypes.array,
  judgesFilter: PropTypes.any,
  datesFilter: PropTypes.array,
  resultsFilter: PropTypes.any,
  organizationsFilter: PropTypes.any,
  sizeClassesFilter: PropTypes.array,
  placementsFilter: PropTypes.array,
  courseTypesFilter: PropTypes.array,
  speedsFilter: PropTypes.array,
  faultsFilter: PropTypes.array,
  updateTrialLocationsFilter: PropTypes.func,
  updateJudgesFilter: PropTypes.func,
  updateDateFilter: PropTypes.func,
  updateResultsFilter: PropTypes.func,
  updateOrganizationsFilter: PropTypes.func,
  updateSizeClassesFilter: PropTypes.func,
  updatePlacementsFilter: PropTypes.func,
  udpdateCourseTypesFilter: PropTypes.func,
  updateSpeedsFilter: PropTypes.func,
  updateFaultsFilter: PropTypes.func,
  fetchJudgesFilter: PropTypes.func,
  fetchTrialLocationsFilter: PropTypes.func,
  applyFilters: PropTypes.func,
  cancelFilters: PropTypes.func,
  fetchingJudgesFilter: PropTypes.bool,
  fetchingTrialLocationsFilter: PropTypes.bool,
  judges: PropTypes.array,
  trialLocations: PropTypes.array,
  trialsCount: PropTypes.number,
  fetchingTrialsCount: PropTypes.bool,
  activeDog: PropTypes.any,
  preventFeedLoading: PropTypes.func,
  hasActivePlan: PropTypes.bool,
  history: PropTypes.object,
};
const mapStateToProps = ({ trialFilters, activeDog, subscriptions }) => ({
  judges: trialFilters.judges,
  trialLocations: trialFilters.trialLocations,
  fetchingJudgesFilter: trialFilters.fetchingJudgesFilter,
  fetchingTrialLocationsFilter: trialFilters.fetchingTrialLocationsFilter,
  activeDog: activeDog,
  hasActivePlan:
      subscriptions.currentPlan &&
      moment(subscriptions.currentPlan.paid_until) > moment(),
});

const TrialFilterWithTranslate = withTranslation()(TrialFilter);

export default withRouter(connect(mapStateToProps, {
  fetchJudgesFilter,
  fetchTrialLocationsFilter,
})(TrialFilterWithTranslate));
