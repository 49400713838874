import React, { Component } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import Modal from "../common/Modal";
import { withTranslation } from "react-i18next";
import moment from "moment";

class ReceiptModal extends Component {
  render() {
    const { payment, transaction, show, onClose, t, user } = this.props;
    if (!show) return null;
    return (
      <div>
        <Modal
          contentClassName="modal-content"
          show={show}
          hideHorizontalLine
          onClose={() => {
            onClose();
          }}
        >
          <pre style={{ width: "100%" }}>
            {`------------------------------------------
${t("subscription.receipt.title")} - ${moment(transaction.payment_date).format(
              "DD MMM YYYY - HH:mm"
            )}
${t("subscription.receipt.user")}: ${user.firstName} ${user.lastName}
${t("subscription.receipt.plan")}: ${payment.plan_name}
${t("subscription.receipt.transactionId")}: ${transaction.stripe_transaction_id}
${t("subscription.receipt.status")}: ${transaction.status}
${t("subscription.receipt.amountWithoutVat")}: ${
              transaction.amount_excluding_vat
            } ${transaction.currency}
${t("subscription.receipt.vat")}: ${payment.vat_percentage}%
${t("subscription.receipt.vatAmount")}: ${transaction.vat_amount} ${
              transaction.currency
            }
${t("subscription.receipt.totalAmount")}: ${transaction.amount} ${
              transaction.currency
            }
------------------------------------------
AgiNotes Oy
VAT ID: FI28675683
`}
          </pre>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = ({ user }) => ({
  user: user.user,
});

const enhance = compose(connect(mapStateToProps), withTranslation());

export default enhance(ReceiptModal);
