import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import moment from "moment";
import {
  SortableContainer,
  SortableElement,
  arrayMove,
} from "react-sortable-hoc";
import { AppContent, Card, Loader, Input, FormGroup, Icon } from "../../common";
import {
  fetchTrainingTopics,
  updateTrainingTopicNoNotification,
  deleteTrainingTopic,
  addTrainingTopic,
  addTrainingTopicForm,
} from "../../actions";
import { countTopicStats } from "../../helpers/Utilities";
// import PaywallNotification from "../../containers/App/PaywallNotification";
import { TrainingTopic } from "./TrainingTopic";

/*
 * Sortable list components
 */
const SortableItem = SortableElement(
  ({
    value: topic,
    onItemEdit,
    onCheck,
    greyIndex,
    onExpand,
    t,
  }) => (
    <TrainingTopic topic={topic} onItemEdit={onItemEdit} onCheck={onCheck} greyIndex ={greyIndex} onExpand={onExpand} t={t} />
  )
);

const SortableList = SortableContainer(
  ({ items: topics, onItemDelete, onItemEdit, onCheck, onExpand, t }) => {
    return (
      <ul className="todo-list">
        {topics.map((topic, index) => (
          <SortableItem
            key={`item-${topic.id}`}
            index={index}
            value={topic}
            onItemDelete={onItemDelete}
            onItemEdit={onItemEdit}
            onCheck={onCheck}
            greyIndex={index}
            onExpand={onExpand}
            t={t}
          />
        ))}
      </ul>
    );
  }
);

/*
 * Training topic list component
 * For the sorting to work properly the list has to be kept in state.
 * Now everytime it is updated, the update has to be done in the state and in the database.
 */
class TrainingTopicList extends Component {
  state = {
    showArchived: false,
    newTrainingTopic: "",
    topicList: [],
  }

  componentDidMount() {
    this.props.fetchTrainingTopics();
  }

  // getDerivedStateFromProps is invoked right before calling the render method, both on the
  // initial mount and on subsequent updates. It should return an object to update the state,
  // or null to update nothing.
  static getDerivedStateFromProps(props, state) {
    // If currently saving or updating then no checks should be done.
    if (!props.topics.fetching && !props.topics.saving) {
      console.log("getDerivedStateFromProps not saving or fetching");
      let update = false;
      // Topic updated in any way.
      for (let topic of state.topicList) {
        let proptopic = props.topics.topics.find(propt => propt.id === topic.id);
        // updatedAt is the only check needed
        if (proptopic && proptopic.updatedAt !== topic.updatedAt) {
          update = true;
        }
      }
      if (props.topics.topics.length !== state.topicList.length) {
        update = true;
      }
      if (update) {
        console.log("update state");
        return {topicList: props.topics.topics};
      }
    }

    return null;
  }

  handleInputChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  /************************
   * Training topic actions
   ************************ */
  handleNewTrainingTopic = (e) => {
    e.preventDefault();
    let newTopic = {
      id: 0,
      done: false,
      name: this.state.newTrainingTopic,
      orderNumber: this.state.topicList.length + 1,
    };

    this.props.addTrainingTopicForm(newTopic);
  }

  handleTopicCheck = (e) => {
    e.stopPropagation();
    const id = parseInt(e.target.value, 10);
    const done = e.target.checked;
    const topic = this.state.topicList.find((topic) => topic.id === id);
    this.props.updateTrainingTopicNoNotification({
      ...topic,
      orderNumber: 0,
      done,
    });
  }

  handleExpand = (id) => {
    const topicIndex = this.state.topicList.findIndex((topic) => topic.id === id);
    let newTopicList = [...this.state.topicList];
    newTopicList[topicIndex].expanded = !newTopicList[topicIndex].expanded;
    this.setState({ topicList: newTopicList });
  }

  handleDelete = (id, e) => {
    // FYI Not in use
    e.stopPropagation();
    // Deletes from state
    let topicIndex = this.state.topicList.findIndex((topic) => topic.id === id);
    let newTopicList = [...this.state.topicList];
    newTopicList.splice(topicIndex, 1);
    this.setState({ topicList: newTopicList });

    // Deletes from database TODO: Not working for topics with subtopics
    this.props.deleteTrainingTopic(id);
  }

  handleEdit = (id, e) => {
    e.stopPropagation();
    this.props.history.push(`/training-topic/${id}`);
  }

  onSortEnd = ({ oldIndex, newIndex }) => {
    const topics = this.state.topicList;
    // TODO: Don't know why parsing has old orderNumbers but [...topics] has new orderNumbers.
    const oldTopics = JSON.parse(JSON.stringify(topics));

    let newTodo = [...topics]
      .filter((t) => !t.done)
      .sort((a, b) => a.orderNumber - b.orderNumber);
    const newDone = [...topics].filter((t) => t.done);

    newTodo = arrayMove(newTodo, oldIndex, newIndex);
    // Setting the orderNumbers to start from one
    newTodo = newTodo.map((item, index) => {
      item.orderNumber = index + 1;
      return item;
    });

    // Update training topic in database only if the orderNumber has changed to lessen requests.
    newTodo.forEach((topic) => {
      oldTopics.forEach((propTopic) => {
        if (
          topic.id === propTopic.id &&
          topic.orderNumber !== propTopic.orderNumber
        ) {
          this.props.updateTrainingTopicNoNotification({ ...topic });
        }
      });
    });

    this.setState({ topicList: newTodo.concat(newDone) });
  }

  /************************
   * Rendering
   ************************ */
  render() {
    const topics = this.state.topicList;
    const todo = topics
      .filter((t) => !t.done)
      .sort((a, b) => a.orderNumber - b.orderNumber);
    const done = topics
      .filter((t) => t.done)
      .sort((a, b) => {
        if (moment(a.updatedAt) < moment(b.updatedAt)) {
          return -1;
        }
        if (moment(a.updatedAt) > moment(b.updatedAt)) {
          return 1;
        }
        return 0;
      });

    const { showArchived, newTrainingTopic } = this.state;
    const { t } = this.props;
    const { fetching } = this.props.topics;
    const showTeaserList = !showArchived && done.length > 5;

    let topicStats = countTopicStats(this.props.topics.topics);

    // Show the loader while fetching.
    if (fetching) {
      return (
        <AppContent noMargin>
          <Loader />
        </AppContent>
      );
    }

    return (
      <AppContent noMargin>
        <Card>
          <form onSubmit={this.handleNewTrainingTopic}>
            <FormGroup className="input-and-button-group">
              <Input
                type="text"
                name="newTrainingTopic"
                label="none"
                placeholder={t("topics.addNewTitle")}
                onChange={this.handleInputChange}
                value={newTrainingTopic}
              />
              <button className="btn btn-primary">
                {t("topics.addNewButton")}
              </button>
            </FormGroup>
          </form>

          {todo.length === 0 ? (
            <div className="text-center">
              <p className="placeholder-text">{t("topics.noTopics")}</p>
            </div>
          ) : (
            <p>  
              {t("topics.trainingTopics")}{": "}
              <span className="text-success">{done.length}</span>
              /{todo.length + done.length}
              {" | "}{t("topics.subtopics")}{": "}
              <span className="text-success">{topicStats.subTopicsDone}</span>
              /{topicStats.subTopicsTotal}
              {" | "}{t("topics.exercises")}{": "}
              <span className="text-success">{topicStats.exerciseDone}</span>
              /{topicStats.exerciseTotal}
            </p>
          )}

          <SortableList
            items={todo}
            onItemDelete={this.handleDelete}
            onItemEdit={this.handleEdit}
            onCheck={this.handleTopicCheck}
            onSortEnd={this.onSortEnd}
            onExpand={this.handleExpand}
            useDragHandle={true}
            t={t}
          />
        </Card>

        {done.length > 0 && (
          <Card>
            <h2>{t("topics.archivedListTitle")}</h2>
            <p>
              {t("topics.trainingTopics")}{": "}
              <span className="text-success">{done.length}</span>
              {" | "}{t("topics.subtopics")}{": "}
              <span className="text-success">{topicStats.archivedSubTopicsTotal}</span>
              {" | "}{t("topics.exercises")}{": "}
              <span className="text-success">{topicStats.archivedExerciseTotal}</span>
            </p>
            <ul className={"todo-list todo-list--done" + (showTeaserList ? " todo-list--teaser" : "")}>
              {showArchived && done.length > 5
                ? this.renderDoneList(done)
                : this.renderDoneList(done.slice(0, 5))}
            </ul>
            {showTeaserList && (
              <div className="text-center">
                <button className="btn--link" onClick={() => this.setState({ showArchived: true })} >
                  {t("topics.showArchived")}
                </button>
              </div>
            )}
          </Card>
        )}
        {/* {!hasActivePlan && <PaywallNotification text="subscription.noPlanYet"
          button="paywall.getPremium" />} */}
      </AppContent>
    );
  }

  // Used one for topics that are done as not sortable
  renderDoneList = (list) => {
    return list.map((topic) => (
      <li key={topic.id} className="column column--align-top">
        <input
          type="checkbox"
          className="todo-list__input"
          value={topic.id}
          checked={topic.done}
          onChange={this.handleTopicCheck}
        />
        <label className="todo-list__label">
          <span className="todo-list__name">{topic.name}</span>
        </label>
        <small>{moment(topic.updatedAt).format("ll")}</small>
        <button
          className="todo-list__delete btn--link px-2"
          onClick={(e) => this.handleEdit(topic.id, e)}
        >
          <Icon icon="editGrey" />
        </button>
      </li>
    ));
  }
}

TrainingTopicList.propTypes = {
  fetchTrainingTopics: PropTypes.func,
  topics: PropTypes.object,
  addTrainingTopic: PropTypes.func,
  addTrainingTopicForm: PropTypes.func,
  updateTrainingTopicNoNotification: PropTypes.func,
  deleteTrainingTopic: PropTypes.func,
  t: PropTypes.func,
  history: PropTypes.object,
};

const mapStateToProps = ({ topics, subscriptions }) => {
  return {
    topics,
    hasActivePlan:
      subscriptions.currentPlan &&
      moment(subscriptions.currentPlan.paid_until) > moment(),
  };
};

const TrainingTopicListWithTranslate = withTranslation()(TrainingTopicList);

export default connect(mapStateToProps, {
  fetchTrainingTopics,
  updateTrainingTopicNoNotification,
  deleteTrainingTopic,
  addTrainingTopic,
  addTrainingTopicForm,
})(TrainingTopicListWithTranslate);
