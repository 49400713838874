import React, { Component } from "react";
import PropTypes from "prop-types";
import Select from "react-select";

class LabeledSelect extends Component {
  render() {
    const {
      label,
      id,
      sizeClasses,
      selectedSizeClasses,
      onChange,
      placeholder
    } = this.props;
    return (
      <React.Fragment>
        <span>{label}</span>
        <Select
          key={id}
          id={id}
          name={id}
          value={
            selectedSizeClasses &&
            selectedSizeClasses
              .filter(sizeClass => sizeClass.organizationId === id)
              .map(sizeClass => ({
                label: sizeClass.name,
                value: sizeClass.id
              }))
          }
          onChange={onChange}
          options={sizeClasses
            .filter(sizeClass => sizeClass.organizationId === id)
            .map(sizeClass => ({ label: sizeClass.name, value: sizeClass.id }))}
          isMulti={true}
          placeholder={placeholder}
        />
      </React.Fragment>
    );
  }
}

LabeledSelect.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  id: PropTypes.number,
  sizeClasses: PropTypes.array,
  selectedSizeClasses: PropTypes.array,
  onChange: PropTypes.func
};

export default LabeledSelect;
