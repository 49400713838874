import { SET_ACTIVE_DOG, ADD_DOG, LOGOUT, UPDATE_DOG } from "../actions/types";

const INITIAL_STATE = {};

export default function(state = INITIAL_STATE, { type, payload }) {
  switch (type) {
    case SET_ACTIVE_DOG:
      return payload ? payload : INITIAL_STATE;

    case ADD_DOG.SUCCEEDED:
      return !state.id && payload ? payload : state;

    case UPDATE_DOG.SUCCEEDED:
      return state.id === payload.id ? payload : state;

    case LOGOUT.REQUEST:
      return { ...INITIAL_STATE };
    default:
      return state;
  }
}
