import { FETCH_COACHES_FILTER } from "./types";

export const fetchCoachesFilter = () => ({
  type: FETCH_COACHES_FILTER.REQUEST,
});

export const fetchCoachesFilterSucceeded = (coaches) => ({
  type: FETCH_COACHES_FILTER.SUCCEEDED,
  payload: coaches,
});

export const fetchCoachesFilterFailed = (error) => ({
  type: FETCH_COACHES_FILTER.FAILED,
  payload: error,
});
