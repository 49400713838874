import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation, Trans } from "react-i18next";
import { Redirect } from "react-router-dom";

import { saveSettings } from "../../actions";
import {
  AppContent,
  FormGroup,
  CheckboxConfirm,
  RoundButton,
} from "../../common";

class Terms extends Component {
  state = {
    allowEmails: null,
    agreedTerms: false,
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const { allowEmails, agreedTerms } = this.state;
    const { userId } = this.props;

    let settings = {
      userId,
      allowEmails,
      agreedTerms,
    };
    this.props.saveSettings(settings);
  };

  handleInputChange = (e) => {
    let { name, value } = e.target;

    if (name === "agreedTerms") value = e.target.checked;
    if (name === "allowEmails") value = e.target.checked;
    this.setState({ [name]: value });
  };

  render() {
    const { t, user } = this.props;
    const { allowEmails, agreedTerms } = this.state;

    let emailsAllowed =
      allowEmails === null
        ? user.allowEmails !== null
          ? user.allowEmails
          : false
        : allowEmails;

    if (user.termsAgreed && this.props.location.pathname === "/accept-terms")
      return <Redirect to="/" />;

    return (
      <AppContent size="small">
        <h2>{t("settings.title")}</h2>

        <form onSubmit={this.handleSubmit}>
          <FormGroup>
            <CheckboxConfirm
              label={t("settings.allowEmails")}
              name="allowEmails"
              onChange={this.handleInputChange}
              value={emailsAllowed}
            />
          </FormGroup>

          <FormGroup>
            <CheckboxConfirm
              label={
                <Trans i18nKey="signup.terms">
                  <a
                    href="https://www.aginotes.com/terms-and-conditions"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    ...
                  </a>
                  <a
                    href="https://www.aginotes.com/privacy-policy"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    ...
                  </a>
                  <a
                    href="https://www.aginotes.com/privacy-policy"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    ...
                  </a>
                </Trans>
              }
              name="agreedTerms"
              onChange={this.handleInputChange}
              value={agreedTerms}
              required
              error={t("signup.terms-invalid")}
            />
          </FormGroup>

          <p>
            <RoundButton atCorner icon={"continue"} size="large" />
          </p>
        </form>
      </AppContent>
    );
  }
}

const mapStateToProps = ({ user }) => {
  return {
    userId: user.user.id,
    user: user.user,
  };
};

const TermsWithTranslate = withTranslation()(Terms);
export default connect(mapStateToProps, { saveSettings })(TermsWithTranslate);
