import {
  ADD_DOG,
  UPDATE_DOG,
  FETCH_DOGS,
  DELETE_DOG,
  UPDATE_DOG_TRIAL_SIZE_CLASS,
  FETCH_DOG
} from "./types";

export const fetchDogs = () => ({
  type: FETCH_DOGS.REQUEST
});

export const fetchDogsSucceeded = dogs => ({
  type: FETCH_DOGS.SUCCEEDED,
  payload: dogs
});

export const fetchDogsFailed = error => ({
  type: FETCH_DOGS.FAILED,
  payload: error
});

export const addDog = dog => ({
  type: ADD_DOG.REQUEST,
  payload: dog
});

export const addDogSucceeded = dog => ({
  type: ADD_DOG.SUCCEEDED,
  payload: dog
});

export const addDogFailed = error => ({
  type: ADD_DOG.FAILED,
  payload: error
});

export const updateDog = dog => ({
  type: UPDATE_DOG.REQUEST,
  payload: dog
});

export const updateDogSucceeded = dog => ({
  type: UPDATE_DOG.SUCCEEDED,
  payload: dog
});

export const updateDogFailed = error => ({
  type: UPDATE_DOG.FAILED,
  payload: error
});

export const deleteDog = id => ({
  type: DELETE_DOG.REQUEST,
  payload: id
});

export const deleteDogSucceeded = () => ({
  type: DELETE_DOG.SUCCEEDED
});

export const deleteDogFailed = error => ({
  type: DELETE_DOG.FAILED,
  payload: error
});

export const fetchDog = id => ({
  type: FETCH_DOG.REQUEST,
  payload: id
});

export const fetchDogSucceeded = dog => ({
  type: FETCH_DOG.SUCCEEDED,
  payload: dog
});

export const fetchDogFailed = error => ({
  type: FETCH_DOG.FAILED,
  payload: error
});

export const updateDogTrialSizeClass = dog => ({
  type: UPDATE_DOG_TRIAL_SIZE_CLASS.REQUEST,
  payload: dog
});

export const updateDogTrialSizeClassSucceeded = () => ({
  type: UPDATE_DOG_TRIAL_SIZE_CLASS.SUCCEEDED
});

export const updateDogTrialSizeClassFailed = error => ({
  type: UPDATE_DOG_TRIAL_SIZE_CLASS.FAILED,
  payload: error
});
