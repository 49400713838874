import React, { Component } from "react";
import PropTypes from "prop-types";
import Switch from "react-switch";

class LabeledSwitch extends Component {
  render() {
    const { text, id, checked, onChange } = this.props;
    return (
      <React.Fragment>
        <span className="switch">{text}</span>
        <span className="switch__toggle">
          <Switch
            key={id}
            id={id}
            onChange={onChange}
            checked={checked}
            offColor="#868e96"
            onColor="#28a745"
            uncheckedIcon={false}
            checkedIcon={false}
          />
        </span>
      </React.Fragment>
    );
  }
}

LabeledSwitch.propTypes = {
  text: PropTypes.string,
  id: PropTypes.number,
  checked: PropTypes.bool,
  onChange: PropTypes.func
};

export default LabeledSwitch;
