import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";

import { updateUser } from "../../actions";
import {
  AppContent,
  ImageUploader,
  FormGroup,
  Input,
  RoundButton,
} from "../../common";

class Profile extends Component {
  state = {
    firstName: "",
    lastName: "",
    email: "",
    team: "",
    id: "",
    photo: "",
    photoFile: null,
  };

  componentDidMount() {
    const { user } = this.props;
    this.setState({ ...user });
  }

  save = (e) => {
    e.preventDefault();
    this.props.updateUser({ ...this.state });
  };

  render() {
    const { t, saving } = this.props;
    const { firstName, lastName, email, team, photo } = this.state;
    return (
      <div>
        <AppContent size="small">
          <ImageUploader
            imageUrl={photo}
            useAvatar
            onUpload={this.handleImageUpload}
          />

          <FormGroup>
            <Input
              label={t("common.firstname")}
              type="text"
              name="firstName"
              value={firstName}
              onChange={this.handleInputChange}
            />
          </FormGroup>

          <FormGroup>
            <Input
              label={t("common.lastname")}
              type="text"
              name="lastName"
              value={lastName}
              onChange={this.handleInputChange}
            />
          </FormGroup>

          <FormGroup>
            <Input
              label={t("common.email")}
              type="text"
              name="email"
              value={email}
              onChange={this.handleInputChange}
              autoCapitalize="off"
            />
          </FormGroup>

          <h2 className="text-center">{t("profile.additional")}</h2>

          <FormGroup>
            <Input
              label={t("profile.team")}
              type="text"
              name="team"
              value={team}
              onChange={this.handleInputChange}
            />
          </FormGroup>
          <RoundButton
            icon={saving ? "loader" : "complete"}
            size="large"
            atCorner
            onClick={this.save}
          />
        </AppContent>
      </div>
    );
  }

  handleImageUpload = (image) => {
    this.setState({ photoFile: image.file });
  };

  handleInputChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };
}

const mapStateToProps = ({ user }) => {
  const { firstName, lastName, email, team, id, photo } = user.user;
  return {
    user: { firstName, lastName, email, team, id, photo },
    saving: user.saving,
  };
};

const ProfileWithTranslate = withTranslation()(Profile);
export default connect(mapStateToProps, { updateUser })(ProfileWithTranslate);
