import React, { Component } from "react";
import PropTypes from "prop-types";
import { Medal } from "../common";

class Placements extends Component {

  render() {
    const { title, positions } = this.props;
    return (
      <div className="placement">
        <h3>{title}</h3>
        <div className="placement__container">
          {Object.keys(positions).map(key => (
            <div key={key} className="placement__inner">
              <Medal placement={parseInt(key, 10)} size="medium" />
              <span>{positions[key]}</span>
            </div>
          ))
          }
        </div>
      </div>
    );
  }
}

Placements.propTypes = {
  title: PropTypes.string,
  positions: PropTypes.any
};

export default Placements;
