import React, { Component } from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import { Chart as ChartElement } from "chart.js";
import { isVisible } from "../helpers/Utilities";

export const POLAR_CHART_BASE = {
  labels: [],
  datasets: [],
};

export const POLAR_CHART_DATASET_BASE = {
  label: "",
  data: [],
  backgroundColor: [
    "rgba(255, 99, 132, .5)",
    "rgba(255, 159, 64, .5)",
    "rgba(255, 205, 86, .5)",
    "rgba(75, 192, 192, .5)",
    "rgba(54, 162, 235, .5)",
    "rgba(153, 102, 255, .5)",
    "rgba(201, 203, 207, .5)",
  ],
  borderWidth: 1,
};

const POLAR_CHART_OPTIONS = {
  legend: {
    position: "bottom",
  },
  title: {
    display: true,
    text: "",
  },
  scales: {
    ticks: {
      stepSize: 1,
    },
  },
  tooltips: {
    enabled: false,
  },
};

export const LINE_CHART_BASE = {
  labels: [],
  datasets: [],
};

export const LINE_CHART_DATASET_BASE = {
  label: "",
  data: [],
  backgroundColor: "rgba(255, 99, 132, .5)",
  borderWidth: 1,
};

const LINE_CHART_OPTIONS = {
  legend: {
    position: "bottom",
  },
  title: {
    display: true,
    text: "",
  },
  tooltips: {
    enabled: false,
  },
};

export const BAR_CHART_BASE = {
  labels: [],
  datasets: [],
};

export const BAR_CHART_DATASET_BASE = {
  label: "",
  data: [],
  backgroundColor: "rgba(255, 99, 132, .5)",
  borderWidth: 1,
};

const BAR_CHART_OPTIONS = {
  legend: {
    position: "bottom",
  },
  title: {
    display: false,
    text: "",
  },
  tooltips: {
    enabled: false,
  },
  responsive: true,
  maintainAspectRatio: true,
  scales: {
    xAxes: [
      {
        stacked: true,
      },
    ],
    yAxes: [
      {
        stacked: true,
        ticks: {
          suggestedMin: 0,
          suggestedMax: 5,
        },
      },
    ],
  },
};

class Chart extends Component {
  constructor(props) {
    super(props);
    let t = props.t;

    this.timefilters = [
      {
        label: t("chart.lastYear"),
        value: -1,
        unit: "years",
      },
      {
        label: t("chart.last30Days"),
        value: -30,
        unit: "days",
      },
      {
        label: t("chart.all"),
        value: -30,
        unit: "years",
      },
    ];
  }

  componentDidMount() {
    this.build();
  }

  componentDidUpdate(prevProps) {
    if (JSON.stringify(prevProps.data) !== JSON.stringify(this.props.data)) {
      if (this.chart) {
        this.chart.destroy();
      }
      this.build();
    }
  }

  build = () => {
    const { type, data, options, plugins } = this.props;

    if (!data) return;

    let datasetBase = POLAR_CHART_DATASET_BASE;
    switch (type) {
      case "line":
        datasetBase = LINE_CHART_DATASET_BASE;
        break;
      case "bar":
        datasetBase = BAR_CHART_DATASET_BASE;
        break;
      default:
        datasetBase = POLAR_CHART_DATASET_BASE;
        break;
    }

    const formattedData = { ...POLAR_CHART_BASE };
    // formattedData.datasets[0].data = [...data.datasets];
    formattedData.datasets = data.datasets.map((dataset) => {
      return {
        ...datasetBase,
        ...dataset,
      };
    });
    formattedData.labels = data.labels ? [...data.labels] : "";

    let optionsBase = POLAR_CHART_OPTIONS;
    switch (type) {
      case "line":
        optionsBase = LINE_CHART_OPTIONS;
        break;
      case "bar":
        optionsBase = BAR_CHART_OPTIONS;
        break;
      default:
        optionsBase = POLAR_CHART_OPTIONS;
        break;
    }

    if (isVisible(this.chartElement)) {
      const chart = new ChartElement(this.chartElement, {
        type,
        data: formattedData,
        options: { ...optionsBase, ...options },
        plugins: plugins,
      });

      this.chart = chart;
      this.chart.tooltip.initialize();
      this.chart.update();
    }
  };

  componentWillUnmount() {
    this.setState({ chart: null });
  }

  render() {
    const {
      name,
      title,
      courseTypes,
      width,
      hideAllMenus,
      courseTypeId,
      onCourseTypeChange,
    } = this.props;

    let chartWidth = width ? width : 400;
    let chartHeight = 400; //height ? height :

    return (
      <React.Fragment>
        <div className="column column--align-top">
          {title && <h2 className="p no-margin">{title}</h2>}

          {!hideAllMenus && (
            <div className="chart-select-group">
              {courseTypes && (
                <select
                  name={name}
                  className="ghost-select chart-select-group__item"
                  onChange={onCourseTypeChange}
                  ref={(element) => (this.courseTypeSelect = element)}
                  value={courseTypeId ? courseTypeId : ""}
                >
                  {courseTypes.map((filter, index) => (
                    <option key={filter.name} value={filter.value}>
                      {filter.label}
                    </option>
                  ))}
                </select>
              )}
            </div>
          )}
        </div>
        <div className="chart-parent">
          <canvas
            width={chartWidth}
            height={chartHeight}
            ref={(element) => {
              this.chartElement = element;
            }}
          />
        </div>
      </React.Fragment>
    );
  }
}

Chart.propTypes = {
  type: PropTypes.oneOf(["line", "polarArea", "bar"]),
  title: PropTypes.any,
  courseTypes: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      label: PropTypes.string,
    })
  ),
  options: PropTypes.object,
  plugins: PropTypes.array,
  width: PropTypes.string,
  height: PropTypes.string,
  hideAllMenus: PropTypes.string,
  name: PropTypes.string,
  courseTypeId: PropTypes.any,
  onCourseTypeChange: PropTypes.func,
};

Chart.defaultProps = {
  onChange: () => {},
  type: "line",
};

export default withTranslation()(Chart);
