import React from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";

const LoginRoute = ({
  component: Component,
  isAuthenticated,
  sharedTopicId,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={props => {
        if (!isAuthenticated) {
          return <Component {...props} />;
        }
        if (sharedTopicId) {
          return (
            <Redirect
              to={{
                pathname: `/shared/training-topic/${sharedTopicId}`,
                state: { from: props.location }
              }}
            />
          );
        } else {
          return (
            <Redirect
              to={{
                pathname: "/",
                state: { from: props.location }
              }}
            />
          );
        }
      }}
    />
  );
};

const mapStateToProps = ({
  auth: { isAuthenticated },
  topics: { sharedTopicId }
}) => ({
  isAuthenticated,
  sharedTopicId
});

export default connect(mapStateToProps)(LoginRoute);
