import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { withTranslation } from "react-i18next";
import ReactPlayer from "react-player";
import InstagramEmbed from "react-instagram-embed";

import { Card, CardHeader, Stars, Image } from "../common";
import { castToNestedFocusArray } from "../helpers/Transformer";
import { Document, Page, pdfjs } from "react-pdf";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const TrainingCard = ({ dog, training, t, onClick, calendar }) => {
  // const shareLink = `http://www.facebook.com/sharer.php?u=${window.location.origin}/dog/${dog.id}/feed/${feedId}`;

  var displayDate = moment(training.date).format("L");
  // var currentDate = moment().format("YYYY-MM-DD");
  // var diff = moment(trainingDate).diff(currentDate, "days");
  // var displayDate = "";
  // if (diff >= 1 || diff <= -1) {
  //   displayDate = moment(trainingDate).from(currentDate);
  // } else {
  //   displayDate = moment(trainingDate)
  //     .calendar(currentDate)
  //     .split(" ")[0];
  // }

  return (
    <Card calendar={calendar}>
      <CardHeader
        image={dog ? dog.photo : ""}
        title={
          t(`training.${training.trainingType}`) +
          " " +
          (training.coach ? training.coach : "")
        }
        subTitle={displayDate}
        onClick={() => onClick(training.id)}
      />

      {renderFocuses()}

      {training.videoUrl && training.videoUrl.indexOf("instagram") === -1 && (
        <ReactPlayer url={training.videoUrl} controls width="100%" />
      )}

      {training.videoUrl && training.videoUrl.indexOf("instagram") !== -1 && (
        <InstagramEmbed url={training.videoUrl} />
      )}

      {!training.videoUrl &&
        training.courseMapVariants &&
        training.courseMapVariants.original &&
        training.courseMapVariants.original.indexOf(".pdf") !== -1 && (
        <Document file={training.courseMapVariants.original}>
          <Page pageNumber={1} />
        </Document>
      )}
      {!training.videoUrl &&
        training.courseMapVariants &&
        training.courseMapVariants.original &&
        training.courseMapVariants.original.indexOf(".pdf") === -1 && (
        <Image src={training.courseMapVariants.hd} />
      )}

      <div className="card__footer">
        {training.rating && <Stars value={training.rating} size="small" />}
        {/* {feedId && (
          <a
            className="share-link"
            href={shareLink}
            target="_blank"
            rel="noreferrer noopener"
            onClick={e => {
              onShare(feedId, e);
            }}
          >
            {t("common.share")}
          </a>
        )} */}
      </div>
    </Card>
  );

  function renderFocuses() {
    if (!training.focuses || training.focuses.length === 0) return null;
    return (
      <React.Fragment>
        <p className="tag no-margin">{t("training.focus")}</p>
        <h3 className="inline-list">
          {castToNestedFocusArray(training.focuses).map((focus) => (
            <span key={focus.key}>
              {t(`misc.${focus.name}`)}
              {focus.children && focus.children.length > 0 && (
                <span>
                  : {focus.children.map((f) => t(`misc.${f.name}`)).join(", ")}
                </span>
              )}
            </span>
          ))}
        </h3>
      </React.Fragment>
    );
  }
};

TrainingCard.propTypes = {
  dog: PropTypes.object,
  training: PropTypes.object,
  onClick: PropTypes.func,
  onShare: PropTypes.func,
  t: PropTypes.func.isRequired,
  calendar: PropTypes.bool
};

TrainingCard.defaultProps = {
  onClick: () => {},
  onShare: () => {},
};

export default withTranslation()(TrainingCard);
