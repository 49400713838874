import React, { Component } from "react";
import PropTypes from "prop-types";
import Autosuggest from "react-autosuggest";

class SuggestInput extends Component {
  render() {
    const {
      suggestions,
      value,
      name,
      label,
      onChange,
      onBlur,
      onFetch,
      onClear,
      suggestionValue,
      renderSuggestion
    } = this.props;

    function shouldRenderSuggestions(value) {
      return value.trim().length > 1;
    }

    return (
      <div>
        <label>{label}</label>
        <Autosuggest
          suggestions={suggestions}
          onSuggestionsFetchRequested={onFetch}
          onSuggestionsClearRequested={onClear}
          getSuggestionValue={suggestionValue}
          renderSuggestion={renderSuggestion}
          shouldRenderSuggestions={shouldRenderSuggestions}
          inputProps={{
            value,
            onChange,
            onBlur,
            name,
            className: "form-control"
          }}
        />
      </div>
    );
  }
}

SuggestInput.propTypes = {
  suggestions: PropTypes.array,
  label: PropTypes.string,
  value: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onFetch: PropTypes.func.isRequired,
  onClear: PropTypes.func.isRequired,
  suggestionValue: PropTypes.func.isRequired,
  renderSuggestion: PropTypes.any.isRequired
};

SuggestInput.defaultProps = {
  onChange: () => { }
};

export default SuggestInput;
