import React from "react";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { AppContent } from "../common";
import image from "../images/running-dog.png";

const Page404 = ({ t }) => {
  return (
    <AppContent size="small">
      <div className="text-center">
        <h2>{t("404.title")}</h2>
        <img src={image} alt={t("404.image-alt")} />
        <p>{t("404.content")}</p>
        <p>
          <Link className="btn btn-primary" to="/">
            {t("404.buttonLabel")}
          </Link>
        </p>
      </div>
    </AppContent>
  );
};

export default withTranslation()(Page404);
