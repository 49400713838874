import React, { Component } from "react";
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from "@stripe/react-stripe-js";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Api from "../../helpers/Api";
import moment from "moment";
import "moment/locale/fi";
import "moment/locale/sv";
import "moment/locale/fr";
import "moment/locale/nb";
import "moment/locale/es";
import "moment/locale/de";
import "moment/locale/en-gb";
import "moment/locale/da";
import "moment/locale/it";
import "moment/locale/pt";
import "moment/locale/nl";
import "moment/locale/pl";

import { FormGroup } from "../../common";

import {
  validateCoupon,
  fetchCoupon,
  updateCoupon,
} from "../../actions/CouponActions";

class CheckoutForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      cardReady: false,
      expiryReady: false,
      cvcReady: false,
      submitting: false,
      couponCode: "",
      couponUsed: false,
      coupon: null,
      isCouponValid: false,
      couponClass: "card-number-field",
    };
  }

  handleSubmit = async (event) => {
    event.preventDefault();

    this.setState({ submitting: true });
    const { stripe, elements } = this.props;

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    var cardNumberElement = elements.getElement("cardNumber");

    var cardExpiryElement = elements.getElement("cardExpiry");

    var cardCvcElement = elements.getElement("cardCvc");

    const latestInvoicePaymentIntentStatus = localStorage.getItem(
      "latestInvoicePaymentIntentStatus"
    );

    // Use your card Element with other Stripe.js APIs
    const res = await stripe.createPaymentMethod({
      type: "card",
      card: cardNumberElement,
    });

    if (res.error) {
      console.log("createPaymentMethod error", res.error);
      // setSubscribing(false);
      // setErrorToDisplay(error && error.message);
      return;
    } else {
      //TODO: Tees tilaus tähän!!!
      const paymentMethodId = res.paymentMethod.id;

      if (latestInvoicePaymentIntentStatus === "requires_payment_method") {
        // Update the payment method and retry invoice payment
        const invoiceId = localStorage.getItem("latestInvoiceId");
        // retryInvoiceWithNewPaymentMethod({
        //   paymentMethodId: paymentMethodId,
        //   invoiceId: invoiceId,
        // });
        return;
      }

      //Create the subscription
      this.createSubscription(paymentMethodId);
    }
  };

  createSubscription = (paymentMethodId) => {
    this.props.onCheckout(paymentMethodId);
    //pitää lisätä error hanglind sen mukaan, mikä voi mennä pieleen!!!
  };

  //TODO: Change this
  validateAndUpdateCoupon = (couponCode, userId) => {
    const { auth_token } = this.props;
    let isCouponValid = false;
    Api({ auth_token })
      .validateCoupon(couponCode)
      .then((res) => {
        if (res.data.is_valid) {
          isCouponValid = true;
          Api({ auth_token })
            .fetchCoupon(couponCode)
            .then((res) => {
              let coupon = res.data;
              coupon.userId = userId;
              Api({ auth_token })
                .updateCoupon(coupon)
                .then((res) => {
                  this.setState((prevState, props) => {
                    return {
                      isCouponValid: isCouponValid,
                      coupon: res.data,
                      couponClass: (prevState.couponClass += " valid-coupon"),
                    };
                  });
                });
            });
        } else
          this.setState((prevState, props) => {
            const couponClass =
              couponCode.length === 11
                ? (prevState.couponClass += " invalid-coupon")
                : "card-number-field";
            return {
              isCouponValid: isCouponValid,
              couponClass: couponClass,
            };
          });
      });
  };

  handleChange = (e) => {
    let couponCode = e.target.value;
    let couponUsed = false;
    this.setState((prevState, props) => {
      if (couponCode) {
        if (prevState.couponCode.length > 10 || couponCode.length > 10) {
          this.validateAndUpdateCoupon(couponCode, this.props.user.id);
          if (couponCode.length > 10) couponUsed = true;
        } else {
          return {
            couponCode: couponCode,
            couponUsed: couponUsed,
            isCouponValid: false,
            couponClass: "card-number-field",
          };
        }
      }
      return {
        couponCode: couponCode,
        couponUsed: couponUsed,
      };
    });
  };

  renderCouponTitle = () => {
    const { t, isCardUpdate } = this.props;
    if (!isCardUpdate)
      return (
        <h2 style={{ textAlign: "left " }}>
          {t("subscription.payment.couponCode")}
        </h2>
      );
  };

  renderCouponInput = () => {
    const { couponCode, couponClass } = this.state;
    if (!this.props.isCardUpdate) {
      return (
        <div className={couponClass}>
          <input
            type="text"
            name="couponCode"
            className="stripe-input"
            placeholder="123-123-123"
            onChange={this.handleChange}
            value={couponCode}
          ></input>
        </div>
      );
    }
  };

  renderPlanInfo = () => {
    const { coupon, isCouponValid } = this.state;
    const { plan, t, isCardUpdate } = this.props;
    if (!isCardUpdate) {
      let pricePerPayment = this.getPricePerPayment();
      if (coupon && isCouponValid) {
        if (plan.id === 1) {
          return (
            <div className="text-grey">
              {String(moment().format("L"))} -{" "}
              {String(moment().add(1, "years").add(-1, "days").format("L"))}:{" "}
              {pricePerPayment}€<br />
              {String(moment().add(1, "years").format("L"))} &rarr;{" "}
              {plan.price_per_payment}€ /{" "}
              {t("subscription.payment." + plan.period)}
            </div>
          );
        } else {
          return (
            <div className="text-grey">
              {String(moment().format("L"))} -{" "}
              {String(
                moment()
                  .add(coupon.discountMonths, "months")
                  .add(-1, "days")
                  .format("L")
              )}
              : {pricePerPayment}€<br />
              {String(
                moment().add(coupon.discountMonths, "months").format("L")
              )}{" "}
              &rarr; {plan.price_per_payment}€ /{" "}
              {t("subscription.payment." + plan.period)}
            </div>
          );
        }
      }
    }
  };

  getDiscount() {
    const { coupon } = this.state;
    return coupon.discountMonths / 12.0;
  }

  getPricePerPayment() {
    const { coupon, isCouponValid } = this.state;
    const { plan } = this.props;
    let pricePerPayment = plan.price_per_payment;
    if (coupon && isCouponValid) {
      if (plan.id === 1) {
        //Yearly
        let discount = this.getDiscount(coupon);
        pricePerPayment = (
          pricePerPayment -
          pricePerPayment * discount
        ).toFixed(2);
      } else {
        //monthly - always at least first month free
        pricePerPayment = 0;
      }
    }
    return pricePerPayment;
  }

  render() {
    const { t, validating, plan, isCardUpdate, stripe } = this.props;
    const {
      cardReady,
      expiryReady,
      cvcReady,
      submitting,
      couponUsed,
      isCouponValid,
    } = this.state;
    const enableSubmit = stripe; // && cardReady && expiryReady && cvcReady;

    if (!stripe) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    let buttonText = this.props.buttonText;
    if (!isCardUpdate) {
      buttonText = t("subscription.payment.pay", {
        currency: plan.currency,
        amount: this.getPricePerPayment(),
      });
    }

    return (
      <form onSubmit={this.handleSubmit}>
        <FormGroup>
          <h2 style={{ textAlign: "left " }}>{t("subscription.creditCard")}</h2>
          <div className="card-number-field">
            <CardNumberElement
              onChange={(e) => this.setState({ cardReady: e.complete })}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <div
              className="card-number-field"
              style={{ flex: 1, margin: "10px", marginLeft: "0px" }}
            >
              <CardExpiryElement
                onChange={(e) => this.setState({ expiryReady: e.complete })}
              />
            </div>
            <div
              className="card-number-field"
              style={{ flex: 1, margin: "10px", marginRight: "0px" }}
            >
              <CardCvcElement
                onChange={(e) => this.setState({ cvcReady: e.complete })}
              />
            </div>
          </div>
          {this.renderCouponTitle()}
          {this.renderCouponInput()}
        </FormGroup>
        <button
          disabled={
            !enableSubmit ||
            submitting ||
            validating ||
            (!isCouponValid && couponUsed)
          }
          className="btn btn-primary"
          style={{ width: "100%" }}
        >
          {buttonText}
        </button>
        {this.renderPlanInfo()}
      </form>
    );
  }
}

CheckoutForm.propTypes = {
  buttonText: PropTypes.string,
  t: PropTypes.func,
  dispatch: PropTypes.func,
  stripe: PropTypes.any,
  user: PropTypes.object,
  onCheckout: PropTypes.func,
  onFailure: PropTypes.func,
  validateCoupon: PropTypes.func,
  updateCoupon: PropTypes.func,
  isCouponValid: PropTypes.bool,
  coupon: PropTypes.object,
  validating: PropTypes.bool,
  updating: PropTypes.bool,
  plan: PropTypes.object,
  isCardUpdate: PropTypes.bool,
};

const mapStateToProps = ({ coupons }) => ({
  isCouponValid: coupons.isValid,
  validating: coupons.validating,
  coupon: coupons.coupon,
  updating: coupons.updating,
});

const mapDispatchToProps = {
  validateCoupon,
  fetchCoupon,
  updateCoupon,
};

const CheckoutFormWithTranslate = withTranslation()(CheckoutForm);
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CheckoutFormWithTranslate);
