import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import moment from "moment";
import ReactPlayer from "react-player";
import PropTypes from "prop-types";
import InstagramEmbed from "react-instagram-embed";

import { Stars, Image } from "../../common";
import { castToNestedFocusArray } from "../../helpers/Transformer";
import { Document, Page, pdfjs } from "react-pdf";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

class TrainingDiaryView extends Component {
  handleGoBack = () => {
    if (localStorage.getItem("trainingFilters")) {
      this.props.history.push(`/training-diary/?f`);
    } else {
      this.props.history.push(`/training-diary/`);
    }
  };

  render() {
    const { t, training } = this.props;
    return (
      <React.Fragment>
        <button className="trial-back-button" onClick={this.handleGoBack}>
          <strong>&larr;</strong> {t("common.back")}
        </button>
        <h1 className="text-center">{moment(training.date).format("L")}</h1>

        {training.rating && (
          <div className="text-center">
            <Stars value={training.rating} />
          </div>
        )}

        <br />
        <br />

        <div>
          <p>
            <span className="tag no-margin">
              {t(`training.${training.trainingType}`)}
            </span>
            <br />
            <span className="h2">{training.coach ? training.coach : ""}</span>
          </p>
          <p className="tag no-margin">{t("training.focus")}</p>
          <p className="h2 inline-list">
            {castToNestedFocusArray(training.focuses).map((focus) => (
              <span key={focus.key}>
                {t(`misc.${focus.name}`)}
                {focus.children &&
                  ": " +
                    focus.children
                      .map((subFocus) => t(`misc.${subFocus.name}`))
                      .join(", ")}
              </span>
            ))}
          </p>
        </div>

        {training.videoUrl && training.videoUrl.indexOf("instagram") === -1 && (
          <div className="p">
            <ReactPlayer url={training.videoUrl} controls width="100%" />
          </div>
        )}

        {training.videoUrl && training.videoUrl.indexOf("instagram") !== -1 && (
          <div className="p">
            <InstagramEmbed url={training.videoUrl} />
          </div>
        )}

        <p className="multiLine">{training.notes}</p>

        {training.courseMap && (
          <div className="p">
            {t("training.courseMap")}
            {training.courseMap.indexOf(".pdf") !== -1 && (
              <Document file={training.courseMap}>
                <Page pageNumber={1} />
              </Document>
            )}
            {training.courseMap.indexOf(".pdf") === -1 && (
              <Image src={training.courseMap} alt={t("training.courseMap")} />
            )}
          </div>
        )}
      </React.Fragment>
    );
  }
}

TrainingDiaryView.propTypes = {
  training: PropTypes.object,
  t: PropTypes.func,
  history: PropTypes.object,
};

export default withTranslation()(TrainingDiaryView);
