import React, { Component } from "react";
import PropTypes from "prop-types";
import defaultImage from "../images/avatar-default.png";

import Loader from "./Loader";
import Medal from "./Medal";

class Avatar extends Component {
  state = {
    imageSrc: "",
    loading: false
  };

  render() {
    const { image, size, loading, className, onClick, placement } = this.props;
    const imageURL = image ? image : defaultImage;
    const classNames = className.split(" ");
    classNames.push(loading ? "avatar--loading" : "");
    const classNamesString = classNames.join(" ");

    return (
      <div
        className={`avatar avatar--${size} ${classNamesString}`}
        style={{ backgroundImage: `url(${imageURL})` }}
        onClick={onClick}
      >
        {placement && <Medal placement={placement} size="small" />}
        {loading ? <Loader size="small" /> : null}
      </div>
    );
  }
}

Avatar.propTypes = {
  image: PropTypes.string,
  size: PropTypes.oneOf(["large", "medium", "small"]),
  loading: PropTypes.bool,
  className: PropTypes.string,
  onClick: PropTypes.func,
  placement: PropTypes.number
};

Avatar.defaultProps = {
  size: "medium",
  loading: false,
  className: ""
};

export default Avatar;
