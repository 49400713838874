import { all, fork } from "redux-saga/effects";

import AuthSagas from "./AuthSagas";
import UserSagas from "./UserSagas";
import DogSagas from "./DogSagas";
import TrainingSagas from "./TrainingSagas";
import SettingsSaga from "./SettingsSaga";
import TrialSaga from "./TrialSagas";
import TopicSaga from "./TopicSagas";
import AdminSagas from "./AdminSagas";
import SubscriptionSaga from "./SubscriptionSagas";
import CouponSagas from "./CouponSagas";
import StatisticSagas from "./StatisticSagas";
import OrganizationSagas from "./OrganizationSagas";
import SizeClassSagas from "./SizeClassSagas";
import CourseTypeSagas from "./CourseTypeSagas";
import TrialFilterSagas from "./TrialFilterSagas";
import TrainingFilterSagas from "./TrainingFilterSagas";

export default function* rootSaga() {
  yield all([
    fork(AuthSagas),
    fork(UserSagas),
    fork(DogSagas),
    fork(TrainingSagas),
    fork(SettingsSaga),
    fork(TrialSaga),
    fork(TopicSaga),
    fork(AdminSagas),
    fork(SubscriptionSaga),
    fork(CouponSagas),
    fork(StatisticSagas),
    fork(OrganizationSagas),
    fork(SizeClassSagas),
    fork(CourseTypeSagas),
    fork(TrialFilterSagas),
    fork(TrainingFilterSagas),
  ]);
}
