import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { Redirect } from "react-router-dom";

import { AppContent, Loader } from "../../common";
import { deleteTraining } from "../../actions/";
import ActionMenu from "../../components/ActionMenu";
import confirm from "../../components/Confirm";
import TrainingDiaryView from "./TrainingDiaryView";

class TrainingDiarySingle extends Component {
  constructor(props) {
    super(props);
    let t = props.t;
    this.state = {
      subActions: [
        {
          label: t("common.edit"),
          onClick: () => {
            const { id } = this.props.match.params;
            this.props.history.push(`/training-diary/edit/${id}`);
          },
        },
        {
          label: t("common.delete"),
          onClick: () => {
            const { id } = this.props.match.params;
            const { dog, t } = this.props;

            confirm(t("training.deleteConfirm"), {
              title: t("common.areyousure"),
              ok: t("common.ok"),
              cancel: t("common.cancel"),
            }).then(
              () => this.props.deleteTraining({ dog, id: Number(id) }),
              () => {}
            );
          },
        },
      ],
    };
  }

  render() {
    const { isAuthenticated, training } = this.props;
    if (isAuthenticated && !training) return <Redirect to="/training-diary" />;

    if (!training)
      return (
        <AppContent>
          <Loader />
        </AppContent>
      );

    return (
      <AppContent size="medium">
        <TrainingDiaryView training={training} history={this.props.history} />

        <ActionMenu subActions={this.state.subActions} mainAction="training" />
      </AppContent>
    );
  }
}

TrainingDiarySingle.propTypes = {
  t: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool,
  training: PropTypes.object,
  deleteTraining: PropTypes.func,
  dog: PropTypes.object,
  history: PropTypes.any,
  match: PropTypes.object,
};

const mapStateToProps = ({ auth, trainings, dogs }, props) => {
  const { id } = props.match.params;
  const training = trainings.trainings.find((t) => t.id === parseInt(id, 10));
  const dog = training ? dogs.dogs.find((d) => d.id === training.dogId) : null;
  return { isAuthenticated: auth.isAuthenticated, training, dog };
};

const TrainingDiarySingleWithTranslate = withTranslation()(TrainingDiarySingle);
export default connect(mapStateToProps, { deleteTraining })(
  TrainingDiarySingleWithTranslate
);
