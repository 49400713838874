import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { Modal, Loader, FormGroup, Select } from "../common";
import ReactSelect from "react-select";
import "react-dates/lib/css/_datepicker.css";
import "react-dates/initialize";
import { DateRangePicker } from "react-dates";
import moment from "moment";

class TrainingFilterModal extends Component {
  state = {
    startDate: "",
    endDate: "",
    focused: null,
  };

  setLast30Days = () => {
    let startDate = moment().add(-30, "day");
    let endDate = moment();
    this.props.updateDateFilter({ startDate, endDate });
  };

  setLastYear = () => {
    let startDate = moment().add(-1, "year");
    let endDate = moment();
    this.props.updateDateFilter({ startDate, endDate });
  };

  onFocusChanged = (focused) => {
    this.setState({ focused });
  };

  render() {
    const {
      t,
      coachesFilter,
      datesFilter,
      trainingTypeFilter,
      updateCoachesFilter,
      updateDateFilter,
      updateTrainingTypeFilter,
      fetchingCoachesFilter,
      coaches,
      showModal,
      onModalClose,
      onApplyFilters,
      trainingsCount,
    } = this.props;

    let loading = fetchingCoachesFilter;

    let trainingTypeOptions = [
      {
        value: "",
        title: t("chart.all"),
      },
      {
        value: "coached",
        title: t("training.coached"),
      },
      {
        value: "independent",
        title: t("training.independent"),
      },
    ];

    let filterDate =
      this.state.startDate && this.state.endDate
        ? [this.state.startDate, this.state.endDate]
        : datesFilter;

    return (
      <Modal
        className="modal-medium"
        contentClassName="modal-content"
        show={showModal}
        hideHorizontalLine
        onClose={() => {
          onModalClose();
        }}
      >
        {!loading && (
          <div className={"filter-body"}>
            <div>
              <FormGroup>
                <label>{t("filters.date")}</label>
                <br />
                <DateRangePicker
                  startDate={
                    filterDate && filterDate[0] ? moment(filterDate[0]) : null
                  }
                  startDateId="startDate"
                  endDate={
                    filterDate && filterDate[1] ? moment(filterDate[1]) : null
                  }
                  endDateId="endDate"
                  onDatesChange={updateDateFilter}
                  focusedInput={this.state.focused}
                  onFocusChange={this.onFocusChanged}
                  showClearDates
                  isOutsideRange={() => false}
                  readOnly={false}
                  withPortal={true}
                  numberOfMonths={1}
                  startDatePlaceholderText={t("filters.startDate")}
                  endDatePlaceholderText={t("filters.endDate")}
                />
                <ul className="list list--simple">
                  <li className="list__item-simple">
                    <button
                      onClick={this.setLast30Days}
                      className="trial-link-button"
                    >
                      {t("filters.last30Days")}
                    </button>
                  </li>
                  <li className="list__item-simple">
                    <button
                      onClick={this.setLastYear}
                      className="trial-link-button"
                    >
                      {t("filters.lastYear")}
                    </button>
                  </li>
                </ul>
              </FormGroup>
            </div>
            <div>
              <FormGroup>
                <Select
                  label={t("filters.trainingType")}
                  name="trainingTypeFilter"
                  value={trainingTypeFilter + ""}
                  onChange={updateTrainingTypeFilter}
                  options={trainingTypeOptions}
                />
              </FormGroup>
            </div>
            <div>
              <FormGroup>
                <label>{t("filters.coach")}</label>
                <ReactSelect
                  key="coaches"
                  id="coaches"
                  value={coachesFilter}
                  onChange={updateCoachesFilter}
                  isMulti={true}
                  options={coaches.map((coach) => ({
                    label: coach.name,
                    value: coach.id,
                  }))}
                  placeholder={t("common.choose")}
                />
              </FormGroup>
            </div>
            <div>
              {trainingsCount} {t("filters.trainingsCount")}
            </div>
            <div>
              <button
                disabled={trainingsCount <= 0}
                className="btn btn-outline-primary"
                onClick={() => {
                  onApplyFilters();
                }}
              >
                {t("filters.applyFilters")}
              </button>
              <button className="btn" onClick={() => onModalClose()}>
                {t("common.cancel")}
              </button>
            </div>
          </div>
        )}

        {loading && <Loader />}
      </Modal>
    );
  }
}

TrainingFilterModal.propTypes = {
  t: PropTypes.func,
  coachesFilter: PropTypes.array,
  datesFilter: PropTypes.array,
  trainingTypeFilter: PropTypes.any,
  updateCoachesFilter: PropTypes.func,
  updateDateFilter: PropTypes.func,
  updateTrainingTypeFilter: PropTypes.func,
  fetchCoachesFilter: PropTypes.func,
  fetchingCoachesFilter: PropTypes.bool,
  coaches: PropTypes.array,
  showModal: PropTypes.bool,
  onModalClose: PropTypes.func,
  onApplyFilters: PropTypes.func,
  trainingsCount: PropTypes.number,
};

const TrainingFilterModalWithTranslate = withTranslation()(TrainingFilterModal);

export default connect()(TrainingFilterModalWithTranslate);
