import {
  FETCH_JUDGES_FILTER,
  FETCH_TRIAL_LOCATIONS_FILTER,
  SET_ACTIVE_DOG,
} from "../actions/types";

const INITIAL_STATE = {
  judges: [],
  trialLocations: [],
  fetchingJudgesFilter: false,
  errorInJudgesFilter: null,
  fetchingTrialLocationsFilter: false,
  errorInTrialLocationsFilter: null,
};

export default (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case FETCH_JUDGES_FILTER.SUCCEEDED:
      return {
        ...state,
        judges: payload,
        fetchingJudgesFilter: false,
      };

    case FETCH_JUDGES_FILTER.REQUEST:
      return {
        ...state,
        ...payload,
        fetchingJudgesFilter: true,
      };

    case FETCH_JUDGES_FILTER.FAILED:
      return {
        ...state,
        errorInJudgesFilter: payload,
        fetchingJudgesFilter: false,
      };

    case FETCH_TRIAL_LOCATIONS_FILTER.SUCCEEDED:
      return {
        ...state,
        trialLocations: payload,
        fetchingTrialLocationsFilter: false,
      };

    case FETCH_TRIAL_LOCATIONS_FILTER.REQUEST:
      return {
        ...state,
        ...payload,
        fetchingTrialLocationsFilter: true,
      };

    case FETCH_TRIAL_LOCATIONS_FILTER.FAILED:
      return {
        ...state,
        errorInTrialLocationsFilter: payload,
        fetchingTrialLocationsFilter: false,
      };

    case SET_ACTIVE_DOG:
      return INITIAL_STATE;

    default:
      return state;
  }
};
