import {
  FETCH_USER,
  UPDATE_USER,
  SIGNUP,
  LOGOUT,
  AFTER_REHYDRATE,
  SAVE_SETTINGS
} from "../actions/types";

const INITIAL_STATE = {
  user: {},
  fetching: false,
  error: false,
  saving: false,
  shouldAcceptTerms: false
};

export default (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case SIGNUP.SUCCEEDED:
      return {
        ...state,
        user: { ...payload }
      };

    case LOGOUT.REQUEST:
      return INITIAL_STATE;

    case FETCH_USER.REQUEST:
      return {
        ...state,
        ...payload
      };

    case SAVE_SETTINGS.SUCCEEDED:
    case FETCH_USER.SUCCEEDED:
      return {
        ...state,
        user: { ...payload }
      };

    case UPDATE_USER.REQUEST:
      return {
        ...state,
        saving: true
      };
    case UPDATE_USER.FAILED:
      return {
        ...state,
        saving: false
      };
    case UPDATE_USER.SUCCEEDED:
      return {
        ...state,
        saving: false,
        user: { ...payload }
      };

    case AFTER_REHYDRATE:
      return { ...state, fetching: false, saving: false };

    default:
      return state;
  }
};
