import { call, put, takeLatest, takeEvery, select } from "redux-saga/effects";

import Api from "../helpers/Api";
import { getAuthToken } from "./selectors";
import {
  FETCH_COUPON,
  UPDATE_COUPON,
  ADD_COUPON,
  VALIDATE_COUPON,
  FETCH_COUPON_AVAILABLE
} from "../actions/types";
import {
  fetchCouponSucceeded,
  fetchCouponFailed,
  updateCouponSucceeded,
  updateCouponFailed,
  addCouponSucceeded,
  addCouponFailed,
  validateCouponSucceeded,
  validateCouponFailed,
  fetchCouponAvailableSucceeded,
  fetchCouponAvailableFailed
} from "../actions";

function* fetchCoupon(action) {
  try {
    const auth_token = yield select(getAuthToken);
    const id = action.payload;
    const coupon = yield call(Api({ auth_token }).fetchCoupon, id);
    yield put(fetchCouponSucceeded(coupon.data));
  } catch (e) {
    yield put(fetchCouponFailed(e.message));
  }
}

function* updateCoupon(action) {
  try {
    const auth_token = yield select(getAuthToken);
    const coupon = yield call(
      Api({
        auth_token,
        "content-type": +"multipart/form-data; boundary=----WebKitFormBoundary7MA4YWxkTrZu0gW"
      }).updateCoupon,
      action.payload
    );
    yield put(updateCouponSucceeded(coupon.data));
  } catch (e) {
    yield put(updateCouponFailed(e.message));
  }
}

function* addCoupon(action) {
  try {
    const auth_token = yield select(getAuthToken);
    const coupon = yield call(
      Api({
        auth_token,
        "content-type": +"multipart/form-data; boundary=----WebKitFormBoundary7MA4YWxkTrZu0gW"
      }).addCoupon,
      action.payload
    );
    yield put(addCouponSucceeded(coupon.data));
  } catch (e) {
    yield put(addCouponFailed(action.payload.code));
  }
}

function* validateCoupon(action) {
  try {
    const auth_token = yield select(getAuthToken);
    const is_coupon_valid = yield call(
      Api({
        auth_token
      }).validateCoupon,
      action.payload
    );
    yield put(validateCouponSucceeded(is_coupon_valid.data.is_valid));
  } catch (e) {
    yield put(validateCouponFailed(e.message));
  }
}

function* fetchCouponAvailable(action) {
  try {
    const auth_token = yield select(getAuthToken);
    const is_coupon_available = yield call(
      Api({
        auth_token
      }).fetchCouponAvailable,
      action.payload
    );
    yield put(
      fetchCouponAvailableSucceeded(is_coupon_available.data.is_available)
    );
  } catch (e) {
    yield put(fetchCouponAvailableFailed(e.message));
  }
}

function* couponSaga() {
  yield takeLatest(FETCH_COUPON.REQUEST, fetchCoupon);
  yield takeLatest(UPDATE_COUPON.REQUEST, updateCoupon);
  yield takeEvery(ADD_COUPON.REQUEST, addCoupon);
  yield takeLatest(VALIDATE_COUPON.REQUEST, validateCoupon);
  yield takeLatest(FETCH_COUPON_AVAILABLE.REQUEST, fetchCouponAvailable);
}

export default couponSaga;
