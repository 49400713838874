import {
  FETCH_ORGANIZATIONS
} from "../actions/types";

const INITIAL_STATE = {
  organizations: [],
  fetchingOrganizations: false,
  errorInOrganizations: null
};

export default (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case FETCH_ORGANIZATIONS.SUCCEEDED:
      return {
        ...state,
        organizations: [...payload],
        fetchingOrganizations: false
      };

    case FETCH_ORGANIZATIONS.REQUEST:
      return {
        ...state,
        ...payload,
        fetchingOrganizations: true
      };

    case FETCH_ORGANIZATIONS.FAILED:
      return {
        ...state,
        errorInOrganizations: payload,
        fetchingOrganizations: false
      };

    default:
      return state;
  }
};
