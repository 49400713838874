/**
 * API handles focuses as 1 dimensional array
 * This function casts them to a 2 dimensional array
 */
export const castToNestedFocusArray = flattedFocusArray => {
  if (!flattedFocusArray) return [];
  // [... new Set[array]] => remove duplicates from array
  const groupsWithChildren = [
    ...new Set(flattedFocusArray.map(i => i.groupName))
  ]
    .filter(i => !!i)
    .map(i => ({
      name: i,
      categoryKey: i,
      key: i,
      count: 0
    }));

  const groupsWithoutChildren = flattedFocusArray
    .filter(i => !i.groupName)
    .map(i => ({
      categoryKey: i.categoryKey,
      name: i.name,
      key: i.key,
      count: i.count
    }));

  const firstLevelCategories = [
    ...groupsWithChildren,
    ...groupsWithoutChildren
  ];

  const focuses = firstLevelCategories.map(category => {
    const children = flattedFocusArray.filter(
      i => i.groupName === category.name
    );
    if (children.length > 0) category.children = children;
    return category;
  });

  return focuses;
};
