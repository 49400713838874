import {
  FETCH_TRIAL_STATS,
  FETCH_TRAINING_STATS,
  FETCH_TRAINING_FOCUS_STATS,
  FETCH_ACTIVITY_STATS,
  FETCH_TRIAL_POSITION_STATS,
  FETCH_CLEAN_RUN_STATS,
  FETCH_SPEED_STATS,
  FETCH_TRIAL_LIST_SPEED_STATS,
  FETCH_TRIAL_LIST_CLEAN_RUN_STATS,
  FETCH_TRIAL_LIST_FAULT_REASON_STATS,
  FETCH_TRIAL_LIST_FAULT_STATS,
  FETCH_TRIAL_LIST_POINT_STATS,
  FETCH_TRIAL_LIST_TIME_STATS,
  FETCH_TRIAL_LIST_DESIGNATED_TIME_STATS,
  LOGOUT,
  SET_ACTIVE_DOG
} from "../actions/types";

const INITIAL_STATE = {
  trialsCount: 0,
  trialsAgility: 0,
  trialsJumping: 0,
  loadingTrials: false,
  errorTrials: null,
  trainingsCount: 0,
  trainingsIndependent: 0,
  trainingsCoached: 0,
  loadingTrainings: false,
  errorTrainings: null,
  activityLabels: "",
  activityTrainingsData: [],
  activityTrialsData: [],
  loadingActivity: false,
  errorActivity: null,
  trialPositions: [],
  loadingPositions: false,
  errorPositions: null,
  cleanRunsAgility: 0,
  cleanRunsJumping: 0,
  cleanRunsTotal: 0,
  loadingCleanRuns: false,
  errorCleanRuns: null,
  speedAll: 0,
  speedAgility: 0,
  speedJumping: 0,
  loadingSpeed: false,
  errorSpeed: null,
  trialListSpeeds: [],
  fetchingTrialListSpeeds: false,
  errorTrialListSpeeds: null,
  trialListCleanRuns: [],
  fetchingTrialListCleanRuns: false,
  errorTrialListCleanRuns: null,
  trialListFaultReasons: [],
  fetchingTrialListFaultReasons: false,
  errorTrialListFaultReasons: null,
  trialListFaults: [],
  fetchingTrialListFaults: false,
  errorTrialListFaults: null,
  trainingFocuses: [],
  fetchingTrainingFocuses: false,
  errorTrainingFocuses: null,
  trialListPoints: [],
  fetchingTrialListPoints: false,
  errorTrialListPoints: null,
  trialListTimes: [],
  fetchingTrialListTimes: false,
  errorTrialListTimes: null,
  trialListDesignatedTimes: [],
  fetchingTrialListDesignatedTimes: false,
  errorTrialListDesignatedTimes: null
};

export default (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case FETCH_TRIAL_STATS.REQUEST:
      return { ...state, loadingTrials: true };
    case FETCH_TRIAL_STATS.FAILED:
      return { ...state, errorTrials: payload, loadingTrials: false };
    case FETCH_TRIAL_STATS.SUCCEEDED:
      return { ...state, ...payload, loadingTrials: false };

    case FETCH_TRAINING_STATS.REQUEST:
      return { ...state, loadingTrainings: true };
    case FETCH_TRAINING_STATS.FAILED:
      return { ...state, errorTrainings: payload, loadingTrainings: false };
    case FETCH_TRAINING_STATS.SUCCEEDED:
      return { ...state, ...payload, loadingTrainings: false };

    case FETCH_ACTIVITY_STATS.REQUEST:
      return { ...state, loadingActivity: true };
    case FETCH_ACTIVITY_STATS.FAILED:
      return { ...state, errorActivity: payload, loadingActivity: false };
    case FETCH_ACTIVITY_STATS.SUCCEEDED:
      return { ...state, ...payload, loadingActivity: false };

    case FETCH_TRIAL_POSITION_STATS.REQUEST:
      return { ...state, loadingPositions: true };
    case FETCH_TRIAL_POSITION_STATS.FAILED:
      return { ...state, errorPositions: payload, loadingPositions: false };
    case FETCH_TRIAL_POSITION_STATS.SUCCEEDED:
      return { ...state, ...payload, loadingPositions: false };

    case FETCH_CLEAN_RUN_STATS.REQUEST:
      return { ...state, loadingCleanRuns: true };
    case FETCH_CLEAN_RUN_STATS.FAILED:
      return { ...state, errorCleanRuns: payload, loadingCleanRuns: false };
    case FETCH_CLEAN_RUN_STATS.SUCCEEDED:
      return { ...state, ...payload, loadingCleanRuns: false };

    case FETCH_SPEED_STATS.REQUEST:
      return { ...state, loadingSpeed: true };
    case FETCH_SPEED_STATS.FAILED:
      return { ...state, errorSpeed: payload, loadingSpeed: false };
    case FETCH_SPEED_STATS.SUCCEEDED:
      return { ...state, ...payload, loadingSpeed: false };

    case FETCH_TRIAL_LIST_SPEED_STATS.REQUEST:
      return { ...state, fetchingTrialListSpeeds: true };
    case FETCH_TRIAL_LIST_SPEED_STATS.FAILED:
      return {
        ...state,
        errorTrialListSpeeds: payload,
        fetchingTrialListSpeeds: false
      };
    case FETCH_TRIAL_LIST_SPEED_STATS.SUCCEEDED:
      return {
        ...state,
        trialListSpeeds: payload,
        fetchingTrialListSpeeds: false
      };

    case FETCH_TRIAL_LIST_CLEAN_RUN_STATS.REQUEST:
      return { ...state, fetchingTrialListCleanRuns: true };
    case FETCH_TRIAL_LIST_CLEAN_RUN_STATS.FAILED:
      return {
        ...state,
        errorTrialListCleanRuns: payload,
        fetchingTrialListCleanRuns: false
      };
    case FETCH_TRIAL_LIST_CLEAN_RUN_STATS.SUCCEEDED:
      return {
        ...state,
        trialListCleanRuns: payload,
        fetchingTrialListCleanRuns: false
      };

    case FETCH_TRIAL_LIST_FAULT_REASON_STATS.REQUEST:
      return { ...state, fetchingTrialListFaultReasons: true };
    case FETCH_TRIAL_LIST_FAULT_REASON_STATS.FAILED:
      return {
        ...state,
        errorTrialListFaultReasons: payload,
        fetchingTrialListFaultReasons: false
      };
    case FETCH_TRIAL_LIST_FAULT_REASON_STATS.SUCCEEDED:
      return {
        ...state,
        trialListFaultReasons: payload,
        fetchingTrialListFaultReasons: false
      };

    case FETCH_TRIAL_LIST_FAULT_STATS.REQUEST:
      return { ...state, fetchingTrialListFaults: true };
    case FETCH_TRIAL_LIST_FAULT_STATS.FAILED:
      return {
        ...state,
        errorTrialListFaults: payload,
        fetchingTrialListFaults: false
      };
    case FETCH_TRIAL_LIST_FAULT_STATS.SUCCEEDED:
      return {
        ...state,
        trialListFaults: payload,
        fetchingTrialListFaults: false
      };

    case FETCH_TRAINING_FOCUS_STATS.REQUEST:
      return { ...state, fetchingTrainingFocuses: true };
    case FETCH_TRAINING_FOCUS_STATS.FAILED:
      return {
        ...state,
        errorTrainingFocuses: payload,
        fetchingTrainingFocuses: false
      };
    case FETCH_TRAINING_FOCUS_STATS.SUCCEEDED:
      return {
        ...state,
        trainingFocuses: payload,
        fetchingTrainingFocuses: false
      };

    case FETCH_TRIAL_LIST_POINT_STATS.REQUEST:
      return { ...state, fetchingTrialListPoints: true };
    case FETCH_TRIAL_LIST_POINT_STATS.FAILED:
      return {
        ...state,
        errorTrialListPoints: payload,
        fetchingTrialListPoints: false
      };
    case FETCH_TRIAL_LIST_POINT_STATS.SUCCEEDED:
      return {
        ...state,
        trialListPoints: payload,
        fetchingTrialListPoints: false
      };

    case FETCH_TRIAL_LIST_TIME_STATS.REQUEST:
      return { ...state, fetchingTrialListTimes: true };
    case FETCH_TRIAL_LIST_TIME_STATS.FAILED:
      return {
        ...state,
        errorTrialListTimes: payload,
        fetchingTrialListTimes: false
      };
    case FETCH_TRIAL_LIST_TIME_STATS.SUCCEEDED:
      return {
        ...state,
        trialListTimes: payload,
        fetchingTrialListTimes: false
      };

    case FETCH_TRIAL_LIST_DESIGNATED_TIME_STATS.REQUEST:
      return { ...state, fetchingTrialListDesignatedTimes: true };
    case FETCH_TRIAL_LIST_DESIGNATED_TIME_STATS.FAILED:
      return {
        ...state,
        errorTrialListDesignatedTimes: payload,
        fetchingTrialListDesignatedTimes: false
      };
    case FETCH_TRIAL_LIST_DESIGNATED_TIME_STATS.SUCCEEDED:
      return {
        ...state,
        trialListDesignatedTimes: payload,
        fetchingTrialListDesignatedTimes: false
      };

    case LOGOUT.REQUEST:
      return { ...INITIAL_STATE };

    case SET_ACTIVE_DOG:
      return INITIAL_STATE;

    default:
      return state;
  }
};
