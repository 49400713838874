import React from "react";
import { confirmable, createConfirmation } from "react-confirm";
import Modal from "../common/Modal";

const Confirm = ({ cancel, proceed, dismiss, show, confirmation, options }) => {
  return (
    <Modal
      onClose={dismiss}
      show={show}
      title={options.title}
      footer={
        <React.Fragment>
          <button className="btn btn-primary" onClick={() => proceed()}>
            {options.ok}
          </button>
          <button className="btn" onClick={() => cancel()}>
            {options.cancel}
          </button>
        </React.Fragment>
      }
    >
      <p>{confirmation}</p>
    </Modal>
  );
};

const confirm = createConfirmation(confirmable(Confirm));

export default function(confirmation, options = {}) {
  return confirm({ confirmation, options });
}
