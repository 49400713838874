import {
  FETCH_TRIALS,
  ADD_TRIAL,
  UPDATE_TRIAL,
  DELETE_TRIAL,
  LOGOUT,
  AFTER_REHYDRATE,
  FETCH_TRIALS_PAGED,
  FETCH_TRIALS_PAGES_COUNT,
  FETCH_TRIALS_COUNT,
  SET_ACTIVE_DOG,
  FETCH_TRIALS_FILTERED,
} from "../actions/types";

const INITIAL_STATE = {
  trials: [],
  trialsPages: 0,
  trialsPage: 1,
  trialsCount: 0,
  fetchingTrials: false,
  fetchingTrialsFiltered: false,
  fetchingTrialsPaged: false,
  fetchingTrialsPagesCount: false,
  fetchingTrialsCount: false,
  saving: false,
  error: null,
};

export default (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
  // Fetch
  case FETCH_TRIALS.REQUEST:
    return { ...state, fetchingTrials: true, error: null, trials: [] };
  case FETCH_TRIALS.SUCCEEDED:
    return { ...state, fetchingTrials: false, trials: payload };
  case FETCH_TRIALS.FAILED:
    return { ...state, fetchingTrials: false, error: payload };

  case FETCH_TRIALS_FILTERED.REQUEST:
    return { ...state, fetchingTrialsFiltered: true, error: null };
  case FETCH_TRIALS_FILTERED.SUCCEEDED:
    return {
      ...state,
      fetchingTrialsFiltered: false,
      trials: payload.trials
    };
  case FETCH_TRIALS_FILTERED.FAILED:
    return { ...state, fetchingTrialsFiltered: false, error: payload };

  case FETCH_TRIALS_PAGED.REQUEST:
    return { ...state, fetchingTrialsPaged: true, error: null };
  case FETCH_TRIALS_PAGED.SUCCEEDED:
    return {
      ...state,
      fetchingTrialsPaged: false,
      trials:
          payload.trialsPage === 1
            ? payload.trials
            : state.trials.concat(payload.trials),
      trialsPage: payload.trialsPage,
    };
  case FETCH_TRIALS_PAGED.FAILED:
    return { ...state, fetchingTrialsPaged: false, error: payload };

  case FETCH_TRIALS_PAGES_COUNT.REQUEST:
    return { ...state, fetchingTrialsPagesCount: true, error: null };
  case FETCH_TRIALS_PAGES_COUNT.SUCCEEDED:
    return {
      ...state,
      fetchingTrialsPagesCount: false,
      trialsPages: payload,
    };
  case FETCH_TRIALS_PAGES_COUNT.FAILED:
    return { ...state, fetchingTrialsPagesCount: false, error: payload };

  case FETCH_TRIALS_COUNT.REQUEST:
    return { ...state, fetchingTrialsCount: true, error: null };
  case FETCH_TRIALS_COUNT.SUCCEEDED:
    return {
      ...state,
      fetchingTrialsCount: false,
      trialsCount: payload,
    };
  case FETCH_TRIALS_COUNT.FAILED:
    return { ...state, fetchingTrialsCount: false, error: payload };

    // Add
  case ADD_TRIAL.REQUEST:
    return { ...state, saving: true, error: null };
  case ADD_TRIAL.SUCCEEDED:
    return {
      ...state,
      saving: false,
      trials: [...state.trials, payload],
    };
  case ADD_TRIAL.FAILED:
    return { ...state, saving: false, error: payload };

    // Update
  case UPDATE_TRIAL.REQUEST:
    return { ...state };
  case UPDATE_TRIAL.SUCCEEDED:
    return {
      ...state,
      trials: state.trials.map((t) => (t.id === payload.id ? payload : t)),
    };
  case UPDATE_TRIAL.FAILED:
    return { ...state };

    // Delete
  case DELETE_TRIAL.REQUEST:
    return { ...state };
  case DELETE_TRIAL.SUCCEEDED:
    return {
      ...state,
      trials: state.trials.filter((t) => t.id !== payload),
    };
  case DELETE_TRIAL.FAILED:
    return { ...state };

  case LOGOUT.REQUEST:
    return { ...INITIAL_STATE };

  case AFTER_REHYDRATE:
    return { ...state, saving: false, fetching: false };

  case SET_ACTIVE_DOG:
    return INITIAL_STATE;

  default:
    return state;
  }
};
