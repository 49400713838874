import React, { Component } from "react";
import { withTranslation } from "react-i18next";

import { AppContent, RoundButton } from "../../common";
import SelectFocusList from "../../components/SelectFocusList";
import { castToNestedFocusArray } from "../../helpers/Transformer";

class FaultView extends Component {
  render() {
    const { t } = this.props;
    return (
      <AppContent size="medium">
        <SelectFocusList
          renderName={(name) => t(`misc.${name}`)}
          list={castToNestedFocusArray(this.props.faultCategories)}
          selectedItems={this.props.faults}
          onChange={this.props.onFaultChange}
          multiple={this.props.multiple}
        />

        <RoundButton
          icon="complete"
          size="large"
          atCorner
          onClick={this.props.onComplete}
        />
      </AppContent>
    );
  }
}

FaultView.propTypes = {};

export default withTranslation()(FaultView);
