import _ from "lodash";

export const formatForApi = {
  map: {
    user: {
      createdAt: "created_at",
      email: "email",
      firstName: "first_name",
      id: "id",
      lastName: "last_name",
      paramId: "param_id",
      photoUrl: "photo_url",
      photoFile: "photo",
      photoVariants: "photo_variants",
      agreedTerms: "terms_of_service",
      updatedAt: "updated_at",
      password: "password",
      team: "team_name",
      allowEmails: "allow_marketing_emails",
      language: "language",
      recaptcha: "g-recaptcha-response",
      facebookOauthAccessToken: "facebook_oauth_access_token",
      units: "units"
    },
    dog: {
      dayOfBirth: "birthdate",
      competitionClass: "competition_class",
      createdAt: "created_at",
      description: "description",
      height: "height_cm",
      id: "id",
      name: "name",
      officialName: "official_name",
      photoFile: "photo",
      photo: "photo_url",
      paramId: "param_id",
      photoId: "photo_id",
      race: "race",
      sex: "sex",
      updatedAt: "updated_at",
      userId: "user_id",
      sizeClasses: "size_classes"
    },
    training: {
      id: "id",
      dogId: "dog_id",
      trainingType: "training_type",
      date: "date",
      notes: "notes",
      videoUrl: "video_url",
      rating: "rating",
      focuses: "training_focuses",
      courseMapFile: "course_map",
      courseMap: "course_map_url",
      coach: "coach_name",
      coachId: "coach_id",
      exerciseId: "exercise_id"
    },
    trial: {
      id: "id",
      dogId: "dog_id",
      date: "date",
      judge: "judge_name",
      judgeId: "judge_id",
      result: "result",
      speed: "speed",
      winningSpeed: "winning_speed",
      timeFaults: "time_faults",
      courseFaults: "course_faults",
      faultReasons: "trial_fault_reasons",
      notes: "notes",
      videoUrl: "video_url",
      certs: "certs",
      placement: "position",
      courseMapFile: "course_map",
      courseMap: "course_map_url",
      location: "trial_location_name",
      locationId: "trial_location_id",
      faults: "trial_faults",
      courseLength: "course_length",
      trialTime: "time",
      winningTime: "winning_time",
      courseTypeId: "course_type_id",
      sizeClassId: "size_class_id",
      organizationId: "organization_id",
      openingPoints: "opening_points",
      closingPoints: "closing_points",
      winningPoints: "winning_points",
      totalPoints: "total_points",
      designatedTime: "designated_time",
      timeDifference: "time_difference",
      cleanRun: "clean_run"
    },
    topic: {
      createdAt: "created_at",
      dogId: "dog_id",
      done: "done",
      id: "id",
      name: "name",
      orderNumber: "order_number",
      trainingSubTopics: "training_sub_topics",
      trainingFocuses: "training_focuses",
      exercises: "exercises",
      updatedAt: "updated_at",
      trainingTopicId: "training_topic_id"
    },
    subtopic: {
      done: "done",
      id: "id",
      name: "name",
      orderNumber: "order_number",
      exercises: "exercises",
      updatedAt: "updated_at",
      createdAt: "created_at",
      trainingTopicId: "training_topic_id",
      notes: "notes"
    },
    exercise: {
      id: "id",
      name: "name",
      orderNumber: "order_number",
      trainedExercises: "trained_exercises",
      notes: "notes",
      done: "done",
      courseMapVariants: "course_map_variants",
      courseMapFile: "course_map",
      videoUrl: "video_url",
      updatedAt: "updated_at",
      createdAt: "created_at"
    },
    coupon: {
      createdAt: "created_at",
      id: "id",
      code: "code",
      validUntil: "valid_until",
      discountMonths: "discount_months",
      coach: "coach",
      usedDate: "used_date",
      userId: "user_id",
      updatedAt: "updated_at"
    },
    sizeClass: {
      id: "id",
      name: "name",
      organizationId: "organization_id"
    }
  },
  user(user) {
    return this.format(user, this.map.user);
  },
  dog(dog) {
    const covertMap = this.map;
    const formattedDog = {};
    let that = this;

    _.each(dog, function(value, key) {
      key = covertMap.dog[key] || key;
      if (Array.isArray(value)) {
        let formattedItem = [];
        if (key === "size_classes") {
          _.each(value, function(value) {
            formattedItem.push(that.format(value, that.map.sizeClass));
          });
          formattedDog[key] = formattedItem;
        } else {
          formattedDog[key] = value;
        }
      } else {
        formattedDog[key] = value;
      }
    });

    return formattedDog;
  },
  training(training) {
    let t = this.format(training, this.map.training);
    if (!t.rating) t.rating = "";
    return t;
  },

  trial(trial) {
    const t = this.format(trial, this.map.trial);

    if (t.dsq) {
      t.result = null;
      delete t.speed;
      delete t.winning_speed;
      delete t.position;
      delete t.time_faults;
      delete t.trial_time;
      delete t.winning_time;
      delete t.opening_points;
      delete t.closing_points;
      delete t.winning_points;
      delete t.total_points;
    }
    return t;
  },

  topic(topic) {
    topic = this.format(topic, this.map.topic);
    if (topic.training_focuses) {
      topic.training_focuses = this.trainingFocusCategories(
        topic.training_focuses
      );
    }
    if (topic.training_sub_topics) {
      topic.training_sub_topics = topic.training_sub_topics.map(subtopic =>
        this.subtopic(subtopic, this.map.subtopic)
      );
    }
    if (topic.exercises) {
      topic.exercises = topic.exercises.map(exercise =>
        this.exercise(exercise, this.map.exercise)
      );
    }
    return topic;
  },
  trainingSubTopics(subtopics) {
    const formattedSubTopics = [];

    subtopics.map(st => {
      if (st.count && st.count > 1) {
        for (let i = 0; i < st.count; i++) {
          formattedSubTopics.push(st);
        }
      } else {
        formattedSubTopics.push(st);
      }
      return st;
    });

    return formattedSubTopics.map(subtopic =>
      this.subtopic(subtopic, this.map.subtopic)
    );
  },
  exercise(exercise) {
    exercise = this.format(exercise, this.map.exercise);
    // courseMapVariants?
    return exercise;
  },
  subtopic(subtopic) {
    subtopic = this.format(subtopic, this.map.subtopic);
    if (subtopic.exercises) {
      subtopic.exercises = subtopic.exercises.map(exercise =>
        this.exercise(exercise, this.map.exercise)
      );
    }

    return subtopic;
  },

  trainingFocusCategories(focuses) {
    const formattedFocuses = [];

    focuses.map(f => {
      if (f.count && f.count > 1) {
        for (let i = 0; i < f.count; i++) {
          formattedFocuses.push(f);
        }
      } else {
        formattedFocuses.push(f);
      }
      return f;
    });

    return formattedFocuses.map(focus => ({
      value: focus.name,
      category_key: focus.categoryKey
    }));
  },
  coupon(coupon) {
    return this.format(coupon, this.map.coupon);
  },

  format(item, map) {
    const formattedItem = {};

    _.each(item, function(value, key) {
      key = map[key] || key;
      formattedItem[key] = value;
    });

    return formattedItem;
  }
};
