import { Component } from "react";
import PropTypes from "prop-types";
import ReactGA from "react-ga";

class GoogleAnalytics extends Component {
  componentDidMount() {
    const { ga } = this.props;
    if (ga) ReactGA.initialize(ga);
  }

  componentDidUpdate(prevProps) {
    const { ga } = this.props;
    if (ga) ReactGA.pageview(window.location.pathname + window.location.search);
  }

  render() {
    return this.props.children;
  }
}

GoogleAnalytics.propTypes = {
  ga: PropTypes.string
};

export default GoogleAnalytics;
