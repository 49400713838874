import { call, put, takeLatest, select } from "redux-saga/effects";

import { FETCH_ADMIN_INFO } from "../actions/types";
import Api from "../helpers/Api";
import { getAuthToken } from "./selectors";
import { fetchAdminInfoSucceeded, fetchAdminInfoFailed } from "../actions";

function* fetchAdminInfo({ type, payload }) {
  try {
    const auth_token = yield select(getAuthToken);
    const res = yield call(Api({ auth_token }).fetchAdminInfo, payload);

    if (res.data.usersCount) {
      yield put(fetchAdminInfoSucceeded(res.data));
    } else {
      yield put(fetchAdminInfoFailed("You don't have access to this page."));
    }
  } catch (e) {
    yield put(fetchAdminInfoFailed(e.message));
  }
}

function* adminSaga() {
  yield takeLatest(FETCH_ADMIN_INFO.REQUEST, fetchAdminInfo);
}

export default adminSaga;
