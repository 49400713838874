import React from "react";
import PropTypes from "prop-types";

const Container = ({ children, size, className, noPadding }) => {
  className += noPadding ? " container--no-padding" : "";
  return (
    <div className={`container container--${size} ${className}`}>
      {children}
    </div>
  );
};

Container.propTypes = {
  children: PropTypes.any,
  size: PropTypes.oneOf(["large", "medium", "small"]),
  noPadding: PropTypes.bool
};

Container.defaultProps = {
  size: "medium"
};

export default Container;
