import { call, put, takeLatest, select } from "redux-saga/effects";

import Api from "../helpers/Api";
import { getAuthToken } from "./selectors";
import {
  LOGIN,
  SIGNUP,
  LOGOUT,
  RETURN_PASSWORD,
  AUTH_EXPIRED
} from "../actions/types";
import {
  loginSucceeded,
  loginFailed,
  signupSucceeded,
  signupFailed,
  fetchUser,
  fetchDogs,
  logoutSucceed,
  logoutFailed,
  returnPasswordSucceeded,
  returnPasswordFailed,
  logout,
  fetchCurrentPlan
} from "../actions";

function* loginUser(action) {
  try {
    const response = yield call(Api().login, action.payload);
    yield put(loginSucceeded(response.data));
    yield put(fetchUser(response.data.user_id));
    yield put(fetchDogs());
    yield put(fetchCurrentPlan());
  } catch (e) {
    yield put(loginFailed("Email or password is invalid"));
  }
}

function* signupUser(action) {
  try {
    const user = yield call(Api().signup, action.payload);
    yield put(signupSucceeded(user.data));
  } catch (e) {
    yield put(signupFailed(e.response.data.errors[0]));
  }
}

function* logoutUser(action) {
  try {
    const auth_token = yield select(getAuthToken);
    const user = yield call(Api({ auth_token }).logout, action.payload);
    yield put(logoutSucceed(user.data));
  } catch (e) {
    yield put(logoutFailed(e.message));
  }
}

function* returnPassword({ payload }) {
  try {
    const message = yield call(Api().returnPassword, payload);
    yield put(returnPasswordSucceeded(message));
  } catch (e) {
    yield put(returnPasswordFailed(e.message));
  }
}

function* checkAuth({ payload }) {
  try {
    yield put(logout());
  } catch (e) { }
}

function* authSaga() {
  yield takeLatest(LOGIN.REQUEST, loginUser);
  yield takeLatest(SIGNUP.REQUEST, signupUser);
  yield takeLatest(LOGOUT.REQUEST, logoutUser);
  yield takeLatest(RETURN_PASSWORD.REQUEST, returnPassword);

  yield takeLatest(AUTH_EXPIRED, checkAuth);
}

export default authSaga;
