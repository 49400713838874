import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import moment from "moment";
import _ from "lodash";
import "react-tabs/style/react-tabs.css";

import {
  AppContent,
  Container,
  Loader,
  RoundButton,
  FormGroup,
  Input,
  TextArea,
  Error
} from "../../common";
import {
  fetchAdminInfo,
  fetchCoupon,
  fetchCouponAvailable,
  addCoupon,
  clearCouponErrors,
  fetchSharedTopics
} from "../../actions";

class AdminDashboard extends Component {
  state = {
    errors: [],
    isValid: true,
    codes: [
      {
        code: "",
        validUntil: "",
        discountMonths: 0,
        comment: "",
        coach: "",
        error: {}
      }
    ],
    textToPrint: ""
  };

  componentDidMount() {
    this.props.fetchAdminInfo();
    this.props.clearCouponErrors();
    this.props.fetchSharedTopics();

    // Generate first new code.
    const newCode = this.generateNewCode();
    const newCodes = [...this.state.codes];
    newCodes[0].code = newCode;
    this.setState({ codes: newCodes });
  }

  addCodeRow = e => {
    e.preventDefault();
    const { codes } = this.state;
    const newCode = this.generateNewCode();
    const newCodeRow = {
      code: newCode,
      validUntil: "",
      discountMonths: 0,
      comment: "",
      coach: "",
      error: {}
    };
    // Set the new state with new row
    const newCodes = [...codes, newCodeRow];
    this.setState({ codes: newCodes });
  };

  // Generate a new code that has not been used before.
  generateNewCode = () => {
    const allowedChars = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    var newCode = "";
    // Generates new random code
    for (let i = 0; i < 3; i++) {
      const part = Array(3)
        .join()
        .split(",")
        .map(function() {
          return allowedChars.charAt(
            Math.floor(Math.random() * allowedChars.length)
          );
        })
        .join("");
      if (i === 0) newCode = newCode.concat("", part);
      else newCode = newCode.concat("-", part);
    }

    this.props.fetchCouponAvailable(newCode);
    // TODO: Check that the timer actually works like intended
    window.setTimeout(
      function(isCouponAvailable, newGeneration) {
        if (!isCouponAvailable) newCode = newGeneration();
      },
      2000,
      this.props.isCouponAvailable,
      this.generateNewCode
    );

    return newCode;
  };

  validate() {
    const newCodes = [...this.state.codes];
    for (let code of newCodes) {
      code.error = {};
      // TODO: Check what to put as value.
      if (!code.validUntil) code.error.validUntil = "Date required";
      if (!code.discountMonths) code.error.discountMonths = "Months required";
    }

    // Overall error check to return.
    let noErrors = true;
    for (let code of newCodes) {
      if (!_.isEmpty(code.error)) noErrors = false;
    }

    this.setState({ codes: newCodes, isValid: noErrors });
    return noErrors;
  }

  saveCodes = e => {
    e.preventDefault();
    if (!_.isEmpty(this.state.codes)) {
      this.props.clearCouponErrors();
      // Check validity of fields.
      const isValid = this.validate();

      if (!isValid) return false;

      for (let code of this.state.codes) {
        this.props.addCoupon(code);
      }
      // TODO: if some don't go through then don't print those or says which didn't save
      this.printCodes();

      // Clear the state of the generated codes and the form lines.
      this.setState({ codes: [] });
    }
  };

  printCodes = () => {
    var textToPrint = "";

    // JSON to csv copy
    var json = this.state.codes;

    var fields = Object.keys(json[0]);
    fields.pop(); // Take out "error"-field.
    var replacer = function(key, value) {
      return value === null ? "" : value;
    };
    var csv = json.map(function(row) {
      return fields
        .map(function(fieldName) {
          return JSON.stringify(row[fieldName], replacer);
        })
        .join(",");
    });
    csv.unshift(fields.join(",")); // add header column
    textToPrint = csv.join("\r\n");

    this.setState({ textToPrint: textToPrint });
  };

  render() {
    const { loading, fetchingSharedTopics } = this.props;
    const { codes, textToPrint } = this.state;
    let codeRows = [];

    if (codes) {
      codeRows = codes.map((code, index) => (
        <FormGroup className="form-inline" id={index} key={index}>
          <Input
            type="text"
            label="Code"
            name="code"
            readOnly
            value={code.code}
          />
          <Input
            type="date"
            label="Valid Until"
            name="validUntil"
            onChange={this.handleInputChange}
            value={code.validUntil}
            required
          />
          <Error show={code.validUntil === ""}>{code.error.validUntil}</Error>
          <Input
            type="number"
            label="Discount Months"
            name="discountMonths"
            onChange={this.handleInputChange}
            value={code.discountMonths}
            required
          />
          <Error show={code.discountMonths < 1}>
            {code.error.discountMonths}
          </Error>
          <Input
            type="text"
            label="Comment"
            name="comment"
            onChange={this.handleInputChange}
            value={code.comment}
          />
          <Input
            type="text"
            label="Coach"
            name="coach"
            onChange={this.handleInputChange}
            value={code.coach}
          />
        </FormGroup>
      ));
    }

    return (
      <AppContent size="full">
        <h2>Admin Dashboard</h2>
        <Tabs>
          <TabList>
            <Tab>Key figures</Tab>
            <Tab>Coupon Codes</Tab>
            <Tab>Shared Training Topics</Tab>
          </TabList>
          <TabPanel>
            <Container size="small">
              {loading ? <Loader /> : this.renderAppInfo()}
            </Container>
          </TabPanel>
          <TabPanel>
            <h3>Code Generator</h3>
            {/* <Container> */}
            <form onSubmit={this.handleNewCodeGeneration}>
              {codeRows}
              <RoundButton size="small" icon="add" onClick={this.addCodeRow} />
              <p />
              <button className="btn btn-primary" onClick={this.saveCodes}>
                Save Codes
              </button>
            </form>
            <p />
            <h3>Generated Codes:</h3>
            <TextArea
              placeholder="Generated codes and code info as CSV."
              readOnly
              value={textToPrint}
              label="none"
              rows="10"
            />
            {this.renderError()}
            {/* </Container> */}
          </TabPanel>
          <TabPanel>
            <Container size="large">
              {fetchingSharedTopics ? (
                <Loader />
              ) : (
                this.renderSharedTrainingTopics()
              )}
            </Container>
          </TabPanel>
        </Tabs>
      </AppContent>
    );
  }

  handleInputChange = e => {
    const { name, value } = e.target;
    // Parent FormGroup knows list position via id.
    const i = e.target.parentElement.parentElement.id;
    const newCodes = [...this.state.codes];
    newCodes[i][name] = value;
    this.setState({ codes: newCodes });
  };

  renderError = () => {
    let failedAdds = this.props.couponError;
    let fails = [];
    if (failedAdds) {
      fails = failedAdds.map(fail => (
        <span key={fail}>Adding coupon {fail} failed. </span>
      ));
    }
    return <div>{fails}</div>;
  };

  renderAppInfo = () => {
    const {
      usersCount,
      dogsCount,
      trainingsCount,
      trialsCount,
      show,
      error
    } = this.props;

    if (!show) return <p>{error && error}</p>;

    return (
      <table className="table">
        <tbody>
          <tr>
            <th>Users</th>
            <td>{usersCount && usersCount}</td>
          </tr>

          <tr>
            <th>Dogs</th>
            <td>{dogsCount && dogsCount}</td>
          </tr>

          <tr>
            <th>Trainings</th>
            <td>{trainingsCount && trainingsCount}</td>
          </tr>

          <tr>
            <th>Trials</th>
            <td>{trialsCount && trialsCount}</td>
          </tr>
        </tbody>
      </table>
    );
  };

  renderSharedTrainingTopics = () => {
    const { sharedTopics } = this.props;
    let shareLink = window.location.origin + "/shared/training-topic/";

    if (sharedTopics) {
      return (
        <React.Fragment>
          <table className="table">
            <thead>
              <tr>
                <th>ID</th>
                <th>Link</th>
                <th>Date</th>
                <th>Topic</th>
                <th>Dog</th>
                <th>User</th>
              </tr>
            </thead>
            <tbody>
              {sharedTopics.map(topic => (
                <tr key={topic.id}>
                  <td>{topic.id}</td>
                  <td>
                    <Input
                      type="text"
                      value={shareLink + topic.guid}
                      readOnly
                    ></Input>
                  </td>
                  <td>
                    {moment(topic.createdAt).format("DD MMM YYYY - HH:mm")}
                  </td>
                  <td>{topic.trainingTopic}</td>
                  <td>{topic.dogName}</td>
                  <td>{topic.userEmail}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </React.Fragment>
      );
    }
  };
}

AdminDashboard.propTypes = {
  fetchAdminInfo: PropTypes.func,
  fetchCouponAvailable: PropTypes.func,
  isCouponAvailable: PropTypes.bool,
  addCoupon: PropTypes.func,
  clearCouponErrors: PropTypes.func,
  couponError: PropTypes.array,
  usersCount: PropTypes.number,
  dogsCount: PropTypes.number,
  trainingsCount: PropTypes.number,
  trialsCount: PropTypes.number,
  loading: PropTypes.bool,
  fetchSharedTopics: PropTypes.func,
  fetchingSharedTopics: PropTypes.bool,
  show: PropTypes.bool,
  error: PropTypes.string,
  sharedTopics: PropTypes.array
};

const mapStateToProps = ({ admin, coupons, topics }) => ({
  ...admin,
  couponError: coupons.error,
  isCouponAvailable: coupons.isAvailable,
  sharedTopics: topics.sharedTopics
});

export default connect(
  mapStateToProps,
  {
    fetchAdminInfo,
    fetchCoupon,
    fetchCouponAvailable,
    addCoupon,
    clearCouponErrors,
    fetchSharedTopics
  }
)(AdminDashboard);
