import {
  FETCH_COUPON,
  UPDATE_COUPON,
  ADD_COUPON,
  VALIDATE_COUPON,
  FETCH_COUPON_AVAILABLE,
  CLEAR_COUPON_ERRORS,
  AFTER_REHYDRATE
} from "../actions/types";

const INITIAL_STATE = {
  coupon: {},
  fetching: false,
  error: [],
  updating: false,
  adding: false,
  isValid: false,
  isAvailable: false,
  validating: false
};

export default (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
  case FETCH_COUPON.SUCCEEDED:
    return {
      ...state,
      coupon: { ...payload },
      fetching: false
    };

  case FETCH_COUPON.REQUEST:
    return {
      ...state,
      ...payload,
      fetching: true
    };

  case UPDATE_COUPON.REQUEST:
    return {
      ...state,
      updating: true
    };

  case UPDATE_COUPON.SUCCEEDED:
    return {
      ...state,
      updating: false,
      coupon: { ...payload }
    };

  case UPDATE_COUPON.FAILED:
    return {
      ...state,
      updating: false,
      error: payload
    };

  case ADD_COUPON.REQUEST:
    return {
      ...state,
      adding: true
    };

  case ADD_COUPON.SUCCEEDED:
    return {
      ...state,
      adding: false,
      coupon: { ...payload }
    };

  case ADD_COUPON.FAILED:
    return {
      ...state,
      adding: false,
      error: [...state.error, payload]
    };

  case VALIDATE_COUPON.REQUEST:
    return {
      ...state,
      validating: true,
      payload: payload
    };

  case VALIDATE_COUPON.SUCCEEDED:
    return {
      ...state,
      validating: false,
      isValid: payload
    };

  case VALIDATE_COUPON.FAILED:
    return {
      ...state,
      validating: false,
      isValid: false
    };

  case FETCH_COUPON_AVAILABLE.REQUEST:
    return {
      ...state,
      validating: true,
      payload: payload
    };

  case FETCH_COUPON_AVAILABLE.SUCCEEDED:
    return {
      ...state,
      isAvailable: payload
    };

  case FETCH_COUPON_AVAILABLE.FAILED:
    return {
      ...state,
      isAvailable: false,
      error: payload
    };

  case CLEAR_COUPON_ERRORS:
    return {
      ...state,
      error: []
    };

  case AFTER_REHYDRATE:
    return {
      ...state,
      fetching: false,
      updating: false,
      validating: false,
      isValid: false,
      coupon: null
    };

  default:
    return state;
  }
};
