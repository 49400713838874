import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";

import { Container, FormGroup, Input } from "../../common";
import { login } from "../../actions";

class Login extends Component {
  state = {
    email: "",
    password: "",
  };

  handleLogin = (e) => {
    e.preventDefault();
    const { login } = this.props;
    const { email, password } = this.state;

    login({ email, password });
  };

  handleInputChange = (e) => {
    const { name, value } = e.target;

    this.setState((prevState, props) => ({
      ...prevState,
      [name]: value,
    }));
  };

  render() {
    const { email, password } = this.state;
    const { isAuthenticated, t } = this.props;

    if (isAuthenticated) return <Redirect to="/" />;

    return (
      <Container size="small">
        <h1>{t("login.title")}</h1>

        <form>
          <FormGroup>
            <Input
              label={t("common.email")}
              type="text"
              name="email"
              placeholder={t("common.email-placeholder")}
              onChange={this.handleInputChange}
              value={email}
              autoCapitalize="off"
            />
          </FormGroup>

          <FormGroup>
            <Input
              label={t("common.password")}
              type="password"
              name="password"
              placeholder={t("common.password-placeholder")}
              onChange={this.handleInputChange}
              value={password}
              autoCapitalize="off"
            />
          </FormGroup>

          <p>
            <button className="btn btn-primary" onClick={this.handleLogin}>
              {t("login.submit")}
            </button>
          </p>

          <p>
            <Link to="/auth/forgot-password">{t("login.forgot")}</Link>
          </p>
        </form>
      </Container>
    );
  }
}

const mapStateToProps = ({ auth }) => ({
  isAuthenticated: auth.isAuthenticated,
  error: auth.loginError,
});

const LoginWithTranslate = withTranslation()(Login);
export default connect(mapStateToProps, { login })(LoginWithTranslate);
