import { LOGIN, SIGNUP, LOGOUT, RETURN_PASSWORD, AUTH_EXPIRED } from "./types";

export const login = ({ email, password, facebookOauthAccessToken }) => ({
  type: LOGIN.REQUEST,
  payload: { email, password, facebookOauthAccessToken }
});

export const loginSucceeded = user => ({
  type: LOGIN.SUCCEEDED,
  payload: user
});

export const loginFailed = error => ({
  type: LOGIN.FAILED,
  payload: error
});

export const signup = user => ({
  type: SIGNUP.REQUEST,
  payload: user
});

export const signupSucceeded = user => ({
  type: SIGNUP.SUCCEEDED,
  payload: user
});

export const signupFailed = error => ({
  type: SIGNUP.FAILED,
  payload: error
});

export const logout = authToken => ({
  type: LOGOUT.REQUEST,
  payload: authToken
});

export const logoutSucceed = user => ({
  type: LOGOUT.SUCCEEDED,
  payload: user
});

export const logoutFailed = error => ({
  type: LOGOUT.FAILED,
  payload: error
});

export const returnPassword = data => ({
  type: RETURN_PASSWORD.REQUEST,
  payload: data
});

export const returnPasswordSucceeded = message => ({
  type: RETURN_PASSWORD.SUCCEEDED,
  payload: message
});

export const returnPasswordFailed = error => ({
  type: RETURN_PASSWORD.FAILED,
  payload: error
});

export const authExpired = () => ({
  type: AUTH_EXPIRED
});
