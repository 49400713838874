import { call, put, takeEvery, select } from "redux-saga/effects";

import Api from "../helpers/Api";
import { getAuthToken, getActiveDog } from "./selectors";
import {
  FETCH_JUDGES_FILTER,
  FETCH_TRIAL_LOCATIONS_FILTER,
} from "../actions/types";
import {
  fetchJudgesFilterFailed,
  fetchJudgesFilterSucceeded,
  fetchTrialLocationsFilterFailed,
  fetchTrialLocationsFilterSucceeded,
} from "../actions";

function* fetchJudgesFilter() {
  try {
    const auth_token = yield select(getAuthToken);
    const dog = yield select(getActiveDog);
    const judges = yield call(Api({ auth_token }).fetchJudgesFilter, {
      dog,
    });
    yield put(fetchJudgesFilterSucceeded(judges.data));
  } catch (e) {
    yield put(fetchJudgesFilterFailed(e.message));
  }
}

function* fetchTrialLocationsFilter() {
  try {
    const auth_token = yield select(getAuthToken);
    const dog = yield select(getActiveDog);
    const trialLocations = yield call(
      Api({ auth_token }).fetchTrialLocationsFilter,
      {
        dog,
      }
    );
    yield put(fetchTrialLocationsFilterSucceeded(trialLocations.data));
  } catch (e) {
    yield put(fetchTrialLocationsFilterFailed(e.message));
  }
}

function* trialFilterSaga() {
  yield takeEvery(FETCH_JUDGES_FILTER.REQUEST, fetchJudgesFilter);
  yield takeEvery(
    FETCH_TRIAL_LOCATIONS_FILTER.REQUEST,
    fetchTrialLocationsFilter
  );
}

export default trialFilterSaga;
