import { FETCH_SIZE_CLASSES, CLEAR_SIZE_CLASSES } from "./types";

export const fetchSizeClasses = (organizationId) => ({
  type: FETCH_SIZE_CLASSES.REQUEST,
  payload: { organizationId: organizationId }
});

export const fetchSizeClassesSucceeded = sizeClasses => ({
  type: FETCH_SIZE_CLASSES.SUCCEEDED,
  payload: sizeClasses
});

export const fetchSizeClassesFailed = error => ({
  type: FETCH_SIZE_CLASSES.FAILED,
  payload: error
});

export const clearSizeClasses = (organizationId) => ({
  type: CLEAR_SIZE_CLASSES,
  payload: { organizationId: organizationId }
});