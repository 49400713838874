import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import moment from "moment";
import { castToNestedFocusArray } from "../../helpers/Transformer";
import { AppContent, Card } from "../../common";
import { SubSortableList } from "./SubSortableList";
import { ExerciseSortableList } from "./ExerciseSortableList";
import {
  noteSharedTopic,
  getSharedTopic,
  copySharedTopic,
} from "../../actions";
import { setActiveDog } from "../../actions/ActiveDogActions";

class SharedTrainingTopic extends Component {
  state = {
    showDogList: false,
    exercisesExpanded: true,
    subtopicsExpanded: true,
    showExercises: false,
    showSubtopics: false,
    form: {
      name: "",
      notes: "",
      trainingFocuses: [],
      trainingSubTopics: [],
      exercises: [],
      newSubTopic: "",
    },
  };

  componentDidMount() {
    // Actually get the topic with the id. TODO: Maybe a loader while fetching
    this.props.getSharedTopic(this.props.id);
    var interval = window.setInterval(
      function (scope) {
        if (!scope.props.topics.fetching) {
          const hasExercises = scope.props.topic.exercises.length > 0;
          const hasSubtopics = scope.props.topic.trainingSubTopics.length > 0;
          // Only exercise expansion needs topics in state. Others could be rendered from props.
          scope.setState({
            form: { ...scope.props.topic },
            showExercises: hasExercises,
            showSubtopics: hasSubtopics,
          });
          clearInterval(interval);
        }
      },
      200,
      this
    );
  }

  onExpand = (section) => {
    this.setState({ [section]: !this.state[section] });
  };

  handleSubTopicEdit = (id, e) => {
    e.stopPropagation();
    let topicId = this.props.id;
    this.props.history.push(`/shared/training-topic/${topicId}/${id}`);
  };

  handleExerciseView = (id, e) => {
    e.stopPropagation();
    let topicId = this.props.id;
    this.props.history.push(`/shared/training-topic/${topicId}/exercise/${id}`);
  };

  onExpandExercise = (id) => {
    let exerciseIndex = this.state.form.exercises.findIndex(
      (exercise) => exercise.id === id
    );
    let newExerciseList = [...this.state.form.exercises];
    newExerciseList[exerciseIndex].expanded = !newExerciseList[exerciseIndex]
      .expanded;
    this.setState({ ...this.state.form, exercises: newExerciseList });
  };

  copyToDog = () => {
    const { isAuthenticated } = this.props;
    this.props.noteSharedTopic(this.props.id);
    if (isAuthenticated) {
      this.setState({ showDogList: true });
    } else {
      this.props.history.push("/auth");
    }
  };

  handleCopy = (e, dogId) => {
    e.preventDefault();
    const fromDog = this.props.topic.dogId;
    const toDog = dogId;
    // Setting active dog to one being copied to.
    let dogs = this.props.dogs;
    const dogToActivate = dogs.find((dog) => dog.id === toDog);
    this.props.setActiveDog(dogToActivate);

    // Copying topic
    this.props.copySharedTopic({
      topicId: this.props.topic.share.guid,
      topicDogID: fromDog,
      dogIdToCopy: toDog,
    });

    // Wait for copy to finish and then move to training topic list.
    var interval = window.setInterval(
      function (scope) {
        if (!scope.props.topics.saving) {
          // Resetting sharedTopicId so it will not linger in redux state.
          scope.props.noteSharedTopic(0);
          // Then move to the training list. TODO: Actually go to the topic?
          scope.props.history.push("/training-topics");
          clearInterval(interval);
        }
      },
      200,
      this
    );
  };

  render() {
    const { isAuthenticated, t } = this.props;
    const { showExercises, showSubtopics } = this.state;
    const { name } = this.state.form;

    return (
      <AppContent size="medium">
        <h2 className="text-center mx-3">{name}</h2>

        {this.renderTrainingFocuses()}
        {this.renderNotes()}

        {showExercises ? this.renderExercises() : <div />}

        {showSubtopics ? this.renderSubtopics() : <div />}

        <Card>
          <div className="d-flex justify-content-center">
            {isAuthenticated ? (
              <button className="btn btn-primary" onClick={this.copyToDog}>
                {t("shared.copyToMyDog")}
              </button>
            ) : (
              <button className="btn btn-primary" onClick={this.copyToDog}>
                {t("shared.signInToCopy")}
              </button>
            )}
          </div>
          {this.renderDogList()}
        </Card>
      </AppContent>
    );
  }

  renderTrainingFocuses() {
    const { t } = this.props;
    const { trainingFocuses } = this.state.form;

    return (
      <Card>
        <h2>{t("training.focuses")}</h2>

        <span className="todo-list__focus">
          {castToNestedFocusArray(trainingFocuses).map((focus, i, arr) => (
            <span key={focus.key}>
              {t(`misc.${focus.name}`)}
              {focus.children && focus.children.length > 0 && (
                <span>
                  : {focus.children.map((f) => t(`misc.${f.name}`)).join(", ")}
                </span>
              )}
              {arr.length - 1 === i ? <span /> : <span>, </span>}
            </span>
          ))}
        </span>
      </Card>
    );
  }

  renderNotes() {
    const { t } = this.props;
    const { notes } = this.state.form;

    return (
      <Card>
        <h2>{t("training.notes")}</h2>
        <p className="multiLine">{notes}</p>
      </Card>
    );
  }

  renderSubtopics() {
    const { trainingSubTopics } = this.state.form;
    const { t } = this.props;

    let sortedTrainingSubTopics = trainingSubTopics.sort(
      (a, b) => a.orderNumber - b.orderNumber
    );

    return (
      <Card>
        <h2 onClick={(e) => this.onExpand("subtopicsExpanded")}>
          {t("topics.subtopicsTitle")}
        </h2>
        {this.state.subtopicsExpanded ? (
          <React.Fragment>
            <SubSortableList
              items={sortedTrainingSubTopics}
              onItemEdit={this.handleSubTopicEdit}
              useDragHandle={true}
              t={t}
            />
          </React.Fragment>
        ) : (
          <span className="todo-list__focus">
            {trainingSubTopics.map((subtopic, i, arr) => (
              <span key={subtopic.id}>
                {subtopic.name}
                {arr.length - 1 === i ? <span /> : <span>, </span>}
              </span>
            ))}
          </span>
        )}

        <img
          src={require("../../images/icons/chevron-down.svg")}
          className={`${
            this.state.subtopicsExpanded
              ? "icon center-icon rotated-icon "
              : "icon center-icon"
          }`}
          alt={"expand-contract"}
          onClick={(e) => this.onExpand("subtopicsExpanded")}
        />
      </Card>
    );
  }

  renderExercises() {
    const { t, hasActivePlan } = this.props;
    const { exercises } = this.state.form;

    let sortedExercises = exercises.sort(
      (a, b) => a.orderNumber - b.orderNumber
    );

    return (
      <Card>
        <h2 onClick={(e) => this.onExpand("exercisesExpanded")}>
          {t("topics.exercisesTitle")}
        </h2>
        {this.state.exercisesExpanded ? (
          <React.Fragment>
            <ExerciseSortableList
              items={sortedExercises}
              onItemView={this.handleExerciseView}
              onExpand={this.onExpandExercise}
              useDragHandle={true}
              t={t}
              hasActivePlan={hasActivePlan}
            />
            <p />
          </React.Fragment>
        ) : (
          <span className="todo-list__focus">
            <span>
              {exercises.length} {t("topics.exercisesList")}:{" "}
            </span>
            {exercises.map((exercise, i, arr) => (
              <span key={exercise.id}>
                {exercise.name}
                {arr.length - 1 === i ? <span /> : <span>, </span>}
              </span>
            ))}
          </span>
        )}

        <img
          src={require("../../images/icons/chevron-down.svg")}
          className={`${
            this.state.exercisesExpanded
              ? "icon center-icon rotated-icon "
              : "icon center-icon"
          }`}
          alt={"expand-contract"}
          onClick={(e) => this.onExpand("exercisesExpanded")}
        />
      </Card>
    );
  }

  renderDogList() {
    let dogs = this.props.dogs;
    let show = this.state.showDogList;
    let dogRender = dogs.map((dog) => {
      return (
        <li
          key={dog.id}
          onClick={(e) => this.handleCopy(e, dog.id)}
          className="settings-menu__link"
        >
          {dog.name}
        </li>
      );
    });
    return (
      <React.Fragment>
        {show ? (
          <ul className="list w-50 mx-auto mt-1">{dogRender}</ul>
        ) : (
          <div />
        )}
      </React.Fragment>
    );
  }
}

SharedTrainingTopic.propTypes = {
  t: PropTypes.func,
  history: PropTypes.object,
  auth_token: PropTypes.any,
  isAuthenticated: PropTypes.bool,
  hasActivePlan: PropTypes.bool,
  dogs: PropTypes.array,
  topics: PropTypes.object,
  topic: PropTypes.object,
  id: PropTypes.string,
  noteSharedTopic: PropTypes.func,
  getSharedTopic: PropTypes.func,
  copySharedTopic: PropTypes.func,
  setActiveDog: PropTypes.func,
};

const mapStateToProps = ({ auth, dogs, topics, subscriptions }, props) => {
  const { id } = props.match.params;

  let topic = topics.sharedTopic;

  return {
    isAuthenticated: auth.isAuthenticated,
    dogs: dogs.dogs,
    topics,
    topic,
    id: id,
    hasActivePlan:
      subscriptions.currentPlan &&
      moment(subscriptions.currentPlan.paid_until) > moment(),
  };
};

const SharedTrainingTopicWithTranslate = withTranslation()(SharedTrainingTopic);
export default connect(mapStateToProps, {
  noteSharedTopic,
  getSharedTopic,
  copySharedTopic,
  setActiveDog,
})(SharedTrainingTopicWithTranslate);
