import React, { Component } from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import { withTranslation } from "react-i18next";

import { Container, Icon } from "../common";

const MenuLink = (props) => (
  <li className="menu__item">
    <NavLink
      {...props}
      className={"menu__link " + props.className}
      activeClassName="menu__link--current"
    >
      {props.children}
    </NavLink>
  </li>
);

MenuLink.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any,
};

class Menu extends Component {
  render() {
    if (!this.props.show) return null;
    let t = this.props.t;
    return (
      <nav className="app-menu">
        <Container size="large" noPadding>
          <ul className="menu">
            <MenuLink to="/calendar" exact>
              <Icon icon="calendar" /> {t("menu.calendar")}
            </MenuLink> 

            <MenuLink to="/training-topics">
              <Icon icon="list" /> {t("menu.trainingList")}
            </MenuLink>

            <MenuLink to="/training-diary">
              <Icon icon="noteBook" />
              {t("menu.trainingDiary")}
            </MenuLink>

            <MenuLink to="/trials">
              <Icon icon="medal" /> {t("menu.trials")}
            </MenuLink>
          </ul>
        </Container>
      </nav>
    );
  }
}

Menu.propTypes = {
  show: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(Menu);
