import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import moment from "moment";
import { AppContent, Card } from "../../common";
import {
  updateTrainingSubTopic,
  deleteTrainingSubTopic,
  updateTrainingExerciseNoNotification,
} from "../../actions";
import { ExerciseSortableList } from "./ExerciseSortableList";

class SharedSubTopicView extends Component {
  state = {
    submitted: false,
    errors: {},
    edited: false,
    edit: false,
    editName: false,
    form: {
      name: "",
      exercises: [],
    },
  };

  componentDidMount() {
    // const { id } = this.props.match.params;
    let exercises = this.props.subtopic.exercises;
    if (!this.props.subtopic.exercises) exercises = [];

    exercises = exercises.sort((a, b) => a.orderNumber - b.orderNumber);

    this.setState({
      form: {
        ...this.props.subtopic,
        exercises: exercises,
      },
      edit: true,
    });
  }

  handleGoBack = () => {
    this.props.history.goBack();
  };

  onExpandExercise = (id) => {
    let exerciseIndex = this.state.form.exercises.findIndex(
      (exercise) => exercise.id === id
    );
    let newExerciseList = [...this.state.form.exercises];
    newExerciseList[exerciseIndex].expanded = !newExerciseList[exerciseIndex]
      .expanded;
    this.setState({ ...this.state.form, exercises: newExerciseList });
  };

  handleExerciseView = (id, e) => {
    e.stopPropagation();
    let topicId = this.props.topic.share.guid;
    let subtopicId = this.props.subtopic.id;
    this.props.history.push(
      `/shared/training-topic/${topicId}/${subtopicId}/exercise/${id}`
    );
  };

  render() {
    const { t } = this.props;

    return (
      <AppContent size="medium">
        <button className="trial-back-button" onClick={this.handleGoBack}>
          <strong>&larr;</strong> {t("common.back")}
        </button>
        <p className="py-2" />
        <h2 className="text-center">
          {this.props.topic.name} | {this.state.form.name}
        </h2>

        {this.renderExercises()}
      </AppContent>
    );
  }

  renderExercises() {
    const { exercises } = this.state.form;
    const { t, hasActivePlan } = this.props;

    return (
      <Card>
        <h2>{t("topics.exercisesTitle")}</h2>
        <ExerciseSortableList
          items={exercises}
          onItemView={this.handleExerciseView}
          onExpand={this.onExpandExercise}
          useDragHandle={true}
          t={t}
          hasActivePlan={hasActivePlan}
        />
        <p />
      </Card>
    );
  }
}

SharedSubTopicView.propTypes = {
  t: PropTypes.func,
  history: PropTypes.object,
  topics: PropTypes.object,
  topic: PropTypes.object,
  subtopic: PropTypes.object,
  focusCategories: PropTypes.array,
  hasActivePlan: PropTypes.bool,
  updateTrainingSubTopic: PropTypes.func,
  deleteTrainingSubTopic: PropTypes.func,
  updateTrainingExerciseNoNotification: PropTypes.func,
};

const mapStateToProps = ({ topics, subscriptions }, props) => {
  const { subtopicid } = props.match.params;
  let topic = topics.sharedTopic;
  // If tries to mount when going back, then just gives dummy topic and subtopic to not get errors.
  let subtopic = {
    name: "",
    exercises: [],
  };
  if (topic) {
    subtopic = topic.trainingSubTopics.find((t) => t.id === Number(subtopicid));
  } else {
    topic = {
      name: "",
      id: 1,
    };
  }
  return {
    topic,
    subtopic,
    saving: topics.saving,
    deleting: topics.deleting,
    hasActivePlan:
      subscriptions.currentPlan &&
      moment(subscriptions.currentPlan.paid_until) > moment(),
  };
};

const SharedSubTopicViewWithTranslate = withTranslation()(SharedSubTopicView);

export default connect(mapStateToProps, {
  updateTrainingSubTopic,
  deleteTrainingSubTopic,
  updateTrainingExerciseNoNotification,
})(SharedSubTopicViewWithTranslate);
