import React, { Component } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";

import { Icon } from "../common";
import Logout from "../helpers/Logout";

class SettingsMenu extends Component {
  state = {
    open: false,
  };

  toggle = (e) => {
    this.setState((prevState, props) => {
      return {
        open: !prevState.open,
      };
    });
  };

  close = () => {
    this.setState({ open: false });
  };

  handleLogout = () => {
    Logout();
  };

  render() {
    const toggleState = this.state.open ? "open" : "closed";
    let t = this.props.t;
    let user = this.props.user;
    let isAdmin = false;
    if (user) {
      isAdmin = user.admin;
    }

    return (
      <div
        className={`settings-menu-container settings-menu-container--${toggleState}`}
      >
        <div
          className={`overlay overlay--${toggleState}`}
          onClick={this.close}
        />

        <ul className={`settings-menu settings-menu--${toggleState}`}>
          <li className="settings-menu__item">
            <Link
              to="/profile"
              className="settings-menu__link"
              onClick={this.close}
            >
              {t("settingsMenu.myProfile")}
            </Link>
          </li>

          <li className="settings-menu__item">
            <Link
              to="/dogs"
              className="settings-menu__link"
              onClick={this.close}
            >
              {t("settingsMenu.myDogs")}
            </Link>
          </li>

          <li className="settings-menu__item">
            <Link
              to="/settings"
              className="settings-menu__link"
              onClick={this.close}
            >
              {t("settingsMenu.settings")}
            </Link>
          </li>

          <li className="settings-menu__item">
            <a
              className="settings-menu__link no-margin"
              href="https://www.aginotes.com/support"
              target="_blank"
              rel="noopener noreferrer"
            >
              {t("settingsMenu.support")}
            </a>
          </li>

          <li className="settings-menu__item">
            <Link
              to="/subscription"
              className="settings-menu__link"
              onClick={this.close}
            >
              {t("settingsMenu.subscription")}
            </Link>
          </li>

          {isAdmin && (
            <li className="settings-menu__item">
              <Link
                to="/admin"
                className="settings-menu__link"
                onClick={this.close}
              >
                {t("Admin")}
              </Link>
            </li>
          )}

          <li className="settings-menu__item">
            <p
              className="settings-menu__link no-margin"
              onClick={this.handleLogout}
            >
              {t("settingsMenu.signOut")}
            </p>
          </li>
        </ul>

        <button className="settigs-menu-button" onClick={this.toggle}>
          <Icon icon="bars" />
        </button>
      </div>
    );
  }
}

SettingsMenu.propTypes = {
  t: PropTypes.func,
  user: PropTypes.object,
};

export default withTranslation()(SettingsMenu);
