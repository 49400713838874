import React, { Component } from "react";
import { toast, ToastContainer, Slide } from "react-toastify";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { toastDelay } from "../config/env";

class Notifications extends Component {
  toastId = null;
  componentDidUpdate() {
    const { notification, t } = this.props;
    var timestamp = sessionStorage.getItem(notification.text);
    if (
      notification.text !== "" &&
      !toast.isActive(this.toastId) &&
      (timestamp == null ||
        (timestamp != null && notification.time - timestamp > 1000))
    ) {
      this.toastId = toast(t(notification.text), { type: notification.type });
      sessionStorage.setItem(notification.text, notification.time);
    }
  }

  render() {
    return (
      <ToastContainer
        position={toast.POSITION.BOTTOM_LEFT}
        pauseOnHover
        closeOnClick
        autoClose={toastDelay}
        transition={Slide}
      />
    );
  }
}

Notifications.propTypes = {
  notification: PropTypes.object,
  t: PropTypes.func,
};

const mapStateToProps = ({ notification }) => ({
  notification,
});

export default connect(mapStateToProps)(withTranslation()(Notifications));
