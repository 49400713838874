import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import moment from "moment";
import ReactPlayer from "react-player";
import InstagramEmbed from "react-instagram-embed";
import { castToNestedFocusArray } from "../../helpers/Transformer";
import { updateTrainingExercise } from "../../actions";
import { AppContent, Card, Image } from "../../common";

class SharedExerciseView extends Component {
  handleGoBack = () => {
    this.props.history.goBack();
  };

  render() {
    const { t, exercise } = this.props;
    let topicName = this.props.topic.name;
    if (this.props.subtopic) {
      topicName = topicName + " | " + this.props.subtopic.name;
    }

    return (
      <AppContent size="medium">
        <button className="trial-back-button" onClick={this.handleGoBack}>
          <strong>&larr;</strong> {t("common.back")}
        </button>
        <div className="d-flex justify-content-end">
          <p className="py-1" />
          {/* <button className="btn btn-info">{t("common.share")}!</button> */}
        </div>
        <Card>
          <h1 className="text-center">{exercise.name}</h1>
          <h2>{topicName}</h2>
          <p>
            <b>{t("training.focuses")}: </b>
            <span>
              {castToNestedFocusArray(this.props.topic.trainingFocuses).map(
                (focus, i, arr) => (
                  <span key={focus.key}>
                    {t(`misc.${focus.name}`)}
                    {focus.children && focus.children.length > 0 && (
                      <span>
                        :{" "}
                        {focus.children
                          .map((f) => t(`misc.${f.name}`))
                          .join(", ")}
                      </span>
                    )}
                    {arr.length - 1 === i ? <span /> : <span>, </span>}
                  </span>
                )
              )}
            </span>
          </p>
          <p />
          <h3>{t("training.notes")}</h3>
          <p className="multiLine">{exercise.notes}</p>
          {exercise.courseMapVariants &&
            exercise.courseMapVariants.thumbnail && (
              <h3>{t("training.courseMap")}</h3>
            )}
          {exercise.courseMapVariants && (
            <div className="d-flex justify-content-center">
              <div className="d-block h-auto">
                <Image src={exercise.courseMapVariants.hd} />
              </div>
            </div>
          )}
          {exercise.videoUrl && <h3>{t("training.videoUrl")}</h3>}
          {exercise.videoUrl &&
            exercise.videoUrl.indexOf("instagram") === -1 && (
              <ReactPlayer url={exercise.videoUrl} controls width="100%" />
            )}
          {exercise.videoUrl &&
            exercise.videoUrl.indexOf("instagram") !== -1 && (
              <InstagramEmbed url={exercise.videoUrl} />
            )}
          <p />
        </Card>
      </AppContent>
    );
  }
}

SharedExerciseView.propTypes = {
  t: PropTypes.func,
  history: PropTypes.object,
  auth_token: PropTypes.any,
  topic: PropTypes.object,
  subtopic: PropTypes.object,
  exercise: PropTypes.object,
  number: PropTypes.number,
};

const mapStateToProps = ({ activeDog, topics, auth, subscriptions }, props) => {
  // Have to find the exercise if it exists from either the topic or the subtopic.
  // Extra checks that they have been found incase mounting on wrong page without correct props.
  const { subtopicid, id } = props.match.params;
  let topic = topics.sharedTopic;
  let subtopic = null;
  let exercise = {
    name: "",
    notes: "",
    id: 1,
    trainedExercises: [],
  };
  let number = 1;
  if (topic && subtopicid) {
    // Exercise belongs to subtopic.
    subtopic = topic.trainingSubTopics.find(
      (st) => st.id === Number(subtopicid)
    );
    if (subtopic.exercises) {
      exercise = subtopic.exercises.find((ex) => ex.id === Number(id));
      number = subtopic.exercises.length + 1;
    }
  } else if (topic && !subtopicid) {
    // Exercise belongs to topic.
    if (topic && topic.exercises) {
      exercise = topic.exercises.find((ex) => ex.id === Number(id));
      number = topic.exercises.length + 1;
    }
  } else {
    // If tries to mount when going back, then just gives dummy topic to not get errors.
    topic = {
      name: "",
      id: 1,
    };
    exercise = {
      name: "",
      notes: "",
      id: 1,
      trainedExercises: [],
    };
  }

  return {
    dog: activeDog,
    topic,
    subtopic,
    exercise,
    number,
    saving: topics.saving,
    error: topics.error,
    auth_token: auth.authToken,
    hasActivePlan:
      subscriptions.currentPlan &&
      moment(subscriptions.currentPlan.paid_until) > moment(),
  };
};

const SharedExerciseViewWithTranslate = withTranslation()(SharedExerciseView);
export default connect(mapStateToProps, { updateTrainingExercise })(
  SharedExerciseViewWithTranslate
);
